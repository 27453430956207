import { defineStore } from 'pinia';
import { computed, Ref, ref } from 'vue';

import { Track } from '@/data/datatypes/Track';
import { DeployedApp, DeployedAppWithRelease, ReleasedApp } from '@/data/version/DeployedApp';

import pinia from '..';
import { asRecord, patchObject, setOrPatchObject } from '../StoreHelper';
import { useTracksStore } from '../Tracks';
import { useReleasedAppsStore } from './ReleasedApps';

export const useDeployedAppsStore = defineStore('DeployedApps', () => {
  const releasedAppsStore = useReleasedAppsStore(pinia);
  const trackStore = useTracksStore(pinia);

  const deployedApps: Ref<Record<string, Record<string, DeployedApp>>> = ref({});

  function setDeployedApps(updatedApps: DeployedApp[]): void {
    const updated: Record<string, Record<string, DeployedApp>> = {};
    updatedApps.forEach(app => {
      if (!updated[app.appId]) {
        updated[app.appId] = {};
      }
      updated[app.appId][app.environmentId] = app;
    });
    patchObject(deployedApps.value, updated);
  }

  function setDeployedApp(app: DeployedApp): void {
    setOrPatchObject(deployedApps.value, app.appId, asRecord(app));
  }

  function removeDeployedApp(details: { appId: string, environmentId: string }) {
    delete deployedApps.value[details.appId][details.environmentId];
    if (Object.keys(deployedApps.value[details.appId]).length === 0) {
      delete deployedApps.value[details.appId];
    }
  }

  const availableDeployedApps: Ref<DeployedAppWithRelease[]> = computed(() => {
    const availableApps: DeployedApp[] = [];
    const allAppIds = Object.keys(deployedApps.value);
    allAppIds.forEach(appId => {
      const environmentIds = Object.keys(deployedApps.value[appId]);
      environmentIds.forEach(envId => {
        availableApps.push(deployedApps.value[appId][envId]);
      });
    });
    const availableDeploedApps: DeployedAppWithRelease[] = [];
    availableApps.forEach(app => {
      const released = releasedAppsStore.releasedApps[app.currentReleaseId];
      if (released) {
        const deployedRelease: ReleasedApp = Object.assign({}, released, { accessRules: app.accessRules });
        availableDeploedApps.push(Object.assign({}, app, { currentRelease: deployedRelease }));
      }
    });
    return availableDeploedApps;
  });

  const tracksByEnvironment: Ref<Record<string, Track[]>> = computed(() => {
    const result: Record<string, Track[]> = {};
    trackStore.allTracks.forEach(track => {
      if (track.environmentId) {
        if (!result[track.environmentId]) {
          result[track.environmentId] = [];
        }
        result[track.environmentId].push(track);
      }
    });
    return result;
  });

  return {
    availableDeployedApps,
    deployedApps,
    setDeployedApps,
    setDeployedApp,
    removeDeployedApp,
    tracksByEnvironment,
  };
});
