import { ChatMessageContent } from '@/data/datatypes/chat/ChatMessageContent';

import OpenGraph from './OpenGraph';

export enum ChatMessageStatus {
  ACTIVE = 'ACTIVE',
  EDITED = 'EDITED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
}

export interface ChatMessageDetails {
  date: number;
  trackId: string;
  chatMessageContent: ChatMessageContent;
  senderId?: string;
  // TODO: FEN-2631 legacy - remove shortly
  content?: string;
  event?: boolean;
  entryId?: string;
  parentMessageId?: string;
  guestId?: string;
  guestColour?: string;
  source?: string;
  senderName?: string;
  senderImageLink?: string;
  lastUpdated?: number;
  // These are added in by the ChatHistory component during rendering
  continuation?: boolean;
  newDay?: boolean;
  openGraphItems?: OpenGraph[];
  entryLinks?: string[];
  fromWorkflow?: boolean;
  isPlaceholder?: boolean; // This is set in the client when adding a temporary placeholder message
}

export interface ChatMessage extends ChatMessageDetails {
  id: string;
  status: ChatMessageStatus;
}

export const CHAT_MESSAGE_PAGE_SIZE: number = 120;
