import anchorme from 'anchorme';
import _ from 'lodash';
import log from 'loglevel';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import Integrations from '@/data/config/Integrations';
import { ChatHistoryItem } from '@/data/datatypes/chat/ChatHistoryItem';
import {
  CHAT_MESSAGE_PAGE_SIZE,
  ChatMessage,
  ChatMessageDetails,
  ChatMessageStatus,
} from '@/data/datatypes/chat/ChatMessage';
import { ChatMessageContent } from '@/data/datatypes/chat/ChatMessageContent';
import { ChatMessagePartType } from '@/data/datatypes/chat/ChatMessageContentType';
import { ChatMessagePart } from '@/data/datatypes/chat/ChatMessagePart';
import { LoadMoreChatMessagesResponse } from '@/data/datatypes/chat/LoadMoreChatMessagesResponse';
import OpenGraph from '@/data/datatypes/chat/OpenGraph';
import { isAStagedFile, StagedFile } from '@/data/datatypes/chat/StagedFile';
import { EmojiReactionPayload } from '@/data/datatypes/EmojiReactionPayload';
import { NlpCommandConfirmationRequest, NlpCommandRequest, NlpCommandResponse } from '@/data/datatypes/NlpCommand';
import { Track, TrackType } from '@/data/datatypes/Track';
import { EntryType, TrackEntry } from '@/data/datatypes/TrackEntry';
import { FullUserDetails } from '@/data/datatypes/UserDetails';
import UserToken from '@/data/datatypes/UserToken';
import { areDatesSameDay, compareMessages, MESSAGE_EDIT_WINDOW } from '@/data/helpers/ChatHelper';
import { BinaryFileUploadPayload, CancelCall } from '@/data/helpers/FileHelper';
import { convertWikiUrlToFender, isWikiUrl } from '@/data/helpers/KnowledgeBaseURLHelper';
import { validateUrl } from '@/data/helpers/UrlHelper';
import DataWorker from '@/data/storage/DataWorker';
import { ObjectStoreHelper } from '@/data/storage/ObjectStoreSync';
import router from '@/router';
import RouteNames from '@/router/RouteNames';
import { ChatMessageState, ChatViewPermissions, SendChatMessagePayload } from '@/stores/ChatMessages.types';
import pinia from '@/stores/index';
import { useTrackEntriesStore } from '@/stores/TrackEntries';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

export const useChatMessagesStore = defineStore('ChatMessages', () => {
  // This looks for either a keycapped number/hash/asterisk, or an emoji character optionally followed by a skin tone
  // modifier optionally followed by repetitions of
  //   [zero-width joiner | [zero-width joiner | variation selector], modifying emoji character].
  // It doesn't handle country flags but they're disabled in our emoji picker anyway.
  // eslint-disable-next-line vue/max-len
  const emojiRegex = new RegExp(/(?:[0-9#*](?:\uFE0E|\uFE0F)\u20E3)|(?:\p{Extended_Pictographic}(?:\uD83C\uDFFB|\uD83C\uDFFC|\uD83C\uDFFD|\uD83C\uDFFE|\uD83C\uDFFF)*(?:(?:\uFE0E|\uFE0F)|(?:(?:\u200D|\uFE0E|\uFE0F)\p{Extended_Pictographic})*)*)/gu);

  // This is the ms timeout after which new subsequent messages from the same sender are no longer considered
  // a continuation, but instead a new message (shows the avatar and name again).
  // 3 minutes.
  const MESSAGE_CONTINUATION_TIMEOUT: number = 180_000;

  const tracksStore = useTracksStore(pinia);

  const stateByTrack: Ref<Record<string, ChatMessageState>> = ref({});
  const ogpData: Ref<Record<string, Promise<OpenGraph>>> = ref({});
  const tracksWithFullChatHistory: Ref<string[]> = ref([]);
  const entriesWithFullCommentHistory: Ref<string[]> = ref([]);
  const editableMessages: Ref<Record<string, boolean>> = ref({});
  const messagesBeingEdited: Ref<Record<string, boolean>> = ref({});
  const allChatTrackIds: Ref<string[]> = ref([]);
  const lastSeenMeetingChatMessageDate: Ref<Record<string, number>> = ref({});

  const chatTrackIds: ComputedRef<string[]> = computed(() => {
    // remove any duplicates
    return Array.from(new Set(allChatTrackIds.value));
  });

  const stateForActiveTrack: ComputedRef<ChatMessageState | undefined> = computed(() => {
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && stateByTrack.value[trackId]) {
      return stateByTrack.value[trackId];
    }
    return undefined;
  });

  const chatMessagesAndEntriesForChatTracks: ComputedRef<Record<string, ChatHistoryItem[]>> = computed(() => {
    const trackEntriesStore = useTrackEntriesStore(pinia);
    const messagesAndEntriesByTrackId: Record<string, ChatHistoryItem[]> = {};
    for (const trackId of chatTrackIds.value) {
      const messageState: ChatMessageState | undefined = stateByTrack.value[trackId];
      if (!messageState || !messageState.trackChatMessagesInitialised) {
        Vue.set(messagesAndEntriesByTrackId, trackId, []);
        continue;
      }

      const entries: TrackEntry[] = trackEntriesStore.entries[trackId]
        ? Object.values(trackEntriesStore.entries[trackId]) : [];

      let oldestMessageTime: number = 0;
      const messages: ChatHistoryItem[] = [];
      messages.push(...messageState.orderedTrackChatMessages
        .map((chatMessage: ChatMessage) => {
          if (oldestMessageTime === 0 || chatMessage.date < oldestMessageTime) {
            oldestMessageTime = chatMessage.date;
          }
          return { id: chatMessage.id, chatMessage, status: chatMessage.status, lastUpdated: chatMessage.lastUpdated };
        })
      );
      const fullHistoryRetrieved: boolean = hasFullHistoryBeenRetrieved.value(trackId);
      messages.push(...entries
        .filter((entry: TrackEntry) => {
          return !entry.defaultEntry && !entry.chatMessageId && entry.created && !isEntryExcludedType(entry, entries) &&
            (entry.created >= oldestMessageTime || fullHistoryRetrieved);
        })
        .map((entry: TrackEntry) => {
          const message: ChatHistoryItem = {
            id: entry.id,
            entry,
            openGraphItems: entry.openGraphItems,
            status: ChatMessageStatus.ACTIVE
          };
          return message;
        })
      );
      messages.sort((historyItem1: ChatHistoryItem, historyItem2: ChatHistoryItem) => {
        const date1: number = historyItem1.chatMessage?.date ?? historyItem1.entry?.created ?? 0;
        const date2: number = historyItem2.chatMessage?.date ?? historyItem2.entry?.created ?? 0;
        return date1 - date2;
      });
      // TODO: Do we need to pass in other params? Possibly not as the messages will have already been processed
      setHistoryItemContinuationInfo(messages, false, undefined, undefined, undefined, undefined,
        stateByTrack.value[trackId]);
      Vue.set(messagesAndEntriesByTrackId, trackId, messages);
    }
    return messagesAndEntriesByTrackId;
  });

  const isWaitingForMessages: ComputedRef<(trackId: string, entryId: string | null) => boolean> = computed(() => {
    return (trackId: string, entryId: string | null): boolean => {
      if (trackId && stateByTrack.value[trackId]) {
        const messageState: ChatMessageState = stateByTrack.value[trackId];
        return entryId ? messageState.waitingForComments : messageState.waitingForMessages;
      }
      return false;
    };
  });

  const hasFullHistoryBeenRetrieved: ComputedRef<(trackId: string, entryId?: string) => boolean> = computed(() => {
    return (trackId: string, entryId?: string) => {
      if (entryId) {
        return entriesWithFullCommentHistory.value.indexOf(entryId) >= 0;
      } else {
        return tracksWithFullChatHistory.value.indexOf(trackId) >= 0;
      }
    };
  });

  const canViewCurrentChats: ComputedRef<ChatViewPermissions> = computed(() => {
    return {
      activeTrackId: tracksStore.activeTrackId,
      canViewActiveTrackChat: tracksStore.currentUserCanViewActiveTrackChat,
      canViewMeetingChat: tracksStore.currentUserCanViewMeetingChat
    };
  });

  function setTrackChatMessagesInitialised(details: { trackId: string; initialised: boolean }): void {
    getOrCreateTrackState(details.trackId).trackChatMessagesInitialised = details.initialised;
  }

  function setEntryCommentsInitialised(details: { trackId: string; entryId: string; initialised: boolean }): void {
    const messageState: ChatMessageState = getOrCreateTrackState(details.trackId);
    Vue.set(messageState.entriesWithCommentsInitalised, details.entryId, details.initialised);
  }

  function setUsersTyping(
    details: { trackId: string; usersTyping: Array<{ userId: string; timestamp: number }> }): void {
    const messageState: ChatMessageState = getOrCreateTrackState(details.trackId);
    for (const userTyping of details.usersTyping) {
      Vue.set(messageState.usersTyping, userTyping.userId, userTyping.timestamp);
    }
    // Timeout the typing state after 4 seconds
    setTimeout(() => {
      for (const userTyping of details.usersTyping) {
        if (messageState.usersTyping[userTyping.userId] &&
          messageState.usersTyping[userTyping.userId] <= userTyping.timestamp) {
          Vue.delete(messageState.usersTyping, userTyping.userId);
        }
      }
    }, 4000);
  }

  function clearUserTyping(details: { trackId: string | null; userId: string }): void {
    if (!details.trackId) {
      return;
    }
    const messageState: ChatMessageState = getOrCreateTrackState(details.trackId);
    if (messageState.usersTyping[details.userId]) {
      Vue.delete(messageState.usersTyping, details.userId);
    }
  }

  function setTrackChatMessages(details: { messages: ChatMessage[]; fullRefresh: boolean }): void {
    const messagesByTrackId: Record<string, ChatMessage[]> = {};
    for (const message of details.messages) {
      if (!messagesByTrackId[message.trackId]) {
        messagesByTrackId[message.trackId] = [];
      }
      messagesByTrackId[message.trackId].push(message);
    }
    for (const trackId of Object.keys(messagesByTrackId)) {
      const messageState: ChatMessageState = getOrCreateTrackState(trackId);
      const parentMessages: ChatMessage[] = setRepliesAndGetParentMessages(messageState, messagesByTrackId[trackId],
        details.fullRefresh, editableMessages.value);
      if (parentMessages.length) {
        setMessagesInState(parentMessages, details.fullRefresh, trackId, messageState,
          'orderedTrackChatMessages', messageState.placeholderMessages);
      }
    }
  }

  function setComments(details: { messages: ChatMessage[]; fullRefresh: boolean; entryId?: string; trackId?: string }):
    void {
    if (details.entryId && details.trackId) {
      const messageState: ChatMessageState = getOrCreateTrackState(details.trackId);
      const parentMessages: ChatMessage[] = setRepliesAndGetParentMessages(messageState,
        details.messages, details.fullRefresh, editableMessages.value);
      if (parentMessages.length) {
        setMessagesInState(parentMessages, details.fullRefresh, details.trackId,
          messageState.orderedCommentsByEntry, details.entryId, messageState.placeholderMessages);
      }
    } else {
      const messagesByTrackAndEntryId: Record<string, Record<string, ChatMessage[]>> = {};
      for (const message of details.messages) {
        if (message.entryId) {
          if (!messagesByTrackAndEntryId[message.trackId]) {
            messagesByTrackAndEntryId[message.trackId] = {};
          }
          if (!messagesByTrackAndEntryId[message.trackId][message.entryId]) {
            messagesByTrackAndEntryId[message.trackId][message.entryId] = [];
          }
          messagesByTrackAndEntryId[message.trackId][message.entryId].push(message);
        }
      }
      for (const trackId of Object.keys(messagesByTrackAndEntryId)) {
        const messageState: ChatMessageState = getOrCreateTrackState(trackId);
        for (const entryId of Object.keys(messagesByTrackAndEntryId[trackId])) {
          const parentMessages: ChatMessage[] = setRepliesAndGetParentMessages(messageState,
            messagesByTrackAndEntryId[trackId][entryId], details.fullRefresh, editableMessages.value);
          if (parentMessages.length) {
            setMessagesInState(parentMessages, details.fullRefresh, trackId, messageState.orderedCommentsByEntry,
              entryId, messageState.placeholderMessages);
          }
        }
      }
    }
  }

  function setNewChatMessageOrComment(message: ChatMessage): void {
    const messageState: ChatMessageState = getOrCreateTrackState(message.trackId);
    const parentMessages: ChatMessage[] = setRepliesAndGetParentMessages(messageState, [message], false,
      editableMessages.value);
    if (parentMessages.length) {
      if (message.entryId) {
        setMessagesInState(parentMessages, false, message.trackId, messageState.orderedCommentsByEntry,
          message.entryId, messageState.placeholderMessages);
      } else {
        setMessagesInState(parentMessages, false, message.trackId, messageState, 'orderedTrackChatMessages',
          messageState.placeholderMessages);
      }
    }
  }

  function setWaitingForMessages(details: { trackId: string; entryId: string | null; waiting: boolean }): void {
    const messageState: ChatMessageState = getOrCreateTrackState(details.trackId);
    if (details.entryId) {
      messageState.waitingForComments = details.waiting;
    } else {
      messageState.waitingForMessages = details.waiting;
    }
  }

  function setSelectedMessageId(details: { trackId: string; messageId: string | null }): void {
    getOrCreateTrackState(details.trackId).selectedMessageId = details.messageId;
  }

  function setFullChatHistoryRetrieved(details: { trackId: string; entryId: string | null }): void {
    if (details.entryId) {
      entriesWithFullCommentHistory.value.push(details.entryId);
    } else {
      tracksWithFullChatHistory.value.push(details.trackId);
    }
  }

  function startEditing(messageId: string): void {
    Vue.set(messagesBeingEdited.value, messageId, true);
  }

  function stopEditing(messageId: string): void {
    Vue.delete(messagesBeingEdited.value, messageId);
  }

  function setChatMessageToView(
    details: { trackId: string; messageId: string | null; parentMessageId?: string }): void {
    getOrCreateTrackState(details.trackId).chatMessageToView = {
      messageId: details.messageId,
      parentMessageId: details.parentMessageId
    };
  }

  function addChatTrackId(trackId: string): void {
    allChatTrackIds.value.push(trackId);
  }

  function removeChatTrackId(trackId: string): void {
    const index: number = allChatTrackIds.value.indexOf(trackId);
    if (index > -1) {
      allChatTrackIds.value.splice(index, 1);
    }
  }

  function setLastSeenMeetingChatMessageDate(details: { entryId: string; lastSeenMessageDate: number }): void {
    Vue.set(lastSeenMeetingChatMessageDate.value, details.entryId, details.lastSeenMessageDate);
  }

  async function loadMoreChatMessages(payload: { trackId: string | null; entryId?: string, fullRefresh?: boolean }):
    Promise<LoadMoreChatMessagesResponse> {
    let response: LoadMoreChatMessagesResponse = {
      fullHistoryRetrieved: false,
      messages: [],
    };
    if (!payload.trackId) {
      return response;
    }
    const messageState: ChatMessageState = getOrCreateTrackState(payload.trackId);
    try {
      const existingMessages: ChatMessage[] = (payload.entryId ? messageState.orderedCommentsByEntry[payload.entryId]
        : messageState.orderedTrackChatMessages) || [];
      const currentOldestMessageDate: number | undefined = existingMessages.length && !payload.fullRefresh
        ? existingMessages[0].date : undefined;

      // Load more messages from the object store.
      response = await ObjectStoreHelper.instance().loadMoreChatMessages(
        payload.trackId, payload.entryId || null, currentOldestMessageDate);
      response.messages.sort(compareMessages);
    } catch (error) {
      log.error(error);
      response.fullHistoryRetrieved = true;
    }
    return response;
  }

  async function ensureFullPageOfMessagesIsLoaded(
    payload: { trackId: string | null; entryId?: string, fullRefresh?: boolean }): Promise<void> {
    if (payload.trackId) {
      const messageState: ChatMessageState = getOrCreateTrackState(payload.trackId);
      const existingMessages: ChatMessage[] = (payload.entryId ? messageState.orderedCommentsByEntry[payload.entryId]
        : messageState.orderedTrackChatMessages) || [];
      if (existingMessages.length === 0 || payload.fullRefresh) {
        await loadMoreChatMessages(payload);
      } else if (existingMessages.length < CHAT_MESSAGE_PAGE_SIZE) {
        // We have some messages already, so don't bother waiting
        loadMoreChatMessages(payload);
      }
    }
  }

  function addPlaceholderChatMessageOrComment(message: ChatMessageDetails): void {
    const userStore = useUserStore(pinia);
    const placeholderMessageId: string = uuid();
    const guestTokenForMeeting: UserToken | undefined = userStore.guestTokenForCurrentMeeting;
    const currentUserId: string | null = guestTokenForMeeting ? null : userStore.currentUserId;
    // Deep-copy the message to avoid modifying the payload that we're sending to the server.
    const placeholderContent: ChatMessageContent = {
      parts: message.chatMessageContent.parts.map(part => Object.assign({}, part)),
      plain: message.chatMessageContent.plain,
    };
    const placeholderMessage: ChatMessage = Object.assign({
      id: placeholderMessageId,
      status: ChatMessageStatus.ACTIVE,
      senderId: currentUserId,
      guestId: guestTokenForMeeting?.guestAccessId,
      isPlaceholder: true,
    }, message);
    placeholderMessage.chatMessageContent = placeholderContent;
    if (guestTokenForMeeting) {
      placeholderMessage.senderName = userStore.guestName ?? undefined;
      placeholderMessage.guestColour = guestTokenForMeeting.guestColour;
    }

    const messageState: ChatMessageState = getOrCreateTrackState(message.trackId);
    messageState.placeholderMessages.push(placeholderMessage);
    setNewChatMessageOrComment(placeholderMessage);
  }

  function handleSendMessageFailure(message: ChatMessageDetails): void {
    const messageState: ChatMessageState = getOrCreateTrackState(message.trackId);
    const index: number = messageState.placeholderMessages.findIndex((placeHolderMessage: ChatMessage) =>
      isPlaceholderForMessage(placeHolderMessage, message));
    if (index > -1) {
      messageState.placeholderMessages[index].status = ChatMessageStatus.FAILED;
      messageState.placeholderMessages.splice(index, 1);
    }
  }

  async function sendChatMessage(payload: SendChatMessagePayload): Promise<ChatMessage | undefined> {
    if (!('id' in payload.message) && payload.message.chatMessageContent.plain) {
      // Display the new message immediately.
      addPlaceholderChatMessageOrComment(payload.message);
    }

    const MAX_RETRY_ATTEMPTS: number = 2;
    let sentMessage: ChatMessage | undefined;
    for (let attempt = 0; attempt < MAX_RETRY_ATTEMPTS && !sentMessage; ++attempt) {
      try {
        if (payload.guestId) {
          sentMessage = await DataWorker.instance().dispatch('ChatMessages/sendChatMessageAsGuest',
            payload.message, payload.guestId);
        } else {
          sentMessage = await DataWorker.instance().dispatch('ChatMessages/sendChatMessage', payload.message);
        }

        // Make sure the message is added to the store before we process entries as we don't want the edited
        // message getting into the store before the original = #8303
        if (sentMessage) {
          setNewChatMessageOrComment(sentMessage);
        }
      } catch (error) {
        log.error(error);
      }
    }
    if (sentMessage) {
      // Upload any attachments
      if (payload.stagedFileDetails && payload.stagedFileDetails.files.length) {
        payload.stagedFileDetails.uploadInProgress = true;
        const onFinishedCallback = () => {
          if (payload.stagedFileDetails) {
            payload.stagedFileDetails.uploadInProgress = false;
          }
        };

        const progressCallback = (fileName: string, percentComplete: number, fileId?: string) => {
          for (const stagedFile of payload.stagedFileDetails?.files || []) {
            if (isAStagedFile(stagedFile) && stagedFile.uploadPercentage && stagedFile.id + '' === fileId) {
              stagedFile.uploadPercentage = percentComplete;
              break;
            }
          }
        };

        const cancelCall = new CancelCall();
        const filteredFiles: StagedFile[] = payload.stagedFileDetails.files
          .filter((obj: StagedFile | TrackEntry) => isAStagedFile(obj)) as StagedFile[];
        const files = filteredFiles.map((fileObject: StagedFile) => fileObject.file);
        const fileIds = filteredFiles.map((fileObject: StagedFile) => '' + fileObject.id);

        // Put the files in an upload state straight away, rather than waiting for first progress callback
        for (const stagedFile of payload.stagedFileDetails.files) {
          if (isAStagedFile(stagedFile)) {
            stagedFile.uploadPercentage = 1;
          }
        }

        const uploadFilesPayload: BinaryFileUploadPayload = {
          trackId: sentMessage.trackId,
          files,
          onFinishedCallback,
          progressCallback,
          cancelCall,
          fileIds,
          chatMessageId: sentMessage.id
        };

        const trackEntriesStore = useTrackEntriesStore();
        await trackEntriesStore.uploadFiles(uploadFilesPayload);
      }
    } else {
      // Sending the message failed.
      handleSendMessageFailure(payload.message);
    }
    return sentMessage;
  }

  async function editChatMessage(
    details: { message: ChatMessage, updatedContent: ChatMessageContent, guestId?: string })
    : Promise<ChatMessage | undefined> {
    // Keep an unprocessed version to send to the server
    const updatedMessage: ChatMessage = Object.assign({}, details.message,
      { chatMessageContent: details.updatedContent, entryLinks: undefined, openGraphItems: undefined });
    // Update the existing message object to reflect the change immediately.
    // Don't change the date so that the placeholder gets replaced with the server response.
    const processedOgData: OpenGraph[] = [];
    const processedParts = processPartList(details.updatedContent.parts, processedOgData, details.message.trackId,
      ogpData.value);
    details.message.status = ChatMessageStatus.EDITED;
    details.message.chatMessageContent = {
      parts: processedParts.parts,
      plain: details.updatedContent.plain,
    };
    Vue.set(details.message, 'entryLinks', processedParts.entryLinks);
    Vue.set(details.message, 'openGraphItems', processedOgData);
    return await sendChatMessage({
      message: updatedMessage,
      guestId: details.guestId,
    });
  }

  async function updateLastTyping(): Promise<void> {
    try {
      const trackId: string | null = tracksStore.activeTrackId;
      if (trackId) {
        await DataWorker.instance().dispatch('ChatMessages/updateLastTyping', trackId);
      }
    } catch (error) {
      log.error(error);
    }
  }

  async function deleteChatMessage(details: { message: ChatMessage; guestId?: string }): Promise<void> {
    try {
      // Set the status as deleted immediately. It will get overwritten by the proper response from the server.
      details.message.status = ChatMessageStatus.DELETED;
      await DataWorker.instance().dispatch('ChatMessages/deleteChatMessage', details.message.trackId,
        details.message.id, details.guestId);
    } catch (error) {
      log.error(error);
    }
  }

  async function shareChatMessage(
    details: { messageToShare: ChatMessage; accompanyingMessage?: ChatMessageContent, emailsToShareWith?: string[] })
    : Promise<ChatMessage | undefined> {
    let sentMessage: ChatMessage | undefined;
    try {
      sentMessage = await DataWorker.instance().dispatch('ChatMessages/shareChatMessage',
        details.messageToShare.trackId, details.messageToShare.id,
        details.accompanyingMessage, details.emailsToShareWith);
      if (sentMessage) {
        setTrackChatMessages({ messages: [sentMessage], fullRefresh: false });
      }
      // If the message is being shared outside the workspace then load the target (if necessary) and navigate to it.
      if (sentMessage && details.emailsToShareWith && details.emailsToShareWith.length > 0) {
        if (!isTrackAccessible(sentMessage.trackId)) {
          await DataWorker.instance().dispatch('Tracks/refreshTracks');
        }
        router.push({ name: RouteNames.PRIVATE_CHAT_ROUTE_NAME, params: { trackId: sentMessage.trackId } });
      }
    } catch (error) {
      log.error(error);
    }
    return sentMessage;
  }

  async function toggleChatMessageReaction(payload: EmojiReactionPayload): Promise<void> {
    try {
      await DataWorker.instance().dispatch('ChatMessages/toggleChatMessageReaction', payload);
    } catch (error) {
      log.error(error);
    }
  }

  async function loadChatHistoryIncludingMessage(details: { messageId: string; trackId: string }): Promise<void> {
    const messageState: ChatMessageState = getOrCreateTrackState(details.trackId);
    const currentOldestMessageDate: number | undefined = (messageState.orderedTrackChatMessages.length
      ? messageState.orderedTrackChatMessages[0].date : undefined);
    await ObjectStoreHelper.instance().loadChatHistoryIncludingMessage(details.trackId, details.messageId,
      currentOldestMessageDate);
  }

  async function initialiseCommentsForEntry(entry: TrackEntry): Promise<void> {
    const messageState: ChatMessageState | undefined = stateByTrack.value[entry.trackId];
    if (!messageState || messageState.entriesWithCommentsInitalised[entry.id] == null) {
      // Set as false so that we don't do a second request while the first is still in progress.
      setEntryCommentsInitialised({ trackId: entry.trackId, entryId: entry.id, initialised: false });
      await DataWorker.instance().initialiseCommentsForEntryId(entry.id, entry.trackId);
    }
  }

  function handleNewChatTrackId(trackId: string): void {
    if (trackId) {
      const alreadyOpen: boolean = (allChatTrackIds.value.indexOf(trackId) > -1);
      // The list can contain duplicates, because someone might have a chat head open for a track that they also
      // have open as the active track, meeting track, or both.
      addChatTrackId(trackId);
      if (!alreadyOpen) {
        // Tell the shared worker about it
        const messageState: ChatMessageState = getOrCreateTrackState(trackId);
        DataWorker.instance().setNewChatTrackId(trackId, messageState.trackChatMessagesInitialised);
      }
    }
  }

  function handleClosedChatTrackId(trackId: string): void {
    if (trackId) {
      removeChatTrackId(trackId);
      if (allChatTrackIds.value.indexOf(trackId) === -1) {
        // There are no more instances of this track open, so tell the shared worker to forget about it
        DataWorker.instance().clearChatTrackId(trackId);
      }
    }
  }

  async function getOpenGraphData(details: { url: string; trackId: string }): Promise<OpenGraph> {
    return await getOgpData(details.url, details.trackId, ogpData.value);
  }

  async function performNlpCommand(
    payload: { trackId: string; request: NlpCommandRequest }): Promise<NlpCommandResponse | undefined> {
    try {
      return await DataWorker.instance().dispatch('ChatMessages/performNlpCommand', payload.trackId, payload.request);
    } catch (e) {
      log.error(e);
    }
  }

  async function confirmNlpCommand(
    payload: { trackId: string; request: NlpCommandConfirmationRequest }): Promise<NlpCommandResponse | undefined> {
    try {
      return await DataWorker.instance().dispatch('ChatMessages/confirmNlpCommand', payload.trackId, payload.request);
    } catch (e) {
      log.error(e);
    }
  }

  async function requestGuestCatchup(): Promise<void> {
    try {
      await DataWorker.instance().dispatch('ChatMessages/requestGuestCatchup');
    } catch (e) {
      log.error(e);
    }
  }

  function handleActiveTrackChatViewPermissionChanged(trackId: string): void {
    DataWorker.instance().setNewChatTrackId(trackId, false, true);
  }

  async function handleMeetingChatViewPermissionChanged(meetingEntry: TrackEntry): Promise<void> {
    await DataWorker.instance().initialiseCommentsForEntryId(meetingEntry.id, meetingEntry.trackId, true);
  }

  function getOrCreateTrackState(trackId: string): ChatMessageState {
    if (!stateByTrack.value[trackId]) {
      Vue.set(stateByTrack.value, trackId, new ChatMessageState());
    }
    return stateByTrack.value[trackId];
  }

  function setRepliesAndGetParentMessages(messageState: ChatMessageState, newMessages: ChatMessage[],
    fullRefresh: boolean, editableMessages: Record<string, boolean>): ChatMessage[] {
    const userStore = useUserStore(pinia);
    const parentMessages: ChatMessage[] = [];
    const repliesToSet: Record<string, ChatMessage[]> = fullRefresh ? {} : messageState.orderedReplies;
    const currentUser: FullUserDetails | null = userStore.currentUserDetails;
    for (const message of newMessages) {
      if (message.parentMessageId) {
        const currentReplies: ChatMessage[] = repliesToSet[message.parentMessageId] || [];
        const placeholderReplaced: boolean = handlePlaceholderMessages([message], currentReplies,
          messageState.placeholderMessages, message.trackId, ogpData.value);
        if (!placeholderReplaced) {
          const existingMessageIndex: number = currentReplies.findIndex((current: ChatMessage) =>
            current.id === message.id);
          if (existingMessageIndex === -1) {
            currentReplies.push(message);
          } else if (message.lastUpdated !== currentReplies[existingMessageIndex].lastUpdated) {
            currentReplies.splice(existingMessageIndex, 1, message);
          }
          currentReplies.sort(compareMessages);
          Vue.set(repliesToSet, message.parentMessageId, currentReplies);
          processMessage(message, message.trackId, ogpData.value);
        }
      } else {
        parentMessages.push(message);
      }
      if (!message.isPlaceholder) {
        setMessageAsEditableIfApplicable(currentUser, message, editableMessages);
      }
    }
    // TODO: Check if this replacement results in a large re-render overhead
    Vue.set(messageState, 'orderedReplies', repliesToSet);
    return parentMessages;
  }

  function setMessageAsEditableIfApplicable(currentUser: FullUserDetails | null, message: ChatMessage,
    editableMessages: Record<string, boolean>): void {
    const userStore = useUserStore(pinia);
    const guestTokenForMeeting: UserToken | undefined = userStore.guestTokenForCurrentMeeting;
    if ((currentUser && currentUser.id === message.senderId) ||
      (guestTokenForMeeting && guestTokenForMeeting.guestAccessId === message.guestId)) {
      const timeElapsedSinceMessageSent: number = Math.max(Date.now() - message.date, 0);
      const editablePeriodRemaining: number = MESSAGE_EDIT_WINDOW - timeElapsedSinceMessageSent;
      const messageTrack: Track = tracksStore.tracks[message.trackId];
      const isPublishRoom = messageTrack?.type === TrackType.BREAKOUT && messageTrack?.publishable;

      // Messages should always be editable for publish rooms
      if (editablePeriodRemaining > 0 || isPublishRoom) {
        Vue.set(editableMessages, message.id, true);

        if (!isPublishRoom) {
          setTimeout(() => {
            Vue.delete(editableMessages, message.id);
          }, editablePeriodRemaining);
        }
      }
    }
  }

  function setHistoryItemContinuationInfo(sortedNewMessages: ChatHistoryItem[], shouldProcessMessage: boolean,
    trackId?: string, ogpData?: Record<string, Promise<OpenGraph>>, closestExistingMessage?: ChatHistoryItem,
    messageListToUpdate?: ChatHistoryItem[], trackMessageState?: ChatMessageState): void {
    const addingToBeginning: boolean = !messageListToUpdate && !!closestExistingMessage;
    let lastSender: string = '';
    let lastTime: number = 0;
    const closestMessageTime: number =
      closestExistingMessage?.chatMessage?.date ?? closestExistingMessage?.entry?.created ?? 0;
    const closestMessageSenderId: string =
      closestExistingMessage?.chatMessage?.senderId ?? closestExistingMessage?.chatMessage?.guestId ??
      closestExistingMessage?.entry?.authorId ?? '';

    if (closestExistingMessage && !addingToBeginning) {
      lastSender = closestMessageSenderId;
      lastTime = closestMessageTime;
    }

    for (const newMessage of sortedNewMessages) {
      const itemDate: number = newMessage.chatMessage?.date ?? newMessage.entry?.created ?? 0;
      const senderId: string = newMessage.chatMessage?.senderId ?? newMessage.chatMessage?.guestId ??
        newMessage.entry?.authorId ?? '';

      newMessage.newDay = !areDatesSameDay(lastTime, itemDate);
      if (!lastSender || (senderId !== lastSender)) {
        lastSender = senderId;
      } else if (!newMessage.newDay && lastTime > (itemDate - MESSAGE_CONTINUATION_TIMEOUT)) {
        newMessage.continuation = true;
      }
      lastTime = itemDate;
      if (messageListToUpdate) {
        // Might as well do this here to avoid looping through the messages again.
        messageListToUpdate.push(newMessage);
      }
      if (shouldProcessMessage && trackId && ogpData) {
        processMessage(newMessage.chatMessage, trackId, ogpData);
      }
    }
    if (addingToBeginning && closestExistingMessage) {
      // Recalculate the previous beginning of the list
      closestExistingMessage.newDay = !areDatesSameDay(lastTime, closestMessageTime);
      if (!closestExistingMessage.newDay && lastTime > (closestMessageTime - MESSAGE_CONTINUATION_TIMEOUT)) {
        closestExistingMessage.continuation = true;
      }
    }
    let lastMessageHadReplies: boolean = false;
    if (trackMessageState) {
      for (const message of sortedNewMessages) {
        const messageReplies: ChatMessage[] = trackMessageState.orderedReplies[message.id];
        if (lastMessageHadReplies || messageReplies) {
          // There are two places the continuation flag is set for some reason. Currently the one from 'chatMessage'
          // appears to take precedence.
          message.continuation = false;
          if (message?.chatMessage) {
            message.chatMessage.continuation = false;
          }
        }
        lastMessageHadReplies = !!messageReplies?.length;
      }
    }
  }

  function isPlaceholderForMessage(placeholderMessage: ChatMessage, newMessage: ChatMessageDetails): boolean {
    return placeholderMessage.trackId === newMessage.trackId &&
      ((!placeholderMessage.entryId && !newMessage.entryId) || placeholderMessage.entryId === newMessage.entryId) &&
      placeholderMessage.chatMessageContent.plain === newMessage.chatMessageContent.plain;
  }

  function handlePlaceholderMessages(orderedNewMessages: ChatMessage[], orderedExistingMessages: ChatMessage[],
    placeholderMessages: ChatMessage[], trackId: string, ogpData: Record<string, Promise<OpenGraph>>): boolean {
    const userStore = useUserStore(pinia);
    let placeholderReplaced: boolean = false;
    if (placeholderMessages.length > 0) {
      let sortRequired: boolean = false;
      const currentUser: FullUserDetails | null = userStore.currentUserDetails;
      const guestTokenForMeeting: UserToken | undefined = userStore.guestTokenForCurrentMeeting;
      const newMessagesFromCurrentUser: ChatMessage[] = orderedNewMessages.filter((message: ChatMessage) =>
        message.status === ChatMessageStatus.ACTIVE &&
        !message.isPlaceholder &&
        ((currentUser && message.senderId === currentUser.id) ||
          (guestTokenForMeeting && message.guestId === guestTokenForMeeting.guestAccessId)));

      for (const newMessage of newMessagesFromCurrentUser) {
        const matchingPlaceholderIndex: number | undefined = placeholderMessages.findIndex(
          (placeholder: ChatMessage) => isPlaceholderForMessage(placeholder, newMessage));

        if (matchingPlaceholderIndex >= 0) {
          const placeholderMessage: ChatMessage = placeholderMessages[matchingPlaceholderIndex];
          // Make sure we don't already have this message in our list.
          let messageExists: boolean = false;
          if (orderedExistingMessages.length &&
            orderedExistingMessages[orderedExistingMessages.length - 1].date >= newMessage.date) {
            for (let i = orderedExistingMessages.length - 1; i >= 0 && !messageExists; --i) {
              if (orderedExistingMessages[i].id === newMessage.id) {
                messageExists = true;
              }
            }
          }
          if (!messageExists) {
            // Find the placeholder in the existing message list, and replace with the real message.
            for (let i = orderedExistingMessages.length - 1; i >= 0; --i) {
              if (orderedExistingMessages[i].id === placeholderMessage.id) {
                processMessage(newMessage, trackId, ogpData);
                orderedExistingMessages.splice(i, 1, newMessage);
                if (!sortRequired) {
                  // We need to recalculate the continuation info because the placeholder's timestamp came from the
                  // client, but the real message's timestamp was set by the server.
                  let previousMessage: ChatMessage | undefined;
                  let nextMessage: ChatMessage | undefined;
                  if (i > 0) {
                    previousMessage = orderedExistingMessages[i - 1];
                  }
                  if (i < (orderedExistingMessages.length - 1)) {
                    nextMessage = orderedExistingMessages[i + 1];
                  }

                  sortRequired = (!!previousMessage && newMessage.date < previousMessage.date) ||
                    (!!nextMessage && newMessage.date > nextMessage.date);

                  if (!sortRequired) {
                    setContinuationInfo([newMessage], false, trackId, ogpData, previousMessage, undefined, true);
                  }
                }
                break;
              }
            }
            // Remove the message from the list of new messages.
            const index: number = orderedNewMessages.findIndex((current: ChatMessage) => current.id === newMessage.id);
            if (index > -1) {
              orderedNewMessages.splice(index, 1);
            }
            // Remove the placeholder from the list of placeholder messages, now that it has been handled.
            placeholderMessages.splice(matchingPlaceholderIndex, 1);
            placeholderReplaced = true;
          }
        }
      }
      if (sortRequired) {
        orderedExistingMessages.sort(compareMessages);
        setContinuationInfo(orderedExistingMessages, false);
      }
    }
    return placeholderReplaced;
  }

  function getOldestNonPlaceholderMessageFromSortedList(sortedMessages: ChatMessage[]): ChatMessage | null {
    return sortedMessages.find(msg => !msg.isPlaceholder) ?? null;
  }

  function getNewestNonPlaceholderMessageFromSortedList(sortedMessages: ChatMessage[]): ChatMessage | null {
    return _.findLast(sortedMessages, (chatMessage: ChatMessage) => !chatMessage.isPlaceholder) ?? null;
  }

  function isAllHistoricalMessages(orderedExistingMessages: ChatMessage[], sortedNewMessages: ChatMessage[]): boolean {
    const oldestExistingMessage: ChatMessage | null = getOldestNonPlaceholderMessageFromSortedList(
      orderedExistingMessages);
    const newestNewMessage: ChatMessage | null = getNewestNonPlaceholderMessageFromSortedList(sortedNewMessages);
    if (oldestExistingMessage && newestNewMessage) {
      return oldestExistingMessage.date >= newestNewMessage.date;
    }
    return false;
  }

  function spliceExistingMessageIfExists(orderedNewMessages: ChatMessage[], orderedExistingMessages: ChatMessage[],
    existingMessageIndex: number, trackId?: string, ogpData?: Record<string, Promise<OpenGraph>>): boolean {
    const existingMessage: ChatMessage = orderedExistingMessages[existingMessageIndex];
    const equalDates: boolean = (orderedNewMessages.length > 0 && existingMessage.date === orderedNewMessages[0].date);
    if (equalDates) {
      for (let i = 0; i < orderedNewMessages.length; ++i) {
        const newMessage: ChatMessage = orderedNewMessages[i];
        if (existingMessage.id === newMessage.id) {
          // We already have this message in our list so don't want to add it again
          if (newMessage.lastUpdated !== existingMessage.lastUpdated) {
            // This is an update, so replace the existing message
            newMessage.newDay = existingMessage.newDay;
            newMessage.continuation = existingMessage.continuation;
            if (trackId && ogpData && newMessage.status !== ChatMessageStatus.DELETED) {
              processMessage(newMessage, trackId, ogpData);
            }
            orderedExistingMessages.splice(existingMessageIndex, 1, newMessage);
          }
          orderedNewMessages.splice(i, 1);
          break;
        }
      }
    }
    return equalDates;
  }

  function setContinuationInfo(sortedNewMessages: ChatMessage[], shouldProcessMessage: boolean,
    trackId?: string, ogpData?: Record<string, Promise<OpenGraph>>, closestExistingMessage?: ChatMessage,
    messageListToUpdate?: ChatMessage[], isHandlingPlaceholder?: boolean): void {
    const addingToBeginning: boolean = !messageListToUpdate && !!closestExistingMessage && !isHandlingPlaceholder;
    let lastSender: string = '';
    let lastTime: number = 0;
    let lastSource: string = '';
    if (closestExistingMessage && !addingToBeginning) {
      lastSender = closestExistingMessage.senderId || '';
      lastTime = closestExistingMessage.date;
      lastSource = closestExistingMessage.source || '';
    }
    for (const newMessage of sortedNewMessages) {
      newMessage.newDay = !areDatesSameDay(lastTime, newMessage.date);
      if (!lastSender || newMessage.fromWorkflow || ((newMessage.senderId || newMessage.guestId) !== lastSender) ||
        (lastSource && lastSource !== newMessage.source)) {
        lastSender = newMessage.senderId || '';
        lastSource = newMessage.source || '';
      } else if (!newMessage.newDay && lastTime > (newMessage.date - MESSAGE_CONTINUATION_TIMEOUT)) {
        newMessage.continuation = true;
      }
      lastTime = newMessage.date;
      if (messageListToUpdate) {
        // Might as well do this here to avoid looping through the messages again.
        messageListToUpdate.push(newMessage);
      }
      if (shouldProcessMessage && trackId && ogpData) {
        processMessage(newMessage, trackId, ogpData);
      }
    }
    if (addingToBeginning && closestExistingMessage) {
      // Recalculate the previous beginning of the list
      closestExistingMessage.newDay = !areDatesSameDay(lastTime, closestExistingMessage.date);
      if (!closestExistingMessage.newDay && lastTime > (closestExistingMessage.date - MESSAGE_CONTINUATION_TIMEOUT)) {
        closestExistingMessage.continuation = true;
      }
    }
  }

  function setMessagesInState(newMessages: ChatMessage[], fullRefresh: boolean, trackId: string,
    messageState: ChatMessageState | Record<string, ChatMessage[]>, stateKey: string,
    placeholderMessages: ChatMessage[]): void {
    const sortedNewMessages: ChatMessage[] = newMessages.sort(compareMessages);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const orderedExistingMessages: ChatMessage[] = (messageState as any)[stateKey];

    if (fullRefresh || !orderedExistingMessages?.length || !sortedNewMessages.length) {
      setContinuationInfo(sortedNewMessages, true, trackId, ogpData.value);
      Vue.set(messageState, stateKey, sortedNewMessages);
    } else {
      const oldestNewMessage: ChatMessage = sortedNewMessages[0];
      const newestNewMessage: ChatMessage = sortedNewMessages[sortedNewMessages.length - 1];
      const oldestExistingMessage: ChatMessage = orderedExistingMessages[0];
      const newestExistingMessage: ChatMessage = orderedExistingMessages[orderedExistingMessages.length - 1];
      const allHistoricalMessages: boolean = isAllHistoricalMessages(orderedExistingMessages, sortedNewMessages);

      if (!allHistoricalMessages) {
        const placeholderReplaced: boolean = handlePlaceholderMessages(sortedNewMessages, orderedExistingMessages,
          placeholderMessages, trackId, ogpData.value);

        if (placeholderReplaced && !sortedNewMessages.length) {
          return;
        }
      }

      if (newestExistingMessage.date <= oldestNewMessage.date) {
        // Add all the new messages at the end.
        // Make sure there is no overlap by rewinding the end of our existing message list and splicing any matching
        // new ones in.
        for (let i = orderedExistingMessages.length - 1; i >= 0; --i) {
          if (!spliceExistingMessageIfExists(sortedNewMessages, orderedExistingMessages, i, trackId, ogpData.value)) {
            break;
          }
        }
        setContinuationInfo(sortedNewMessages, true, trackId, ogpData.value, newestExistingMessage,
          orderedExistingMessages);

        if (!newestNewMessage.isPlaceholder) {
          // We don't want the track last message to be a pointer to the chat history item as that can cause the chat
          // history to be incorrect when a message is sent in a thread. This needs to be a (very) deep clone to avoid
          // the linking issue.
          const newestMessage: ChatMessage = JSON.parse(JSON.stringify(newestNewMessage));
          if (!newestMessage.entryId) {
            // Update last chat message for the matching track
            tracksStore.setLastChatMessageInTrack({
              trackId: trackId,
              newestMessage,
              numNewMessages: sortedNewMessages.length
            });
          }
        }
      } else if (allHistoricalMessages) {
        // Add all the new messages at the beginning
        setContinuationInfo(sortedNewMessages, true, trackId, ogpData.value, oldestExistingMessage);
        sortedNewMessages.reverse();
        // Make sure there is no overlap by going through our existing messages and splicing in any matching new ones
        for (let i = 0; i < orderedExistingMessages.length; ++i) {
          if (!spliceExistingMessageIfExists(sortedNewMessages, orderedExistingMessages,
            i, trackId, ogpData.value)) {
            break;
          }
        }
        for (const newMessage of sortedNewMessages) {
          orderedExistingMessages.unshift(newMessage);
        }
      } else {
        // The new message list contains a mixture of new and old ones, or some edits to existing messages
        let messagesModified: boolean = false;
        const messagesToSet: ChatMessage[] = orderedExistingMessages;
        for (const message of sortedNewMessages) {
          const existingMessageIndex: number = messagesToSet.findIndex((current: ChatMessage) =>
            current.id === message.id);
          if (existingMessageIndex === -1) {
            processMessage(message, trackId, ogpData.value);
            messagesToSet.push(message);
            messagesModified = true;
          } else if (message.lastUpdated !== messagesToSet[existingMessageIndex].lastUpdated) {
            processMessage(message, trackId, ogpData.value);
            messagesToSet.splice(existingMessageIndex, 1, message);
            messagesModified = true;
          }
        }
        if (messagesModified) {
          messagesToSet.sort(compareMessages);
          setContinuationInfo(messagesToSet, false);
          Vue.set(messageState, stateKey, messagesToSet);
        }
      }
    }
  }

  function processMessage(message: ChatMessage | undefined, trackId: string,
    ogpData: Record<string, Promise<OpenGraph>>): void {
    if (!message || !message.chatMessageContent) {
      return;
    }
    const processedOgData: OpenGraph[] = [];
    const processedParts = processPartList(message.chatMessageContent.parts, processedOgData, trackId, ogpData);
    message.chatMessageContent.parts = processedParts.parts;
    message.entryLinks = processedParts.entryLinks;
    Vue.set(message, 'openGraphItems', processedOgData);
  }

  function processPartList(parts: ChatMessagePart[], ogData: OpenGraph[], trackId: string,
    ogpData: Record<string, Promise<OpenGraph>>, isWithinFormattingBlock: boolean = false):
    { parts: ChatMessagePart[], entryLinks: string[] } {
    const result: { parts: ChatMessagePart[], entryLinks: string[] } = { parts: [], entryLinks: [] };
    let largeEmojis: boolean = true;
    for (const part of parts) {
      if (part.type === ChatMessagePartType.TEXT && part.content && !part.styles?.includes('cb')) {
        const links = anchorme.list(part.content);
        if (links.length) {
          largeEmojis = false;
          let lastIndex = 0;
          for (const link of links) {
            if (anchorme.validate.file(link.string)) {
              continue;
            }
            if (link.start > lastIndex) {
              const preLinkText = {
                type: ChatMessagePartType.TEXT,
                content: part.content.substring(lastIndex, link.start),
                styles: part.styles,
                style: part.style,
              };
              processAndAddTextPart(preLinkText, result.parts);
            }
            const linkUrl = extractSingleLink(link.string);
            if (validateUrl(linkUrl)) {
              const routeLink = extractEntryLink(linkUrl);
              if (routeLink) {
                result.entryLinks.push(routeLink);
              } else {
                updateOgData(linkUrl, ogData, trackId, ogpData);
              }
            }
            const linkPart = {
              type: ChatMessagePartType.LINK,
              linkAddress: linkUrl,
              linkText: link.string,
              styles: part.styles,
              style: part.style,
            };
            result.parts.push(linkPart);
            lastIndex = link.end;
          }
          if (lastIndex < part.content.length) {
            const postLinkText = {
              type: ChatMessagePartType.TEXT,
              content: part.content.substr(lastIndex),
              styles: part.styles,
              style: part.style,
            };
            processAndAddTextPart(postLinkText, result.parts);
          }
        } else {
          const textPartHasLargeEmojis: boolean = processAndAddTextPart(part, result.parts);
          largeEmojis = largeEmojis && textPartHasLargeEmojis;
        }
      } else if (part.type === ChatMessagePartType.LINK) {
        largeEmojis = false;
        if (part.linkAddress) {
          const entryLink = extractEntryLink(part.linkAddress);
          if (entryLink) {
            result.entryLinks.push(entryLink);
          } else {
            updateOgData(part.linkAddress, ogData, trackId, ogpData);
          }
        }
        result.parts.push(part);
      } else if (part.type === ChatMessagePartType.LIST && part.items) {
        largeEmojis = false;
        // The properties of the parts have not been deep cloned, as we need to alter the item text parts
        const clonedList: ChatMessagePart = {
          type: part.type,
          ordered: part.ordered,
          items: [],
        };
        for (const listItem of part.items) {
          const clonedItem = Object.assign({}, listItem);
          const processedListParts = processPartList(listItem.itemTextParts, ogData, trackId, ogpData, true);
          clonedItem.itemTextParts = processedListParts.parts;
          clonedList.items?.push(clonedItem);
          result.entryLinks.push(...processedListParts.entryLinks);
        }
        result.parts.push(clonedList);
      } else if (part.type === ChatMessagePartType.WRAPPED_TEXT && part.messageParts) {
        largeEmojis = false;
        // Clone this part in case it wraps a part which wraps further parts
        const clonedPart = Object.assign({}, part);
        const processedWrappedParts = processPartList(part.messageParts, ogData, trackId, ogpData, true);
        clonedPart.messageParts = processedWrappedParts.parts;
        result.entryLinks.push(...processedWrappedParts.entryLinks);
        result.parts.push(clonedPart);
        largeEmojis = false;
      } else if (part.type === ChatMessagePartType.SHARED_MESSAGE && part.sharedMessage?.chatMessageContent.parts) {
        const clonedPart = Object.assign({}, part);
        const processedSharedParts = processPartList(part.sharedMessage?.chatMessageContent.parts, ogData, trackId,
          ogpData, true);
        // only include entry links where the shared message is for the current track
        if (processedSharedParts.entryLinks && part.sharedMessage.trackId === trackId) {
          result.entryLinks.push(...processedSharedParts.entryLinks);
        }
        if (clonedPart.sharedMessage) {
          clonedPart.sharedMessage.chatMessageContent.parts = processedSharedParts.parts;
          result.parts.push(clonedPart);
        }
      } else {
        result.parts.push(part);
      }
    }
    if (largeEmojis && !isWithinFormattingBlock) {
      for (const part of result.parts) {
        if (part.styles) {
          const emojiStyleIndex: number = part.styles.indexOf('emoji');
          if (emojiStyleIndex > -1) {
            part.styles[emojiStyleIndex] = 'emojiLarge';
          }
        }
      }
    }
    return result;
  }

  function processAndAddTextPart(part: ChatMessagePart, existingParts: ChatMessagePart[]): boolean {
    if (!part.content) {
      existingParts.push(part);
      return true;
    }
    emojiRegex.lastIndex = 0;
    let lastIndex = 0;
    let emojiCount = 0;
    let largeEmojis: boolean = existingParts.length === 0;
    let result = emojiRegex.exec(part.content);
    while (result) {
      if (result.index > lastIndex) {
        const preEmojiContent: string = part.content.substring(lastIndex, result.index);
        existingParts.push({
          type: ChatMessagePartType.TEXT,
          content: preEmojiContent,
          styles: part.styles,
          style: part.style,
        });
        if (largeEmojis && preEmojiContent.trim()) {
          largeEmojis = false;
        }
      }
      existingParts.push({
        type: ChatMessagePartType.TEXT,
        content: result[0],
        styles: ['emoji'],
      });
      if (++emojiCount >= 10) {
        largeEmojis = false;
      }
      lastIndex = result.index + result[0].length;
      result = emojiRegex.exec(part.content);
    }
    if (lastIndex < part.content.length) {
      const postEmojiContent: string = part.content.substr(lastIndex);
      existingParts.push({
        type: ChatMessagePartType.TEXT,
        content: postEmojiContent,
        styles: part.styles,
        style: part.style,
      });
      if (largeEmojis && postEmojiContent.trim()) {
        largeEmojis = false;
      }
    }
    return largeEmojis;
  }

  function isValidEntryLink(path: string): boolean {
    const routeData = router.resolve(path);
    const validRoutes: string[] = [
      RouteNames.TRACK_ENTRY_ROUTE_NAME,
    ];

    return !!(routeData && routeData.route && routeData.route.name && validRoutes.includes(routeData.route.name));
  }

  function extractEntryLink(url: string): string | null {
    const local = location.protocol + '//' + location.host;
    if (url.startsWith('/')) {
      url = local + url;
    }
    if (url.startsWith(local)) {
      const path: string = url.substr(local.length);
      if (isValidEntryLink(path)) {
        return path;
      }
    }
    return null;
  }

  function extractSingleLink(link: string) {
    if (anchorme.validate.email(link)) {
      if (/^mailto:/i.test(link)) {
        return link;
      } else {
        return 'mailto:' + link;
      }
    } else {
      if (/^https?:\/\//i.test(link)) {
        return link;
      } else {
        return 'https://' + link;
      }
    }
  }

  function isEntryExcludedType(entryToCheck: TrackEntry, entries: TrackEntry[]) {
    const excludedTypes: EntryType[] = [EntryType.folder, EntryType.shared_content, EntryType.meeting_invite];
    return !entryToCheck?.type || excludedTypes.includes(entryToCheck.type) ||
      isEntryChildOfMeetingRecord(entryToCheck, entries) || isEntryTranscription(entryToCheck) ||
      (entryToCheck.type === EntryType.private && !isTrackAccessible(entryToCheck.subType)) ||
      (entryToCheck.type === EntryType.email && !entryToCheck.parentId);
  }

  function isEntryChildOfMeetingRecord(entryToCheck: TrackEntry, entries: TrackEntry[]) {
    for (const entry of entries) {
      if (entry.type === EntryType.meeting_record && entryToCheck.parentId === entry.id) {
        return true;
      }
    }
    return false;
  }

  function isEntryTranscription(entryToCheck: TrackEntry) {
    return entryToCheck.subType && entryToCheck.subType === 'transcription';
  }

  function isTrackAccessible(breakoutTrackId: string | undefined): boolean {
    if (!breakoutTrackId) {
      return false;
    }
    const track = tracksStore.tracks[breakoutTrackId];
    return !!track;
  }

  function updateOgData(link: string, ogData: OpenGraph[], trackId: string,
    ogpData: Record<string, Promise<OpenGraph>>) {
    getOgpData(link, trackId, ogpData).then((openGraph: OpenGraph) => {
      // Check there's some data (apart from title) to display before using it
      if (openGraph && (openGraph.image || openGraph.description)) {
        // Switch the URL in the OGP data back from the wiki url to the fender /k url
        if (openGraph.url && isWikiUrl(openGraph.url)) {
          openGraph.url = convertWikiUrlToFender(openGraph.url);
        }
        let urlAlreadyProcessed = false;
        for (const item of ogData) {
          if (item.url === openGraph.url) {
            urlAlreadyProcessed = true;
          }
        }
        if (!urlAlreadyProcessed) {
          ogData.push(openGraph);
        }
      }
    });
  }

  async function getOgpData(url: string, trackId: string, ogpData: Record<string, Promise<OpenGraph>>):
    Promise<OpenGraph> {
    const existingOpenGraphPromise: Promise<OpenGraph> = ogpData[url];
    if (!existingOpenGraphPromise) {
      let promise:Promise<OpenGraph>;
      if (url.includes('/kb/wiki/')) {
        promise = new Promise((resolve) => {
          const iframe = document.createElement('iframe');

          iframe.addEventListener('load', () => {
            const channel = new MessageChannel();
            channel.port1.onmessage = (e) => {
              document.body.removeChild(iframe);
              resolve(<OpenGraph> e.data);
            };

            const ogpPostMessage = { xwikiAction: 'getOpenGraphData' };
            iframe.contentWindow?.postMessage(ogpPostMessage, Integrations.XWIKI_SERVER, [channel.port2]);
          });
          iframe.style.display = 'none';
          iframe.setAttribute('src', url);
          document.body.appendChild(iframe);
        });
      } else {
        promise = DataWorker.instance().dispatch('ChatMessages/getOpenGraphData', trackId, url);
      }

      // Store the promise so that other requests from the same URL can await it.
      Vue.set(ogpData, url, promise);
      const openGraph: OpenGraph = await promise;
      return openGraph;
    } else {
      return await existingOpenGraphPromise;
    }
  }

  return {
    stateByTrack,
    editableMessages,
    messagesBeingEdited,
    lastSeenMeetingChatMessageDate,
    chatTrackIds,
    stateForActiveTrack,
    chatMessagesAndEntriesForChatTracks,
    isWaitingForMessages,
    hasFullHistoryBeenRetrieved,
    canViewCurrentChats,
    setTrackChatMessagesInitialised,
    setEntryCommentsInitialised,
    setUsersTyping,
    clearUserTyping,
    setTrackChatMessages,
    setComments,
    setWaitingForMessages,
    setSelectedMessageId,
    setFullChatHistoryRetrieved,
    startEditing,
    stopEditing,
    setChatMessageToView,
    setLastSeenMeetingChatMessageDate,
    loadMoreChatMessages,
    ensureFullPageOfMessagesIsLoaded,
    sendChatMessage,
    editChatMessage,
    updateLastTyping,
    deleteChatMessage,
    shareChatMessage,
    toggleChatMessageReaction,
    loadChatHistoryIncludingMessage,
    initialiseCommentsForEntry,
    handleNewChatTrackId,
    handleClosedChatTrackId,
    getOpenGraphData,
    performNlpCommand,
    confirmNlpCommand,
    requestGuestCatchup,
    handleActiveTrackChatViewPermissionChanged,
    handleMeetingChatViewPermissionChanged
  };
});
