import UserToken from '@/data/datatypes/UserToken';

const LOCAL_STORAGE_USER_TOKEN_KEY: string = 'token';

/**
 * Key used to store the time of the last logout.
 * This local store entry is used to allow other tabs to detect when one tab has triggered a logout.
 * The other tabs will then do the same to ensure consistency...
 */
const LOCAL_STORAGE_LOGOUT_TS_KEY: string = 'logoutTS';

// const LOCAL_STORAGE_GUEST_STATE_KEY = 'guestState';

function isTokenValid(token: UserToken | null): boolean {
  if (!token) {
    return false;
  }
  return Date.now() < (Number(token.exp) * 1000);
}

/**
 * Gets an existing user token from local storage. If `validate` is `true`, the token will only be returned if it's a
 * valid token (i.e. it hasn't expired). If `validate` is set to `false`, the token will be returned (if found) even if
 * it is not a valid token. This is useful in some circumstances (e.g. silent sign on) but defaults to true.
 *
 * @param validate whether or not we want to ensure the token is valid (i.e. not expired) before returning it
 */
function getUserTokenFromLocalStorage(validate = true): UserToken | null {
  const tokenStr = localStorage.getItem(LOCAL_STORAGE_USER_TOKEN_KEY);
  if (!tokenStr) {
    return null;
  }
  try {
    const token: UserToken = JSON.parse(tokenStr);
    if (validate && !isTokenValid(token)) {
      return null;
    }
    return token;
  } catch (error) {
    return null;
  }
}

function setUserTokenInLocalStorage(token: UserToken): void {
  localStorage.setItem(LOCAL_STORAGE_USER_TOKEN_KEY, JSON.stringify(token));
}

function removeUserTokenFromLocalStorage(): void {
  localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN_KEY);
}

function setLogoutMarkerInLocalStorage(): void {
  localStorage.setItem(LOCAL_STORAGE_LOGOUT_TS_KEY, Date.now().toString());
}

function removeLogoutMarkerFromLocalStorage(): void {
  localStorage.removeItem(LOCAL_STORAGE_LOGOUT_TS_KEY);
}

/**
 * Returns true if the passed storage event indicates that ones of the browser tabs
 * has just triggered a logout.
 */
function eventSignalsLogout(event: StorageEvent): boolean {
  // The value is a timestamp but any change in value would indicate a logout
  // The exception is when the newValue is null as it means the marker is being removed...
  return (event.key === LOCAL_STORAGE_LOGOUT_TS_KEY) && !!event.newValue;
}

/**
 * Returns the EPOC in milliseconds of when the token expires (0 if not present).
 */
function tokenExpiryMillis(): number {
  const tokenIssuedAt: number = Number(getUserTokenFromLocalStorage(false)?.exp);
  if (isNaN(tokenIssuedAt)) {
    return 0;
  } else {
    return tokenIssuedAt * 1000;
  }
}

/**
 * Returns the EPOC in milliseconds of when the token was last issued (0 if not present).
 */
function tokenIssuedAtMillis(): number {
  const tokenIssuedAt: number = Number(getUserTokenFromLocalStorage(false)?.iat);
  if (isNaN(tokenIssuedAt)) {
    return 0;
  } else {
    return tokenIssuedAt * 1000;
  }
}

// function setGuestStateInLocalStorage(toSet: Record<string, UserState>) {
//   localStorage.setItem(LOCAL_STORAGE_GUEST_STATE_KEY, JSON.stringify(toSet));
// }
//
// function getGuestStateFromLocalStorage(): Record<string, UserState> | null {
//   const tokenStr = localStorage.getItem(LOCAL_STORAGE_GUEST_STATE_KEY);
//   if (!tokenStr) {
//     return null;
//   }
//   return JSON.parse(tokenStr);
// }
export {
  eventSignalsLogout,
  getUserTokenFromLocalStorage,
  isTokenValid,
  removeLogoutMarkerFromLocalStorage,
  removeUserTokenFromLocalStorage,
  setLogoutMarkerInLocalStorage,
  setUserTokenInLocalStorage,
  tokenExpiryMillis,
  tokenIssuedAtMillis,
};
