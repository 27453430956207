import log from 'loglevel';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { Execution } from '@/data/datatypes/rulesengine/Execution';
import { Ruleset } from '@/data/datatypes/rulesengine/Ruleset';
import { RulesetExecutionPayload } from '@/data/datatypes/rulesengine/RulesetExecutionPayload';
import { RulesetTrackExecutionPayload } from '@/data/datatypes/rulesengine/RulesetTrackExecutionPayload';
import DataWorker from '@/data/storage/DataWorker';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';

export const useRulesetsStore = defineStore('Rulesets', () => {
  const rulesets: Ref<Record<string, Ruleset>> = ref({});
  const inProgressRulesetRequests: Ref<Record<string, Promise<Ruleset>>> = ref({});

  // Remembers the input fact values for the last "debug ruleset" execution, so that
  // the user can come back to the debug screen and not have to re-enter the test
  // values.
  // The key here is the ruleset ID, and the value is another record that maps
  // input fact names to their values.
  const lastDebugInputFacts: Ref<Record<string, Record<string, string>>> = ref({});

  const allTenantRulesets: ComputedRef<Ruleset[]> = computed(() => {
    return Object.values(rulesets.value);
  });

  const lastDebugRulesetInputFacts: ComputedRef<Record<string, Record<string, string>>> = computed(() => {
    return lastDebugInputFacts.value;
  });

  async function addRuleset(ruleset: Ruleset): Promise<Ruleset | undefined> {
    const request: Promise<Ruleset> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/createRuleset', ruleset).then(
        (created: Ruleset) => {
          resolve(created);
        }).catch((error) => {
        reject(error);
      });
    });
    return await performMonitoredRequest<Ruleset>('Ruleset', request);
  }

  async function importRuleset(ruleset: Ruleset): Promise<Ruleset | undefined> {
    const request: Promise<Ruleset> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/importRuleset', ruleset).then(
        (imported: Ruleset) => {
          resolve(imported);
        }).catch((error) => {
        reject(error);
      });
    });
    return await performMonitoredRequest<Ruleset>('Ruleset', request);
  }

  async function exportRuleset(rulesetId: string): Promise<Ruleset | undefined> {
    const request: Promise<Ruleset> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/exportRuleset', rulesetId).then(
        (exported: Ruleset) => {
          resolve(exported);
        }).catch((error) => {
        reject(error);
      });
    });
    return await performMonitoredRequest<Ruleset>('Ruleset', request);
  }

  async function executeRuleset(rulesetExecutionPayload: RulesetExecutionPayload, test?: boolean):
    Promise<Execution | undefined> {
    const request: Promise<Execution> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/executeRuleset', rulesetExecutionPayload, test).then(
        (result: Execution) => {
          resolve(result);
        }).catch((error) => {
        reject(error);
      });
    });
    return await performMonitoredRequest<Execution>('Ruleset', request);
  }

  async function executeRulesetOnTrack(rulesetTrackExecutionPayload: RulesetTrackExecutionPayload):
    Promise<Execution | undefined> {
    const request: Promise<Execution> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/executeRulesetOnTrack', rulesetTrackExecutionPayload).then(
        (result: Execution) => {
          resolve(result);
        }).catch((error) => {
        reject(error);
      });
    });
    return await performMonitoredRequest<Execution>('Ruleset', request);
  }

  async function updateRuleset(ruleset: Ruleset): Promise<void> {
    const request: Promise<Ruleset> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/updateRuleset', ruleset).then(
        (created: Ruleset) => {
          resolve(created);
        }).catch((error) => {
        reject(error);
      });
    });
    await performMonitoredRequest<Ruleset>('Ruleset', request);
  }

  async function deleteRuleset(ruleset: Ruleset): Promise<void> {
    const request: Promise<void> = new Promise((resolve, reject) => {
      DataWorker.instance().dispatch('Rulesets/deleteRuleset', ruleset).then(
        () => {
          resolve();
        }).catch((error) => {
        reject(error);
      });
    });
    await performMonitoredRequest<void>('Ruleset', request);
  }

  function setRulesets(details: {rulesets: Ruleset[], fullRefresh: boolean}): void {
    // If it's a full refresh, then delete any records that aren't in the updated object
    if (details.fullRefresh) {
      const removedRulesets: string[] = Object.keys(rulesets.value).filter((rulesetId: string) => {
        return details.rulesets.findIndex((ruleset: Ruleset) => ruleset.id === rulesetId) === -1;
      });
      for (const removedId of removedRulesets) {
        if (rulesets.value[removedId]) {
          Vue.delete(rulesets.value, removedId);
        }
      }
    }
    for (const ruleset of details.rulesets) {
      setOrPatchObject(rulesets.value, ruleset.id, asRecord(ruleset));
    }
  }

  async function getRulesetsAsGuest(guestId: string, trackId: string): Promise<Ruleset[]> {
    const rulesets: Ruleset[] = await DataWorker.instance().dispatch('Rulesets/getRulesetsAsGuest',
      guestId, trackId);
    setRulesets({ rulesets, fullRefresh: true });
    return rulesets;
  }

  function addInProgressRequest<T>(details: { entityName: string, requestId: string; request: Promise<T> }): void {
    switch (details.entityName) {
      case 'Ruleset':
        inProgressRulesetRequests.value[details.requestId] = details.request as Promise<Ruleset>;
        break;
      default:
        break;
    }
  }

  function removeInProgressRequest(details: { entityName: string, requestId: string }): void {
    switch (details.entityName) {
      case 'Ruleset':
        delete inProgressRulesetRequests.value[details.requestId];
        break;
      default:
        break;
    }
  }

  function setLastDebugInputFacts(details: { rulesetId: string, inputFacts: Record<string, string> }): void {
    Vue.set(lastDebugInputFacts.value, details.rulesetId, details.inputFacts);
  }

  async function waitForInProgressUpdates(entityName: string): Promise<void> {
    let inProgressUpdates: Promise<unknown>[] = [];
    switch (entityName) {
      case 'Ruleset':
        inProgressUpdates = Object.values(inProgressRulesetRequests.value);
        break;
      default:
        break;
    }

    if (inProgressUpdates.length) {
      try {
        await Promise.all(inProgressUpdates);
      } catch (error) {
        // The error will be logged by the action that triggered the update
      }
    }
  }

  async function performMonitoredRequest<T>(entityName: string, request: Promise<T>): Promise<T | undefined> {
    const requestId: string = uuid();
    addInProgressRequest<T>({ entityName, requestId, request });
    try {
      return await request;
    } catch (error) {
      log.error(`Failed to perform ${entityName} request: ${error}`);
    } finally {
      removeInProgressRequest({ entityName, requestId });
    }
  }

  return {
    rulesets,
    allTenantRulesets,
    lastDebugRulesetInputFacts,
    addRuleset,
    importRuleset,
    exportRuleset,
    executeRuleset,
    executeRulesetOnTrack,
    getRulesetsAsGuest,
    updateRuleset,
    deleteRuleset,
    setRulesets,
    setLastDebugInputFacts,
    waitForInProgressUpdates
  };
});
