import log from 'loglevel';

import { Attribute, AttributeType, FormulaAttributeOptionType } from '@/data/tasks/Attribute';
import {
  castHandlerTable,
  functionHandlerTable,
  FunctionName,
  functionReturnTypeHandler,
  operatorHandlerTable,
  TimeUnit
} from '@/data/tasks/FormulaHandlers';

import { indexOfNextUnescaped } from '../helpers/ParsingHelper';

export enum ExpressionType {
  CONSTANT = 'constant',
  REFERENCE = 'reference',
  OPERATION = 'operation',
  FUNCTION = 'function',
  FUNCTION_CALL = 'func_open_paren',
}

export enum Operator {
  ADD = '+',
  SUBTRACT = '-',
  MULTIPLY = '*',
  DIVIDE = '/',
  POWER = '^',
  CAST = 'AS',
  REMAINDER = '%',
  GREATER = '>',
  LESS = '<',
  GREATER_OR_EQUAL = '>=',
  LESS_OR_EQUAL = '<=',
  EQUAL = '=',
  NOT_EQUAL = '!=',
  OR = 'OR',
  AND = 'AND',
}

// These single characters when encountered outside of a string or attribute identifier
// are always complete tokens, regardless of surrounding white space
const singleCharTokens = ['(', ')', '*', '/', '%', '^', ','];

const rightAssociativeOpperators = [Operator.POWER];

const ATTRIBUTE_TYPES = Object.values(AttributeType);

interface Token {
  value: string;
  start: number;
  precedingWhitespace: string;
  isFunction?: boolean;
  numArgs?: number;
}
export interface Expression {
  expressionType: ExpressionType,
  /** Was this expression enclosed in brackets when defined */
  bracketed?: boolean,
  /** The whitespace immediately to the left of this expression defaults to a single space */
  spaceBefore?: string,
  /** The whitespace between the expression and its closing bracket */
  spaceBeforeBracket?: string,
}

/**
 * An expression which does not contain any other expressions
 */
interface TerminalExpression extends Expression {
  /** The whitespace between the opening bracket and the expression */
  spaceAfterBracket?: string,
}
export interface ConstantExpression extends TerminalExpression {
  constantType: AttributeType,
  value: string,
  quoteStyle?: string,
}

export interface ReferenceExpression extends TerminalExpression {
  referenceColumn: string,
}

export interface OperationExpression extends Expression {
  operator: Operator,
  leftOperand: Expression,
  rightOperand: Expression,
  /** The whitespace between the left operand and the operator, defaults to a single space */
  spaceBetween?: string,
}

export interface FunctionExpression extends Expression {
  functionName: FunctionName,
  args: Expression[],
}

export interface FormulaResult {
  type: AttributeType,
  value: string,
}

// Keep track of the current function and its argument count - i.e. the innermost nested function whose arguments are
// being input. We can use this to display UI hints about expected arguments for the 'current' function, which will be
// currentFuncStack[0].
//    e.g.: | == caret
//      min(|           <-- [['min', 1]]
//      min(20, |       <-- [['min', 2]]
//      min(max(2, ceil(|  <-- [['ceil', 1], [['max', 2], ['min', 1]]
const currentFuncStack: [FunctionName, number][] = [];

/**
 * Reconstruct the string form of the passed expression
 */
export function expressionAsString(expression: Expression): string {
  let value = '';
  if (expression.expressionType === ExpressionType.CONSTANT) {
    const constant = expression as ConstantExpression;
    switch (constant.constantType) {
      case AttributeType.NUMBER:
        value = constant.value;
        break;
      case AttributeType.PERCENT:
        value = constant.value + '%';
        break;
      case AttributeType.TEXT:
      case AttributeType.LONG_TEXT: {
        const quoteStyle = constant.quoteStyle || '\'';
        value = quoteStyle + constant.value.replaceAll(quoteStyle, '\\' + quoteStyle) + quoteStyle;
        break;
      }
      case AttributeType.TYPE:
        value = constant.value;
        break;
      case AttributeType.TIME_UNIT:
        value = constant.value;
        break;
    }
  } else if (expression.expressionType === ExpressionType.REFERENCE) {
    const reference = expression as ReferenceExpression;
    value = '{' + reference.referenceColumn + '}';
  } else if (expression.expressionType === ExpressionType.OPERATION) {
    const operation = expression as OperationExpression;
    const leftValue = expressionAsString(operation.leftOperand);
    const rightValue = expressionAsString(operation.rightOperand);
    value = leftValue + (operation.spaceBetween !== undefined ? operation.spaceBetween : ' ') +
      operation.operator + (operation.rightOperand.spaceBefore === undefined ? ' ' : '') +
      rightValue;
  } else if (expression.expressionType === ExpressionType.FUNCTION) {
    const funcExpr = expression as FunctionExpression;
    value = funcExpr.functionName + '(' + `${funcExpr.args.map(arg => expressionAsString(arg))}` + ')';
  }
  if (expression.bracketed) {
    value = '(' +
      ((expression as TerminalExpression).spaceAfterBracket !== undefined
        ? (expression as TerminalExpression).spaceAfterBracket : '') +
      value +
      (expression.spaceBeforeBracket !== undefined ? expression.spaceBeforeBracket : '') + ')';
  }
  if (expression.spaceBefore !== undefined) {
    value = expression.spaceBefore + value;
  }
  return value;
}

export function evaluateConditionalFormula(formula: string, appSchema: Attribute[],
  properties: Record<string, string>): FormulaResult | undefined {
  let result: FormulaResult | undefined;
  try {
    const expression: Expression = JSON.parse(formula);
    result = resolveExpression(expression, appSchema, properties, []);
  } catch (error) {
    result = undefined;
  }

  return result;
}

export function evaluateFormulaAttribute(
  attributeName: string, appSchema: Attribute[], properties: Record<string, string>): FormulaResult {
  const attribute: Attribute | undefined = appSchema.find(attribute => attribute.name === attributeName);
  if (!attribute) {
    throw new Error('Missing attribute with name');
  }
  const expression: Expression = getExpressionForAttribute(attribute);
  return resolveExpression(expression, appSchema, properties, [attribute.name]);
}

export function determineFormulaReturnType(
  expression: Expression, appSchema: Attribute[], attributeName?: string): AttributeType {
  return resolveReturnType(expression, appSchema, attributeName ? [attributeName] : []);
}

export function getFormulaReturnType(attributeName: string, appSchema: Attribute[]): AttributeType {
  const attribute: Attribute | undefined = appSchema.find(attribute => attribute.name === attributeName);
  if (!attribute) {
    throw new Error('Missing attribute with name');
  }
  const expression: Expression = getExpressionForAttribute(attribute);
  return resolveReturnType(expression, appSchema, [attribute.name]);
}

function getExpressionForAttribute(attribute: Attribute): Expression {
  const formulaString = attribute?.options?.[FormulaAttributeOptionType.FORMULA];
  if (!formulaString) {
    throw new Error('Missing formula value for attribute ' + attribute.name);
  }
  // TODO handle old style formula
  return JSON.parse(formulaString);
}

function resolveExpression(
  expression: Expression,
  appSchema: Attribute[],
  properties: Record<string, string>,
  referencedAttributeNames: string[] = []): FormulaResult {
  switch (expression.expressionType) {
    case ExpressionType.CONSTANT: {
      const constant = expression as ConstantExpression;
      return { type: constant.constantType, value: constant.value };
    }
    case ExpressionType.REFERENCE: {
      const reference = expression as ReferenceExpression;
      const referencedAttribute: Attribute | undefined =
        appSchema.find(attribute => attribute.name === reference.referenceColumn);
      if (!referencedAttribute) {
        throw new Error('Formula refers to missing field ' + reference.referenceColumn);
      }
      if (referencedAttributeNames.includes(referencedAttribute.name)) {
        throw new Error('Formula contains a circular reference ' +
          [...referencedAttributeNames, reference.referenceColumn].join(' -> '));
      }
      if (referencedAttribute.type === AttributeType.FORMULA) {
        const referencedExpression: Expression = getExpressionForAttribute(referencedAttribute);
        return resolveExpression(referencedExpression, appSchema, properties,
          [...referencedAttributeNames, reference.referenceColumn]);
      } else if (referencedAttribute.type === AttributeType.LAST_UPDATED) {
        return { type: AttributeType.CREATED_DATE, value: properties.__UPDATED || properties.__CREATED };
      } else if (referencedAttribute.type === AttributeType.CREATED_DATE) {
        return { type: AttributeType.CREATED_DATE, value: properties.__CREATED };
      } else {
        if (referencedAttribute.type === AttributeType.NUMBER && !properties[referencedAttribute.name]) {
          throw new Error('Missing value for number field: ' + referencedAttribute.name);
        } else if (referencedAttribute.type === AttributeType.TEXT ||
          referencedAttribute.type === AttributeType.LONG_TEXT) {
          const stringValue = !properties[referencedAttribute.name] ? '' : properties[referencedAttribute.name];
          return { type: referencedAttribute.type, value: stringValue };
        }
        return { type: referencedAttribute.type, value: properties[referencedAttribute.name] };
      }
    }
    case ExpressionType.OPERATION: {
      const operation = expression as OperationExpression;
      const leftResult = resolveExpression(operation.leftOperand, appSchema, properties, referencedAttributeNames);
      const rightResult = resolveExpression(operation.rightOperand, appSchema, properties, referencedAttributeNames);
      if (operation.operator === Operator.CAST) {
        // Casts need special handling because the return type is defined by the second operand,
        // which may only be a constant
        const targetType: AttributeType = rightResult.value as AttributeType;
        if (!ATTRIBUTE_TYPES.includes(targetType)) {
          throw new Error('Can not convert to unknown field type ' + targetType);
        }
        const handler = castHandlerTable[leftResult.type]?.[targetType];
        if (!handler) {
          throw new Error('Can not convert a ' + leftResult.type + ' to a ' + targetType);
        }
        return { type: targetType, value: handler(leftResult.value) };
      } else {
        const handler = operatorHandlerTable[operation.operator]?.[leftResult.type]?.[rightResult.type];
        if (!handler) {
          throw new Error('Can not apply the ' + operation.operator + ' operator to a ' +
            leftResult.type + ' and a ' + rightResult.type);
        }
        return { type: handler.returnType, value: handler.resolve(leftResult.value, rightResult.value) };
      }
    }
    case ExpressionType.FUNCTION: {
      const funcExpr = expression as FunctionExpression;
      const name = funcExpr.functionName;
      const functionHandler = functionHandlerTable[name];
      if (!functionHandler) {
        throw new Error(`Function expression has unknown function name ${name}`);
      }

      const expressionArgs = funcExpr.args.map(argExpr =>
        resolveExpression(argExpr, appSchema, properties, []));

      const returnTypeArgs = expressionArgs.map(arg => arg.type);
      const returnType = functionReturnTypeHandler[name]?.getReturnType(...returnTypeArgs);

      if (returnType) {
        const result = functionHandler(...expressionArgs);
        return { type: returnType, value: result };
      } else {
        throw new Error(`Invalid args for function name ${name}`);
      }
    }
  }
  throw new Error('Expression type missing or unknown ' + expression.expressionType);
}

function resolveReturnType(
  expression: Expression,
  appSchema: Attribute[],
  referencedAttributeNames: string[] = [],
  checkCircularReferences = true): AttributeType {
  switch (expression.expressionType) {
    case ExpressionType.CONSTANT: {
      const constant = expression as ConstantExpression;
      return constant.constantType;
    }
    case ExpressionType.REFERENCE: {
      const reference = expression as ReferenceExpression;
      const referencedAttribute: Attribute | undefined =
        appSchema.find(attribute => attribute.name === reference.referenceColumn);
      if (!referencedAttribute) {
        throw new Error('Formula refers to missing field ' + reference.referenceColumn);
      }
      if (checkCircularReferences && referencedAttributeNames.includes(referencedAttribute.name)) {
        throw new Error('Formula contains a circular reference ' +
          [...referencedAttributeNames, reference.referenceColumn].join(' -> '));
      }
      if (referencedAttribute.type === AttributeType.FORMULA) {
        const referencedExpression: Expression = getExpressionForAttribute(referencedAttribute);
        return resolveReturnType(referencedExpression, appSchema,
          [...referencedAttributeNames, reference.referenceColumn]);
      } else {
        return referencedAttribute.type;
      }
    }
    case ExpressionType.OPERATION: {
      const operation = expression as OperationExpression;
      const leftType = resolveReturnType(operation.leftOperand, appSchema, referencedAttributeNames);
      if (operation.operator === Operator.CAST) {
        // Casts need special handling because the return type is defined by the second operand,
        // which may only be a constant
        const typeExpression = operation.rightOperand as ConstantExpression;
        if (operation.rightOperand.expressionType !== ExpressionType.CONSTANT ||
          typeExpression.constantType !== AttributeType.TYPE) {
          throw new Error('Invalid definition of field type for conversion');
        }
        const targetType: AttributeType = typeExpression.value as AttributeType;
        if (!ATTRIBUTE_TYPES.includes(targetType)) {
          throw new Error('Can not convert to unknown field type ' + targetType);
        }
        if (!castHandlerTable[leftType]?.[targetType]) {
          throw new Error('Can not convert a ' + leftType + ' to a ' + targetType);
        }
        return targetType;
      } else {
        const rightType = resolveReturnType(operation.rightOperand, appSchema, referencedAttributeNames);
        const handler = operatorHandlerTable[operation.operator]?.[leftType]?.[rightType];
        if (!handler) {
          throw new Error('Can not apply the ' + operation.operator + ' operator to a ' +
            leftType + ' and a ' + rightType);
        }
        return handler.returnType;
      }
    }
    case ExpressionType.FUNCTION: {
      const functionExpression = expression as FunctionExpression;
      const argReturnTypes = functionExpression.args.map(argExpr =>
        resolveReturnType(argExpr, appSchema, referencedAttributeNames));
      const handler = functionReturnTypeHandler[functionExpression.functionName];

      if (!handler) {
        throw new Error('Invalid or missing function name ' + functionExpression.functionName);
      } else {
        return handler.getReturnType(...argReturnTypes);
      }
    }
  }
  throw new Error('Expression type missing or unknown ' + expression.expressionType);
}

function createOperationExpression(
  leftOperand: Expression | undefined,
  rightOperand: Expression | undefined,
  operator: { operator: string, precedingWhitespace: string } | undefined): OperationExpression {
  if (!leftOperand) {
    throw new Error('Missing left operand to ' + operator?.operator + ' expression.');
  }
  if (!rightOperand) {
    throw new Error('Missing right operand to ' + operator?.operator + ' expression.');
  }
  if (!operator || !(Object.values(Operator) as string[]).includes(operator.operator)) {
    if (operator?.operator as string === '(') {
      throw new Error('Mismatched parentheses, no matching ) for (');
    } else {
      throw new Error('Invalid operator ' + operator?.operator);
    }
  }
  const result: OperationExpression = {
    expressionType: ExpressionType.OPERATION,
    leftOperand: leftOperand,
    rightOperand: rightOperand,
    operator: operator.operator as Operator,
  };
  if (operator.precedingWhitespace && !['', ' '].includes(operator.precedingWhitespace)) {
    result.spaceBetween = operator.precedingWhitespace;
  }
  return result;
}

function processFormulaParentheses(expressionStack: Expression[],
  operatorStack: { operator: string, precedingWhitespace: string }[], token: Token): void {
  // work backwards through the stacks until encountering a (
  while (operatorStack.length) {
    const operator = operatorStack.pop();
    const nextOperator = operatorStack[operatorStack.length - 1];
    if (operator?.operator === '(' || operator?.operator === ',') {
      if (expressionStack.length) {
        const enclosedExpression = expressionStack[expressionStack.length - 1];
        if (!isFunctionOperator(nextOperator?.operator)) {
          enclosedExpression.bracketed = true;
        }

        // spaceBefore will only be defined for a terminal expression so it's safe to assign it as
        // spaceAfterBracket
        (enclosedExpression as TerminalExpression).spaceAfterBracket = enclosedExpression.spaceBefore;
        if (operator.precedingWhitespace && !['', ' '].includes(operator.precedingWhitespace)) {
          enclosedExpression.spaceBefore = operator.precedingWhitespace;
        }
        if (token.precedingWhitespace !== '') {
          enclosedExpression.spaceBeforeBracket = token.precedingWhitespace;
        }
      }

      if (isFunctionOperator(nextOperator?.operator) && token.value === ')') {
        const funcargs: Expression[] = [];
        const funcOp = operatorStack.pop();
        let arg = expressionStack.pop();
        while (arg && arg.expressionType !== ExpressionType.FUNCTION_CALL) {
          funcargs.push(arg);
          arg = expressionStack.pop();
        }

        expressionStack.push({
          expressionType: ExpressionType.FUNCTION,
          functionName: funcOp?.operator as FunctionName,
          args: funcargs.reverse(),
        } as FunctionExpression);
        currentFuncStack.shift();
      }

      // If we were processing a ',' token, then we've gone backwards and popped the opening '('. Push this back on the
      // operator stack - as we continue processing tokens, and hit another ',' or ')', we'll again work backwards to
      // this opening '('
      if (token.value === ',') {
        operatorStack.push({ operator: '(', precedingWhitespace: '' });
      }
      return;
    }
    const rightOperand = expressionStack.pop();
    const leftOperand = expressionStack.pop();
    const operation = createOperationExpression(leftOperand, rightOperand, operator);
    expressionStack.push(operation);
  }
  throw new Error('Mismatched parentheses, no matching ( for ) at ' + token.start);
}

function findAttributeTypeFor(type: string): AttributeType | undefined {
  for (const attributeType of ATTRIBUTE_TYPES) {
    if (type.toLowerCase() === attributeType.toLowerCase()) {
      return attributeType;
    }
  }
  return undefined;
}

/**
 * Execute parsing algorithm to update the current function status, but ignore the result. This will only parse the
 * expression string up to the given position index. This can be used to work out which argument/function highlighting
 * to show in the UI when the caret is _not_ at the end of the formula string.
 *
 *  e.g. "MIN(MAX(|FLOOR("  where | is the caret position, we want the arg highlighting for MAX (but our 'real' parsed
 *                          expression should still take the whole formula string into account)
 *
 *  @param expressionString The whole formula expression string
 *  @param expressionStringPosition The position in that string up to which to parse
 */
export function getFunctionAtPosition(expressionString: string, appSchema: Attribute[],
  expressionStringPosition: number): [FunctionName, number] | null {
  let tokens: Token[];
  try {
    tokens = tokeniseExpressionString(expressionString);
  } catch (err) {
    // Ignore tokenisation errors
    return null;
  }

  // Parse up to the token that includes the specified position.
  const lastTokenIdx = tokens.findIndex(tok => tok.start >= expressionStringPosition);
  if (lastTokenIdx > -1) {
    tokens = tokens.slice(0, lastTokenIdx);
  }

  try {
    parseExpressionTo(tokens, appSchema);
  } catch (err) {
    // Ignore parse errors as we're just trying to work out which function we're inside, up to the given position; we
    // don't need to display a UI error (that's based on the whole function expression string)
  }

  return currentFuncStack[0] ?? null;
}

export function clearCurrentFunctionStack(): void {
  currentFuncStack.splice(0, currentFuncStack.length);
}

export function parseExpression(expressionString: string, appSchema: Attribute[]): Expression {
  const tokens: Token[] = tokeniseExpressionString(expressionString);
  const finalExpression = parseExpressionTo(tokens, appSchema);
  if (finalExpression === undefined) {
    throw new Error('Failed to parse expression');
  }
  return finalExpression;
}

export function parseExpressionTo(tokens: Token[], appSchema: Attribute[]): Expression {
  // A formula should consist of a sequence of expressions separated by operators, starting and ending
  // with an expression, this boolean is to track if the next token should be an expression or an operator
  let expectExpressionNext = true;
  const expressionStack: Expression[] = [];
  const operatorStack: { operator: string, precedingWhitespace: string }[] = [];

  clearCurrentFunctionStack();

  // Parsing
  while (tokens.length) {
    const token = tokens.shift();
    if (token === undefined || !token.value.length) {
      continue;
    }

    if (token.value === ',') {
      processFormulaParentheses(expressionStack, operatorStack, token);
      // Increment argument count for the current function
      if (currentFuncStack[0]?.[1]) {
        currentFuncStack[0][1] += 1;
      }
      continue;
    }

    if (token.value === '(') {
      // TODO May want to reinstate this error check
      // if (!expectExpressionNext) {
      //   throw new Error('Unexpected ( at ' + token.start + ' expected an operator.');
      // }

      // If the previously encountered operator was a function, we know that the opening paren is the start of function
      // args. Push a sentinel expression onto the expression stack; when we're resolving and we hit a ), we can pop all
      // the expressions up to this sentinel and treat them as function args (and then discard the sentinel expression)
      const topOfOperatorStack = operatorStack[operatorStack.length - 1]?.operator;
      if (isFunctionOperator(topOfOperatorStack)) {
        expressionStack.push({
          expressionType: ExpressionType.FUNCTION_CALL
        });
        currentFuncStack.unshift([topOfOperatorStack as FunctionName, 1]);
      }
      operatorStack.push({ operator: token.value, precedingWhitespace: token.precedingWhitespace });
      continue;
    }
    if (token.value === ')') {
      // TODO May want to reinstate this error check
      // if (expectExpressionNext) {
      //   throw new Error('Unexpected ) at ' + token.start + ' expected a value or expression.');
      // }
      processFormulaParentheses(expressionStack, operatorStack, token);
      continue;
    }

    const operatorPrecedence = getOperatorPrecedence(token.value);

    // Token is an operator
    if (operatorPrecedence > -1) {
      if (expectExpressionNext) {
        throw new Error(
          'Unexpected operator (' + token.value + ') at ' + token.start + ' expected a value or expression.');
      }
      // Token is an operator, process any operator from the top of the stack with the same or higher
      // precedence
      while (operatorStack.length) {
        const topOperator = operatorStack[operatorStack.length - 1].operator.toUpperCase() as Operator;
        const topOperatorPrecedence = getOperatorPrecedence(topOperator);
        // If this operator is higher precedence or equal and is right associative, keep stacking the operations
        // so they're processed highest->lowest
        if (operatorPrecedence > topOperatorPrecedence ||
          (operatorPrecedence === topOperatorPrecedence && isRightAssociative(topOperator))) {
          break;
        } else {
          // The next operator has lower precedence or equal and is left associative
          // so the preceding expression needs to be processed first
          const rightOperand = expressionStack.pop();
          const leftOperand = expressionStack.pop();
          expressionStack.push(createOperationExpression(leftOperand, rightOperand, operatorStack.pop()));
        }
      }
      operatorStack.push({ operator: token.value.toUpperCase(), precedingWhitespace: token.precedingWhitespace });
      expectExpressionNext = true;
      continue;
    } else if (token.isFunction) {
      operatorStack.push({
        operator: token.value.toUpperCase(),
        precedingWhitespace: token.precedingWhitespace,
      });
      continue;
    } else {
      // TODO May want to reinstate this error check
      // if (!expectExpressionNext) {
      //   throw new Error(
      //     'Unexpected value or expression (' + token.value + ') at ' + token.start + ' expected an operator.');
      // }

      // this token is a variable or constant
      const attribute: Attribute | undefined = attributeForToken(token.value, appSchema);
      if (attribute) {
        const expression: ReferenceExpression =
          { expressionType: ExpressionType.REFERENCE, referenceColumn: attribute.name };
        if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
          expression.spaceBefore = token.precedingWhitespace;
        }

        expressionStack.push(expression);
        expectExpressionNext = false;
        continue;
      }
      // attribute type for cast operator
      const attributeType = findAttributeTypeFor(token.value);
      if (attributeType) {
        const expression: ConstantExpression = {
          expressionType: ExpressionType.CONSTANT,
          constantType: AttributeType.TYPE,
          value: attributeType,
        };
        if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
          expression.spaceBefore = token.precedingWhitespace;
        }
        expressionStack.push(expression);
        expectExpressionNext = false;
        continue;
      }
      // time unit type for date functions
      if (isTimeUnit(token.value)) {
        const expression: ConstantExpression = {
          expressionType: ExpressionType.CONSTANT,
          constantType: AttributeType.TIME_UNIT,
          value: token.value,
        };
        if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
          expression.spaceBefore = token.precedingWhitespace;
        }
        expressionStack.push(expression);
        expectExpressionNext = false;
        continue;
      }
      // Anything containing a number will parse as one, so rule out everything else first
      const number = parseFloat(token.value);
      if (!isNaN(number)) {
        if (token.value.endsWith('%')) {
          const expression: ConstantExpression = {
            expressionType: ExpressionType.CONSTANT,
            constantType: AttributeType.PERCENT,
            value: String(number),
          };
          if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
            expression.spaceBefore = token.precedingWhitespace;
          }
          expressionStack.push(expression);
        } else {
          const expression: ConstantExpression = {
            expressionType: ExpressionType.CONSTANT,
            constantType: AttributeType.NUMBER,
            value: String(number),
          };
          if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
            expression.spaceBefore = token.precedingWhitespace;
          }
          expressionStack.push(expression);
        }
        expectExpressionNext = false;
        continue;
      }
      if (token.value.startsWith('"') || token.value.startsWith('\'')) {
        const stringValue = token.value.substring(1, token.value.length - 1);
        const expression: ConstantExpression = {
          expressionType: ExpressionType.CONSTANT,
          constantType: AttributeType.TEXT,
          value: stringValue,
          quoteStyle: token.value[0],
        };
        if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
          expression.spaceBefore = token.precedingWhitespace;
        }
        expressionStack.push(expression);
        expectExpressionNext = false;
        continue;
      }
      if (['true', 'false'].includes(token.value.toLocaleLowerCase())) {
        const expression: ConstantExpression = {
          expressionType: ExpressionType.CONSTANT,
          constantType: AttributeType.CHECKBOX,
          value: token.value.toLocaleLowerCase() === 'true' ? 'true' : '',
        };
        if (token.precedingWhitespace && token.precedingWhitespace !== ' ') {
          expression.spaceBefore = token.precedingWhitespace;
        }
        expressionStack.push(expression);
        expectExpressionNext = false;
        continue;
      }
    }
    throw new Error('Cannot identify token ' + token.value + ' starting at position ' + token.start);
  }

  // Creating operation expressions
  while (expressionStack.length > 1) {
    const operator = operatorStack.pop();
    const rightOperand = expressionStack.pop();
    const leftOperand = expressionStack.pop();
    expressionStack.push(createOperationExpression(leftOperand, rightOperand, operator));
    expectExpressionNext = false;
  }

  // The expression stack should end up containing exactly one expression which included the whole tree
  const result = expressionStack.pop();
  if (!result) {
    throw new Error('Error parsing formula');
  }
  if (operatorStack.length > 0) {
    const lastOperator = operatorStack.pop();
    if (lastOperator?.operator === '(') {
      throw new Error('Mismatched parentheses, no matching ) for initial (');
    }
    if (!isFunctionOperator(lastOperator?.operator)) {
      throw new Error('Operator ' + lastOperator?.operator + ' is missing a value or expression');
    }
  }

  log.debug(`Returning final expression: ${JSON.stringify(result, null, 2)}`);
  return result;
}

function isFunctionOperator(operator: string | undefined): boolean {
  if (operator === undefined) {
    return false;
  }
  return Object.values(FunctionName).includes(operator.toUpperCase() as FunctionName);
}

function attributeForToken(token: string, appSchema: Attribute[]): Attribute | undefined {
  if (token.startsWith('{') && token.endsWith('}')) {
    const name = token.substring(1, token.length - 1);
    return appSchema.find((attribute: Attribute) => attribute.name === name);
  }
}

function tokeniseExpressionString(expressionString: string): Token[] {
  // TODO handle escaping where the last character of a string or attribute name needs to be \
  const tokens: Token[] = [];
  let precedingWhitespace = '';
  for (let i = 0; i < expressionString.length; i++) {
    const nextChar = expressionString.at(i);
    if (isWhitespace(nextChar)) {
      precedingWhitespace += nextChar;
      continue;
    }
    if (nextChar && singleCharTokens.includes(nextChar)) {
      tokens.push({
        value: nextChar,
        start: i,
        precedingWhitespace: precedingWhitespace,
      });
      precedingWhitespace = '';
      continue;
    }
    if (nextChar === '\'' || nextChar === '"') {
      // String token, find the closing unescaped quote
      const closing = indexOfNextUnescaped(expressionString, nextChar, i);
      if (closing > -1) {
        let stringValue = expressionString.substring(i, closing + 1);
        stringValue = stringValue.replaceAll('\\' + nextChar, nextChar);
        tokens.push({
          value: stringValue,
          start: i,
          precedingWhitespace: precedingWhitespace,
        });
        precedingWhitespace = '';
        i = closing;
      } else {
        throw new Error('Missing closing ' + nextChar + ' for string starting at ' + i);
      }
    } else if (nextChar === '{') {
      // Attribute name, find the closing unescaped }
      const closing = indexOfNextUnescaped(expressionString, '}', i);
      if (closing > -1) {
        let stringValue = expressionString.substring(i, closing + 1);
        stringValue = stringValue.replaceAll('\\}', '}');
        tokens.push({
          value: stringValue,
          start: i,
          precedingWhitespace: precedingWhitespace,
        });
        precedingWhitespace = '';
        i = closing;
      } else {
        throw new Error('Missing closing } for attribute starting at ' + i);
      }
    } else {
      // If we have tokens and the last one wasn't an operator then the next one should be
      const expectOperator = tokens.length !== 0 &&
        getOperatorPrecedence(tokens[tokens.length - 1].value) === -1 &&
        tokens[tokens.length - 1].value !== '(' && tokens[tokens.length - 1].value !== ',';

      // an operator or other literal, find the next whitespace
      const closing = findEndOfOperatorOrLiteral(expressionString, i, expectOperator);
      const str = expressionString.substring(i, closing + 1);
      if (isFunctionOperator(str)) {
        tokens.push({
          value: str,
          start: i,
          isFunction: true,
          precedingWhitespace,
        });
      } else {
        tokens.push({
          value: str,
          start: i,
          precedingWhitespace: precedingWhitespace,
        });
      }
      precedingWhitespace = '';
      i = closing;
    }
  }
  return tokens;
}

function isWhitespace(nextChar: string | undefined): boolean {
  return (nextChar || '').trim().length === 0;
}

function findEndOfOperatorOrLiteral(expressionString: string, from: number, expectOperator: boolean): number {
  // Numerical literal (including percentage)
  if (!isNaN(parseInt(expressionString.at(from) || '')) ||
    // if we're not expecting this token to be an operator then +, -, or . are probably
    // part of a numerical constant, so try to parse one out by preference
    (!expectOperator &&
      ['+', '-', '.'].includes(expressionString.at(from) || '') &&
      !isNaN(parseInt(expressionString.at(from + 1) || '')))) {
    for (let i = from + 1; i < expressionString.length; i++) {
      const thisChar = expressionString.at(i) || '';
      if (thisChar === '%') {
        return i;
      } else if (thisChar !== '.' && isNaN(parseInt(thisChar))) {
        return i - 1;
      }
    }
  } else {
    // Operators and types
    for (let i = from; i < expressionString.length; i++) {
      const candidateToken = expressionString.substring(from, i);
      const nextCandidate = expressionString.substring(from, i + 1);
      // An operator ends at a white space, single character token,
      // a number, or when the resulting string matches an operator
      // and adding the next character would make it not an operator
      // this only works because the only operators which start with
      // other operators are <= and >= which are only 1 character
      // longer than the operators they contain
      if (isWhitespace(expressionString.at(i)) ||
        singleCharTokens.includes(expressionString.at(i) || '') ||
        !isNaN(parseInt(expressionString.at(i) || '')) ||
        (expectOperator &&
          getOperatorPrecedence(candidateToken) !== -1 &&
          getOperatorPrecedence(nextCandidate) === -1) ||
        (!expectOperator &&
          (findAttributeTypeFor(candidateToken)) &&
          !findAttributeTypeFor(nextCandidate) &&
          !startOfFunctionName(expressionString, from)) ||
          isTimeUnit(candidateToken) ||
          (['true', 'false'].includes(candidateToken.toLowerCase()))) {
        return i - 1;
      }
    }
  }
  return expressionString.length;
}

// Before deciding to parse a string token as an attribute type, we need to check if it goes on to become a function
// name.
// For example, `date` is an attribute type, but we might be parsing 'dateadd', which is a function name.
// We don't want to have a `date` attribute expression parsed, followed by an 'add' text expression, so let's read up to
// the next opening bracket to see if there's a function name first. If it is, we'll create a `dateadd` function
// expression; if not we'll carry on with the creation of a `date` attribute type expression
function startOfFunctionName(expressionString: string, from: number): boolean {
  let functionNameCandidate = expressionString.at(from);
  if (functionNameCandidate === undefined) {
    return false;
  }

  // Get the next characters up to an opening (
  for (let i = from + 1; i < expressionString.length; i++) {
    const nextChar = expressionString.at(i);

    // We've read up to the next ( without finding a function name
    if (nextChar === '(') {
      return false;
    }

    functionNameCandidate += nextChar;
    if (isFunctionOperator(functionNameCandidate)) {
      return true;
    }
  }

  return false;
}

function isTimeUnit(token: string): boolean {
  return Object.values(TimeUnit).includes(token.toUpperCase() as TimeUnit);
}

/**
 * Returns true if the passed operator is right associative or false if it is left associative
 * Determines if operators with the same precedent should be processed left ot right or right to left
 * E.g. 1 + 2 - 3 parses as (1 + 2) - 3 but 1 ^ 2 ^ 3 parses as 1 ^ (2 ^ 3)
 * @param operator
 */
function isRightAssociative(operator: Operator): boolean {
  return rightAssociativeOpperators.includes(operator);
}

function getOperatorPrecedence(operator: string): number {
  // The order of precedence mostly matches that of Java except for exponentiation and cast
  // which are not operators in Java, the order for those is just set to try to match expectations
  // where possible
  const precedence = [ // Lowest to highest
    ['AS'],
    ['OR'],
    ['AND'],
    ['=', '!='],
    ['<', '>', '<=', '>='],
    ['+', '-'],
    ['/', '*', '%'],
    ['^'],
    [',']
  ];
  for (let i = 0; i < precedence.length; i++) {
    if (precedence[i].includes(operator.toUpperCase())) {
      return i;
    }
  }
  return -1;
}

export function rebuildFormula(formulaString: string): string {
  const formulaExpression: Expression = JSON.parse(formulaString);
  return expressionAsString(formulaExpression);
}
