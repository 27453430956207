import { computed, ComputedRef, onUnmounted, Ref, ref } from 'vue';

// 20 minutes - The period (in milliseconds) of inactivity required to enter the IDLE state
const IDLE_PERIOD_MS: number = 20 * 60 * 1000;

export function useUserIdleDetection() {
  const idleTimeoutHandle: Ref<number | null> = ref(null);
  const isUserIdle: Ref<boolean> = ref(false);

  const userIdle: ComputedRef<boolean> = computed(() => {
    return isUserIdle.value;
  });

  /**
   * Function used for monitoring user activity
   *
   * Possible TODO: Would it be more efficient, performance wise, to use timestamp variables
   * rather than constantly set/reset timers?
   * All the idle timer lib I have seen admittedly do use the timer mechanism but still could
   * be something to consider.
   *
   * Another potentially better improvement could perhaps be to keep the timer/timestamp handlings
   * in a separate file, and just keep the result in the Vue component. This would minimise the
   * Vue state changes (and could possibly be more critical than the timer vs timestamp variable ).
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onUserActivity(event: Event): void {
    isUserIdle.value = false;
    if (idleTimeoutHandle.value) {
      window.clearTimeout(idleTimeoutHandle.value);
    }

    idleTimeoutHandle.value = window.setTimeout((): void => {
      idleTimeoutReached();
    }, IDLE_PERIOD_MS);
  }

  function idleTimeoutReached(): void {
    isUserIdle.value = true;
    if (idleTimeoutHandle.value) {
      window.clearTimeout(idleTimeoutHandle.value);
      idleTimeoutHandle.value = null;
    }
  }

  onUnmounted(() => {
    window.removeEventListener('mousedown', onUserActivity);
    window.removeEventListener('mousemove', onUserActivity);
    window.removeEventListener('mousewheel', onUserActivity);
    window.removeEventListener('pointerdown', onUserActivity);
    window.removeEventListener('keydown', onUserActivity);
    window.removeEventListener('touchmove', onUserActivity);
    window.removeEventListener('touchstart', onUserActivity);
    window.removeEventListener('wheel', onUserActivity);

    if (idleTimeoutHandle.value) {
      window.clearTimeout(idleTimeoutHandle.value);
      idleTimeoutHandle.value = null;
    }
  });

  window.addEventListener('mousedown', onUserActivity);
  window.addEventListener('mousemove', onUserActivity);
  window.addEventListener('mousewheel', onUserActivity);
  window.addEventListener('pointerdown', onUserActivity);
  window.addEventListener('keydown', onUserActivity);
  window.addEventListener('touchmove', onUserActivity);
  window.addEventListener('touchstart', onUserActivity);
  window.addEventListener('wheel', onUserActivity);

  idleTimeoutHandle.value = window.setTimeout((): void => {
    idleTimeoutReached();
  }, IDLE_PERIOD_MS);

  return {
    userIdle,
  };
}
