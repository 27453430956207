import { defineStore } from 'pinia';
import Vue, { Ref, ref } from 'vue';

import { EntryThumbnail } from '@/data/datatypes/EntryThumbnails';
import { patchArray, patchObject } from '@/stores/StoreHelper';

export const useEntryThumbnailsStore = defineStore('EntryThumbnails', () => {
  const thumbnailsForTrack: Ref<Record<string, EntryThumbnail[]>> = ref({});

  function setThumbnails(details: { thumbnails: EntryThumbnail[], fullRefresh: boolean }): void {
    const byTrackId: Record<string, EntryThumbnail[]> = {};
    for (const thumbnail of details.thumbnails) {
      if (!byTrackId[thumbnail.trackId]) {
        Vue.set(byTrackId, thumbnail.trackId, []);
      }
      byTrackId[thumbnail.trackId].push(thumbnail);
    }
    patchObject(thumbnailsForTrack.value, byTrackId, details.fullRefresh);
  }

  function setThumbnailsForTrack(details: { trackId: string, thumbnails: EntryThumbnail[] }): void {
    if (!thumbnailsForTrack.value[details.trackId]) {
      Vue.set(thumbnailsForTrack.value, details.trackId, []);
    }
    patchArray(thumbnailsForTrack.value[details.trackId], details.thumbnails, true);
  }

  function cleanupUnobservedTracks(details: { activeTrackId?: string, meetingTrackId?: string }): void {
    const activeTrackId = details.activeTrackId;
    const meetingTrackId = details.meetingTrackId;
    // Clean up records that aren't for the active track / meeting track.
    for (const trackId of Object.keys(thumbnailsForTrack.value)) {
      if ((trackId !== activeTrackId) && (trackId !== meetingTrackId)) {
        Vue.delete(thumbnailsForTrack.value, trackId);
      }
    }
  }

  return {
    thumbnailsForTrack,
    setThumbnails,
    setThumbnailsForTrack,
    cleanupUnobservedTracks
  };
});
