import log from 'loglevel';
import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { ReleasedApp } from '@/data/version/DeployedApp';

import { patchObject } from '../StoreHelper';

export const useReleasedAppsStore = defineStore('ReleasedApps', () => {
  const releasedApps: Ref<Record<string, ReleasedApp>> = ref({});

  function setReleasedApps(newApps: ReleasedApp[]): void {
    log.debug(`Setting ${newApps.length} released apps`);
    const updatedReleasedApps: Record<string, ReleasedApp> = {};
    newApps.forEach(app => { updatedReleasedApps[app.releaseId] = app; });
    patchObject(releasedApps.value, updatedReleasedApps, true);
  }

  return {
    releasedApps,
    setReleasedApps,
  };
});
