import OpenGraph from '@/data/datatypes/chat/OpenGraph';
import { MeetingParticipant } from '@/data/datatypes/meeting/PastMeetingDetails';
import { EntryVersion } from '@/stores/TrackEntries.types';

import ScheduledMeeting from './meeting/ScheduledMeeting';
import { MemberEntryAccess } from './MemberEntryAccess';
import { ViewedBy } from './ViewedBy';

/* eslint-disable  camelcase */
export enum EntryType {
  unknown = 'unknown',
  email = 'email',
  file = 'file',
  meeting = 'meeting',
  link = 'link',
  agenda = 'agenda',
  actions = 'actions',
  meeting_record = 'meeting_record',
  meeting_invite = 'meeting_invite',
  folder = 'folder',
  private = 'private',
  publish_room = 'publish_room', // Used for publishable rooms
  appItem = 'app_item',
  shared_content = 'shared_content',
}

export const EntryTypeName = {
  unknown: 'Unknown',
  email: 'Email',
  file: 'File',
  meeting: 'Meeting',
  link: 'Link',
  agenda: 'Agenda',
  actions: 'Actions',
  meeting_record: 'Meeting Record',
  meeting_invite: 'Meeting Invite',
  folder: 'Folder',
  private: 'Private',
  app_item: 'App Item',
  shared_content: 'Shared Content'
};
/* eslint-enable  camelcase */

export interface TrackEntryDetails {
  trackId: string;
  type?: EntryType;
  title?: string;
  subType?: string;
  created?: number;
  lastUpdated?: number;
  parentId?: string;
  authorId?: string;
  state?: Record<string, unknown>;
  meetingUuid?: string;
  meetingNumber?: string;
  url?: string;
  defaultEntry?: boolean;
  authorized?: boolean;
  downloadable?: boolean;
  emailMessageId?: string;
  access?: MemberEntryAccess[];
  body?: string;
  subject?: string;
  text?: Text[];
  viewedBy?: ViewedBy[];
  // eslint-disable-next-line no-use-before-define
  innerEntry?: TrackEntry;
  chatMessageId?: string;
  reactions?: Record<string, string[]>;
  openGraphItems?: OpenGraph[];
  commentCount?: number;
  workspacePublisherId?: string;
  currentVersion?: EntryVersion;
}

export interface TrackEntry extends TrackEntryDetails {
  id: string;
}

export interface MeetingTrackEntry extends TrackEntry {
  participants?: MeetingParticipant [];
  startDate?: number;
  endDate?: number;
  rowNumber?: number; // used for calendar month view
  hourNumber?: number; // used for calendar week view
  colorOverride?: string; // used for calendar view
  busySlot?: boolean; // used for calendar view
  busySlotOwner?: string; // email used for calendar view (needed because not a real meeting).
  startHourSlot?: number;
  endHourSlot?: number;
  delta?: number;
  durationInMinute?: number;
  level?: number;
  marginLeft?: number;
  width?: number;
  isRightMost?: boolean;
  isBlocked?: boolean;
  meetingFromCalendarIntegration?: ScheduledMeeting;
}

export interface EntryDetails {
  entryId?: string,
  subject: string,
  body: string,
  refreshToken: string,
}

/**
 * Returns true if the server is able to publish this kind of entry.
 * (this is in the context of workspaces publication).
 */
export function isEntryPublishable(entry: TrackEntry): boolean {
  if (!entry) {
    return false;
  }
  if (entry.type === EntryType.file) {
    return true;
  }
  if (entry.type === EntryType.appItem) {
    switch (entry.subType) {
      case 'googledrive':
      case 'onedrive':
        return true;
      default:
        return false;
    }
  }
  return false;
}
