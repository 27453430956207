import log from 'loglevel';

import { MeetingsGetterInterface, MeetingsInterface } from '@/custom_typings/cafexmeetings/meetings-api';
import { perfLog, perfMsg } from '@/data/log/PerformanceLog';

import Integrations from './config/Integrations';
import { niceLoadScript } from './helpers/ScriptHelper';

class DummyMeetingsApi implements MeetingsGetterInterface {
  public getInstance(): MeetingsInterface {
    throw new Error('Not yet initialised');
  }
}
(window as unknown as Record<string, unknown>).MeetingsApi = new DummyMeetingsApi();

export default class MeetingsApiAccessor {
  private static promise: Promise<void>;
  /**
   * Add required resources to the DOM.
   */
  public static async initialiseMeetings(): Promise<void> {
    const loadStart: number = performance.now();
    log.debug('Meetings API initialising');
    if (MeetingsApiAccessor.promise !== undefined) {
      return MeetingsApiAccessor.promise;
    }

    let resolution: () => void;

    MeetingsApiAccessor.promise = new Promise<void>(resolve => {
      resolution = resolve;
    });

    const sipjsLoaded = new Promise<void>((resolve) => {
      niceLoadScript(`${Integrations.MEETINGS_CLIENT}/assets/sip-0.13.8.min.js`, () => {
        resolve();
      }, 'sipjs');
    });
    const adapterjsLoaded = new Promise<void>((resolve) => {
      niceLoadScript(`${Integrations.MEETINGS_CLIENT}/assets/adapter.min.js`, () => {
        resolve();
      });
    });
    const uaparserLoaded = new Promise<void>((resolve) => {
      niceLoadScript(`${Integrations.MEETINGS_CLIENT}/assets/ua-parser.min.js`, () => {
        resolve();
      });
    });

    await Promise.all([sipjsLoaded, adapterjsLoaded, uaparserLoaded]);

    niceLoadScript(`${Integrations.MEETINGS_CLIENT}/meetingscompiled.min.js`, () => {
      MeetingsConfig.JOIN_PARTICIPANT_CONFERENCE = false;
      MeetingsConfig.STOP_VIDEO_ON_SCREENSHARE = false;
      MeetingsConfig.USE_PASSTHROUGH_SCREENSHARE = true;

      resolution();
      perfLog.debug(perfMsg('Meetings API initialised', false, loadStart));
    });

    return MeetingsApiAccessor.promise;
  }
}
