/**
 * Determines if the contrast colour to go with the given colour should be darker or lighter.
 *
 * If you use black where this function returns true and white otherwise, then it will meet the minimum WCAG AA colour
 * contrast requirement of 4.5 for normal text.
 *
 * @param hexColour The colour to check
 * @returns true if the contrast colour should be darker than the specified colour, otherwise false.
 */
export const needsDarkContrast = (hexColour: string): boolean => {
  const hexCode = extractHexCode(hexColour);

  const red = parseInt(hexCode.substring(0, 2), 16);
  const green = parseInt(hexCode.substring(2, 4), 16);
  const blue = parseInt(hexCode.substring(4, 6), 16);

  // The threshold value (0.179) has been calculated assuming contrast colours of black and white
  // i.e. contrast ratio between hexColour and black compared to contrast ratio between white and hexColour
  return relativeLuminance(red, green, blue) > 0.179;
};

/**
 * Get a contrast colour (black or white) for provided colour.
 *
 * The returned contrast colour will always meet the required minimum WCAG AA colour contrast of 4.5 for normal text.
 *
 * @param hexColour Background colour in hexadecimal form
 */
export const getContrastColour = (hexColour: string): string => {
  const black = '#000000';
  const white = '#ffffff';

  return needsDarkContrast(hexColour) ? black : white;
};

/**
 * Calculate the contrast ratio of two colours given the relative luminance of each.
 *
 * @param relativeLuminanceLighter Relative luminance of the lighter of the colours
 * @param relativeLuminanceDarker Relative luminance of the darker of the colours
 */
export const contrastRatio = (relativeLuminanceLighter: number, relativeLuminanceDarker: number): number => {
  return (relativeLuminanceLighter + 0.05) / (relativeLuminanceDarker + 0.05);
};

/**
 * Calculate the relative luminance for colour specified by colour components.
 *
 * @param red 8-bit red component
 * @param green 8-bit green component
 * @param blue 8-bit blue component
 */
export const relativeLuminance = (red: number, green: number, blue: number): number => {
  const linearValues = [red, green, blue].map((component) => {
    // Normalise colour component to value in [0, 1]
    const normalised = component / 255;
    // Calculate gamma-expanded value
    return normalised <= 0.03928 ? normalised / 12.92 : Math.pow((normalised + 0.055) / 1.055, 2.4);
  });

  return linearValues[0] * 0.2126 + linearValues[1] * 0.7152 + linearValues[2] * 0.0722;
};

export function lightenColour(hexColour: string, percentage: number): string {
  const hexCode = extractHexCode(hexColour);

  const amt = Math.round(2.55 * percentage);

  const red = parseInt(hexCode.substring(0, 2), 16) + amt;
  const green = parseInt(hexCode.substring(2, 4), 16) + amt;
  const blue = parseInt(hexCode.substring(4, 6), 16) + amt;

  return rgbToHex(red, green, blue);
}

// Original source: https://stackoverflow.com/questions/13348129/using-native-javascript-to-desaturate-a-colour
/**
 * Alter the saturation of the given colour.
 * @param hexColour The colour to change the saturation of
 * @param percentage The percentage saturation to apply
 * @returns The modified colour
 */
export function saturateColour(hexColour: string, percentage: number): string {
  const hexCode = extractHexCode(hexColour);

  const amt = Math.round(2.55 * percentage);

  const red = Math.min(255, parseInt(hexCode.substring(0, 2), 16) + amt);
  const green = Math.min(255, parseInt(hexCode.substring(2, 4), 16) + amt);
  const blue = Math.min(255, parseInt(hexCode.substring(4, 6), 16) + amt);

  // Magic values based on average vision: https://en.m.wikipedia.org/wiki/Grayscale#Luma_coding_in_video_systems
  const grey = red * 0.3086 + green * 0.6094 + blue * 0.0820;

  const fract = percentage / 100;
  const satR: number = Math.round(red * fract + grey * (1 - fract));
  const satG: number = Math.round(green * fract + grey * (1 - fract));
  const satB: number = Math.round(blue * fract + grey * (1 - fract));

  return rgbToHex(satR, satG, satB);
}

export function isWhite(colour: string): boolean {
  return !!colour.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i);
}

function extractHexCode(hexColour: string): string {
  let hexCode = '';

  // Strip leading '#' character if present
  if (hexColour.charAt(0) === '#') {
    hexCode = hexColour.slice(1);
  }

  // Ensure six-character hex code
  if (hexColour.length === 3) {
    hexCode = hexColour.split('').map(hexCharacter => hexCharacter + hexCharacter).join('');
  }

  return hexCode;
}

function rgbToHex(red: number, green: number, blue: number): string {
  return '#' +
    (red < 255 ? red < 1 ? 0 : red : 255).toString(16).padStart(2, '0') +
    (green < 255 ? green < 1 ? 0 : green : 255).toString(16).padStart(2, '0') +
    (blue < 255 ? blue < 1 ? 0 : blue : 255).toString(16).padStart(2, '0');
}
