import { computed, ComputedRef } from 'vue';

import { Notification } from '@/data/datatypes/Notification';
import { ActivityType } from '@/data/datatypes/Track';
import { FullUserDetails, LimitedUserDetails } from '@/data/datatypes/UserDetails';
import KnowledgeBaseNotificationBuilder from '@/data/helpers/notifications/KnowledgeBaseNotificationBuilder';
import { sortNotifications } from '@/data/helpers/notifications/NotificationHelper';
import TrackActivityNotificationBuilder from '@/data/helpers/notifications/TrackActivityNotificationBuilder';
import { MiniApp } from '@/data/tasks/MiniApp';
import { TasksTable } from '@/data/tasks/TasksTable';
import { MiniAppView, TaskViewType } from '@/data/tasks/TaskView';
import { useKnowledgeBaseStore } from '@/stores/KnowledgeBase';
import { DEFAULT_COLLABORATION_APP_ID, useTasksStore } from '@/stores/Tasks';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

export function useNotifications() {
  const tracksStore = useTracksStore();
  const userStore = useUserStore();
  const tasksStore = useTasksStore();
  const knowledgeBaseStore = useKnowledgeBaseStore();

  const currentUser: ComputedRef<FullUserDetails> = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return userStore.currentUser!;
  });

  const displayDetailsForUsers: ComputedRef<Map<string, LimitedUserDetails>> = computed(() => {
    return userStore.displayDetailsForUsers;
  });

  const allMiniAppsById: ComputedRef<Record<string, MiniApp>> = computed(() => {
    return tasksStore.allMiniAppsById;
  });

  const allMiniAppViews: ComputedRef<MiniAppView[]> = computed(() => {
    return tasksStore.allMiniAppViews;
  });

  function getViewForTable(app: MiniApp, tableId: string | undefined): MiniAppView | undefined {
    if (tableId) {
      const viewTypesToConsider = [TaskViewType.BASE, TaskViewType.KANBAN, TaskViewType.CARD];
      const defaultViewIdForApp: string = app?.defaultViewId ?? '';
      // Return the default view if it's for this table, or the first useful view otherwise.
      return allMiniAppViews.value.reduce(
        (potentialViewToOpen: MiniAppView | undefined, viewToConsider: MiniAppView) => {
          if (viewToConsider.tableId === tableId && viewTypesToConsider.indexOf(viewToConsider.type) > -1) {
            if (!potentialViewToOpen) {
              return viewToConsider;
            }
            if (potentialViewToOpen.id === defaultViewIdForApp) {
              return potentialViewToOpen;
            }
            if (viewToConsider.id === defaultViewIdForApp) {
              return viewToConsider;
            }
          }
          return potentialViewToOpen;
        }, undefined);
    }
  }

  const allNotifications: ComputedRef<Notification[]> = computed(() => {
    const notifications: (Notification | undefined)[] = [];
    if (tracksStore.groupedTrackActivities) {
      notifications.push(...tracksStore.groupedTrackActivities.map(ta => {
        let tables: TasksTable[];
        if (ta[0].tableId && tasksStore.tables[ta[0].track.id] && tasksStore.tables[ta[0].track.id][ta[0].tableId]) {
          tables = [tasksStore.tables[ta[0].track.id][ta[0].tableId]];
        } else if (ta[0].track.environmentId) {
          tables = tasksStore.tablesByEnvironment[ta[0].track.environmentId] ?? [];
        } else {
          tables = tasksStore.getDevelopmentTablesForTenantApps;
        }
        const table = tables.find(table => table.id === ta[0].tableId);
        const appId = table?.miniAppId || ta[0].track.owningMiniAppId;
        const app = allMiniAppsById.value[appId || DEFAULT_COLLABORATION_APP_ID];
        const view = getViewForTable(app, ta[0].tableId);
        if (ta[0].type === ActivityType.NON_TASK_RECORD_MODIFIED) {
          ta[0].message = 'modified ' + ta.length + ' ' + table?.name + ((ta.length === 1) ? ' record' : ' records');
        }
        const builder = new TrackActivityNotificationBuilder(
          currentUser.value,
          displayDetailsForUsers.value,
          ta[0],
          app,
          view,
          ta.slice(1));
        const notification = builder.build();
        if (notification) {
          notification.deleteHandler = () => {
            for (const activity of ta) {
              tracksStore.removeTrackActivity(activity);
            }
          };
        }
        return notification;
      }));
    }

    if (knowledgeBaseStore.allArticlesWithNotifications) {
      knowledgeBaseStore.allArticlesWithNotifications.forEach(a => {
        if (a.notifications) {
          notifications.push(...a.notifications?.map(n => {
            const builder = new KnowledgeBaseNotificationBuilder(displayDetailsForUsers.value, n);
            const notification = builder.build();
            notification.deleteHandler = () => {
              knowledgeBaseStore.removeArticleNotification({ article: a, notification: n });
            };
            return notification;
          }));
        }
      });
    }

    // Explicit cast when filtering out the undefined values to keep the compiler happy
    return (notifications.filter(n => !!n) as Notification[]).sort(sortNotifications).reverse();
  });

  return {
    allNotifications,
  };
}
