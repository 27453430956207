export const extensionTitleRemover = (titleInput: string): string => {
  if (!titleInput || titleInput === 'null') {
    return 'Unknown Entry';
  }
  return titleInput.replace(/\.[^/.]+$/, '');
};

export const getExtension = (filename: string): string | null => {
  if (filename.indexOf('.') < 0) {
    return null;
  }

  const extension = /[^.]+$/.exec(filename);
  return extension ? extension[0] : null;
};
