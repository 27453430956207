const UPDATE_RECHECK_DELAY = (10 + (Math.random() * 5)) * 60 * 1000;
let updateCheckTimer: ReturnType<typeof setTimeout>;

/**
 * Check for an update to the service worker and schedule a further check in 2 minutes to allow for
 * the deployment of the new service worker lagging the deployment of the back end server
 */
export function checkForServiceWorkerUpdate(): void {
  clearTimeout(updateCheckTimer);
  updateCheckTimer = setTimeout(() => {
    navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        registration.update();
      }
    });
  }, UPDATE_RECHECK_DELAY);
}
