import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { AppDeploymentRecord } from '@/data/version/AppDeploymentRecord';

import { patchObject } from '../StoreHelper';

export const useDeploymentHistoryStore = defineStore('DeploymentHistory', () => {
  const environmentAppDeploymentRecords: Ref<Record<string, AppDeploymentRecord[]>> = ref({});

  // TODO clean up records for environments when the environment is removed

  function setDeploymentRecords(newRecords: AppDeploymentRecord[]): void {
    const updated: Record<string, AppDeploymentRecord[]> = {};
    newRecords.forEach(rec => {
      if (!updated[rec.environmentId]) {
        updated[rec.environmentId] = [];
      }
      updated[rec.environmentId].push(rec);
    });
    patchObject(environmentAppDeploymentRecords.value, updated);
  }

  return {
    setDeploymentRecords,
    environmentAppDeploymentRecords,
  };
});
