export enum Direction {
  IN = 'IN',
  OUT = 'OUT',
}

export interface Relationship {
  id?: string;
  label: string;
  direction: Direction;
  targetId: string;
  targetLabel: string;
  properties?: Record<string, Record<string, unknown>>;
}

export function cloneRelationship(relationship: Relationship): Relationship {
  const id = relationship?.id;
  const label = relationship.label;
  const direction = relationship.direction;
  const targetId = relationship.targetId;
  const targetLabel = relationship.targetLabel;
  const properties = relationship.properties ? Object.assign({}, relationship.properties) : undefined;

  return { id, label, direction, targetId, targetLabel, properties };
}
