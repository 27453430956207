export const AppName: string = 'CafeX';

export class BrandColors {
  public static DISCO_PURPLE: string = '#410ABC';

  // Old branding below

  public static BRIGHT_GREEN: string = '#96BC00';
  public static GREEN_STROKE: string = '#689400';
  public static GREEN: string = '#3A913F';
  public static DARK_GREEN: string = '#007B5F';
  public static DARK_TURQUOISE: string = '#1A8EA0';
  public static LIGHT_TURQUOISE: string = '#00BBDC';
  public static BRIGHT_BLUE: string = '#0094BA';
  public static DARK_BLUE: string = '#00629B';
  public static VERY_DARK_BLUE: string = '#00263A';
  public static BRIGHT_YELLOW: string = '#FFB81C';
  public static ORANGE: string = '#EF9600';
  public static ORANGE_STROKE: string = '#A35710';
  public static BRIGHT_RED: string = '#D50032';
  public static DARK_RED: string = '#A50034';
  public static PLUM: string = '#702263';
  public static DARK_FUCHSIA: string = '#A62182';
  public static FUCHSIA: string = '#D53A99';
  public static SOFT_PINK: string = '#FFAAE9';
  public static GREY: string = '#BEC6C8';
  public static GREY_ICON: string = '#929FA2';
  public static GREY_ALT: string = '#DCDFE0';
  public static GREY_VERY_DARK: string = '#293133';

  // This is reserved for primary UI color use
  public static UI_PRIMARY_BLUE: string = '#4CAEE5';

  public static APP_COLOUR_CHAT: string = '#88B800';
  public static APP_COLOUR_WORKFLOWS: string = '#E17055';
  public static APP_COLOUR_MEETINGS: string = '#FDCB6E';
  public static APP_COLOUR_ASSETS: string = '#09D6E3';

  public static CALENDAR_EVENT_BLUE: string = '#2d73af';

  public static DEFAULT_GENERATED_COLOURS: string[] = [
    BrandColors.BRIGHT_GREEN,
    BrandColors.BRIGHT_BLUE,
    BrandColors.BRIGHT_YELLOW,
    BrandColors.BRIGHT_RED,
    BrandColors.DARK_GREEN,
    BrandColors.DARK_BLUE,
    BrandColors.ORANGE,
    BrandColors.DARK_RED,
    BrandColors.GREEN_STROKE,
    BrandColors.VERY_DARK_BLUE,
    BrandColors.PLUM,
    BrandColors.ORANGE_STROKE,
    BrandColors.GREEN,
    BrandColors.DARK_TURQUOISE,
    BrandColors.DARK_FUCHSIA,
    BrandColors.LIGHT_TURQUOISE,
    BrandColors.FUCHSIA,
    BrandColors.SOFT_PINK,
    BrandColors.GREY,
  ];
}

/**
 * Generates a repeating sequence of the default generated colours array, up to a length of `numberOfRequiredColours`.
 * Useful, e.g., for ChartJS charts that require the provided colours array to be the same length as its data array.
 *
 * @param numberOfRequiredColours The total number of colours required. The
 * {@link BrandColors.DEFAULT_GENERATED_COLOURS} will be partially or wholly repeated to match the number of colours
 * needed.
 */
export function repeatDefaultGeneratedColours(numberOfRequiredColours: number): string[] {
  const colours: string[] = [];
  const numDefaultGeneratedColours = BrandColors.DEFAULT_GENERATED_COLOURS.length;
  for (let i = 0; i < numberOfRequiredColours; i++) {
    colours.push(BrandColors.DEFAULT_GENERATED_COLOURS[i % numDefaultGeneratedColours]);
  }
  return colours;
}

export function generateSeedColour(): string {
  const colours = [BrandColors.BRIGHT_GREEN, BrandColors.GREEN_STROKE, BrandColors.GREEN, BrandColors.DARK_GREEN,
    BrandColors.DARK_TURQUOISE, BrandColors.LIGHT_TURQUOISE, BrandColors.BRIGHT_BLUE,
    BrandColors.DARK_BLUE, BrandColors.BRIGHT_YELLOW, BrandColors.ORANGE_STROKE,
    BrandColors.ORANGE, BrandColors.BRIGHT_RED, BrandColors.DARK_RED,
    BrandColors.PLUM, BrandColors.DARK_FUCHSIA];
  const index = Math.floor(Math.random() * colours.length);
  return colours[index];
}

export interface AppPattern {
  gradient: string,
  color: string,
}

export const gradientSet1: Record<string, AppPattern> = {
  'crease-01': {
    gradient: 'conic-gradient(from 65deg at 17.23% 77.87%, #B3C256 0deg, #D3D65A 360deg)',
    color: '#b4c456',
  },
  'crease-02': {
    gradient: 'conic-gradient(from 245deg at 60.07% 50%, #63E1DA 0deg, #3FB0D3 360deg)',
    color: '#40b2d3',
  },
  'crease-03': {
    gradient: 'conic-gradient(from 112deg at 52.82% 41.98%, #EA7474 0deg, #FFB13D 360deg)',
    color: '#ffb13d',
  },
  'crease-04': {
    gradient: 'conic-gradient(from 156.22deg at 19.78% 41.07%, #CB65B4 0deg, #FFB13D 360deg)',
    color: '#ffb13d',
  },
  'crease-05': {
    gradient: 'conic-gradient(from 290deg at 55.82% 42.98%, #FF96E2 0deg, #C74FFF 360deg)',
    color: '#c64ffe',
  },
  'crease-06': {
    gradient: 'conic-gradient(from 63deg at 48.97% 55.34%, #F096FF 0deg, #A55DED 360deg)',
    color: '#a55ded',
  },
  'crease-07': {
    gradient: 'conic-gradient(from 252deg at 87.62% 50%, #6C98A9 0deg, #456068 342.07deg, #6C98A9 360deg)',
    color: '#5d818f',
  },
  'crease-08': {
    gradient: 'conic-gradient(from 239deg at 58.48% 36.18%, #6C98A9 0deg, #456068 1.38deg, #6C98A9 360deg)',
    color: '#47636b',
  },
  'crease-09': {
    gradient: 'conic-gradient(from 129deg at 58.48% 36.18%, #6CA5A9 0deg, #456862 1.38deg, #6CA5A9 360deg)',
    color: '#6aa1a5',
  },
};

export const gradientSet2: Record<string, AppPattern> = {
  'pastel-01': {
    gradient: 'conic-gradient(from 134.41deg at 0% 100%, #D8B7D4 0deg, #6A88BA 221.94deg, #D8B7D4 360deg)',
    color: '#a8a3c9',
  },
  'pastel-02': {
    gradient: 'conic-gradient(from 134.41deg at 0% 100%, #FFB8A2 0deg, #BB91C6 221.94deg, #FFB8A2 360deg)',
    color: '#d8a2b7',
  },
  'pastel-03': {
    gradient: 'linear-gradient(114.13deg, #B479FF 0%, #FFCD4E 101.33%)',
    color: '#c890d5',
  },
  'pastel-04': {
    gradient: 'linear-gradient(105.48deg, #FFC476 34.54%, #D264E4 118.55%)',
    color: '#ffc475',
  },
  'pastel-05': {
    gradient: 'linear-gradient(105.48deg, rgb(74 223 232) 9.54%, rgb(210, 100, 228) 118.55%)',
    color: '#c4a0ec',
  },
  'pastel-06': {
    gradient: 'linear-gradient(114.13deg, #79D7FF 0%, #C376FF 101.33%)',
    color: '#a6a3ff',
  },
  'pastel-07': {
    gradient: 'linear-gradient(114.13deg, #68DCB9 0%, #AF93FF 101.33%)',
    color: '#80cacb',
  },
  'pastel-08': {
    gradient: 'linear-gradient(114.13deg, rgb(77 202 255) 0%, rgb(224 229 0) 101.33%)',
    color: '#9fd898',
  },
  'pastel-09': {
    gradient: 'linear-gradient(113.95deg, rgb(255 231 33) 0.13%, rgb(229, 92, 232) 114.92%)',
    color: '#ec8cd8',
  },
};

export const gradientSet3: Record<string, AppPattern> = {
  'foil-01': {
    gradient: 'conic-gradient(from -72.1deg at 30.32% -9.82%, #62E0DA -24.29deg, #419AB5 141.25deg, #5ED8D5 220.9deg,' +
      ' #8CEBE6 233.9deg, #3798B6 234.41deg, #38AAC7 254.59deg, #62E0DA 335.71deg, #419AB5 501.25deg)',
    color: '#55c2ca',
  },
  'foil-02': {
    gradient: 'conic-gradient(from 320deg at 56.24% -14.31%, #EE9C9C -24.29deg, #419AB5 141.25deg, #E59393 220.9deg,' +
      ' #FFB6B6 233.9deg, #B96767 234.41deg, #C87676 254.59deg, #EE9C9C 335.71deg, #419AB5 501.25deg)',
    color: '#ca7777',
  },
  'foil-03': {
    gradient: 'conic-gradient(from 95deg at 35.6% 107.8%, #D1E16F -58.12deg, #899540 88.18deg, #C5CD59 218.46deg,' +
      ' #D3D65A 233.9deg, #989A3D 234.41deg, #A4AA4A 254.59deg, #E8F375 285.62deg, #A2AF50 286.8deg,' +
      ' #D1E16F 301.88deg, #899540 448.18deg)',
    color: '#b9c255',
  },
  'foil-04': {
    gradient: 'conic-gradient(from 122deg at 27.05% 236.79%, rgb(89, 124, 136) -58.12deg, rgb(72, 100, 108) 88.18deg,' +
      ' rgb(92, 129, 142) 233.51deg, rgb(108, 152, 169) 246deg, rgb(70, 97, 106) 246.2deg,' +
      ' rgb(75, 104, 114) 264.52deg, rgb(108, 152, 169) 286.66deg, rgb(70, 97, 105) 286.8deg,' +
      ' rgb(89, 124, 136) 301.88deg, rgb(72, 100, 108) 448.18deg)',
    color: '#587a86',
  },
  'foil-05': {
    gradient: 'conic-gradient(from 98deg at 46.29% 139.93%, rgb(247, 154, 83) -25.75deg, rgb(234, 117, 116) 84.46deg,' +
      ' rgb(244, 146, 90) 227.31deg, rgb(248, 170, 110) 236.74deg, rgb(255, 210, 143) 245deg,' +
      ' rgb(234, 117, 116) 245.5deg, rgb(243, 141, 95) 264.52deg, rgb(255, 177, 62) 286.66deg,' +
      ' rgb(234, 117, 116) 287.07deg, rgb(247, 154, 83) 334.25deg, rgb(234, 117, 116) 444.46deg)',
    color: '#f6955a',
  },
  'foil-06': {
    gradient: 'conic-gradient(from 312deg at 56.6% -57.35%, rgb(98, 224, 218) -24.29deg, rgb(65, 154, 181) 141.25deg,' +
      ' rgb(162, 94, 216) 220.9deg, rgb(235, 140, 214) 234deg, rgb(149, 55, 182) 234.5deg,' +
      ' rgb(204, 114, 168) 272.4deg, rgb(98, 224, 218) 335.71deg, rgb(65, 154, 181) 501.25deg)',
    color: '#aa4eb1',
  },
  'foil-07': {
    gradient: 'conic-gradient(from 224deg at -5.91% 43%, rgb(224, 166, 98) -24.29deg, rgb(205, 139, 78) 141.25deg,' +
      ' rgb(252, 169, 109) 220.9deg, rgb(235, 191, 140) 234deg, rgb(149, 55, 182) 234.5deg,' +
      ' rgb(204, 114, 168) 272.4deg, rgb(224, 166, 98) 335.71deg, rgb(205, 139, 78) 501.25deg)',
    color: '#cc76a3',
  },
  'foil-08': {
    gradient: 'conic-gradient(from 136deg at 0% 86.59%, rgb(205, 135, 95) -72.93deg, rgb(255, 215, 137) 31.8deg,' +
      ' rgb(230, 169, 125) 240.14deg, rgb(255, 209, 142) 286.66deg, rgb(205, 135, 95) 287.07deg,' +
      ' rgb(255, 215, 137) 391.8deg)',
    color: '#e2a770',
  },
  'foil-09': {
    gradient: 'conic-gradient(from 151deg at -5.72% 54.57%, rgb(67, 128, 185) -72.93deg, rgb(137, 170, 255) 31.8deg,' +
      ' rgb(101, 154, 204) 208.23deg, rgb(142, 187, 255) 286.66deg, rgb(67, 128, 185) 287.07deg,' +
      ' rgb(137, 170, 255) 391.8deg)',
    color: '#6493d9',
  },
};

export const gradientSet4: Record<string, AppPattern> = {
  'geometric-01': {
    gradient: 'conic-gradient(from 96.71deg at 21.39% 62%, rgba(255, 255, 255, 0.16) -130.24deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.31) 0.03deg, rgba(3, 3, 3, 0.18) 115.91deg,' +
      ' rgba(0, 0, 0, 0.02) 116.96deg, rgba(128, 128, 128, 0.33) 229.03deg, rgba(255, 255, 255, 0.16) 229.76deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(68.13deg, #059A9A 17.11%, #63C4A1 61.35%)',
    color: '#187a78',
  },
  'geometric-02': {
    gradient: 'conic-gradient(from -71.49deg at 62.88% 61.18%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(3, 3, 3, 0.1) 116.82deg,' +
      ' rgba(0, 0, 0, 0) 116.96deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(94.97deg, #5877A6 0%, #58A68E 100.79%)',
    color: '#5b9595',
  },
  'geometric-03': {
    gradient: 'conic-gradient(from 247.32deg at 73.22% 44.53%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(84.98deg, #58A1A6 0%, #8D58A6 100%)',
    color: '#76709e',
  },
  'geometric-04': {
    gradient: 'conic-gradient(from 242.68deg at 55.26% 43.3%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #A6588B 1.87%, #6358A6 98.68%)',
    color: '#845590',
  },
  'geometric-05': {
    gradient: 'conic-gradient(from 59.1deg at 32.03% 50%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #88AFC6 1.87%, #C8DA5D 98.68%)',
    color: '#9bb78e',
  },
  'geometric-06': {
    gradient: 'conic-gradient(from -15.96deg at 63.11% 58.08%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #C8DA5D 1.87%, #D58580 99.79%)',
    color: '#d6b58a',
  },
  'geometric-07': {
    gradient: 'conic-gradient(from 254.48deg at 50% 50%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #FC9993 1.87%, #FFB951 99.79%)',
    color: '#f09e73',
  },
  'geometric-08': {
    gradient: 'conic-gradient(from -31.97deg at 37.01% 44.69%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #FFBE5E 1.87%, #93C9FC 99.79%)',
    color: '#bab2a0',
  },
  'geometric-09': {
    gradient: 'conic-gradient(from 52.87deg at 67.8% 44.44%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #6885A0 1.87%, #488585 99.79%)',
    color: '#568089',
  }
};

export const gradientSet5: Record<string, AppPattern> = {
  'faceted-01': {
    gradient: 'conic-gradient(from 324deg at 70.67% 36.21%, rgba(255, 255, 255, 0.16) -130.24deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.31) 1deg, rgba(3, 3, 3, 0.18) 115.91deg,' +
      ' rgba(0, 0, 0, 0.02) 116.96deg, rgba(128, 128, 128, 0.33) 229.03deg, rgba(255, 255, 255, 0.16) 229.76deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from 121.06deg at 29.29% 62.07%,' +
      ' rgba(255, 255, 255, 0.16) -130.24deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.31) 0.03deg,' +
      ' rgba(3, 3, 3, 0.18) 115.91deg, rgba(0, 0, 0, 0.02) 116.96deg, rgba(128, 128, 128, 0.33) 229.03deg,' +
      ' rgba(255, 255, 255, 0.16) 229.76deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' linear-gradient(68.13deg, #059A9A 17.11%, #8ED57D 61.35%)',
    color: '#8bcaaf',
  },
  'faceted-02': {
    gradient: 'conic-gradient(from -17.22deg at 8.6% 66.71%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(3, 3, 3, 0.1) 116.82deg,' +
      ' rgba(0, 0, 0, 0) 116.96deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from -26.09deg at 94.24% 32.16%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(3, 3, 3, 0.1) 116.82deg, rgba(0, 0, 0, 0) 116.96deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' conic-gradient(from -72.4deg at 65.69% 58.74%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(3, 3, 3, 0.1) 116.82deg,' +
      ' rgba(0, 0, 0, 0) 116.96deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(94.97deg, #5860A6 0%, #58A660 100.79%)',
    color: '#8087ab',
  },
  'faceted-03': {
    gradient: 'conic-gradient(from 217.52deg at 18.25% 38.4%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from 247.32deg at 73.22% 44.53%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' linear-gradient(84.98deg, #86A4D1 0%, #8D58A6 100%)',
    color: '#847bad',
  },
  'faceted-04': {
    gradient: 'conic-gradient(from 250.78deg at 25.92% 43.99%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from 215.12deg at 77.11% 37.34%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' linear-gradient(92.23deg, #EC9AD0 1.87%, #5893A6 98.68%)',
    color: '#b18ab0',
  },
  'faceted-05': {
    gradient: 'conic-gradient(from 25.01deg at 77.68% 74.91%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from 59.1deg at 32.03% 50%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg,' +
      ' rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg,' +
      ' rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #88AFC6 1.87%, #DA645D 98.68%)',
    color: '#c58c8d',
  },
  'faceted-06': {
    gradient: 'conic-gradient(from 26.66deg at 12.25% 40.97%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from -15.96deg at 63.11% 58.08%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' linear-gradient(92.23deg, #C8DA5D 1.87%, #D58580 99.79%)',
    color: '#c9c67a',
  },
  'faceted-07': {
    gradient: 'conic-gradient(from -17.51deg at 86.41% 50%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from 81.91deg at 6.21% 19.5%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' conic-gradient(from 254.48deg at 50% 50%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #FC9993 1.87%, #FFB951 99.79%)',
    color: '#e4a089',
  },
  'faceted-08': {
    gradient: 'conic-gradient(from -49.88deg at 67.95% 71.63%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from -7.98deg at 19.3% 61.44%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' linear-gradient(92.23deg, #FFBE5E 1.87%, #93F6FC 99.79%)',
    color: '#b4cdad',
  },
  'faceted-09': {
    gradient: 'conic-gradient(from 53.68deg at 14.26% 67.14%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), conic-gradient(from 166.32deg at 92.78% 21.85%,' +
      ' rgba(255, 255, 255, 0.16) -128.31deg, rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg,' +
      ' rgba(0, 0, 0, 0) 116.86deg, rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg,' +
      ' rgba(255, 255, 255, 0.16) 231.69deg, rgba(239, 239, 239, 0.18) 360.01deg),' +
      ' conic-gradient(from 111.06deg at 58.55% 55.82%, rgba(255, 255, 255, 0.16) -128.31deg,' +
      ' rgba(239, 239, 239, 0.18) 0.01deg, rgba(7, 7, 7, 0.08) 0.03deg, rgba(0, 0, 0, 0) 116.86deg,' +
      ' rgba(3, 3, 3, 0.1) 116.99deg, rgba(128, 128, 128, 0.12) 231.69deg, rgba(255, 255, 255, 0.16) 231.69deg,' +
      ' rgba(239, 239, 239, 0.18) 360.01deg), linear-gradient(92.23deg, #6885A0 1.87%, #488585 99.79%)',
    color: '#517381',
  },
};

export const gradientSet6: Record<string, AppPattern> = {
  'gradient-01': {
    gradient: 'linear-gradient(110.27deg, #517CC2 10.19%, #EA80DD 100%)',
    color: '#977ECF',
  },
  'gradient-02': {
    gradient: 'linear-gradient(113.6deg, #D15699 2.62%, #FFA842 100%)',
    color: '#E9806D',
  },
  'gradient-03': {
    gradient: 'linear-gradient(113.6deg, #4096D4 2.62%, #62E1AC 100%)',
    color: '#50BAC2',
  },
  'gradient-04': {
    gradient: 'linear-gradient(113.6deg, #CD06AD 0%, #FF8D69 100%)',
    color: '#E74B8B',
  },
  'gradient-05': {
    gradient: 'linear-gradient(113.6deg, #A75EEE 2.62%, #93E4FD 100%)',
    color: '#9F99F5',
  },
  'gradient-06': {
    gradient: 'linear-gradient(113.6deg, #9B1956 2.62%, #41B6DB 100%)',
    color: '#726193',
  },
  'gradient-07': {
    gradient: 'linear-gradient(113.6deg, #59A928 0%, #E2E541 100%)',
    color: '#94C333',
  },
  'gradient-08': {
    gradient: 'linear-gradient(113.6deg, #34474D 2.62%, #7CAEC2 100%)',
    color: '#567784',
  },
  'gradient-09': {
    gradient: 'linear-gradient(113.6deg, #884BB8 2.62%, #79FFD7 100%)',
    color: '#829CC6',
  },
};

export const gradientSet7: Record<string, AppPattern> = {
  'shine-01': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) 1.51%, rgba(0, 0, 0, 0.23) 49.76%,' +
      ' rgba(255, 255, 255, 0) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(93.66deg, #0A4F81 1.63%, #0F5F99 41.08%, #2974AB 99.49%)',
    color: '#0b4f82',
  },
  'shine-02': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(0, 0, 0, 0.16) 49.76%,' +
      ' rgba(255, 255, 255, 0) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(85.01deg, #59D4D8 0%, #63E1DA 32.66%, #41B2D4 100.03%)',
    color: '#3fa1b5',
  },
  'shine-03': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(0, 0, 0, 0.31) 49.76%,' +
      ' rgba(255, 255, 255, 0) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(95.19deg, #62199B -0.92%, #8A1BB1 29.96%, #65229A 99.54%)',
    color: '#6e1ba2',
  },
  'shine-04': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(0, 0, 0, 0.31) 49.76%,' +
      ' rgba(255, 255, 255, 0.2) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(95.19deg, #740237 -0.92%, #9C1940 31.69%, #90229A 100.07%)',
    color: '#861c46',
  },
  'shine-05': {
    gradient: 'linear-gradient(339deg, rgba(255, 153, 0, 0) -20.96%, rgba(235, 42, 0, 0.29) 49.76%,' +
      ' rgba(255, 255, 255, 0.17) 49.77%, rgba(255, 122, 0, 0.35) 105.94%),' +
      ' linear-gradient(95.19deg, #FF8A00 -0.92%, #FF9838 31.69%, #FF912C 100.07%)',
    color: '#fa7a26',
  },
  'shine-06': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(0, 0, 0, 0.06) 49.76%,' +
      ' rgba(255, 255, 255, 0) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(102.91deg, #FFB800 7%, #FFDA55 100.08%)',
    color: '#ffb800',
  },
  'shine-07': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(69, 101, 0, 0.39) 49.76%,' +
      ' rgba(255, 255, 255, 0) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(102.91deg, #B5C457 7%, #D2D628 35.85%, #B5C457 100.08%)',
    color: '#acbb41',
  },
  'shine-08': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(0, 0, 0, 0.31) 49.76%,' +
      ' rgba(255, 255, 255, 0) 49.77%, rgba(255, 255, 255, 0) 105.94%),' +
      ' linear-gradient(102.91deg, #48656D 7%, #4A7683 33.66%, #48656D 100.08%)',
    color: '#496870',
  },
  'shine-09': {
    gradient: 'linear-gradient(339deg, rgba(255, 255, 255, 0) -9.72%, rgba(0, 0, 0, 0.31) 49.76%,' +
      ' rgba(255, 255, 255, 0.12) 49.77%, rgba(255, 255, 255, 0) 105.94%), #113742',
    color: '#264651',
  },
};

export const gradientSet8: Record<string, AppPattern> = {
  'bokeh-01': {
    gradient: 'radial-gradient(circle  at 22.5% 54.64%, rgb(255 255 255 / 25%) 9.1%, rgb(245 47 206 / 0%) 12.32%),' +
      ' radial-gradient(circle  at 12.5% 34.64%, rgb(255 255 255 / 25%) 7.1%, rgb(245 47 206 / 0%) 3.32%),' +
      ' radial-gradient(circle  at 26.5% 99.64%, rgb(246 222 246 / 38%) 10.1%, rgb(245 47 206 / 0%) 13.32%),' +
      ' radial-gradient(circle  at 78.77% 10.97%, rgb(255 255 255 / 31%) 13.35%, rgb(255 255 255 / 0%) 16.75%),' +
      ' radial-gradient(circle  at 98.52% 151.69%, rgb(183 236 255 / 50%) 19.62%, rgba(98, 224, 218, 0) 25.36%),' +
      ' radial-gradient(circle  at 92.9% 87.78%, rgb(218 237 235 / 50%) 20.62%, rgba(98, 224, 218, 0) 16.36%),' +
      ' radial-gradient(circle  at 11.12% 450.11%, #005c83 51.13%, #76d6ff 71.33%, #03bee6 75.54%, #124765 94.56%)',
    color: '#12AAD2',
  },
  'bokeh-02': {
    gradient: 'radial-gradient(circle  at 12.5% 54.64%, rgb(255 255 255 / 25%) 7.1%, rgb(245 47 206 / 0%) 11.32%),' +
      ' radial-gradient(circle  at 2.5% 34.64%, rgb(255 255 255 / 25%) 7.1%, rgb(245 47 206 / 0%) 7.32%),' +
      ' radial-gradient(circle  at 42.5% 64.64%, rgb(246 222 246 / 38%) 12.1%, rgb(245 57 206 / 0%) 15.32%),' +
      ' radial-gradient(circle  at 78.77% 10.97%, rgb(255 255 255 / 24%) 14.35%, rgb(255 255 255 / 0%) 16.75%),' +
      ' radial-gradient(circle  at 118.52% 151.69%, rgb(183 236 255 / 50%) 25.62%, rgba(98, 224, 218, 0) 28.36%),' +
      ' radial-gradient(circle  at 26.9% 107.78%, rgb(218 287 235 / 50%) 23.62%, rgba(98, 224, 218, 0) 26.36%),' +
      ' radial-gradient(circle  at 93.31% 156.18%, #4B6872 20%, #6D98A9 37.71%, #A7CFE6 49.2%, #5B7E8C 71%,' +
      ' #48646D 87.67%, #141B1E 282.64%)',
    color: '#6890A1',
  },
  'bokeh-03': {
    gradient: 'radial-gradient(circle  at 12.5% 54.64%, rgb(255 255 255 / 25%) 8.1%, rgb(245 47 206 / 0%) 10.32%),' +
      ' radial-gradient(circle  at 2.5% 34.64%, rgb(255 255 255 / 25%) 7.1%, rgb(245 47 206 / 0%) 6.32%),' +
      ' radial-gradient(circle  at 42.5% 64.64%, rgb(246 222 246 / 38%) 13.1%, rgb(245 47 206 / 0%) 16.32%),' +
      ' radial-gradient(circle  at 78.77% 10.97%, rgb(255 255 255 / 24%) 14.35%, rgb(255 255 255 / 0%) 16.75%),' +
      ' radial-gradient(circle  at 98.52% 151.69%, rgb(183 236 255 / 50%) 29.62%, rgba(98, 224, 218, 0) 33.36%),' +
      ' radial-gradient(circle  at 56.9% 87.78%, rgb(218 137 235 / 50%) 26.62%, rgba(98, 224, 218, 0) 30.36%),' +
      ' radial-gradient(circle at 0% 277.36%, #6A88BA 38.24%, #A4EFF3 66.42%, #D8B7D4 73.58%, #A47D9F 96.02%)',
    color: '#D1A9E6',
  },
  'bokeh-04': {
    gradient: 'radial-gradient(circle  at 12.5% 54.64%, rgb(255 255 255 / 25%) 9.1%, rgb(245 47 206 / 0%) 6.32%),' +
      ' radial-gradient(circle  at 2.5% 34.64%, rgb(255 255 255 / 25%) 7.1%, rgb(245 47 206 / 0%) 6.32%),' +
      ' radial-gradient(circle  at 22.5% 64.64%, rgb(249 248 249 / 53%) 10.1%, rgb(245 7 206 / 0%) 11.32%),' +
      ' radial-gradient(circle  at 78.77% 10.97%, rgb(255 255 255 / 24%) 17.35%, rgb(255 255 255 / 0%) 20.75%),' +
      ' radial-gradient(circle  at 18.52% 151.69%, rgb(183 236 255 / 50%) 30.62%, rgba(98, 224, 218, 0) 36.36%),' +
      ' radial-gradient(circle  at 56.9% 47.78%, rgb(258 287 270 / 30%) 28.62%, rgba(98, 224, 218, 0) 32.36%),' +
      ' radial-gradient(circle  at 78.29% 157.99%, #fe47a7 0%, #FFEFC7 43.75%, #ffc52f 47.92%, #46a1e1 100%)',
    color: '#F9C441',
  },
  'bokeh-05': {
    gradient: 'radial-gradient(circle at 12.5% 54.64%, rgb(255 255 255 / 25%) 8%, rgb(245 47 206 / 0%) 10.5%),' +
      ' radial-gradient(circle at 2.5% 34.64%, rgb(255 255 255 / 25%) 7.3%, rgb(245 47 206 / 0%) 7.72%),' +
      ' radial-gradient(circle at 42.5% 64.64%, rgb(246 222 246 / 28%) 15.1%, rgb(245 47 206 / 0%) 17.5%),' +
      ' radial-gradient(circle at 78.77% 10.97%, rgb(255 255 255 / 24%) 11.35%, rgb(255 255 255 / 0%) 12.75%),' +
      ' radial-gradient(circle at 98.52% 151.69%, rgb(183 236 255 / 50%) 31.62%, rgba(98, 224, 218, 0) 35.36%),' +
      ' radial-gradient(circle at 56.9% 87.78%, rgb(218 137 235 / 50%) 30.62%, rgba(98, 224, 218, 0) 36.36%),' +
      ' radial-gradient(circle at 112.1% 116.9%, #567BC3 0%, #5AB8D6 44.25%, #578BC8 48.42%, #577CC4 83%)',
    color: '#587FC4',
  },
  'bokeh-06': {
    gradient:
      'radial-gradient(circle  at 73.15% 26.63%, rgba(255, 255, 255, 0.2) 8.1%, rgba(255, 255, 255, 0) 10.32%),' +
      ' radial-gradient(circle  at 21.14% 50%, rgba(255, 255, 255, 0.4) 8.35%, rgba(255, 255, 255, 0) 11.75%),' +
      ' radial-gradient(circle  at 78.18% 61.54%, rgba(255, 255, 255, 0.3) 9.63%, rgba(255, 255, 255, 0) 13.36%),' +
      ' radial-gradient(circle  at 28.82% 31.61%, rgba(255, 255, 255, 0.3) 5.62%, rgba(255, 255, 255, 0) 8.36%),' +
      ' radial-gradient(circle  at 57.49% 25.63%, rgba(255, 255, 255, 0.3) 17.62%, rgba(255, 255, 255, 0) 20.36%),' +
      ' radial-gradient(circle  at 45.07% 55.55%, rgba(255, 255, 255, 0.2) 11.63%, rgba(255, 255, 255, 0) 13.36%),' +
      ' radial-gradient(circle  at 11.68% 100%, rgba(255, 255, 255, 0.3) 15.62%, rgba(255, 255, 255, 0) 16.83%),' +
      ' linear-gradient(105.48deg, #FFC476 14.54%, #D264E4 138.55%)',
    color: '#E88FB8',
  },
  'bokeh-07': {
    gradient: 'radial-gradient(circle  at 12.5% 54.64%, rgb(255 255 255 / 25%) 10.1%, rgb(245 47 206 / 0%) -3.68%),' +
      ' radial-gradient(circle  at 2.5% 34.64%, rgb(255 255 255 / 25%) 7.1%, rgb(245 47 206 / 0%) 8.32%),' +
      ' radial-gradient(circle  at 42.5% 64.64%, rgb(246 222 246 / 38%) 13.1%, rgb(245 47 206 / 0%) 15.32%),' +
      ' radial-gradient(circle  at 78.77% 10.97%, rgb(255 255 255 / 24%) 14.35%, rgb(255 255 255 / 0%) 16.75%),' +
      ' radial-gradient(circle  at 98.52% 151.69%, rgb(183 236 255 / 50%) 32.62%, rgba(98, 224, 218, 0) 34.36%),' +
      ' radial-gradient(circle  at 56.9% 87.78%, rgb(218 137 235 / 50%) 26.62%, rgba(98, 224, 218, 0) 31.36%),' +
      ' radial-gradient(circle  at -8.77% -62.41%, #68DCB9 8.81%, #CEDDD9 47.48%, #BEAAF8 68.65%, #9B84DB 96.88%)',
    color: '#A690E4',
  },
  'bokeh-08': {
    gradient: 'radial-gradient(circle  at 42.5% 14.64%, rgb(246 222 246 / 38%) 25.1%, rgb(245 47 206 / 0%) 28.32%),' +
      ' radial-gradient(circle  at 18.77% 10.97%, rgb(255 255 255 / 24%) 23.35%, rgb(255 255 255 / 0%) 25.75%),' +
      ' radial-gradient(circle  at 78.52% 121.69%, rgb(183 236 255 / 50%) 35.62%, rgba(98, 224, 218, 0) 41.36%),' +
      ' radial-gradient(circle  at 46.9% 87.78%, rgb(218 137 235 / 50%) 25.62%, rgba(98, 224, 218, 0) 30.36%),' +
      ' linear-gradient(109deg, #e2da63 0%, #ff6fe5 100%)',
    color: '#FE7CD8',
  },
  'bokeh-09': {
    gradient:
      'radial-gradient(circle  at 72.5% 14.64%, rgba(158, 255, 249, 0.2) 20.1%, rgba(47, 245, 234, 0) 23.32%),' +
      ' radial-gradient(circle  at 18.77% 10.97%, rgba(140, 235, 230, 0.6) 26.35%, rgba(98, 224, 218, 0) 28.75%),' +
      ' radial-gradient(circle  at 88.52% 121.69%, rgba(140, 235, 230, 0.5) 31.62%, rgba(98, 224, 218, 0) 35.36%),' +
      ' radial-gradient(circle  at 56.9% 87.78%, rgba(140, 235, 230, 0.5) 30.62%, rgba(98, 224, 218, 0) 34.36%),' +
      ' linear-gradient(109deg, #4946a7 0%, #ff6fe5 100%)',
    color: '#8E56BE',
  },
};

export const gradients: AppPattern[] = [
  ...Object.values(gradientSet1),
  ...Object.values(gradientSet2),
  ...Object.values(gradientSet3),
  ...Object.values(gradientSet4),
  ...Object.values(gradientSet5),
  ...Object.values(gradientSet6),
  ...Object.values(gradientSet7),
  ...Object.values(gradientSet8),
];

export const gradientMap: Record<string, AppPattern> = {
  ...gradientSet1,
  ...gradientSet2,
  ...gradientSet3,
  ...gradientSet4,
  ...gradientSet5,
  ...gradientSet6,
  ...gradientSet7,
  ...gradientSet8,
};

export function generateSeedPattern(): string {
  const index = Math.round(Math.random() * gradients.length - 0.5);
  return Object.keys(gradientMap)[index];
}
