import { cloneDeep } from 'lodash';

import { AttributeStyle, cloneStyles, stylesAreEqual } from '@/data/tasks/AttributeStyle';

import { cloneRelationship, Relationship } from './Relationship';

type attribute = string;
type value = string;

export interface Task {
  id: string;
  trackId: string;
  tableId: string;
  createdBy?: string;
  lastUpdatedBy?: string;
  properties: Record<attribute, value>;
  relationships?: Relationship[];
  styles?: Record<attribute, AttributeStyle>;
}

// This allows us to decide whether we can enable the formatting buttons for a new, unsaved row
export interface TempTask extends Task {
  hasInput: boolean;
}

export function isTempTask(task: Task): task is TempTask {
  return 'hasInput' in task;
}

export function cloneTask(task: Task): Task {
  const id = task.id;
  const trackId = task.trackId;
  const tableId = task.tableId;
  const createdBy = task.createdBy;
  const lastUpdatedBy = task.lastUpdatedBy;
  const properties = cloneDeep(task.properties);
  const relationships: Relationship[] | undefined = task.relationships?.map(r => cloneRelationship(r));
  const styles = task.styles ? cloneStyles(task.styles) : undefined;

  return { id, trackId, tableId, createdBy, lastUpdatedBy, properties, relationships, styles } as Task;
}

export function tasksAreEqual(taskA: Task, taskB: Task): boolean {
  if (!taskA || !taskB) {
    return false;
  }
  const taskAKeys: string[] = getTaskKeys(taskA);
  const taskBKeys: string[] = getTaskKeys(taskB);
  if (taskAKeys.length !== taskBKeys.length ||
    taskA.relationships?.length !== taskB.relationships?.length) {
    return false;
  } else {
    for (const aKey of taskAKeys) {
      if (!taskBKeys.includes(aKey) || taskA.properties[aKey] !== taskB.properties[aKey]) {
        return false;
      }
    }
    if (taskA.relationships && taskB.relationships) {
      for (const rel of taskA.relationships) {
        if (!taskB.relationships.find(r => r.targetId === rel.targetId)) {
          return false;
        }
      }
    }

    if (taskA.styles && taskB.styles) {
      if (!stylesAreEqual(taskA.styles, taskB.styles)) {
        return false;
      }
    } else if ((!taskA.styles && taskB.styles) || (taskA.styles && !taskB.styles)) {
      return false;
    }
  }
  return true;
}

export function getTaskKeys(task: Task): string[] {
  return Object.keys(task.properties).filter(k => k.indexOf('__') !== 0);
}

export function taskIsEmpty(task: Task): boolean {
  const taskKeys: string[] = getTaskKeys(task);
  const filteredRels: Relationship[] = task.relationships?.filter(r => r.label !== 'inTable') || [];
  if (filteredRels.length || taskKeys.length) {
    return false;
  }
  return true;
}

export const TEMP_TASK_ID: string = 'tmpTaskId';
