// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/cafex-non-app-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ident[data-v-c45f82e4]{position:relative;aspect-ratio:1;justify-content:flex-start;color:#fff}.ident .overlay[data-v-c45f82e4]{width:100%;height:100%;display:flex;flex-direction:column;justify-content:space-between}.ident .overlay.externalTenant[data-v-c45f82e4]{background:linear-gradient(180deg,hsla(0,0%,60%,.5),hsla(0,0%,60%,.1) 50%,hsla(0,0%,60%,0) 75%);background-blend-mode:multiply;position:absolute;top:0;left:0}.ident .overlay .tenantLabel[data-v-c45f82e4]{margin:.75rem 1rem .75rem 2.5rem;overflow:hidden;text-overflow:ellipsis;box-sizing:border-box;text-align:right;white-space:nowrap}.ident .overlay .tenantLabel .name[data-v-c45f82e4]{font-weight:500}.ident .overlay .appName[data-v-c45f82e4]{padding-left:1.25rem;font-size:1.25rem;font-weight:500;flex-basis:50%;display:flex;align-items:center}.ident.cafexBackground[data-v-c45f82e4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
