import { computed, ComputedRef, watch } from 'vue';

import browserDetection from '@/data/BrowserDetection';
import { TrackingCodes } from '@/data/datatypes/tracking/TrackingCodes';
import { usePendoStore } from '@/stores/Pendo';
import { useUserStore } from '@/stores/User';

const userStore = useUserStore();
const pendoStore = usePendoStore();

let existingScript: HTMLScriptElement | null = null;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pendo: any,
  }
}

export function usePendo() {
  const pendoCodes: ComputedRef<TrackingCodes> = computed(() => {
    return userStore.trackingCodes;
  });

  function startPendo(): void {
    // TODO: Guest handling
    const currentUserId: string | undefined = userStore.currentUser?.id;
    if (!currentUserId) {
      return;
    }
    const role: string | undefined = !currentUserId ? 'GUEST'
      : userStore.isUserTenantAdmin ? 'ADMIN' : undefined;
    const tenantId: string = userStore.tenantId ?? 'GUEST';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initData: any = {
      visitor: {
        id: currentUserId ?? 'GUEST',
        role,
        // TODO: license type
        // TODO: office suite type if admin?
      },
      account: {
        id: tenantId,
      },
    };
    pendoStore.setInitData(initData);
    window.pendo.initialize(initData);
  }

  function pendoInit(apiKey: string, host: string): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pendoFunc: (p: Window, e: Document) => void = (p, e) => {
      let w, x;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const o: any = p.pendo = p.pendo || {};
      o._q = o._q || [];
      const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] = o[m] || function (...args: unknown[]) {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(args, 0)));
          };
        })(v[w]);
      }
      const y: HTMLScriptElement = e.createElement('script');
      y.addEventListener('load', startPendo);
      y.async = !0;
      y.src = `${host}/agent/static/${apiKey}/pendo.js`;
      const z: HTMLScriptElement = e.getElementsByTagName('script')[0];
      z.parentNode?.insertBefore(y, z);
      existingScript = y;
    };

    pendoFunc(window, document);
  }

  watch(() => pendoCodes.value, (newValue: TrackingCodes, oldValue?: TrackingCodes) => {
    const apiKey: string | undefined = newValue?.pendo;
    const host: string | undefined = newValue?.pendoHost ?? 'https://cdn.pendo.io';
    if (apiKey === oldValue?.pendo && host === oldValue?.pendoHost) {
      return;
    }
    if (existingScript) {
      // TODO: This isn't a function, despite existing in the TS typings. Can we unbind / stop the current script?
      // window.pendo.stopGuides();
      existingScript.parentElement?.removeChild(existingScript);
      existingScript = null;
    }
    if (!apiKey || !host) {
      return;
    }

    if (!newValue.pendoMobile && browserDetection.isMobile()) {
      return;
    }
    pendoInit(apiKey, host);
  }, { immediate: true });
}
