import { AppName } from '@/data/Branding';
import { ChatMessage } from '@/data/datatypes/chat/ChatMessage';
import { Track, TrackDetails, TrackType } from '@/data/datatypes/Track';
import { LimitedUserDetails } from '@/data/datatypes/UserDetails';
import { removeDiacritics } from '@/data/helpers/SearchHelper';

const MESSAGE_EDIT_WINDOW: number = 120_000; // Allow edits for two minutes after a message is sent.

// A shared cache across the app of ID -> details of message senders
export const sendersCache: Record<string, { name: string; altImage?: string; user?: LimitedUserDetails }> = {};

function getOtherParticipantIds(track: Track | TrackDetails, currentUserId: string): string[] {
  let partIds: string[] = [];
  if (track && track.typeMetadata) {
    partIds = track.typeMetadata.split(',').filter((partId: string) => {
      return partId !== currentUserId;
    });
  }
  return partIds;
}

function isPrivateChat(track: Track | TrackDetails): boolean {
  return track && (track.type === TrackType.PRIVATE_CHAT);
}

function isSystemGeneratedChatByIds(track: Track | TrackDetails, otherParticipantIds: string[]): boolean {
  return isPrivateChat(track) && otherParticipantIds.length === 0;
}

function isSystemGeneratedChat(track: Track | TrackDetails, currentUserId: string): boolean {
  return isSystemGeneratedChatByIds(track, getOtherParticipantIds(track, currentUserId));
}

function getPrivateChatUsers(track: Track | TrackDetails, currentUserId: string,
  displayDetailsForUsers: Map<string, LimitedUserDetails>): LimitedUserDetails[] {
  let users: LimitedUserDetails[] = [];
  if (track.typeMetadata) {
    const memberIds: string[] = getOtherParticipantIds(track, currentUserId);
    users = memberIds.map((id) => displayDetailsForUsers.get(id))
      .filter((user?: LimitedUserDetails) => !!user) as LimitedUserDetails[];
  }
  return users;
}

function getPrivateChatDisplayNames(track: Track | TrackDetails, currentUserId: string,
  displayDetailsForUsers: Map<string, LimitedUserDetails>): string[] {
  return getPrivateChatUsers(track, currentUserId, displayDetailsForUsers).map(
    (user) => user.displayName || 'Unknown');
}

function getTruncatedPrivateChatTitle(track: Track | TrackDetails, currentUserId: string,
  displayDetailsForUsers: Map<string, LimitedUserDetails>): string {
  let name: string = track.title || '';
  if (track.typeMetadata) {
    const privateChatDisplayNames = getPrivateChatDisplayNames(track, currentUserId, displayDetailsForUsers);
    if (privateChatDisplayNames.length > 2) {
      const remaining = privateChatDisplayNames.length - 2;
      name = privateChatDisplayNames.splice(0, 2).join(', ') + ' + ' + remaining + ' more';
    } else {
      name = privateChatDisplayNames.join(', ');
    }
  }
  return name;
}

function getPrivateChatTitle(track: Track | TrackDetails, currentUserId: string,
  displayDetailsForUsers: Map<string, LimitedUserDetails>):
    string {
  let name: string = track.title || '';
  if (track.typeMetadata) {
    name = getPrivateChatDisplayNames(track, currentUserId, displayDetailsForUsers).join(', ');
  }
  return name;
}

function getChatName(track: Track | TrackDetails, currentUserId: string,
  displayDetailsForUsers: Map<string, LimitedUserDetails>):
    string {
  if (isSystemGeneratedChat(track, currentUserId)) {
    return AppName;
  } else if (isPrivateChat(track)) {
    return getPrivateChatTitle(track, currentUserId, displayDetailsForUsers);
  } else if (track && track.title) {
    return track.title;
  } else {
    return '';
  }
}

function isOnline(track: Track | TrackDetails, currentUserId: string, onlineUserIds: string[]):
    boolean {
  const otherParticipantIds: string[] = getOtherParticipantIds(track, currentUserId);
  if (isSystemGeneratedChatByIds(track, otherParticipantIds) || !isPrivateChat(track) ||
      otherParticipantIds.length > 1) {
    return true;
  }
  return onlineUserIds.includes(otherParticipantIds[0]);
}

function doesChatMatchSearch(track: Track | TrackDetails, currentUserId: string,
  displayDetailsForUsers: Map<string, LimitedUserDetails>, searchTerm: string): boolean {
  if (!searchTerm) {
    return true;
  }
  searchTerm = removeDiacritics(searchTerm).toLowerCase();
  // If there's no metadata, then match on name
  if (!track.typeMetadata) {
    return track.title?.includes(searchTerm) ?? false;
  }

  // Check if any member email addresses match
  const chatMembers: LimitedUserDetails[] = getPrivateChatUsers(track, currentUserId, displayDetailsForUsers);
  for (const user of chatMembers) {
    if (user.email.includes(searchTerm) || removeDiacritics(user.displayName?.toLowerCase())?.includes(searchTerm)) {
      return true;
    }
  }

  return removeDiacritics(getChatName(track, currentUserId, displayDetailsForUsers)).toLowerCase().includes(searchTerm);
}

const compareMessages: (first: ChatMessage, second: ChatMessage) => number =
  (first: ChatMessage, second: ChatMessage) => {
    if (first.date === second.date) {
      return first.id < second.id ? -1 : 1;
    }
    return first.date - second.date;
  };

function extractSenderName(message?: ChatMessage, user?: LimitedUserDetails): string {
  let toReturn = AppName;
  if (user) {
    toReturn = user.displayName;
  } else if (message?.senderName) {
    toReturn = message.senderName;
  }

  // messages that have come from system bots don't need
  // to be identified
  if (message?.source) {
    if (message?.fromWorkflow) {
      toReturn = message.source + ' (Workflow)';
    } else if (message.source !== 'system') {
      toReturn = toReturn + ' (using ' + message.source + ')';
    }
  }
  return toReturn;
}

function areDatesSameDay(date1: number, date2: number): boolean {
  const parsedDate1: Date = new Date(date1);
  const parsedDate2: Date = new Date(date2);
  return parsedDate1.getFullYear() === parsedDate2.getFullYear() &&
    parsedDate1.getMonth() === parsedDate2.getMonth() &&
    parsedDate1.getDate() === parsedDate2.getDate();
}

export {
  areDatesSameDay,
  compareMessages,
  doesChatMatchSearch,
  extractSenderName,
  getChatName,
  getOtherParticipantIds,
  getPrivateChatUsers,
  getTruncatedPrivateChatTitle,
  isOnline,
  isPrivateChat,
  MESSAGE_EDIT_WINDOW
};
