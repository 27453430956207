import { render, staticRenderFns } from "./AppIdent.vue?vue&type=template&id=c45f82e4&scoped=true&"
import script from "./AppIdent.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AppIdent.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./AppIdent.vue?vue&type=style&index=0&id=c45f82e4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c45f82e4",
  null
  
)

export default component.exports