import { CombinedNotificationPreference } from '@/data/config/CombinedNotificationPreference';
import { NotificationPreference } from '@/data/config/NotificationPreference';

export enum MemberStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REMOVED = 'REMOVED',
}

export enum MemberRole {
  COORDINATOR = 'COORDINATOR', // read/write/remove
  COLLABORATOR = 'COLLABORATOR', // read/write
  VIEWER = 'VIEWER', // read
}

export interface MemberDetails {
  userId: string;
  trackId: string;
  added: number;
  lastChatThread?: number;
  lastChat?: number;
  // lastOnline isn't currently checked anywhere in the UI apart from for the logged-in user, so currently the server
  // doesn't send websocket messages when another user's lastOnline time is updated.
  lastOnline?: number;
  status?: MemberStatus;
  role?: MemberRole;
  email?: string;
  confirmed?: boolean;
  chatNotificationPreference?: NotificationPreference | null;
  entryCommentsNotificationPreference?: NotificationPreference | null;
  entriesChangedNotificationPreference?: CombinedNotificationPreference | null;
  shouldCopyFromTemplate: boolean | undefined;
  // Specifies whether the chat should be hidden for the user, e.g. it is a private chat that has been hidden
  trackHidden?: boolean;
}

export interface Member extends MemberDetails {
  id: string;
}

export interface BatchMemberDetails {
  email: string;
  role: MemberRole;
  sendEmail: boolean;
}

export interface AddMemberPayload {
  email: string;
  role: MemberRole;
  sendEmail: boolean;
  message?: string;
}

export interface TrackMemberAdd {
  trackId: string;
  memberEmail: AddMemberPayload;
}
