/**
 * A class for checking, and managing, the state of a user's push messaging subscriptions on the server. This is
 * used in addition to the PushManager web push messaging subscriptions that are handled by the Service Worker.
 *
 * As the PushManager subscriptions are per device/browser (and not per CafeX user), we need to associate web push
 * subscriptions with CafeX users, and ensure we update or remove those subscriptions when a user logs in or out of
 * the app - otherwise, the server could be sending notifications to push message URLs in use by users that aren't the
 * intended recipient of the message.
 *
 * Both Push API subscriptions and Apple Push Notification service (APNs) registrations are supported (for MacOS
 * Safari; iOS Safari does not support push notifications).
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/PushManager
 * See: https://web-push-book.gauntface.com/
 */
import BrowserDetection from '@/data/BrowserDetection';
import { ApnsFullEndpointProperties } from '@/data/datatypes/pushmessaging/pushmessaging.types';
import IosDeviceToken from '@/data/pushmessaging/IosDeviceToken';
// import {logDebug, logError, logWarn} from '@/data/utils/Log';
import {
  NotificationPermissionState,
  NotificationPermissionStatus,
} from '@/data/pushmessaging/NotificationPermissionStatus';
import { PushDeviceType, PushRegistrationMessage } from '@/data/pushmessaging/PushRegistrationMessage';
import ServerData from '@/data/ServerData';
import pinia from '@/stores';
import { usePushNotificationStore } from '@/stores/PushNotification';

import Integrations from '../config/Integrations';

// // temporarily copy logging functions from v1 for dev purposes
// function isLogLevelEnabled(level: string): boolean {
//   return level !== 'wibble';
// }

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// function buildArgs(message: string, ...optionalParams: any[]): any[] {
//   const args: any[] = [`PushMessaging: ${message}`];
//   if (optionalParams) {
//     optionalParams.forEach((param) => {
//       if (param && (!Array.isArray(param) || param.length)) {
//         args.push(param);
//       }
//     });
//   }
//   return args;
// }

// // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
// function logTrace(message: string, ...optionalParams: any[]): void {
//   if (isLogLevelEnabled('TRACE')) {
//     // eslint-disable-next-line prefer-spread
//     console.trace.apply(console, buildArgs(message, optionalParams));
//   }
// }

// // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
// function logDebug(message: string, ...optionalParams: any[]): void {
//   if (isLogLevelEnabled('DEBUG')) {
//     // eslint-disable-next-line prefer-spread
//     console.log.apply(console, buildArgs(message, optionalParams));
//   }
// }

// // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
// function logWarn(message: string, ...optionalParams: any[]): void {
//   if (isLogLevelEnabled('WARN')) {
//     // eslint-disable-next-line prefer-spread
//     console.warn.apply(console, buildArgs(message, optionalParams));
//   }
// }

// // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
// function logError(message: string, ...optionalParams: any[]): void {
//   if (isLogLevelEnabled('ERROR')) {
//     // eslint-disable-next-line prefer-spread
//     console.error.apply(console, buildArgs(message, optionalParams));
//   }
// }

export default class PushMessaging {
  /**
   * Event raised when updating the rest of the application with new permission state
   */
  public static PUSH_PERMISSIONS_UPDATED_EVENT = 'push-permissions-updated';

  public static setRegistration(swRegistration: ServiceWorkerRegistration): void {
    PushMessaging.swRegistration = swRegistration;
    this.checkPendingNonSafariRegistration();
  }

  /**
   * If there is an existing web push subscription, register it with the current CafeX user.
   * If there is no existing web push subscription, attempt to subscribe for web push, and then
   * register that subscription with the current CafeX user.
   */
  public static async register(): Promise<void> {
    // logDebug('Initiating push notification registration (PushMessaging.register())');
    let pushSettings: NotificationPermissionStatus;

    if (BrowserDetection.isIosApp()) {
      this.registerIosApp();
    } else if (BrowserDetection.isSafari()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!('safari' in window && 'pushNotification' in window.safari)) {
        return;
      }
      pushSettings = await PushMessaging.getSafariPushNotificationStatus();
      if (pushSettings.enabled) {
        this.registerSafari();
      } else {
        // logWarn(`Push notifications are not enabled (Safari) (notifications supported:
        //   ${pushSettings.supported}). Reason: ${pushSettings.description}`);
      }
    } else {
      this.pendingNonSafariRegistration = true;
      pushSettings = await PushMessaging.getNonSafariPushNotificationStatus();
      // logDebug(`Push settings: ${JSON.stringify(pushSettings)}`);
      if (pushSettings.enabled) {
        return this.checkPendingNonSafariRegistration();
      } else {
        // logWarn(`Push notifications are not enabled (non-Safari) (supported: ${pushSettings.supported}).
        //  Reason: ${pushSettings.description}`);
      }
    }
  }

  /**
   * If there is a web push endpoint subscribed, unregister it from the CafeX user
   */
  public static async unregister(): Promise<void> {
    const pushNotificationStore = usePushNotificationStore(pinia);
    pushNotificationStore.setBannerDismissed(false);
    if (BrowserDetection.isIosApp()) {
      return this.unregisterIosApp();
    } else if (BrowserDetection.isSafari()) {
      return this.unregisterSafari();
    } else {
      return this.unregisterNonSafari();
    }
  }

  public static async requestNonSafariPermission(): Promise<void> {
    // logDebug('Requesting non-APNS push permission');
    await Notification.requestPermission();
    this.raisePermissionStateEvent();
  }

  /**
   * Requests permission for a Safari device to receive push notifications via APNs.
   *
   * @param permissionData The current push notification permission data (from `pushNotification.permission()`)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public static async requestSafariPermission(newPermissionData?: any) : Promise<void> {
    const pushNotificationStore = usePushNotificationStore(pinia);
    const apnsDetails: ApnsFullEndpointProperties | null = await PushMessaging.getApnsDetails();

    if (!apnsDetails) {
      // logError('APNS Website Push ID is not configured');
      return;
    }

    // logDebug(`Requesting permission for Push ID: ${pushId}`);

    if (BrowserDetection.isSafari() && 'safari' in window && 'pushNotification' in window.safari) {
      // newPermissionData is absent => means the user just asked us to get the authorisation from Safari...
      // newPermissionData is present => means this is in fact the callback from Safari giving us the reponse.
      let permissionData = newPermissionData;
      if (!permissionData) {
        // No data yet, so try and obtain what Safari had so far about this...
        permissionData = window.safari.pushNotification.permission(apnsDetails.current.pushId);
      }

      switch (permissionData.permission) {
        case 'default':
          // This is a new web service URL and its validity is unknown.
          window.safari.pushNotification.requestPermission(
            `${Integrations.BE}/api/apns`,
            apnsDetails.current.pushId, // The Website Push ID.
            {}, // Data that you choose to send to your server to help you identify the user.
            PushMessaging.requestSafariPermission, // The callback function.
          );
          break;

        case 'denied':
          // logWarn('Push notification permission denied');
          if (!newPermissionData) {
            // newPermissionData is absent => this is the user request
            // But the state is already "denied", this means Safari won't ask the user again
            // (in case we would try and spam the user) and will still block the notifications...
            // So we need to warn the user about this...
            pushNotificationStore.setPushNotificationStillDenied(true);
          }
          PushMessaging.raisePermissionStateEvent();
          break;

        case 'granted':
          // The web service URL is a valid push provider, and the user said yes.
          // permissionData.deviceToken is now available to use.

          // But first remove the old APNS subscription if any (to stop double notification)
          if (apnsDetails.previous.pushId !== apnsDetails.current.pushId) {
            await PushMessaging.unregisterSafari(true);
          }
          // Register the current APNS subscription
          await PushMessaging.registerSafari();
          PushMessaging.raisePermissionStateEvent();
          break;

        default:
          // logWarn('Got unexpected request permission result', permissionData);
          PushMessaging.raisePermissionStateEvent();
          break;
      }
    }
  }

  public static async getNotificationPermissionState(): Promise<NotificationPermissionStatus> {
    let result;
    if (BrowserDetection.isSafari()) {
      result = await this.getSafariPushNotificationStatus();
    } else {
      result = await this.getNonSafariPushNotificationStatus();
    }

    return result;
  }

  public static async requestPushNotificationPermission(): Promise<void> {
    // logDebug('Requesting push notification permission');
    if (BrowserDetection.isSafari()) {
      await this.requestSafariPermission();
    } else {
      await this.requestNonSafariPermission();
    }
    await this.register();
  }

  private static pendingNonSafariRegistration = false;
  private static swRegistration: ServiceWorkerRegistration | null = null;
  private static applicationServerKey: Uint8Array = PushMessaging.urlBase64ToUint8Array(
    'BIWlBg7AQRvYK12HChy31JdF9fudCTK60W054NufPXdJ9qJMc502ePsRwY9DNV16IZ1O2HrBDdiS5DB41jtx5p0');

  private static async checkPendingNonSafariRegistration() {
    // logDebug(`checkPendingNonSafariRegistration: registration=${!!this.swRegistration},` +
    //   `pendingNonSafariRegistration: ${this.pendingNonSafariRegistration}`);
    if (this.swRegistration && this.pendingNonSafariRegistration) {
      this.pendingNonSafariRegistration = false;
      await this.registerNonSafari();
    }
  }

  /**
   * Obtains the Website Push ID from local storage, or from system settings. This is a system setting (per Cafex App
   * server instance), but needs to be available to the client for making APNS push messaging requests.
   */
  private static async getApnsDetails(): Promise<ApnsFullEndpointProperties | null> {
    // logDebug('getApnsDetails() :: entry');
    const pushNotificationStore = usePushNotificationStore(pinia);
    const apnsDetails: ApnsFullEndpointProperties | null = await pushNotificationStore.getApnsDetails();
    // logDebug('getApnsDetails() :: exit with result: ', storedPushId);
    return apnsDetails;
  }

  /**
   * Raise an event notifying the rest of the application of the current permission state.
   */
  private static async raisePermissionStateEvent() {
    const pushNotificationStore = usePushNotificationStore(pinia);
    const state = await this.getNotificationPermissionState();
    pushNotificationStore.setNotificationState(state);
  }

  private static async unregisterCafexPush(registrationMsg: PushRegistrationMessage): Promise<void> {
    // logDebug('Unregistering from CafeX push notifications', registrationMsg);
    return ServerData.unregisterPushNotifications(registrationMsg);
  }

  /**
   * Unregisters a Safari browser (specifically, that browser's APNs Device Token) from CafeX. It will no
   * longer be associated with a user; notifications for that user will no longer be delivered here.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static async unregisterSafari(previousEndpointOnly?: boolean): Promise<any> {
    // logDebug('Attempting to unregister Safari push notifications');

    if (!('safari' in window && 'pushNotification' in window.safari)) {
      return;
    }

    const apnsDetails: ApnsFullEndpointProperties | null = await PushMessaging.getApnsDetails();

    if (!apnsDetails) {
      // logError('APNS Website Push ID is not configured');
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Promise<any>[] = [];

    if (!previousEndpointOnly) {
      const endpoint = apnsDetails.current;
      const permissionData = window.safari.pushNotification.permission(endpoint.pushId);
      if (permissionData.permission === 'granted') {
        const apnsEndpointInfo = {
          pushId: endpoint.pushId,
          token: permissionData.deviceToken,
          urlArgCount: endpoint.urlArgCount,
        };
        const registrationMsg: PushRegistrationMessage = {
          endpoint: JSON.stringify(apnsEndpointInfo),
          enabled: false,
          deviceType: PushDeviceType.DESKTOP,
          apns: true,
        };
        promises.push(this.unregisterCafexPush(registrationMsg));
      }
    }

    // Delete the previous APNS endpoint registration
    // This is to be safe and protect against double notifications.
    const endpoint = apnsDetails.previous;
    const permissionData = window.safari.pushNotification.permission(endpoint.pushId);

    if (permissionData.permission === 'granted') {
      const apnsEndpointInfo = {
        pushId: endpoint.pushId,
        token: permissionData.deviceToken,
        urlArgCount: endpoint.urlArgCount,
      };
      let registrationMsg: PushRegistrationMessage = {
        endpoint: JSON.stringify(apnsEndpointInfo),
        enabled: false,
        deviceType: PushDeviceType.DESKTOP,
        apns: true,
      };
      promises.push(this.unregisterCafexPush(registrationMsg));

      // But also delete it if it uses the old format (where the endpoint was just the device token),
      // which will be the case at the start of the transition to the new code.
      // However the old and new code can run a parallel for a while in separate windows (in Safari in particular)
      // So to be safe, we always ensure the old record is really gone.
      registrationMsg = {
        endpoint: permissionData.deviceToken,
        enabled: false,
        deviceType: PushDeviceType.DESKTOP,
        apns: true,
      };
      promises.push(this.unregisterCafexPush(registrationMsg));
    }

    // logDebug(`Unregistering Safari device from CafeX. deviceToken: ${deviceToken}`);

    // Ideally, we would just pass the list to the server rather than doing that couple of requests,
    // but that will have to be implemented later.
    return Promise.all(promises);
  }

  /**
   * Unregisters a non-Safari browser (specifically, that browser's Push API Endpoint) from CafeX. It will no
   * longer be associated with a user; notifications for that user will no longer be delivered here.
   */
  private static async unregisterNonSafari(): Promise<void> {
    // logDebug('Attempting to unregister non-Safari push notifications');
    if (this.swRegistration == null) {
      // logWarn('No registered Service Worker');
      return;
    }
    if (!PushMessaging || !PushMessaging.swRegistration || !PushMessaging.swRegistration.pushManager) {
      // logWarn('No push manager');
      return;
    }

    const pushSubscription = await PushMessaging.swRegistration.pushManager.getSubscription();
    if (pushSubscription == null) {
      return;
    }

    const registrationMsg = PushMessaging.createPushRegistrationMessage(pushSubscription);

    // logDebug(`Unregistering non-Safari device from CafeX. deviceToken: ${registrationMsg.endpoint}`);
    return this.unregisterCafexPush(registrationMsg);
  }

  private static async unregisterIosApp(): Promise<void> {
    // logDebug('Attempting to unregister iOS app push notifications');
    let registrationMessage: PushRegistrationMessage;
    try {
      const deviceToken = await IosDeviceToken.getToken();
      registrationMessage = {
        endpoint: deviceToken,
        enabled: true,
        deviceType: PushDeviceType.IOSAPP,
        apns: true,
      };
    } catch (error) {
      // logWarn(`Failed to generate push notification device token for iOS app when unregistering: ${error}`);
      return;
    }

    return await this.unregisterCafexPush(registrationMessage);
  }

  /**
   * Registers a Safari endpoint (that is, an APNs Device Token string) with CafeX.
   */
  private static async registerSafari(): Promise<void> {
    // logDebug('Registering for Safari push notifications');
    const pushId = await PushMessaging.getApnsDetails();

    if (!pushId) {
      // logError('APNS Website Push ID is not configured. Not registering for push notifications');
      return;
    }
    // This should have a permission of 'granted' and a 'deviceToken' property available
    const permissionData = window.safari.pushNotification.permission(pushId.current.pushId);

    // Check just in case permission has changed since we last checked
    if (permissionData.permission !== 'granted') {
      // logWarn('Push notification permission not granted when trying to register for Safari APNs notification',
      //   permissionData);
      return;
    }

    const deviceToken = permissionData.deviceToken;
    const apnsEndpointInfo = {
      pushId: pushId.current.pushId,
      token: deviceToken,
      urlArgCount: pushId.current.urlArgCount
    };
    const registrationMsg: PushRegistrationMessage = {
      endpoint: JSON.stringify(apnsEndpointInfo),
      enabled: true,
      deviceType: PushDeviceType.DESKTOP,
      apns: true,
    };

    return this.registerCafexPush(registrationMsg);
  }

  private static async registerIosApp(): Promise<void> {
    // logDebug('Registering for iOS app push notifications');

    let registrationMessage: PushRegistrationMessage;
    try {
      const deviceToken = await IosDeviceToken.getToken();
      registrationMessage = {
        endpoint: deviceToken,
        enabled: true,
        deviceType: PushDeviceType.IOSAPP,
        apns: true,
      };
    } catch (error) {
      // logWarn(`Failed to generate push notification device token for iOS app: ${error}`);
      return;
    }

    return this.registerCafexPush(registrationMessage);
  }

  /**
   * Registers a non-Safari push subscription endpoint with CafeX
   */
  private static async registerNonSafari(): Promise<void> {
    // logDebug('Registering for non-Safari push notifications');

    let pushSubscription: PushSubscription | null | undefined = null;
    try {
      pushSubscription = await PushMessaging.swRegistration?.pushManager.getSubscription() ?? null;
    } catch (error) {
      // logDebug(`Failed to get subscription from pushManager: ${error}`);
    }
    if (pushSubscription == null) {
      try {
        pushSubscription = await PushMessaging.createPushSubscription();
      } catch (error) {
        // logDebug(`Failed to create push subscription through pushManager: ${error}`);
      }
    }
    // logDebug(`pushSubscription: ${JSON.stringify(pushSubscription)}`);
    if (pushSubscription == null) {
      throw new Error('Could not get or create Push Messaging subscription');
    }

    const registrationMsg = PushMessaging.createPushRegistrationMessage(pushSubscription);
    return this.registerCafexPush(registrationMsg);
  }

  /**
   * Associates a unique push notification identifier (either a Push API endpoint, or APNs Device Token) with a CafeX
   * user, so that the server can send notifications for this user, to this device.
   */
  private static async registerCafexPush(registrationMsg: PushRegistrationMessage): Promise<void> {
    // logDebug('Registering for push notifications with CafeX', registrationMsg);
    return ServerData.registerForPushNotifications(registrationMsg);
  }

  private static async getNonSafariPushNotificationStatus(): Promise<NotificationPermissionStatus> {
    // logDebug('Checking non-Safari push notification permissions...');

    // Check if notifications are supported
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      // logWarn('Notifications are not supported');
      return this.createNotificationPermissionStatus(false, false, 'unsupported',
        'Notifications are not supported in this browser');
    }

    // Check if the Push API is supported
    if (!('PushManager' in window)) {
      // logWarn('Push messaging is not supported');
      return this.createNotificationPermissionStatus(false, false, 'unsupported',
        'Push notifications are not supported in this browser');
    }

    if (Notification.permission === 'default') {
      // logDebug('Notifications aren\'t enabled');
      return this.createNotificationPermissionStatus(true, false, Notification.permission,
        'Notifications are not enabled for this device');
    }

    if (Notification.permission === 'denied') {
      // logWarn('Notifications have been blocked');
      return this.createNotificationPermissionStatus(true, false, Notification.permission,
        'Notifications are blocked on this device. Please change your browser settings to receive them');
    }

    if (Notification.permission === 'granted') {
      // logDebug('Notifications are allowed');
      return this.createNotificationPermissionStatus(true, true, Notification.permission);
    }

    // logDebug(`Unknown notification permission state: ${Notification.permission}`);
    return this.createNotificationPermissionStatus(false, false, 'unknown');
  }

  private static async getSafariPushNotificationStatus(): Promise<NotificationPermissionStatus> {
    // logDebug('Checking Safari push notification permissions...');

    if (!('safari' in window && 'pushNotification' in window.safari)) {
      return this.createNotificationPermissionStatus(false, false, 'unsupported',
        'This browser does not support push notifications');
    }

    const pushId = await PushMessaging.getApnsDetails();
    if (!pushId) {
      // Server side is not configured for APNS, so don't ask the user for permission...
      return this.createNotificationPermissionStatus(false, false, 'unconfigured',
        'APNS Website Push ID is not configured.');
    }

    const pushPermission = window.safari.pushNotification.permission(pushId.current.pushId).permission;
    // logDebug(`Push Permission for Push ID ${pushId} is: ${pushPermission}`);

    let result: NotificationPermissionStatus;

    switch (pushPermission) {
      case 'granted':
        result = this.createNotificationPermissionStatus(true, true, pushPermission);
        break;
      case 'denied':
        result = this.createNotificationPermissionStatus(true, false, pushPermission,
          'Notifications are blocked on this device. Please change your browser preferences to receive them');
        break;
      case 'default':
        result = this.createNotificationPermissionStatus(true, false, pushPermission,
          'Browser notifications have not been enabled for this device');
        break;
      default:
        result = this.createNotificationPermissionStatus(true, false, 'unknown',
          `Could not get Safari push notification permission status for ${pushId}`);
        break;
    }

    return result;
  }

  /**
   * Helper method for creating notification permission status objects
   */
  private static createNotificationPermissionStatus(supported: boolean, enabled: boolean,
    state: NotificationPermissionState, description?: string): NotificationPermissionStatus {
    return {
      supported,
      enabled,
      state,
      description,
    };
  }

  private static async createPushSubscription(): Promise<PushSubscription | undefined> {
    const subscribeOptions: PushSubscriptionOptions = {
      userVisibleOnly: true,
      applicationServerKey: this.applicationServerKey
    };

    return PushMessaging.swRegistration?.pushManager.subscribe(subscribeOptions);
  }

  /**
   * Helper method for creating Push API subscription messages
   *
   * @param pushSubscription
   * @param enabled Describes whether the endpoint is enabled *within* the CafeX app (i.e. our internal config)
   */
  private static createPushRegistrationMessage(pushSubscription: PushSubscription, enabled: boolean = true):
    PushRegistrationMessage {
    const key = pushSubscription.getKey('p256dh');
    const auth = pushSubscription.getKey('auth');

    return {
      endpoint: pushSubscription.endpoint,
      key: key ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(key)))) : '',
      auth: auth ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(auth)))) : '',
      deviceType: PushMessaging.getPushDeviceType(),
      enabled,
      apns: false,
    };
  }

  // see: https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
  private static urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private static getPushDeviceType(): PushDeviceType {
    if (BrowserDetection.isMobileOrTablet()) {
      return PushDeviceType.MOBILE;
    } else {
      return PushDeviceType.DESKTOP;
    }
  }
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    safari: any;
  }
}
