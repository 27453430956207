export type FillStyle = 'cover' | 'pill';

export interface AttributeStyle {
  bold?: boolean;
  italic?: boolean;
  strikeThrough?: boolean;
  underline?: boolean;
  textColour?: string;
  bgColour?: string;
  fillStyle?: FillStyle;
}

type attribute = string;
export function stylesAreEqual(stylesA: Record<attribute, AttributeStyle>,
  stylesB: Record<attribute, AttributeStyle>) : boolean {
  const attributesA: attribute[] = Object.keys(stylesA);
  const attributesB: attribute[] = Object.keys(stylesB);

  if (attributesA.length !== attributesB.length) {
    return false;
  }

  if (attributesA.some(keyA => !attributesB.includes(keyA))) {
    return false;
  }

  for (const attribute of attributesA) {
    const attributeStyleA = stylesA[attribute];
    const attributeStyleB = stylesB[attribute];

    if (attributeStyleA && attributeStyleB) {
      const styleTypeAKeys: string[] = Object.keys(attributeStyleA);
      const styleTypeBKeys: string[] = Object.keys(attributeStyleB);

      if (styleTypeAKeys.length !== styleTypeBKeys.length) {
        return false;
      }

      for (const style of styleTypeAKeys) {
        const attributeStyleKey: keyof AttributeStyle = style as keyof AttributeStyle;
        if (!(styleTypeBKeys.includes(attributeStyleKey)) ||
          attributeStyleA[attributeStyleKey] !== attributeStyleB[attributeStyleKey]) {
          return false;
        }
      }
    } else if (attributeStyleA === undefined && attributeStyleB === undefined) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

export function cloneStyles(styles: Record<attribute, AttributeStyle>) : Record<attribute, AttributeStyle> {
  const stylesToReturn: Record<string, AttributeStyle> = {};
  for (const attribute of Object.keys(styles)) {
    const attributeStyle = Object.assign({}, styles[attribute]);
    stylesToReturn[attribute] = attributeStyle;
  }
  return stylesToReturn;
}
