import { computed, ComputedRef, watch } from 'vue';

import { KnowledgBaseNotificationState } from '@/data/datatypes/knowledgemgmt/KnowledgeBaseNotification';
import { useKnowledgeBaseStore } from '@/stores/KnowledgeBase';
import { useTracksStore } from '@/stores/Tracks';
import { NotificationsState } from '@/stores/Tracks.types';

const tracksStore = useTracksStore();
const knowledgeBaseStore = useKnowledgeBaseStore();

export function useFavicon() {
  const kbNotificationState: ComputedRef<KnowledgBaseNotificationState> = computed(() => {
    return knowledgeBaseStore.kbNotificationState;
  });

  const notificationsState: ComputedRef<NotificationsState> = computed(() => {
    return tracksStore.notificationsState;
  });

  function updateLink(link: HTMLLinkElement, suffix: string): void {
    let fileName: string = 'favicon';
    if (link.href.indexOf('32x32') >= 0) {
      fileName += '-32x32';
    }
    if (link.href.indexOf('16x16') >= 0) {
      fileName += '-16x16';
    }
    fileName += suffix;

    link.href = link.href.replace(/favicon[^.]*/, fileName);
  }

  function updateNotificationState(): void {
    let suffix: string = '';

    switch (notificationsState.value) {
      case NotificationsState.GENERAL:
        suffix = '-general';
        break;
      case NotificationsState.DIRECT:
        suffix = '-direct';
        break;
    }

    if (notificationsState.value === NotificationsState.NONE &&
      kbNotificationState.value === KnowledgBaseNotificationState.PENDING) {
      suffix = '-general';
    }

    for (const link of document.querySelectorAll('link[rel~="icon"]') as NodeListOf<HTMLLinkElement>) {
      if (link.href.indexOf('favicon') >= 0) {
        updateLink(link, suffix);
      }
    }
  }

  watch(() => notificationsState.value, () => {
    updateNotificationState();
  }, { immediate: true });

  watch(() => kbNotificationState.value, () => {
    updateNotificationState();
  }, { immediate: true });
}
