function initialiseName(name: string): string {
  let initials = '?';
  if (name) {
    let displayName = name.trim();
    if (displayName) {
      const initialCodePoint: number | undefined = displayName.codePointAt(0);
      // If it starts with a non-latin character, then just use that.
      if (initialCodePoint && initialCodePoint > 192) {
        initials = String.fromCodePoint(initialCodePoint);
      } else {
        displayName = displayName.replace(/[^a-zA-Z0-9]+/gim, ' ').trim();
        if (displayName) {
          initials = displayName.substring(0, 1);
          const spaceIndex = displayName.indexOf(' ');
          if (spaceIndex > 0) {
            initials += displayName.substring(spaceIndex + 1, spaceIndex + 2);
          }
        }
      }
    }
  }
  return initials;
}

function convertUUIDtoNumber(uuid: string): number {
  let digitsTotal = 0;

  for (let i = 0; i < uuid.length; i++) {
    const asNum = Number(uuid.charAt(i));

    if (Number.isNaN(asNum) === false) {
      digitsTotal += asNum;
    }
  }

  return digitsTotal;
}

export { convertUUIDtoNumber, initialiseName };
