export enum ChatMessagePartType {
  TEXT = 'TEXT',
  MENTION = 'MENTION',
  ENTRY = 'ENTRY',
  REACTIONS = 'REACTIONS',
  LIST = 'LIST',
  WRAPPED_TEXT = 'WRAPPED_TEXT',
  LINK = 'LINK',
  ADAPTIVE_CARD = 'ADAPTIVE_CARD',
  SHARED_MESSAGE = 'SHARED_MESSAGE',
  WORKSPACE = 'WORKSPACE',
}
