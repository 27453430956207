import { computed, ComputedRef, watch } from 'vue';

import { TrackingCodes } from '@/data/datatypes/tracking/TrackingCodes';
import { useUserStore } from '@/stores/User';

const userStore = useUserStore();

export function useHotjar() {
  const hotJarCodes: ComputedRef<TrackingCodes> = computed(() => {
    return userStore.trackingCodes;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function init(h: any, o: Document, t: string, j: string): void {
    if (!hotJarCodes.value.hotjar) {
      return;
    }
    const hjid: string = hotJarCodes.value.hotjar;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    h.hj = h.hj || function (...args: any[]) {
      (h.hj.q = h.hj.q || []).push(args);
    };
    h._hjSettings = {
      hjid,
      hjsv: 6
    };
    const a: HTMLHeadElement = o.getElementsByTagName('head')[0];
    const r: HTMLScriptElement = o.createElement('script');
    r.async = true;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  }

  watch(() => hotJarCodes.value, () => {
    init(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, { immediate: true });
}
