import log from 'loglevel';

import { OngoingMeetingState } from '@/custom_typings/cafexmeetings/meetings-api';
import { CallMessage } from '@/data/datatypes/chat/CallMessage';
import { EntryThumbnail } from '@/data/datatypes/EntryThumbnails';
import ScheduledMeeting from '@/data/datatypes/meeting/ScheduledMeeting';
import { Member } from '@/data/datatypes/Member';
import { ScrollView } from '@/data/datatypes/online/ScrollView';
import { TrackOnlineStatus } from '@/data/datatypes/online/TrackOnlineStatus';
import { PinnedTrackItem } from '@/data/datatypes/PinnedTrackItem';
import { Ruleset } from '@/data/datatypes/rulesengine/Ruleset';
import { TextFile } from '@/data/datatypes/TextFile';
import { CallMessageType, Track } from '@/data/datatypes/Track';
import { TrackEntry } from '@/data/datatypes/TrackEntry';
import { compareMessages } from '@/data/helpers/ChatHelper';
import { MiniApp } from '@/data/tasks/MiniApp';
import { Task } from '@/data/tasks/Task';
import { TasksTable } from '@/data/tasks/TasksTable';
import { UIFlow } from '@/data/tasks/UIFlow';
import pinia from '@/stores';
import { useAppAccountsStore } from '@/stores/AppAccounts';
import { useChatMessagesStore } from '@/stores/ChatMessages';
import { useConnectionStore } from '@/stores/Connection';
import { useEntryTextFilesStore } from '@/stores/EntryTextFiles';
import { useEntryThumbnailsStore } from '@/stores/EntryThumbnails';
import { useKnowledgeBaseStore } from '@/stores/KnowledgeBase';
import { useMeetingStore } from '@/stores/Meeting';
import { usePinnedTrackItemStore } from '@/stores/PinnedTrackItems';
import { useAppVersionsStore } from '@/stores/release/AppVersions';
import { useDeployedAppsStore } from '@/stores/release/DeployedApps';
import { useDeploymentHistoryStore } from '@/stores/release/DeploymentHistory';
import { useEnvironmentsStore } from '@/stores/release/Environments';
import { useReleasedAppsStore } from '@/stores/release/ReleasedApps';
import { useReleasesStore } from '@/stores/release/Releases';
import { useRulesetsStore } from '@/stores/Rulesets';
import { useTasksStore } from '@/stores/Tasks';
import { useTenantsStore } from '@/stores/Tenants';
import { useTrackEntriesStore } from '@/stores/TrackEntries';
import { useTrackMembersStore } from '@/stores/TrackMembers';
import { useTrackOnlineStatusesStore } from '@/stores/TrackOnlineStatuses';
import { useTracksStore } from '@/stores/Tracks';
import { useUIStateStore } from '@/stores/UIState';
import { useUserStore } from '@/stores/User';
import ConnectionState from '@/workers/datatypes/ConnectionState';
import OnlineUserState from '@/workers/datatypes/OnlineUserState';

import { AppAccount } from '../datatypes/AppAccount';
import { CHAT_MESSAGE_PAGE_SIZE, ChatMessage } from '../datatypes/chat/ChatMessage';
import { LoadMoreChatMessagesResponse } from '../datatypes/chat/LoadMoreChatMessagesResponse';
import ArticleSubscriptionConfig from '../datatypes/knowledgemgmt/ArticleSubscriptionConfig';
import KnowledgeArticleSummary from '../datatypes/knowledgemgmt/KnowledgeArticleSummary';
import { ThirdPartyMeetingDetails } from '../datatypes/meeting/ThirdPartyMeetingDetails';
import { MeetingStatus } from '../datatypes/online/OnlineStatus';
import Tenant from '../datatypes/Tenant';
import { ExternalUserDetails, FullUserDetails, TenantUserDetails } from '../datatypes/UserDetails';
import { perfLog, perfMsg } from '../log/PerformanceLog';
import { NavBarItem } from '../NavBarItem';
import { AppReleaseMetadata } from '../release/AppReleaseMetadata';
import { Environment } from '../release/Environment';
import { MiniAppViewTheme } from '../tasks/MiniAppViewTheme';
import { MiniAppView } from '../tasks/TaskView';
import { AppDeploymentRecord } from '../version/AppDeploymentRecord';
import { AppDevelopmentVersion } from '../version/AppDevelopmentVersion';
import { DeployedApp, ReleasedApp } from '../version/DeployedApp';
import { PagedResult } from './AbstractDb';
import DataWorker from './DataWorker';
import ObjectStores from './ObjectStores';
import StoredItem from './StoredItem';

const trackOnlineStatusesStore = useTrackOnlineStatusesStore(pinia);
const pinnedTrackItemsStore = usePinnedTrackItemStore(pinia);
const trackMembersStore = useTrackMembersStore(pinia);
const tenantsStore = useTenantsStore(pinia);
const tracksStore = useTracksStore(pinia);
const trackEntriesStore = useTrackEntriesStore(pinia);
const meetingStore = useMeetingStore(pinia);
const appVersionStore = useAppVersionsStore(pinia);
const appReleaseStore = useReleasesStore(pinia);
const appEnvironmentsStore = useEnvironmentsStore(pinia);
const releasedAppsStore = useReleasedAppsStore(pinia);
const deployedAppsStore = useDeployedAppsStore(pinia);
const deploymentHistoryStore = useDeploymentHistoryStore(pinia);
const userStore = useUserStore(pinia);
const tasksStore = useTasksStore(pinia);
const chatMessagesStore = useChatMessagesStore(pinia);
const rulesetsStore = useRulesetsStore(pinia);
const appAccountsStore = useAppAccountsStore(pinia);
const connectionStore = useConnectionStore(pinia);
const entryThumbnailsStore = useEntryThumbnailsStore(pinia);
const entryTextFilesStore = useEntryTextFilesStore(pinia);
const uiStateStore = useUIStateStore(pinia);

interface ChatMessageReloadResponse {
  messages: ChatMessage[];
  fullPageRetrieved: boolean;
  subsequentPageExists: boolean;
}

function getIdDateKeyRange(entityId: string, fromDate: number, toDate: number): IDBKeyRange {
  return IDBKeyRange.bound([entityId, fromDate], [entityId, toDate]);
}

async function reloadFullChatMessagePageFromIndexedDB(trackId: string, entryId: string | null,
  messageDateUpperBound: number, db: ObjectStores): Promise<ChatMessageReloadResponse> {
  let pagedResult: PagedResult;
  let selectMoreFromIndexedDB: boolean = true;
  let fullPageRetrieved: boolean = false;
  const messages: ChatMessage[] = [];
  do {
    const entityIdMessageDateRange: IDBKeyRange = getIdDateKeyRange(entryId || trackId, 0, messageDateUpperBound);
    if (entryId) {
      pagedResult = await db.getPageByIndex(ObjectStores.COMMENTS_STORE,
        ObjectStores.ENTRY_ID_MESSAGE_DATE_INDEX, CHAT_MESSAGE_PAGE_SIZE, true, entityIdMessageDateRange);
    } else {
      pagedResult = await db.getPageByIndex(ObjectStores.CHAT_MESSAGES_STORE,
        ObjectStores.TRACK_ID_MESSAGE_DATE_INDEX, CHAT_MESSAGE_PAGE_SIZE, true, entityIdMessageDateRange);
    }

    const messagesFromDB: ChatMessage[] = pagedResult.objects.map((item: StoredItem<ChatMessage>) => item.entity);
    if (messagesFromDB.length) {
      messages.push(...messagesFromDB);
      const parentMessageCount: number = messages.filter((current: ChatMessage) => !current.parentMessageId).length;
      if (parentMessageCount >= CHAT_MESSAGE_PAGE_SIZE) {
        selectMoreFromIndexedDB = false;
        fullPageRetrieved = true;
      } else {
        messageDateUpperBound = Math.min(messageDateUpperBound,
          ...(messagesFromDB.map((current: ChatMessage) => current.date - 1)));
      }
    } else {
      selectMoreFromIndexedDB = false;
    }
  } while (selectMoreFromIndexedDB);

  if (messages.length) {
    if (entryId) {
      chatMessagesStore.setComments({ trackId, entryId, messages, fullRefresh: false });
    } else {
      chatMessagesStore.setTrackChatMessages({ messages, fullRefresh: false });
    }
  }
  return {
    messages,
    fullPageRetrieved,
    subsequentPageExists: pagedResult.subsequentPageExists
  };
}

// IndexedDB methods called from the Pinia stores:
export class ObjectStoreHelper {
  private db: ObjectStores;
  private static theInstance: ObjectStoreHelper;

  public constructor(db: ObjectStores) {
    this.db = db;
  }

  public async loadMoreChatMessages(trackId: string, entryId: string | null, currentOldestMessageDate?: number):
      Promise<LoadMoreChatMessagesResponse> {
    const fullHistoryRetrieved: boolean =
      chatMessagesStore.hasFullHistoryBeenRetrieved(trackId, entryId ?? undefined);
    const messageDateUpperBound = (currentOldestMessageDate ? (currentOldestMessageDate - 1)
      : Number.MAX_SAFE_INTEGER);

    const response: ChatMessageReloadResponse = await reloadFullChatMessagePageFromIndexedDB(trackId, entryId,
      messageDateUpperBound, this.db);
    const messagesLoaded: ChatMessage[] = response.messages;

    const waitingForMessages: boolean = chatMessagesStore.isWaitingForMessages(trackId, entryId);
    if (!response.subsequentPageExists && !fullHistoryRetrieved && !waitingForMessages) {
      chatMessagesStore.setWaitingForMessages({ trackId, entryId, waiting: true });
      if (!response.fullPageRetrieved) {
        // We didn't find a full page in the DB, so wait for more from the server and set them in Pinia immediately.
        const messages: ChatMessage[] = await DataWorker.instance().dispatch(
          'ChatMessages/loadAndReturnMoreChatMessages', trackId, entryId);
        if (messages.length) {
          if (entryId) {
            chatMessagesStore.setComments({ trackId, entryId, messages, fullRefresh: false });
          } else {
            chatMessagesStore.setTrackChatMessages({ messages, fullRefresh: false });
          }
          messagesLoaded.push(...messages);
        } else {
          chatMessagesStore.setFullChatHistoryRetrieved({ trackId, entryId });
        }
        chatMessagesStore.setWaitingForMessages({ trackId, entryId, waiting: false });
      } else {
        // We already have enough in Pinia. Load more so that they're in the DB waiting for when we scroll up.
        DataWorker.instance().dispatch('ChatMessages/loadMoreChatMessages', trackId, entryId).then(
          (newMessagesFound: boolean) => {
            if (!newMessagesFound) {
              chatMessagesStore.setFullChatHistoryRetrieved({ trackId, entryId });
            }
            chatMessagesStore.setWaitingForMessages({ trackId, entryId, waiting: false });
          });
      }
    }
    return {
      fullHistoryRetrieved,
      messages: messagesLoaded.filter((current: ChatMessage) => !current.parentMessageId).sort(compareMessages),
    };
  }

  public async loadChatHistoryIncludingMessage(trackId: string, messageId: string,
    currentOldestMessageDate: number | undefined): Promise<void> {
    const HALF_PAGE_SIZE: number = Math.floor(CHAT_MESSAGE_PAGE_SIZE / 2);
    const loadedMessages: Record<string, ChatMessage> = {};
    let loadMoreFromServer: boolean = true;
    let subsequentQueryDateLowerBound: number = 0;

    // See if the message is in the indexed DB
    const existingMessage: StoredItem<ChatMessage> = await this.db.get(ObjectStores.CHAT_MESSAGES_STORE, messageId);
    if (existingMessage) {
      // Make sure there is a bit of history loaded before this message
      const beforeQuery: IDBKeyRange = getIdDateKeyRange(trackId, 0, existingMessage.entity.date - 1);
      const pagedResultBefore: PagedResult = await this.db.getPageByIndex(ObjectStores.CHAT_MESSAGES_STORE,
        ObjectStores.TRACK_ID_MESSAGE_DATE_INDEX, HALF_PAGE_SIZE, false, beforeQuery);
      for (const message of pagedResultBefore.objects) {
        loadedMessages[message.entity.id] = message.entity;
      }
      loadedMessages[existingMessage.entity.id] = existingMessage.entity;

      if (pagedResultBefore.objects.length >= HALF_PAGE_SIZE) {
        // We have the message and enough history before it.
        loadMoreFromServer = false;
      }
      subsequentQueryDateLowerBound = existingMessage.entity.date;
    }

    // Fill in the gap between the requested message and the oldest message currently in the store (or just get
    // everything left in the DB if our message isn't in there).
    const messageDateUpperBound = currentOldestMessageDate || Number.MAX_SAFE_INTEGER;
    if (messageDateUpperBound >= subsequentQueryDateLowerBound) {
      const onOrAfterQuery: IDBKeyRange = getIdDateKeyRange(trackId, subsequentQueryDateLowerBound,
        messageDateUpperBound);
      const onOrAfterResult: StoredItem<ChatMessage>[] = await this.db.getByIndex(ObjectStores.CHAT_MESSAGES_STORE,
        ObjectStores.TRACK_ID_MESSAGE_DATE_INDEX, onOrAfterQuery);
      for (const message of onOrAfterResult) {
        loadedMessages[message.entity.id] = message.entity;
      }
    }

    if (loadMoreFromServer) {
      // We didn't find enough messages in the DB, so fetch more from the server.
      if (!chatMessagesStore.hasFullHistoryBeenRetrieved(trackId)) {
        const messagesFromServer: ChatMessage[] = await DataWorker.instance().dispatch(
          'ChatMessages/loadChatMessageHistoryIncluding', trackId, messageId);
        for (const message of messagesFromServer) {
          loadedMessages[message.id] = message;
        }
      }
    }

    const messagesToSet: ChatMessage[] = Object.values(loadedMessages);
    if (messagesToSet.length) {
      chatMessagesStore.setTrackChatMessages({ messages: messagesToSet, fullRefresh: false });
    }
  }

  public static initialise(db: ObjectStores): void {
    log.debug('Initialising ObjectStoreHelper');
    if (!ObjectStoreHelper.theInstance) {
      ObjectStoreHelper.theInstance = new ObjectStoreHelper(db);
    }
  }

  public static instance(): ObjectStoreHelper {
    if (!ObjectStoreHelper.theInstance) {
      log.error('ObjectStoreHelper not initialised');
      throw new Error('ObjectStoreHelper not initialised');
    }
    return ObjectStoreHelper.theInstance;
  }
}

export default class ObjectStoreSync {
  private db: ObjectStores;
  private static instance: ObjectStoreSync;
  private static initPromise: Promise<void> | undefined;

  public static async getInstance(): Promise<ObjectStoreSync> {
    if (!this.instance && !this.initPromise) {
      this.initPromise = new Promise((resolve) => {
        this.openDb().then((db: void | ObjectStores) => {
          if (db) {
            this.instance = new ObjectStoreSync(db);
          }
          resolve();
        });
      });
    }
    if (this.initPromise) {
      await this.initPromise;
    }
    return this.instance;
  }

  private static async openDb(): Promise<ObjectStores | void> {
    if (!this.instance) {
      const dbUpgradeRequestedCallback = (db: IDBDatabase) => {
        // We need to close our IDB connection and tell the user to refresh the page.
        // Our shared worker will close itself shortly, if it hasn't already.
        log.debug('Closing IDB connection due to version upgrade request');
        db.close();
        connectionStore.setDbInvalidated();
      };
      const db: ObjectStores = new ObjectStores(dbUpgradeRequestedCallback);
      await db.open();
      return db;
    }
  }

  private constructor(db: ObjectStores) {
    this.db = db;
    ObjectStoreHelper.initialise(db);
  }

  private async time(name: string, funct: () => Promise<unknown>): Promise<void> {
    const start: number = performance.now();
    perfLog.debug(perfMsg(`starting ${name}`));
    await funct();
    perfLog.debug(perfMsg(`finished ${name}`, false, start));
  }

  public reloadAll(): void {
    const start: number = performance.now();
    perfLog.debug(perfMsg('Reloading all IDB data'));
    Promise.all([
      this.time('loadTracks', this.loadTracks.bind(this)),
      this.time('loadTrackEntries', this.loadTrackEntries.bind(this)),
      this.time('loadTrackMembers', this.loadTrackMembers.bind(this)),
      this.time('loadTenants', this.loadTenants.bind(this)),
      this.time('loadTenantUsers', this.loadTenantUsers.bind(this)),
      this.time('loadExternalUsers', this.loadExternalUsers.bind(this)),
      this.time('loadOnlineUsers', this.loadOnlineUsers.bind(this)),
      this.time('loadOnlineTrackStatuses', this.loadOnlineTrackStatuses.bind(this)),
      this.time('loadKnowledgeArticles', this.loadKnowledgeArticles.bind(this)),
      this.time('loadAppAccounts', this.loadAppAccounts.bind(this)),
      this.time('loadTasks', this.loadTasks.bind(this)),
      this.time('loadTaskTables', this.loadTaskTables.bind(this)), // This will load track-level ones as well
      this.time('loadMiniApps', this.loadMiniApps.bind(this)), // This will load track-level ones as well
      this.time('loadRulesets', this.loadTenantRulesets.bind(this)),
      this.time('loadEntryTextFiles', this.loadEntryTextFiles.bind(this)),
      this.time('reloadUserTokenFromStorage', this.reloadUserTokenFromStorage.bind(this)),
      this.time('loadNavBarItems', this.loadNavBarItems.bind(this)),
      this.time('loadMiniAppViews', this.loadMiniAppViews.bind(this)), // This will load track-level ones as well
      this.time('loadKnowledgeArticleSubscriptionConfig', this.loadKnowledgeArticleSubscriptionConfig.bind(this)),
      this.time('loadAppDevelopmentVersions', this.loadAppDevelopmentVersions.bind(this)),
      this.time('loadAppReleaseMetadata', this.loadAppReleaseMetadata.bind(this)),
      this.time('loadAppEnvironments', this.loadAppEnvironments.bind(this)),
      this.time('loadReleasedApps', this.loadReleasedApps.bind(this)),
      this.time('loadDeployedApps', this.loadDeployedApps.bind(this)),
      this.time('loadDeploymentHistory', this.loadDeploymentHistory.bind(this)),
      this.time('loadMiniAppViewThemes', this.loadMiniAppViewThemes.bind(this)),
    ]).then(() => {
      perfLog.debug(perfMsg('All IDB data reloaded', false, start));
      uiStateStore.setInitialIDBLoadComplete(true);
    }).catch((err) => {
      log.error(`Failed to reload all IndexedDB data: ${err}`);
      log.error(err);
    });
  }

  public async reload(objectStoreName: string, timestamp: number, extraData?: unknown): Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.TRACKS_STORE:
        await this.loadTracks(timestamp);
        break;
      case ObjectStores.TRACK_ENTRIES_STORE:
        await this.loadTrackEntries(timestamp, extraData as string);
        break;
      case ObjectStores.ENTRY_TEXT_FILES_STORE:
        await this.loadEntryTextFiles(timestamp);
        break;
      case ObjectStores.TRACK_MEMBERS_STORE:
        await this.loadTrackMembers(timestamp);
        break;
      case ObjectStores.TENANTS_STORE:
        await this.loadTenants(timestamp);
        break;
      case ObjectStores.TENANT_USERS_STORE:
        await this.loadTenantUsers(timestamp);
        break;
      case ObjectStores.EXTERNAL_USERS_STORE:
        await this.loadExternalUsers(timestamp);
        break;
      case ObjectStores.ONLINE_USERS_STORE:
        await this.loadOnlineUsers();
        break;
      case ObjectStores.TRACK_ONLINE_STATUSES_STORE:
        await this.loadOnlineTrackStatuses();
        break;
      case ObjectStores.KB_ARTICLES_STORE:
        await this.loadKnowledgeArticles();
        break;
      case ObjectStores.KB_SUBSCRIPTION_CONFIG_STORE:
        await this.loadKnowledgeArticleSubscriptionConfig();
        break;
      case ObjectStores.APP_ACCOUNTS_STORE:
        await this.loadAppAccounts(timestamp);
        break;
      case ObjectStores.CHAT_MESSAGES_STORE:
        await this.loadTrackChatMessages(timestamp);
        break;
      case ObjectStores.COMMENTS_STORE:
        await this.loadComments(timestamp);
        break;
      case ObjectStores.CURRENT_USER_STORE:
        await this.loadCurrentUser();
        break;
      case ObjectStores.PINNED_TRACK_ITEMS_STORE:
        await this.loadPinnedTrackItems(timestamp);
        break;
      case ObjectStores.CONNECTION_STATE_STORE:
        await this.loadConnectionState(extraData as ConnectionState);
        break;
      case ObjectStores.USER_TOKEN_STORE:
        await this.reloadUserTokenFromStorage();
        break;
      case ObjectStores.TASKS_STORE:
        await this.loadTasks(timestamp, extraData as string);
        break;
      case ObjectStores.TASKS_TABLE_STORE:
        await this.loadTaskTables(timestamp);
        break;
      case ObjectStores.MINI_APPS_STORE:
        await this.loadMiniApps(timestamp);
        break;
      case ObjectStores.TRACK_TASKS_TABLE_STORE:
        await this.loadTrackTaskTables(timestamp, extraData as string);
        break;
      case ObjectStores.TRACK_MINI_APPS_STORE:
        await this.loadTrackMiniApps(timestamp, extraData as string);
        break;
      case ObjectStores.UI_FLOWS_STORE:
        await this.loadUiFlows(timestamp);
        break;
      case ObjectStores.ENTRY_THUMBNAIL_STORE:
        await this.loadEntryThumbnails(timestamp);
        break;
      case ObjectStores.RULESETS_STORE:
        await this.loadTenantRulesets(timestamp);
        break;
      case ObjectStores.NAV_BAR_ITEMS_STORE:
        await this.loadNavBarItems(timestamp);
        break;
      case ObjectStores.MINI_APP_VIEWS_STORE:
        await this.loadMiniAppViews(timestamp);
        break;
      case ObjectStores.TRACK_MINI_APP_VIEWS_STORE:
        await this.loadTrackMiniAppViews(timestamp, extraData as string);
        break;
      case ObjectStores.APP_DEVELOPMENT_VERSION_STORE:
        await this.loadAppDevelopmentVersions(timestamp);
        break;
      case ObjectStores.APP_RELEASES_STORE:
        await this.loadAppReleaseMetadata(timestamp);
        break;
      case ObjectStores.APP_ENVIRONMENT_STORE:
        await this.loadAppEnvironments(timestamp); break;
        break;
      case ObjectStores.RELEASED_APP_STORE:
        await this.loadReleasedApps();
        break;
      case ObjectStores.DEPLOYED_APP_STORE:
        await this.loadDeployedApps();
        break;
      case ObjectStores.DEPLOYMENT_HISTORY_STORE:
        await this.loadDeploymentHistory();
        break;
      case ObjectStores.MINI_APP_VIEW_THEMES_STORE:
        await this.loadMiniAppViewThemes(timestamp, extraData as string);
        break;
      default:
        break;
    }
  }

  public async synchronisedReload(objectStoreName: string, timestamp: number, extraData?: unknown): Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.TASKS_STORE:
        await this.handleMiniAppDataSynchronisedReload(timestamp, extraData as boolean);
        break;
      default:
        break;
    }
  }

  public async reloadForGuest(objectStoreName: string, data: ChatMessage[]): Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.COMMENTS_STORE:
        chatMessagesStore.setComments({ messages: data, fullRefresh: false });
        break;
      default:
        break;
    }
  }

  public async handleNewActiveTrackId(trackId: string, objectStoreName: string): Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.TRACK_ENTRIES_STORE:
        await this.loadTrackEntriesByTrackId(trackId);
        break;
      case ObjectStores.TRACK_MEMBERS_STORE:
        await this.loadTrackMembersByTrackId(trackId);
        break;
      case ObjectStores.TRACK_ONLINE_STATUSES_STORE:
        await this.loadOnlineTrackStatuses();
        break;
      case ObjectStores.PINNED_TRACK_ITEMS_STORE:
        await this.loadPinnedTrackItemsByTrackId(trackId);
        break;
      case ObjectStores.TASKS_STORE:
        await this.loadTasksForTrack(trackId);
        break;
      case ObjectStores.TRACK_TASKS_TABLE_STORE:
        await this.loadTaskTablesForTrack(trackId);
        break;
      case ObjectStores.TRACK_MINI_APPS_STORE:
        await this.loadMiniAppsForTrack(trackId);
        break;
      case ObjectStores.ENTRY_THUMBNAIL_STORE:
        await this.loadEntryThumbnailsForTrack(trackId);
        this.clearUpThumbnailsForOldTracks(trackId);
        break;
      case ObjectStores.TRACK_MINI_APP_VIEWS_STORE:
        await this.loadMiniAppViewsForTrack(trackId);
        break;
      default:
        break;
    }
  }

  public async handleNewActiveEntryId(entryId: string, objectStoreName: string): Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.ENTRY_TEXT_FILES_STORE:
        await this.loadEntryTextFilesByEntry(entryId);
        break;
      default:
        break;
    }
  }

  public async handleNewActiveMiniAppId(miniAppId: string, objectStoreName: string): Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.MINI_APP_VIEW_THEMES_STORE:
        await this.loadMiniAppViewThemesByMiniApp(miniAppId);
        break;
      default:
        break;
    }
  }

  public async handleNewMeetingTrackId(meetingTrackId: string, status: MeetingStatus, objectStoreName: string):
    Promise<void> {
    switch (objectStoreName) {
      case ObjectStores.ENTRY_THUMBNAIL_STORE:
        switch (status) {
          case MeetingStatus.NOT_IN_MEETING:
            this.clearUpThumbnailsForOldTracks();
            break;
          case MeetingStatus.JOINING_MEETING:
            await this.loadEntryThumbnailsForTrack(meetingTrackId);
            this.clearUpThumbnailsForOldTracks(undefined, meetingTrackId);
            break;
          case MeetingStatus.IN_MEETING:
            // Everything should be ready after the JOINING phase
            break;
        }
        break;
      default:
        break;
    }
  }

  public uninitialiseTrackData(trackId: string): void {
    trackEntriesStore.setEntriesInitialised({ trackId, initialised: false });
    tasksStore.setTasksInitialised({ trackId, initialised: false });
    tasksStore.setTaskTablesInitialised({ trackId, initialised: false });
    tasksStore.setMiniAppViewsInitialised({ trackId, initialised: false });
    tasksStore.setMiniAppsInitialised({ trackId, initialised: false });
  }

  public uninitialiseMiniAppData(miniAppId: string): void {
    tasksStore.setThemesInitialisedForMiniApp({ miniAppId, initialised: false });
  }

  public handleUsersTyping(trackId: string, usersTyping: Array<{userId: string; timestamp: number}>): void {
    chatMessagesStore.setUsersTyping({ trackId, usersTyping });
  }

  public handleCallMessage(callMessage: CallMessage, callMessageType: CallMessageType): void {
    tracksStore.setCallMessage({ callMessage, callMessageType });
  }

  public handleRecentlyViewedTracksUpdate(trackIds: string[]): void {
    tracksStore.setRecentlyViewedTracks({ recentlyViewedTrackIds: trackIds });
  }

  public handleCalendarMeetingsUpdate(calendarMeetings: ScheduledMeeting[]): void {
    userStore.setCalendarMeetings({ calendarMeetings });
  }

  public async loadNavBarItems(timestamp?: number): Promise<void> {
    const navBarItems: NavBarItem[] = await this.getObjectsFromStore(
      ObjectStores.NAV_BAR_ITEMS_STORE, timestamp);
    userStore.setNavBarItems({ navBarItems, fullRefresh: !timestamp });
  }

  public handlePresentationViewScrolled(liveshareId: string, scrollView: ScrollView): void {
    trackEntriesStore.setLastPresentationScrollTo({ liveshareId, scrollView });
  }

  public handleGuestMeetingRecordEntry(meetingRecordEntry: TrackEntry): void {
    trackEntriesStore.setGuestMeetingRecordEntry(meetingRecordEntry);
    chatMessagesStore.setEntryCommentsInitialised({
      trackId: meetingRecordEntry.trackId,
      entryId: meetingRecordEntry.id,
      initialised: true
    });
  }

  public handleTriggerGuestOnlineUpdate(trackId: string): void {
    userStore.updateGuestOnlineStatus(trackId);
  }

  public handleThirdPartyMeetingDetails(thirdPartyMeetingDetails: ThirdPartyMeetingDetails): void {
    meetingStore.setThirdPartyMeetingDetails(thirdPartyMeetingDetails);
  }

  public async loadTracks(timestamp?: number): Promise<void> {
    const connectionState: ConnectionState | null =
        await this.getSingleEntryFromStore(ObjectStores.CONNECTION_STATE_STORE);
    await tracksStore.waitForInProgressUpdates();
    const tracks: Track[] = await this.getObjectsFromStore(ObjectStores.TRACKS_STORE, timestamp);
    await tracksStore.setNewTrackList({ tracks, fullRefresh: !timestamp });
    if (connectionState) {
      // On first load this will be the most recent server poll from the SharedWorker.
      // In operation this will be the time of the SharedWorker->Server poll that caused the IndexedDb data to update
      tracksStore.setLastDataUpdateTime(connectionState.lastTrackPoll);
    }
  }

  public async loadTrackEntries(timestamp?: number, newActiveTrackId?: string): Promise<void> {
    const trackEntries: TrackEntry[] = await this.getObjectsFromStore(
      ObjectStores.TRACK_ENTRIES_STORE, timestamp);
    // Don't set OGP data if we are doing the initial load, to avoid lots of requests on page load.
    const setOGPData: boolean = timestamp !== undefined;
    trackEntriesStore.setEntries({ entries: trackEntries, fullRefresh: !timestamp, setOGPData });
    if (newActiveTrackId) {
      trackEntriesStore.setEntriesInitialised({ trackId: newActiveTrackId, initialised: true });
    }
  }

  public async loadEntryTextFiles(timestamp?: number): Promise<void> {
    const textFiles: TextFile[] = await this.getObjectsFromStore(
      ObjectStores.ENTRY_TEXT_FILES_STORE, timestamp);
    entryTextFilesStore.setTextFiles({ textFiles: textFiles, fullRefresh: !timestamp });
    entryTextFilesStore.setTextFilesLoaded(true);
  }

  public async loadTrackMembers(timestamp?: number): Promise<void> {
    const trackMembers: Member[] = await this.getObjectsFromStore(
      ObjectStores.TRACK_MEMBERS_STORE, timestamp);
    trackMembersStore.setMembers({ members: trackMembers, fullRefresh: !timestamp });
  }

  public async loadTenantRulesets(timestamp?: number): Promise<void> {
    await rulesetsStore.waitForInProgressUpdates('Rulesets');
    const tenantRulesets: Ruleset[] = await this.getObjectsFromStore(
      ObjectStores.RULESETS_STORE, timestamp);
    rulesetsStore.setRulesets({ rulesets: tenantRulesets, fullRefresh: !timestamp });
  }

  public async loadTenants(timestamp?: number): Promise<void> {
    const tenants: Tenant[] = await this.getObjectsFromStore(ObjectStores.TENANTS_STORE, timestamp);
    tenantsStore.setTenants({ tenants, fullRefresh: !timestamp });
  }

  public async loadTenantUsers(timestamp?: number): Promise<void> {
    const users: TenantUserDetails[] = await this.getObjectsFromStore(ObjectStores.TENANT_USERS_STORE, timestamp);
    userStore.setTenantUsers({ users, fullRefresh: !timestamp });
  }

  public async loadExternalUsers(timestamp?: number): Promise<void> {
    const users: ExternalUserDetails[] = await this.getObjectsFromStore(ObjectStores.EXTERNAL_USERS_STORE,
      timestamp);
    userStore.setNewExternalUserList({ users, fullRefresh: !timestamp });
  }

  public async loadOnlineUsers(): Promise<void> {
    const onlineState: OnlineUserState =
        await this.getSingleEntryFromStore(ObjectStores.ONLINE_USERS_STORE) || new OnlineUserState();
    userStore.setOnlineUserState(onlineState);
  }

  public async loadOnlineTrackStatuses(): Promise<void> {
    const trackOnlineStatus: TrackOnlineStatus[] = await this.getObjectsFromStore(
      ObjectStores.TRACK_ONLINE_STATUSES_STORE);
    trackOnlineStatusesStore.setTrackOnlineStatuses({ trackOnlineStatus: trackOnlineStatus, fullRefresh: true });
  }

  public async loadKnowledgeArticles(): Promise<void> {
    const knowledgeBaseStore = useKnowledgeBaseStore(pinia);
    const articles: KnowledgeArticleSummary[] = await this.getObjectsFromStore(ObjectStores.KB_ARTICLES_STORE);
    knowledgeBaseStore.setArticlesFetchingMetadata({ articles: articles, removeMissing: true });
  }

  public async loadKnowledgeArticleSubscriptionConfig(): Promise<void> {
    const knowledgeBaseStore = useKnowledgeBaseStore(pinia);
    const config: ArticleSubscriptionConfig | null =
      await this.getSingleEntryFromStore(ObjectStores.KB_SUBSCRIPTION_CONFIG_STORE);
    if (config) {
      knowledgeBaseStore.setArticleSubscriptionConfig(config);
    }
  }

  public async loadAppAccounts(timestamp?: number): Promise<void> {
    const accounts: AppAccount[] = await this.getObjectsFromStore(ObjectStores.APP_ACCOUNTS_STORE, timestamp);
    appAccountsStore.setAppAccounts({ accounts, fullRefresh: !timestamp });
  }

  public async loadTrackChatMessages(timestamp: number): Promise<void> {
    const messages: ChatMessage[] = await this.getObjectsFromStore(
      ObjectStores.CHAT_MESSAGES_STORE, timestamp);
    chatMessagesStore.setTrackChatMessages({ messages, fullRefresh: false });
  }

  public async loadComments(timestamp: number): Promise<void> {
    const messages: ChatMessage[] = await this.getObjectsFromStore(
      ObjectStores.COMMENTS_STORE, timestamp);
    chatMessagesStore.setComments({ messages, fullRefresh: !timestamp });
  }

  private async loadTrackEntriesByTrackId(trackId: string): Promise<void> {
    const trackEntries: TrackEntry[] = await this.getObjectsFromStoreByTrackId(
      ObjectStores.TRACK_ENTRIES_STORE, trackId);
    trackEntriesStore.setEntriesForTrack({ trackId, entries: trackEntries, setOGPData: true });
  }

  private async loadTrackMembersByTrackId(trackId: string): Promise<void> {
    const trackMembers: Member[] = await this.getObjectsFromStoreByTrackId(
      ObjectStores.TRACK_MEMBERS_STORE, trackId);
    trackMembersStore.setMembersForTrack({ trackId, members: trackMembers });
  }

  public async loadTrackChatMessagesByTrackId(trackId: string, fullRefresh = false): Promise<void> {
    await chatMessagesStore.ensureFullPageOfMessagesIsLoaded({ trackId, fullRefresh });
    chatMessagesStore.setTrackChatMessagesInitialised({ trackId, initialised: true });
  }

  public async loadCommentsByEntryId(entryId: string, trackId: string, fullRefresh = false): Promise<void> {
    await chatMessagesStore.ensureFullPageOfMessagesIsLoaded({ trackId, entryId, fullRefresh });
    chatMessagesStore.setEntryCommentsInitialised({ trackId, entryId, initialised: true });
  }

  private async loadEntryTextFilesByEntry(entryId: string): Promise<void> {
    const result: StoredItem<TextFile>[] = await this.db.getByIndex(ObjectStores.ENTRY_TEXT_FILES_STORE,
      ObjectStores.ENTRY_ID_INDEX, entryId);
    const textFiles: TextFile[] = result.map((value) => value.entity);
    entryTextFilesStore.setTextFiles({ textFiles: textFiles, fullRefresh: false });
  }

  public async loadCurrentUser(): Promise<void> {
    const currentUser: FullUserDetails = await this.getCurrentUserFromStore();
    userStore.setCurrentUser(currentUser);
    await userStore.updateGuestTokens();
  }

  public async loadConnectionState(connectionState: ConnectionState): Promise<void> {
    connectionStore.setConnectionState(connectionState);
  }

  public async loadPinnedTrackItems(timestamp?: number): Promise<void> {
    const items: PinnedTrackItem[] = await this.getObjectsFromStore(
      ObjectStores.PINNED_TRACK_ITEMS_STORE, timestamp);
    pinnedTrackItemsStore.setItems({ items, fullRefresh: !timestamp });
  }

  public async getCurrentDBUser(): Promise<FullUserDetails | undefined> {
    const items: FullUserDetails[] = await this.db.getAll(ObjectStores.CURRENT_USER_STORE);
    if (items?.length) {
      return items[0];
    }
  }

  private async loadPinnedTrackItemsByTrackId(trackId: string): Promise<void> {
    const items: PinnedTrackItem[] = await this.getObjectsFromStoreByTrackId(
      ObjectStores.PINNED_TRACK_ITEMS_STORE, trackId);
    pinnedTrackItemsStore.setItemsForTrack({ trackId, items });
  }

  private async loadTasksForTrack(trackId: string): Promise<void> {
    const tasks: Task[] = await this.getObjectsFromStoreByTrackId(ObjectStores.TASKS_STORE, trackId);
    tasksStore.setTasksForTrack({ trackId, tasks });
  }

  private async loadTaskTablesForTrack(trackId: string): Promise<void> {
    const taskTables:TasksTable[] =
      await this.getObjectsFromStoreByTrackId(ObjectStores.TRACK_TASKS_TABLE_STORE, trackId);
    tasksStore.setTaskTablesForTrack({ trackId, taskTables });
  }

  private async loadMiniAppsForTrack(trackId: string): Promise<void> {
    const miniApps: MiniApp[] =
      await this.getObjectsFromStoreByTrackId(ObjectStores.TRACK_MINI_APPS_STORE, trackId);
    tasksStore.setMiniAppsForTrack({ trackId, miniApps });
  }

  private async loadTasks(timestamp?: number, newActiveTrackId?: string): Promise<void> {
    await tasksStore.waitForInProgressUpdates('Task');
    const tasks: Task[] = await this.getObjectsFromStore(ObjectStores.TASKS_STORE, timestamp);
    tasksStore.setTasks({ tasks, fullRefresh: !timestamp });
    if (newActiveTrackId) {
      tasksStore.setTasksInitialised({ trackId: newActiveTrackId, initialised: true });
    }
  }

  private async loadTaskTables(timestamp?: number): Promise<void> {
    await tasksStore.waitForInProgressUpdates('TasksTable');
    const taskTables: TasksTable[] = await this.getObjectsFromStore(ObjectStores.TASKS_TABLE_STORE, timestamp);
    if (!timestamp) {
      // The Pinia store has tenant-level and track-level tables in the same object, so we need to reload both
      // when doing a full refresh
      const trackTaskTables = (await this.db.getAll(ObjectStores.TRACK_TASKS_TABLE_STORE)).map(
        (item: StoredItem<TasksTable>) => item.entity);
      taskTables.push(...trackTaskTables);
    }
    tasksStore.setTaskTables({ taskTables, fullRefresh: !timestamp });
  }

  private async loadTrackTaskTables(timestamp?: number, newActiveTrackId?: string): Promise<void> {
    await tasksStore.waitForInProgressUpdates('TasksTable');
    const taskTables: TasksTable[] =
      await this.getObjectsFromStore(ObjectStores.TRACK_TASKS_TABLE_STORE, timestamp);
    if (!timestamp) {
      // The Pinia store has tenant-level and track-level tables in the same object, so we need to reload both
      // when doing a full refresh
      const tenantTaskTables = (await this.db.getAll(ObjectStores.TASKS_TABLE_STORE)).map(
        (item: StoredItem<TasksTable>) => item.entity);
      taskTables.push(...tenantTaskTables);
    }
    tasksStore.setTaskTables({ taskTables, fullRefresh: !timestamp });
    if (newActiveTrackId) {
      tasksStore.setTaskTablesInitialised({ trackId: newActiveTrackId, initialised: true });
    }
  }

  private async loadMiniApps(timestamp?: number): Promise<void> {
    await tasksStore.waitForInProgressUpdates('MiniApp');
    const miniApps: MiniApp[] = await this.getObjectsFromStore(ObjectStores.MINI_APPS_STORE, timestamp);
    if (!timestamp) {
      // The Pinia store has tenant-level and track-level mini-apps in the same object, so we need to reload both
      // when doing a full refresh
      const trackMiniApps = (await this.db.getAll(ObjectStores.TRACK_MINI_APPS_STORE)).map(
        (item: StoredItem<MiniApp>) => item.entity);
      miniApps.push(...trackMiniApps);
    }
    tasksStore.setMiniApps({ miniApps, fullRefresh: !timestamp });
    tasksStore.setTenantMiniAppsInitialised();
  }

  private async loadTrackMiniApps(timestamp?: number, newActiveTrackId?: string): Promise<void> {
    await tasksStore.waitForInProgressUpdates('MiniApp');
    const miniApps: MiniApp[] = await this.getObjectsFromStore(ObjectStores.TRACK_MINI_APPS_STORE, timestamp);
    if (!timestamp) {
      // The Pinia store has tenant-level and track-level mini-apps in the same object, so we need to reload both
      // when doing a full refresh
      const tenantMiniApps = (await this.db.getAll(ObjectStores.MINI_APPS_STORE)).map(
        (item: StoredItem<MiniApp>) => item.entity);
      miniApps.push(...tenantMiniApps);
    }
    tasksStore.setMiniApps({ miniApps, fullRefresh: !timestamp });
    if (newActiveTrackId) {
      tasksStore.setMiniAppsInitialised({ trackId: newActiveTrackId, initialised: true });
    }
  }

  private async loadUiFlows(timestamp?: number): Promise<void> {
    await tasksStore.waitForInProgressUpdates('UiFlows');
    const uiFlows: UIFlow[] = await this.getObjectsFromStore(ObjectStores.UI_FLOWS_STORE, timestamp);
    tasksStore.setUiFlows({ uiFlows, fullRefresh: !timestamp });
  }

  private async loadEntryThumbnails(timestamp?: number): Promise<void> {
    const thumbnails: EntryThumbnail[] =
      await this.getObjectsFromStore(ObjectStores.ENTRY_THUMBNAIL_STORE, timestamp);
    entryThumbnailsStore.setThumbnails({ thumbnails, fullRefresh: !timestamp });
  }

  private async loadEntryThumbnailsForTrack(trackId: string): Promise<void> {
    const thumbnails: EntryThumbnail[] =
      await this.getObjectsFromStoreByTrackId(ObjectStores.ENTRY_THUMBNAIL_STORE, trackId);
    entryThumbnailsStore.setThumbnailsForTrack({ trackId, thumbnails });
  }

  private async clearUpThumbnailsForOldTracks(activeTrackId?: string, meetingTrackId?: string): Promise<void> {
    if (!activeTrackId) {
      activeTrackId = tracksStore.activeTrackId ?? undefined;
    }
    if (!meetingTrackId) {
      const inMeeting: OngoingMeetingState = meetingStore.inMeeting;
      if (inMeeting !== OngoingMeetingState.NO_MEETING) {
        meetingTrackId = meetingStore.meetingTrackId ?? undefined;
      }
    }
    entryThumbnailsStore.cleanupUnobservedTracks({ activeTrackId, meetingTrackId });
  }

  private async reloadUserTokenFromStorage(): Promise<void> {
    userStore.refreshCurrentTokenFromStorage();
  }

  private async loadMiniAppViewsForTrack(trackId: string): Promise<void> {
    const miniAppViews: MiniAppView[] =
      await this.getObjectsFromStoreByTrackId(ObjectStores.TRACK_MINI_APP_VIEWS_STORE, trackId);
    tasksStore.setMiniAppViewsForTrack({ trackId, miniAppViews });
  }

  private async loadMiniAppViews(timestamp?: number): Promise<void> {
    const miniAppViews: MiniAppView[] =
      await this.getObjectsFromStore(ObjectStores.MINI_APP_VIEWS_STORE, timestamp);
    if (!timestamp) {
      // The Pinia store has tenant-level and track-level views in the same object, so we need to reload both
      // when doing a full refresh
      const trackViews = (await this.db.getAll(ObjectStores.TRACK_MINI_APP_VIEWS_STORE)).map(
        (item: StoredItem<MiniAppView>) => item.entity);
      miniAppViews.push(...trackViews);
    }
    tasksStore.setMiniAppViews({ miniAppViews, fullRefresh: !timestamp });
  }

  private async loadTrackMiniAppViews(timestamp?: number, newActiveTrackId?: string): Promise<void> {
    const miniAppViews: MiniAppView[] =
      await this.getObjectsFromStore(ObjectStores.TRACK_MINI_APP_VIEWS_STORE, timestamp);
    if (!timestamp) {
      // The Pinia store has tenant-level and track-level views in the same object, so we need to reload both
      // when doing a full refresh
      const tenantViews = (await this.db.getAll(ObjectStores.MINI_APP_VIEWS_STORE)).map(
        (item: StoredItem<MiniAppView>) => item.entity);
      miniAppViews.push(...tenantViews);
    }
    tasksStore.setMiniAppViews({ miniAppViews, fullRefresh: !timestamp });
    if (newActiveTrackId) {
      tasksStore.setMiniAppViewsInitialised({ trackId: newActiveTrackId, initialised: true });
    }
  }

  private async loadAppDevelopmentVersions(timestamp?: number): Promise<void> {
    const appDevelopmentVersions: AppDevelopmentVersion[] =
      await this.getObjectsFromStore(ObjectStores.APP_DEVELOPMENT_VERSION_STORE, timestamp);
    appVersionStore.setAppDevelopmentVersions({ appDevelopmentVersions, fullRefresh: !timestamp });
  }

  private async loadAppReleaseMetadata(timestamp?: number): Promise<void> {
    const appReleaseMetadata: AppReleaseMetadata[] =
      await this.getObjectsFromStore(ObjectStores.APP_RELEASES_STORE, timestamp);
    appReleaseStore.setAppReleaseMetadata({ appReleaseMetadata, fullRefresh: !timestamp });
  }

  private async loadAppEnvironments(timestamp?: number): Promise<void> {
    const appEnvironments: Environment[] =
      await this.getObjectsFromStore(ObjectStores.APP_ENVIRONMENT_STORE, timestamp);
    appEnvironmentsStore.setEnvironments({ environments: appEnvironments, fullRefresh: !timestamp });
  }

  private async loadReleasedApps(): Promise<void> {
    const releasedApps: ReleasedApp[] =
      await this.getObjectsFromStore(ObjectStores.RELEASED_APP_STORE);
    releasedAppsStore.setReleasedApps(releasedApps);
  }

  private async loadDeployedApps(): Promise<void> {
    const deployedApps: DeployedApp[] =
      await this.getObjectsFromStore(ObjectStores.DEPLOYED_APP_STORE);
    deployedAppsStore.setDeployedApps(deployedApps);
  }

  private async loadDeploymentHistory(timestamp?: number): Promise<void> {
    const deploymentRecords: AppDeploymentRecord[] =
      await this.getObjectsFromStore(ObjectStores.DEPLOYMENT_HISTORY_STORE, timestamp);
    deploymentHistoryStore.setDeploymentRecords(deploymentRecords);
  }

  private async loadMiniAppViewThemes(timestamp?: number, newActiveMiniAppId?: string): Promise<void> {
    const themes: MiniAppViewTheme[] =
      await this.getObjectsFromStore(ObjectStores.MINI_APP_VIEW_THEMES_STORE, timestamp);
    tasksStore.setThemes({ themes, fullRefresh: !timestamp });
    if (newActiveMiniAppId) {
      tasksStore.setThemesInitialisedForMiniApp({ miniAppId: newActiveMiniAppId, initialised: true });
    }
  }

  private async loadMiniAppViewThemesByMiniApp(miniAppId: string): Promise<void> {
    const result: StoredItem<MiniAppViewTheme>[] = await this.db.getByIndex(ObjectStores.MINI_APP_VIEW_THEMES_STORE,
      ObjectStores.MINI_APP_ID_INDEX, miniAppId);
    const themes: MiniAppViewTheme[] = result.map((value) => value.entity);
    tasksStore.setThemes({ themes, fullRefresh: false });
  }

  private async handleMiniAppDataSynchronisedReload(timestamp: number, isTenantApp: boolean):
    Promise<void> {
    await tasksStore.waitForInProgressUpdates('Task');
    const tablesStore = isTenantApp ? ObjectStores.TASKS_TABLE_STORE : ObjectStores.TRACK_TASKS_TABLE_STORE;
    const taskTables: TasksTable[] = await this.getObjectsFromStore(tablesStore, timestamp);

    const viewsStore = isTenantApp ? ObjectStores.MINI_APP_VIEWS_STORE
      : ObjectStores.TRACK_MINI_APP_VIEWS_STORE;
    const miniAppViews: MiniAppView[] = await this.getObjectsFromStore(viewsStore, timestamp);

    const tasks: Task[] = await this.getObjectsFromStore(ObjectStores.TASKS_STORE, timestamp);

    tasksStore.setTaskTables({ taskTables, fullRefresh: !timestamp });
    tasksStore.setMiniAppViews({ miniAppViews, fullRefresh: !timestamp });
    tasksStore.setTasks({ tasks, fullRefresh: !timestamp });
  }

  private async getCurrentUserFromStore(): Promise<FullUserDetails> {
    const items: FullUserDetails[] = await this.db.getAll(ObjectStores.CURRENT_USER_STORE);
    return items[0];
  }

  private async getObjectsFromStore<T>(objectStoreName: string, timestamp?: number): Promise<T[]> {
    let items: StoredItem<T>[];
    if (timestamp) {
      // Get all items that were inserted by the worker for the specified timestamp.
      // There seems to be a scenario where the provided timestamp is 1ms different to the one that was inserted,
      // so query using an expanded range.
      const range = IDBKeyRange.bound(timestamp - 100, timestamp + 100);
      items = await this.db.getByIndex(objectStoreName, ObjectStores.TIMESTAMP_INDEX, range);
    } else {
      // Load all items for the entire object store
      items = await this.db.getAll(objectStoreName);
    }
    return items.map((item: StoredItem<T>) => item.entity);
  }

  private async getObjectsFromStoreByTrackId<T>(objectStoreName: string, trackId: string): Promise<T[]> {
    const items: StoredItem<T>[] = await this.db.getByIndex(objectStoreName,
      ObjectStores.TRACK_ID_INDEX, trackId);
    return items.map((item: StoredItem<T>) => item.entity);
  }

  private async getSingleEntryByKeyFromStore<T>(objectStoreName: string, key: IDBValidKey): Promise<T | null> {
    const items: T[] = await this.db.get(objectStoreName, key);
    let item: T | null = null;
    if (items && items[0]) {
      item = items[0];
    }
    return item;
  }

  private async getSingleEntryFromStore<T>(objectStoreName: string): Promise<T | null> {
    const items: T[] = await this.db.getAll(objectStoreName);
    let item: T | null = null;
    if (items && items[0]) {
      item = items[0];
    }
    return item;
  }
}
