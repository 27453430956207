import log from 'loglevel';
import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import SmartTable from 'vuejs-smart-table';

import App from '@/App.vue';
import { AppName } from '@/data/Branding';
import { perfLog, perfMsg } from '@/data/log/PerformanceLog';
import MeetingsApiAccessor from '@/data/MeetingsApiAccessor';
import DataWorker from '@/data/storage/DataWorker';
import router from '@/router';
import pinia from '@/stores';
import { useMeetingStore } from '@/stores/Meeting';

import Integrations from './data/config/Integrations';
import ObjectStoreSync from './data/storage/ObjectStoreSync';

window.fenderLoadStart = performance.now();
perfLog.debug(perfMsg(`Starting ${AppName} load: ${window.fenderLoadStart}`));

document.addEventListener('DOMContentLoaded', () => {
  perfLog.debug(perfMsg('DOM content loaded', true, window.pageLoadStart));
});
window.addEventListener('load', () => {
  perfLog.debug(perfMsg('Window loaded', true, window.pageLoadStart));
});

// TODO: This is only used by admin / reporting pages. I think it's best to move this to those 3 views
Vue.use(SmartTable);

Vue.config.productionTip = false;
// Turning this on adds Vue timing events to the browser dev tools performance tab
// Vue.config.performance = true;
Vue.prototype.$appName = AppName;

declare global {
  interface Window {
    fenderLoadStart: number;
  }

  interface Document {
    webkitFullscreenElement: HTMLDivElement;
    webkitExitFullscreen?: () => Promise<void>;
  }

  interface HTMLElement {
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

perfLog.debug(perfMsg('Loading pre-requisites', true));

// Start out data loading from the indexed DB
ObjectStoreSync.getInstance().then((objectStoreSync: ObjectStoreSync) => {
  objectStoreSync.reloadAll();
});

// Start the Meetings API initialising. We don't need to block to wait for it however
if (Integrations.MEETINGS_CLIENT) {
  MeetingsApiAccessor.initialiseMeetings().then(() => {
    const meetingStore = useMeetingStore(pinia);
    meetingStore.setInitialised(true);
  });
} else {
  log.debug('Meetings not configured, skipping initialisation');
}

const waitingForInstance: number = performance.now();
DataWorker.waitForInstance().then(() => {
  perfLog.debug(perfMsg('Data worker loaded', true, waitingForInstance));
});

if (Integrations.ANALYTICS_SERVER) {
  Vue.use(VueMatomo, {
    host: Integrations.ANALYTICS_SERVER,
    siteId: 1,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15, // in seconds
    trackPageView: false, // controlled by our code
  });
} else {
  log.debug('Analytics not configured, skipping initialisation');
}

const mounting: number = performance.now();
perfLog.debug(perfMsg('Pre-requisites loaded', true, window.pageLoadStart));
export const MainVue: Vue = new Vue({
  name: 'MainVue',
  router,
  pinia,
  render: h => h(App)
}).$mount('#app');

// Declare the _paq variable that is used by Matomo as its queueing buffer.
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _paq: any;
  }
}
window._paq = window._paq || [];

perfLog.debug(perfMsg('App mounted', false, mounting));
perfLog.debug(perfMsg('Total load', false, window.pageLoadStart));
