const TRACK_MAIN_BASE_ROUTE_NAME: string = 'TrackMainBase';
const TRACK_DEFAULT_ROUTE_NAME: string = 'TrackDefault';
const TRACK_DASHBOARD_ROUTE_NAME: string = 'TrackDashboard';
const MOST_RECENT_TRACK_ROUTE_NAME: string = 'MostRecentTrack';
const MOST_RECENT_CALENDAR_TRACK_ROUTE_NAME: string = 'MostRecentCalendarTrack';
const MOST_RECENT_CHAT_ROUTE_NAME: string = 'MostRecentChat';
const TRACK_ENTRIES_ROUTE_NAME: string = 'TrackEntries';
const TRACK_ENTRY_ROUTE_NAME: string = 'TrackEntry';
const TRACK_BASE_ENTRY_ROUTE_NAME: string = 'TrackBaseEntry';
const TRACK_ENTRIES_VIEW_ROUTE_NAME: string = 'TrackEntriesView';
const TRACK_ENTRY_COMMENTS_ROUTE_NAME: string = 'TrackEntryComments';
const TRACK_ENTRY_APPROVALS_ROUTE_NAME: string = 'TrackEntryApprovals';
const TRACK_ENTRY_HISTORY_ROUTE_NAME: string = 'TrackEntryHistory';
// Note: this must be 'meeting' to allow a guest token to be retrieved
const TRACK_MEETING_ROUTE_NAME: string = 'meeting';
const TRACK_MEETINGS_ROUTE_NAME: string = 'TrackMeetings';
const SINGLE_TRACK_TASK_ROUTE_NAME: string = 'SingleTrackTask';
const TRACK_TASKS_ROUTE_NAME: string = 'TrackTasks';
const NEW_TRACK_ROUTE_NAME: string = 'NewTrack';
const TRACK_CHAT_ROUTE_NAME: string = 'TrackChat';
const TRACK_INFO_ROUTE_NAME: string = 'TrackInfo';
const TRACK_HISTORY_ROUTE_NAME: string = 'TrackHistory';
const CHAT_START_CONVERSATION: string = 'StartConversation';
const PRIVATE_CHAT_ROUTE_NAME: string = 'PrivateChat';
const CHAT_MEETING_ROUTE_NAME: string = 'ChatMeeting';
const CHAT_ENTRIES_ROUTE_NAME: string = 'ChatEntries';
const CHAT_ENTRY_ROUTE_NAME: string = 'ChatEntry';
const SEARCH_ROUTE_NAME: string = 'Search';
const TASKS_ROUTE_NAME: string = 'Tasks';
const TASKS_TABLE_ROUTE_NAME: string = 'TasksTable';
const APP_ROUTE_NAME: string = 'App';
const APP_TABLE_ROUTE_NAME: string = 'AppTable';
const PEOPLE_ROUTE_NAME: string = 'People';
const MEETING_LIST_ROUTE_NAME: string = 'MeetingList';
const NOTIFICATIONS_ROUTE_NAME: string = 'Notifications';
const NEW_MEETING_ROUTE_NAME: string = 'NewTrack';
const SINGLE_CHAT_THREAD_ROUTE_NAME: string = 'SingleChatThread';
const CONTEXT_CARDS_ROUTE_NAME: string = 'ContextCards';
const EDIT_CONTEXT_CARD_ROUTE_NAME: string = 'ContextCard';
const HOME_ROUTE_NAME: string = 'Home';
const KNOWLEDGE_MANAGEMENT_ROUTE_NAME: string = 'KnowledgeMgmt';
const RULES_ENGINE_ROUTE_NAME: string = 'RulesEngine';
const RULES_ENGINE_CREATE_RULESET_ROUTE_NAME: string = 'RulesEngineCreateRuleset';
const RULES_ENGINE_EDIT_RULESET_ROUTE_NAME: string = 'RulesEngineEditRuleset';
const RULES_ENGINE_DEBUG_RULESET_ROUTE_NAME: string = 'RulesEngineDebugRuleset';
const ALL_CHAT_THREADS_ROUTE_NAME: string = 'AllChatThreads';
const PROFILE_SETTINGS_ROUTE_NAME: string = 'ProfileSettings';
const RECONNECT_ROUTE_NAME: string = 'Reconnect';
const TENANT_SETTINGS_ROUTE_NAME: string = 'TenantSettings';
const TENANT_SETTINGS_TRACK_ROUTE_NAME: string = 'TenantSettingsTrack';
const SYSTEM_SETTINGS_ROUTE_NAME: string = 'SystemSettings';
const SYSTEM_SETTINGS_ACCOUNTS_ROUTE_NAME: string = 'SystemSettingsAccounts';
const SYSTEM_SETTINGS_ACCOUNT_ROUTE_NAME: string = 'SystemSettingsAccount';
const SYSTEM_SETTINGS_CAFEX_IDENTITY_ROUTE_NAME: string = 'SystemSettingsCafexIdentity';
const PRIVATE_CHAT_SINGLE_THREAD_ROUTE_NAME = 'PrivateChatSingleThread';
const THANKYOU_ROUTE_NAME = 'ThankYouPage';
const TEMPLATES_ROUTE_NAME: string = 'Templates';
const CONTACT_LIST_ROUTE_NAME: string = 'ContactList';
const PERSONAL_CONTACT_LIST_ROUTE_NAME: string = 'PersonalContactList';
const USER_DIRECTORY_ROUTE_NAME: string = 'UserDirectory';
const EXTERNAL_DIRECTORY_ROUTE_NAME: string = 'ExternalDirectory';
const CALENDAR_ROUTE_NAME: string = 'Calendar';
const CALENDAR_FILTER_ROUTE_NAME: string = 'CalendarFilter';
const TRACK_CALENDAR_ROUTE_NAME: string = 'TrackCalendar';
const TRACK_CALENDAR_ROUTE_LEVEL_NAME: string = 'TrackCalendarLevel';
const TRACK_CALENDAR_FILTER_ROUTE_NAME: string = 'TrackCalendarFilter';
const MEETINGS_ROUTE_NAME: string = 'Meetings';
const RECENT_CHANGES_ROUTE_NAME: string = 'RecentChanges';
const PUBLISH_TEMPLATES_ROUTE_NAME = 'PublishTemplates';
const PUBLISH_TEMPLATES_CONFIG_ROUTE_NAME = 'PublishTemplatesConfig';
const APPS_ROUTE_NAME = 'Apps';
const EDIT_APP_ROOT_ROUTE_NAME = 'EditAppRoot';
const EDIT_APP_ROUTE_NAME = 'EditApp';
const EDIT_APP_DETAILS_ROUTE_NAME = 'EditAppDetails';
const EDIT_APP_TABLES_ROUTE_NAME = 'EditAppTables';
const EDIT_APP_VIEWS_ROUTE_NAME = 'EditAppViews';
const EDIT_APP_UI_FLOWS_ROUTE_NAME = 'EditAppUiFlows';
const EDIT_APP_DATA_SOURCES_ROUTE_NAME = 'EditAppDataSources';
const EDIT_APP_WORKFLOWS_ROUTE_NAME = 'EditAppWorkflows';
const EDIT_APP_RULESETS_ROUTE_NAME = 'EditAppRulesets';
const APP_STUDIO_EDIT_APP_RULESETS_ROUTE_NAME = 'AppStudioEditAppRulesets';
const CREATE_APP_RULESETS_ROUTE_NAME: string = 'CreateAppRulesets';
const APP_STUDIO_CREATE_APP_RULESETS_ROUTE_NAME: string = 'AppStudioCreateAppRulesets';
const TEST_APP_RULESETS_ROUTE_NAME: string = 'TestAppRulesets';
const APP_STUDIO_TEST_APP_RULESETS_ROUTE_NAME: string = 'AppStudioTestAppRulesets';
const APP_RULESETS_LIST_ROUTE_NAME: string = 'AppRulesetsList';
const EDIT_APP_CONTEXT_CARD_ROUTE_NAME = 'EditAppContextCard';
const APP_CONTEXT_CARDS_LIST_ROUTE = 'AppConectextCardList';
const CREATE_APP_CONTEXT_CARD_ROUTE_NAME = 'CreateAppContextCard';
const EDIT_APP_PERMISSIONS_ROUTE_NAME = 'EditAppPermissions';
const EDIT_APP_TRACK_TEMPLATES_ROUTE_NAME = 'EditTrackTemplates';
const EDIT_APP_WORKSPACE_LABELS_ROUTE_NAME = 'EditAppWorkspaceLabels';
const EDIT_APP_CUSTOM_VIEW_ROUTE_NAME = 'EditAppCustomView';
const TRACK_APP_ROUTE_NAME = 'TrackApp';
const TRACK_APP_VIEW_ROUTE_NAME = 'TrackAppView';
const TRACK_EDIT_APP_ROUTE_NAME = 'TrackAppEdit';
const TRACK_EDIT_APP_DETAILS_ROUTE_NAME = 'TrackAppEditDetails';
const TRACK_EDIT_APP_TABLES_ROUTE_NAME = 'TrackAppEditTables';
const TRACK_EDIT_APP_VIEWS_ROUTE_NAME = 'TrackAppEditViews';
const TRACK_EDIT_APP_WORKFLOWS_ROUTE_NAME = 'TrackEditWorkflows';
const TRACK_EDIT_APP_DATA_SOURCES_ROUTE_NAME = 'TrackEditDataSources';
const ICONS_ROUTE_NAME = 'Icons';
const BROWSER_INFO_ROUTE_NAME = 'BrowserInfo';
const GRADIENTS_ROUTE_NAME = 'Gradients';
const COMPONENTS_ROUTE_NAME = 'Components';
const UNSUPPORTED_BROWSER_ROUTE_NAME = 'Unsupported';
const SERVER_MESSAGE_ROUTE_NAME = 'ServerMessage';
const CREATE_UI_FLOW_ROUTE_NAME = 'CreateUiFlow'; // Create new UiFlow for MiniApp template
const EDIT_UI_FLOW_ROUTE_NAME = 'EditUiFlow'; // Edit UiFlow for MiniApp template
const WORKSPACE_CREATE_UI_FLOW_ROUTE_NAME = 'WorkspaceCreateUiFlow'; // Create new UiFlow but in a workspace context
const WORKSPACE_EDIT_UI_FLOW_ROUTE_NAME = 'WorkspaceEditUiFlow'; // Edit UiFlow but in a workspace context
const EDIT_WORKFLOW_ROUTE_NAME = 'EditWorkflow'; // Edit Workflow for MiniApp template
const APP_STUDIO_EDIT_WORKFLOW_ROUTE_NAME = 'AppStudioEditWorkflow';
const APP_STUDIO_FLOW_RUNS_ROUTE_NAME = 'AppStudioFlowRuns';
const APP_STUDIO_FLOW_CONNECTIONS_ROUTE_NAME = 'AppStudioFlowConnections';
const FLOW_RUNS_ROUTE_NAME = 'FlowRuns';
const FLOW_CONNECTIONS_ROUTE_NAME = 'FlowConnections';
const TRACK_UI_FLOW_ROUTE_NAME: string = 'TrackUiFlow';
const STATS_ROUTE_NAME: string = 'Stats';
const REPORTING_ROUTE_NAME = 'Reporting';
const REPORTING_TENANT_ROUTE_NAME = 'TenantReporting';
const REPORTING_MEETINGS_ROUTE_NAME = 'MeetingsReporting';
const REPORTING_MEETINGS_DETAIL_ROUTE_NAME = 'MeetingsDetailsReporting';
const APP_STUDIO_ROUTE_NAME: string = 'AppStudio';
const APP_STUDIO_CUSTOM_VIEW_ROUTE_NAME: string = 'AppStudioCustomView';
const APP_STUDIO_DATA_SOURCE_ROUTE_NAME: string = 'AppStudioDataSources';
const APP_STUDIO_WORKFLOWS_ROUTE_NAME: string = 'AppStudioWorkflows';
const APP_STUDIO_RULESETS_ROUTE_NAME: string = 'AppStudioRulesets';
const APP_STUDIO_ANALYTICS_ROUTE_NAME: string = 'AppStudioAnalytics';
const APP_STUDIO_SETTINGS_ROUTE_NAME: string = 'AppStudioSettings';
const APP_STUDIO_EDIT_APP_TABLES_ROUTE_NAME = 'AppStudioEditAppTables';
const APP_STUDIO_UI_FLOWS_ROUTE_NAME = 'AppStudioUiFlows';
const APP_STUDIO_CREATE_UI_FLOW_ROUTE_NAME = 'AppStudioCreateUiFlow';
const APP_STUDIO_EDIT_UI_FLOW_ROUTE_NAME = 'AppStudioEditUiFlow';
const APP_STUDIO_WORKSPACE_LABELS_ROUTE_NAME = 'AppStudioWorkspaceLabels';
const APP_STUDIO_CONTEXT_CARDS_ROUTE_NAME: string = 'AppStudioContextCards';
const APP_STUDIO_EDIT_CONTEXT_CARD_ROUTE_NAME: string = 'AppStudioEditContextCard';
const APP_STUDIO_CREATE_CONTEXT_CARD_ROUTE_NAME: string = 'AppStudioCreateContextCard';
const APP_STUDIO_VERSIONS_ROUTE_NAME: string = 'AppStudioViewVersions';
const APP_ENVIRONMENT_TRACK_VIEW_NAME: string = 'AppEnvironmentTrackView';
const APP_ENVIRONMENT_TRACK_VIEW_FULL_SCREEN_NAME: string = 'AppEnvironmentTrackViewFullScreen';
const APP_ENVIRONMENT_MOST_RECENT_TRACK_VIEW_NAME: string = 'AppEnvironmentMostRecentTrack';
const APP_STUDIO_AI_ROUTE_NAME: string = 'AppStudioViewAI';
const TRACK_APP_GUEST_VIEW_ROUTE_NAME: string = 'TrackAppGuestView';

// Add all route names here
export default {
  TRACK_MAIN_BASE_ROUTE_NAME,
  TRACK_DEFAULT_ROUTE_NAME,
  TRACK_DASHBOARD_ROUTE_NAME,
  MOST_RECENT_TRACK_ROUTE_NAME,
  TRACK_ENTRIES_ROUTE_NAME,
  TRACK_ENTRY_ROUTE_NAME,
  TRACK_ENTRIES_VIEW_ROUTE_NAME,
  TRACK_ENTRY_COMMENTS_ROUTE_NAME,
  TRACK_ENTRY_APPROVALS_ROUTE_NAME,
  TRACK_ENTRY_HISTORY_ROUTE_NAME,
  TRACK_MEETING_ROUTE_NAME,
  TRACK_MEETINGS_ROUTE_NAME,
  SINGLE_TRACK_TASK_ROUTE_NAME,
  TRACK_TASKS_ROUTE_NAME,
  TRACK_APP_ROUTE_NAME,
  TRACK_APP_VIEW_ROUTE_NAME,
  TRACK_EDIT_APP_ROUTE_NAME,
  TRACK_EDIT_APP_DETAILS_ROUTE_NAME,
  TRACK_EDIT_APP_TABLES_ROUTE_NAME,
  TRACK_EDIT_APP_VIEWS_ROUTE_NAME,
  TRACK_EDIT_APP_WORKFLOWS_ROUTE_NAME,
  TRACK_EDIT_APP_DATA_SOURCES_ROUTE_NAME,
  NEW_TRACK_ROUTE_NAME,
  TRACK_CHAT_ROUTE_NAME,
  TRACK_BASE_ENTRY_ROUTE_NAME,
  TRACK_INFO_ROUTE_NAME,
  TRACK_HISTORY_ROUTE_NAME,
  CHAT_START_CONVERSATION,
  MOST_RECENT_CHAT_ROUTE_NAME,
  PRIVATE_CHAT_ROUTE_NAME,
  CHAT_MEETING_ROUTE_NAME,
  CHAT_ENTRIES_ROUTE_NAME,
  CHAT_ENTRY_ROUTE_NAME,
  SEARCH_ROUTE_NAME,
  TASKS_ROUTE_NAME,
  TASKS_TABLE_ROUTE_NAME,
  APP_ROUTE_NAME,
  APP_TABLE_ROUTE_NAME,
  NOTIFICATIONS_ROUTE_NAME,
  NEW_MEETING_ROUTE_NAME,
  SINGLE_CHAT_THREAD_ROUTE_NAME,
  CONTEXT_CARDS_ROUTE_NAME,
  EDIT_CONTEXT_CARD_ROUTE_NAME,
  HOME_ROUTE_NAME,
  KNOWLEDGE_MANAGEMENT_ROUTE_NAME,
  RULES_ENGINE_ROUTE_NAME,
  RULES_ENGINE_CREATE_RULESET_ROUTE_NAME,
  RULES_ENGINE_EDIT_RULESET_ROUTE_NAME,
  RULES_ENGINE_DEBUG_RULESET_ROUTE_NAME,
  ALL_CHAT_THREADS_ROUTE_NAME,
  PROFILE_SETTINGS_ROUTE_NAME,
  RECONNECT_ROUTE_NAME,
  TENANT_SETTINGS_ROUTE_NAME,
  TENANT_SETTINGS_TRACK_ROUTE_NAME,
  SYSTEM_SETTINGS_ROUTE_NAME,
  SYSTEM_SETTINGS_ACCOUNTS_ROUTE_NAME,
  SYSTEM_SETTINGS_ACCOUNT_ROUTE_NAME,
  SYSTEM_SETTINGS_CAFEX_IDENTITY_ROUTE_NAME,
  PRIVATE_CHAT_SINGLE_THREAD_ROUTE_NAME,
  PEOPLE_ROUTE_NAME,
  MEETING_LIST_ROUTE_NAME,
  THANKYOU_ROUTE_NAME,
  TEMPLATES_ROUTE_NAME,
  CONTACT_LIST_ROUTE_NAME,
  PERSONAL_CONTACT_LIST_ROUTE_NAME,
  USER_DIRECTORY_ROUTE_NAME,
  EXTERNAL_DIRECTORY_ROUTE_NAME,
  CALENDAR_ROUTE_NAME,
  CALENDAR_FILTER_ROUTE_NAME,
  TRACK_CALENDAR_ROUTE_NAME,
  TRACK_CALENDAR_FILTER_ROUTE_NAME,
  MOST_RECENT_CALENDAR_TRACK_ROUTE_NAME,
  TRACK_CALENDAR_ROUTE_LEVEL_NAME,
  MEETINGS_ROUTE_NAME,
  RECENT_CHANGES_ROUTE_NAME,
  PUBLISH_TEMPLATES_ROUTE_NAME,
  PUBLISH_TEMPLATES_CONFIG_ROUTE_NAME,
  APPS_ROUTE_NAME,
  EDIT_APP_ROOT_ROUTE_NAME,
  EDIT_APP_ROUTE_NAME,
  EDIT_APP_DETAILS_ROUTE_NAME,
  EDIT_APP_TABLES_ROUTE_NAME,
  EDIT_APP_PERMISSIONS_ROUTE_NAME,
  EDIT_APP_WORKSPACE_LABELS_ROUTE_NAME,
  EDIT_APP_VIEWS_ROUTE_NAME,
  EDIT_APP_UI_FLOWS_ROUTE_NAME,
  EDIT_APP_DATA_SOURCES_ROUTE_NAME,
  EDIT_APP_WORKFLOWS_ROUTE_NAME,
  EDIT_APP_RULESETS_ROUTE_NAME,
  APP_STUDIO_EDIT_APP_RULESETS_ROUTE_NAME,
  EDIT_APP_CONTEXT_CARD_ROUTE_NAME,
  APP_CONTEXT_CARDS_LIST_ROUTE,
  CREATE_APP_CONTEXT_CARD_ROUTE_NAME,
  CREATE_APP_RULESETS_ROUTE_NAME,
  APP_STUDIO_CREATE_APP_RULESETS_ROUTE_NAME,
  TEST_APP_RULESETS_ROUTE_NAME,
  APP_STUDIO_TEST_APP_RULESETS_ROUTE_NAME,
  APP_RULESETS_LIST_ROUTE_NAME,
  EDIT_APP_TRACK_TEMPLATES_ROUTE_NAME,
  ICONS_ROUTE_NAME,
  BROWSER_INFO_ROUTE_NAME,
  GRADIENTS_ROUTE_NAME,
  COMPONENTS_ROUTE_NAME,
  UNSUPPORTED_BROWSER_ROUTE_NAME,
  SERVER_MESSAGE_ROUTE_NAME,
  CREATE_UI_FLOW_ROUTE_NAME,
  EDIT_UI_FLOW_ROUTE_NAME,
  WORKSPACE_CREATE_UI_FLOW_ROUTE_NAME,
  WORKSPACE_EDIT_UI_FLOW_ROUTE_NAME,
  TRACK_UI_FLOW_ROUTE_NAME,
  STATS_ROUTE_NAME,
  REPORTING_ROUTE_NAME,
  REPORTING_TENANT_ROUTE_NAME,
  REPORTING_MEETINGS_ROUTE_NAME,
  REPORTING_MEETINGS_DETAIL_ROUTE_NAME,
  EDIT_APP_CUSTOM_VIEW_ROUTE_NAME,
  EDIT_WORKFLOW_ROUTE_NAME,
  FLOW_RUNS_ROUTE_NAME,
  FLOW_CONNECTIONS_ROUTE_NAME,
  APP_STUDIO_EDIT_WORKFLOW_ROUTE_NAME,
  APP_STUDIO_FLOW_RUNS_ROUTE_NAME,
  APP_STUDIO_FLOW_CONNECTIONS_ROUTE_NAME,
  APP_STUDIO_ROUTE_NAME,
  APP_STUDIO_CUSTOM_VIEW_ROUTE_NAME,
  APP_STUDIO_DATA_SOURCE_ROUTE_NAME,
  APP_STUDIO_WORKFLOWS_ROUTE_NAME,
  APP_STUDIO_RULESETS_ROUTE_NAME,
  APP_STUDIO_ANALYTICS_ROUTE_NAME,
  APP_STUDIO_SETTINGS_ROUTE_NAME,
  APP_STUDIO_EDIT_APP_TABLES_ROUTE_NAME,
  APP_STUDIO_UI_FLOWS_ROUTE_NAME,
  APP_STUDIO_CREATE_UI_FLOW_ROUTE_NAME,
  APP_STUDIO_EDIT_UI_FLOW_ROUTE_NAME,
  APP_STUDIO_WORKSPACE_LABELS_ROUTE_NAME,
  APP_STUDIO_CONTEXT_CARDS_ROUTE_NAME,
  APP_STUDIO_EDIT_CONTEXT_CARD_ROUTE_NAME,
  APP_STUDIO_CREATE_CONTEXT_CARD_ROUTE_NAME,
  APP_STUDIO_VERSIONS_ROUTE_NAME,
  TRACK_APP_GUEST_VIEW_ROUTE_NAME,
  APP_ENVIRONMENT_TRACK_VIEW_NAME,
  APP_ENVIRONMENT_TRACK_VIEW_FULL_SCREEN_NAME,
  APP_ENVIRONMENT_MOST_RECENT_TRACK_VIEW_NAME,
  APP_STUDIO_AI_ROUTE_NAME,
};
