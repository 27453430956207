import log from 'loglevel';

import { AppName } from '@/data/Branding';
import IosNative from '@/data/IosNative';
import DataWorker from '@/data/storage/DataWorker';
import router from '@/router';

const IOS_UPGRADE_MESSAGE =
  `A newer version of ${AppName} is available. For best performance download it from the App Store`;

interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webkit?: any;
  gotIosDeviceToken: (token: string, appVersion: string) => void;
  failedToGetIosDeviceToken: (appVersion: string) => void;
  pushIosRoute: (iosPath: string) => void;
  workerPing: () => void;
  reconnectLiveuser: () => void;
}

declare let window: Window;

const IOS_REQUIRED_VERSION = '1.3.4';

export default class IosDeviceToken {
  public static async getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      if (!this.invokeNative('getDeviceToken')) {
        reject(new Error('Native function getDeviceToken not found'));
      }
    });
  }

  /**
   * Format is "<major><minor><patch>", where each component is a two-digit token. For example, app
   * version 1.3.4 is represented by "010304"; version 10.5.13 would be "100513".
   *
   * It's possible that this will return null, because older versions of the app do not supply a version
   * number in the token call.
   */
  public static getIosAppVersion(): number {
    return IosDeviceToken.versionNumber;
  }

  /**
   * We add functions to the window object so that they can be invoked by the native iOS app, to
   * pass us the natively generated device token.
   */
  public static addWindowFunctions(): void {
    if (!window.gotIosDeviceToken) {
      window.gotIosDeviceToken = (token, appVersion) => {
        IosDeviceToken.checkAppVersion(appVersion);
        IosDeviceToken.gotDeviceToken(token);
      };
    }

    if (!window.failedToGetIosDeviceToken) {
      window.failedToGetIosDeviceToken = (appVersion) => {
        IosDeviceToken.checkAppVersion(appVersion);
        IosDeviceToken.failedToGetDeviceToken();
      };
    }

    // this function is used by the app to change the route when push notifications are received
    if (!window.pushIosRoute) {
      window.pushIosRoute = (iosPath) => {
        log.debug(`iOS app pushing Vue router path: ${iosPath}`);
        router.push({ path: iosPath });
      };
    }

    if (!window.workerPing) {
      window.workerPing = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        DataWorker.instance().ping().then((value) => {
          IosNative.invokeNative('workerPong');
        });
      };
    }

    if (!window.reconnectLiveuser) {
      window.reconnectLiveuser = () => {
        DataWorker.instance().iosReconnectLiveuser();
      };
    }
  }

  public static gotDeviceToken(token: string): void {
    this.resolve(token);
  }

  public static failedToGetDeviceToken(): void {
    this.reject('App failed to generate token');
  }

  private static resolve: (token: string) => void;
  private static reject: (reason: unknown) => void;
  private static versionNumber: number;

  private static checkAppVersion(version: string) {
    if (!version) {
      // show the app version check dialog
      IosDeviceToken.alertDialog(IOS_UPGRADE_MESSAGE);
    } else {
      const required = IosDeviceToken.toVersionNumber(IOS_REQUIRED_VERSION);
      const current = IosDeviceToken.toVersionNumber(version);
      IosDeviceToken.versionNumber = current;

      if (required > current) {
        IosDeviceToken.alertDialog(IOS_UPGRADE_MESSAGE);
      }
    }
  }

  private static alertDialog(message: string) {
    // GlobalEvents.$emit('showGenericAlert', message);
    // TODO:
    log.debug(message);
  }

  private static toVersionNumber(versionString: string): number {
    const parts: string[] = versionString.split('.').reverse();
    let result: number = 0;
    let multiplier: number = 1;

    for (const part of parts) {
      result += parseInt(part, 10) * multiplier;
      multiplier *= 100;
    }

    return result;
  }

  private static invokeNative(method: string, args: unknown = null): boolean {
    if (window.webkit) {
      if (window.webkit.messageHandlers[method]) {
        window.webkit.messageHandlers[method].postMessage(args);
        return true;
      } else {
        // logWarn('Could not find native function: ' + method);
      }
    }
    return false;
  }
}
