import log from 'loglevel';

import BrowserDetection from '../BrowserDetection';
import DataWorker from '../storage/DataWorker';
import { WorkerMessageType } from '../storage/WorkerMessageType';
/** Max file size, in bytes.
 *  This is also enforced by server ingress */
export const MAX_FILE_UPLOAD_SIZE = 200000000;

export class CancelCall {
  private cancelFunctionMap: Map<string, () => void> = new Map();
  public cancel(fileName: string): void {
    const cancelFunc = this.cancelFunctionMap.get(fileName);
    if (cancelFunc) {
      log.debug('CancelCall calling function');
      cancelFunc();
    }
  }

  public setCancelFunction(fileName: string, cancelFunction: () => void): void {
    this.cancelFunctionMap.set(fileName, cancelFunction);
  }
}

export interface FileUploadResult {
  id?: string;
  name: string;
  uploaded: boolean;
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: any;
}

export interface FileUploadPayload {
  files: File[];
  fileIds?: string[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  progressCallback?: (fileName: string, percentUploaded: number, fileId?: string) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFinishedCallback?: (uploadResults: FileUploadResult[]) => any;
  cancelCall: CancelCall;
}

export interface BinaryFileUploadPayload extends FileUploadPayload {
  trackId: string;
  parentId?: string;
  chatMessageId?: string;
}

export interface AppImportPayload extends FileUploadPayload {
  trackId?: string;
  newAppName: string;
  newAppDescription?: string;
  includeData: boolean;
}

export interface TableDataImportPayload extends FileUploadPayload {
  trackId: string;
  tableId: string;
  firstRowHeaders: boolean;
  delimiter?: string;
}

function fileExceedsMaxSize(file: File): boolean {
  return file.size > MAX_FILE_UPLOAD_SIZE;
}

function validateFileForAppImport(file: File): string | undefined {
  if (fileExceedsMaxSize(file)) {
    return 'The selected file is too large.';
  }
  if (!file.name?.toLowerCase().endsWith('.zip')) {
    return 'The selected file must end with .zip';
  }
}

function validateFileForRulesetImport(file: File): string | undefined {
  if (fileExceedsMaxSize(file)) {
    return 'The selected file is too large.';
  }
  if (!file.name?.toLowerCase().endsWith('.json')) {
    return 'The selected file must end with .json';
  }
}

function validateFileForDataImport(file: File): string | undefined {
  if (fileExceedsMaxSize(file)) {
    return 'The selected file is too large.';
  }
  if (!file.name?.toLowerCase().endsWith('.csv')) {
    return 'The selected file must end with .csv';
  }
}

function myArrayBuffer(file: File) {
  // this: File or Blob
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsArrayBuffer(file);
  });
}

/**
 * Create an array of promises to worker actions for uploading the specified files
 * @param payload The files to upload
 * @param uploadResults The object to which the upload results will be added
 * @param workerAction The action to dispatch to the shared worker
 * @param workerArgsResolver A function that returns the array of arguments to pass to the worker action
 * @returns The array of promises
 */
async function setupFileUploadCalls(payload: FileUploadPayload, uploadResults: FileUploadResult[],
  workerAction: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workerArgsResolver: (file: File, fileId: string | undefined) => any[]): Promise<Array<Promise<any>>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadPromises: Array<Promise<any>> = [];
  for (const file of payload.files) {
    const fileIndex = payload.files.indexOf(file);
    // eslint-disable-next-line
    const fileProgressCallback = (event: any) => {
      event = Array.isArray(event) ? event[0] : event;
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        if (payload.progressCallback) {
          if (payload.fileIds && payload.fileIds[fileIndex] !== undefined) {
            payload.progressCallback(file.name, percentComplete, payload.fileIds[fileIndex]);
          } else {
            payload.progressCallback(file.name, percentComplete);
          }
        }
      }
    };
    const cancelCallback = (cancelFunc: () => void) => {
      payload.cancelCall.setCancelFunction(file.name, cancelFunc);
    };

    let fileArrayBuffer;
    if ('arrayBuffer' in file) {
      fileArrayBuffer = await file.arrayBuffer();
    }
    if (!fileArrayBuffer) {
      fileArrayBuffer = await myArrayBuffer(file as File) as ArrayBuffer;
    }

    const fileId = payload.fileIds ? payload.fileIds[fileIndex] : undefined;
    // Add a then/catch to the added Promises here, so we can keep track of which Promises (i.e. uploads) succeeed
    // or fail. Without them, any single Rejected track entry creation would cause the whole array of Promises to
    // be Rejected
    const args = workerArgsResolver(file, fileId);
    uploadPromises.push(DataWorker.instance().dispatchWithTransfer(workerAction, args, [fileArrayBuffer],
      fileProgressCallback, cancelCallback)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        const result: FileUploadResult = { name: file.name, uploaded: true, status: 200 };
        if (response) {
          result.response = response;
        }
        uploadResults.push(result);
      })
      .catch((error) => {
        if (error === WorkerMessageType.CANCELLED) {
          uploadResults.push({ name: file.name, uploaded: false, status: 800 });
        } else {
          log.error(`File upload failed for: ${file.name} ${error.response?.status}`);
          uploadResults.push({ name: file.name, uploaded: false, status: error.response?.status });
        }
      }));
  }
  return uploadPromises;
}

function triggerFileDownload(downloadUrl: string): void {
  if (BrowserDetection.isIosApp()) {
    // append a query parameter that signals to the iOS app that this is a download to be handled natively
    const sep = downloadUrl.includes('?') ? '&' : '?';
    const queryParam = sep + 'iosdownload=true';
    downloadUrl += queryParam;
  }

  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = downloadUrl;
  a.click();
  document.body.removeChild(a);
}

export {
  fileExceedsMaxSize, setupFileUploadCalls, triggerFileDownload,
  validateFileForAppImport, validateFileForDataImport,
  validateFileForRulesetImport
};
