import { defineStore } from 'pinia';
import Vue, { Ref, ref } from 'vue';

export const useStaticResourcesStore = defineStore('StaticResources', () => {
  const resolvedPaths: Ref<Record<string, string>> = ref({});

  function setResolvedPath(name: string, resolved: string): void {
    Vue.set(resolvedPaths.value, name, resolved);
  }

  return {
    resolvedPaths,
    setResolvedPath
  };
});
