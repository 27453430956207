export const enum PushDeviceType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  IOSAPP = 'IOSAPP',
  UNKNOWN = 'UNKNOWN',
}

export interface PushRegistrationMessage {
  endpoint: string;
  deviceType: PushDeviceType;
  enabled: boolean;
  apns: boolean;
  key?: string;
  auth?: string;
}
