enum MeetingStatus {
  NOT_IN_MEETING = 'NOT_IN_MEETING',
  IN_MEETING = 'IN_MEETING',
  JOINING_MEETING = 'JOINING_MEETING',
}

interface OnlineStatus {
  trackId?: string;
  sharing?: boolean;
  meetingStatus?: MeetingStatus;
  userId?: string;
  guestId?: string;
  name?: string;
  color?: string;
  newChats?: boolean;
  lastChatSeen?: Date;
}

class OnlineStatusReq implements OnlineStatus {
  public trackId?: string;
  public entryId?: string;
  public sharing?: boolean;
  public meetingStatus?: MeetingStatus;
  public lastChatSeen?: Date;
  public userActive?: boolean;

  constructor(trackId?: string, entryId?: string, sharing?: boolean, meetingStatus?: MeetingStatus,
    lastChatSeen?: Date, userActive?: boolean) {
    this.trackId = trackId;
    this.entryId = entryId;
    this.sharing = sharing;
    this.meetingStatus = meetingStatus;
    this.lastChatSeen = lastChatSeen;
    this.userActive = userActive;
  }
}

class GuestOnlineStatusReq implements OnlineStatus {
  public trackId?: string;
  public entryId?: string;
  public meetingStatus?: MeetingStatus;
  public guestId?: string;
  /** This is used to update the guest name. */
  public name?: string;
  public lastChatSeen?: Date;

  constructor(trackId?: string, entryId?: string, meetingStatus?: MeetingStatus, lastChatSeen?: Date) {
    this.trackId = trackId;
    this.entryId = entryId;
    this.meetingStatus = meetingStatus;
    this.lastChatSeen = lastChatSeen;
  }
}

export default OnlineStatus;
export { GuestOnlineStatusReq, MeetingStatus, OnlineStatusReq };
