import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import Integrations from '@/data/config/Integrations';
import { AppAccount, UserCredential } from '@/data/datatypes/AppAccount';
import { AppItem } from '@/data/datatypes/AppItem';
import { TrackEntry } from '@/data/datatypes/TrackEntry';
import DataWorker from '@/data/storage/DataWorker';
import { IdentifiedAppItems, ItemLocator, ItemsLocator } from '@/stores/AppAccounts.types';
import pinia from '@/stores/index';
import { useTrackEntriesStore } from '@/stores/TrackEntries';

export function authAppAccount(appId: string, windowClosedCallback: () => void, existingAccountId?: string): void {
  let url: string = `${Integrations.BE}/api/apps/${appId}/account/auth`;
  if (existingAccountId) {
    url += `?aid=${existingAccountId}`;
  }
  const win = window.open(url);

  let iteration = 0;
  const maxIterations = 5 * 60 * 5; // 5 minutes
  const pollTimer = window.setInterval(() => {
    if ((win && win.closed !== false) || (iteration++ > maxIterations)) {
      window.clearInterval(pollTimer);
      windowClosedCallback();
    }
  }, 300);
}

export const useAppAccountsStore = defineStore('AppAccounts', () => {
  const appAccounts: Ref<Record<string, AppAccount[]>> = ref({});
  const userAccounts: Ref<Record<string, AppAccount[]>> = ref({});
  const activeAppId: Ref<string | null> = ref(null);
  const appItemList: Ref<Record<string, AppItem[]>> = ref({});
  const selectedAppAccount: Ref<AppAccount | null> = ref(null);
  const containerItemId: Ref<string> = ref('');

  const accounts: ComputedRef<AppAccount[]> = computed(() => {
    if (activeAppId.value && appAccounts.value[activeAppId.value]) {
      return appAccounts.value[activeAppId.value];
    } else {
      return [];
    }
  });

  const appItems: ComputedRef<AppItem[]> = computed(() => {
    if (activeAppId.value && selectedAppAccount.value) {
      const key: string = getItemsKey(activeAppId.value, selectedAppAccount.value.id, containerItemId.value);
      return appItemList.value[key] || [];
    }
    return [];
  });

  const appItemsForLocator: ComputedRef<(locator: ItemsLocator) => AppItem[]> = computed(() => {
    return (locator: ItemsLocator): AppItem[] => {
      const key: string = getItemsKey(locator.appId, locator.accountId, locator.parentId);
      return appItemList.value[key] || [];
    };
  });

  const authUrl: ComputedRef<string> = computed(() => {
    return `${Integrations.BE}/api/apps/${activeAppId.value}/account/auth`;
  });

  const reAuthUrl: ComputedRef<string> = computed(() => {
    if (selectedAppAccount.value) {
      return `${Integrations.BE}/api/apps/${activeAppId.value}/account/auth?aid=${selectedAppAccount.value.id}`;
    }
    return `${Integrations.BE}/api/apps/${activeAppId.value}/account/auth`;
  });

  function setActiveAppId(appId: string): void {
    activeAppId.value = appId;
  }

  function setAppAccounts(details: { accounts: AppAccount[]; fullRefresh: boolean }): void {
    const accountsToSet: Record<string, AppAccount[]> = details.fullRefresh ? {} : appAccounts.value;
    for (const account of details.accounts) {
      if (!accountsToSet[account.appId]) {
        Vue.set(accountsToSet, account.appId, []);
      }
      if (!accountsToSet[account.appId].find((current: AppAccount) => current.id === account.id)) {
        accountsToSet[account.appId].push(account);
      }
    }
    if (details.fullRefresh) {
      appAccounts.value = accountsToSet;
    }
  }

  function setSelectedAppAccount(selectedAccount: AppAccount | null): void {
    selectedAppAccount.value = selectedAccount;
  }

  function setParentItemId(containerId: string): void {
    containerItemId.value = containerId;
  }

  function setUserAccounts(details: { userId: string; accounts: AppAccount[] }): void {
    Vue.set(userAccounts.value, details.userId, details.accounts);
  }

  function setAppItems(items: IdentifiedAppItems): void {
    const key: string = getItemsKey(items.appId, items.accountId, items.parentId);
    Vue.set(appItemList.value, key, items.items);
  }

  async function changeActiveAppId(appId: string): Promise<void> {
    try {
      setActiveAppId(appId);
      await DataWorker.instance().dispatch('AppAccounts/setActiveAppId', activeAppId.value);
    } catch (error) {
      log.error(`Unable to change active app ID - ${error}`);
    }
  }

  async function disableUserAppAccount(account: AppAccount): Promise<void> {
    try {
      await DataWorker.instance().dispatch('AppAccounts/disableUserAccount', account);
    } catch (error) {
      log.error(`Unable to disable app account ${account.id} - ${error}`);
    }
  }

  async function reenableUserAppAccount(account: AppAccount): Promise<void> {
    try {
      await DataWorker.instance().dispatch('AppAccounts/reenableUserAccount', account);
    } catch (error) {
      log.error(`Unable to re-enable app account ${account.id} - ${error}`);
    }
  }

  async function deleteUserAppAccount(account: AppAccount): Promise<void> {
    try {
      await DataWorker.instance().dispatch('AppAccounts/deleteUserAccount', account);
    } catch (error) {
      log.error(`Unable to delete app account ${account.id} - ${error}`);
    }
  }

  async function updateAppAccount(account: AppAccount): Promise<void> {
    await DataWorker.instance().dispatch('AppAccounts/updateAppAccount', account);
  }

  async function refreshUserAccounts(userId: string): Promise<AppAccount[] | undefined> {
    try {
      const accounts: AppAccount[] = await DataWorker.instance().dispatch('AppAccounts/getUserAccounts', userId);
      setUserAccounts({ userId, accounts });
      return accounts;
    } catch (error) {
      log.error(`Unable to refresh user accounts - ${error}`);
    }
  }

  async function refreshAppAccounts(): Promise<void> {
    try {
      await DataWorker.instance().dispatch('AppAccounts/getAppAccountDataFromServer');
    } catch (error) {
      log.error(`Unable to refresh app accounts - ${error}`);
    }
  }

  async function getItems(itemsLocator: ItemsLocator): Promise<void> {
    const result: AppItem[] = await DataWorker.instance().dispatch('AppAccounts/getAppItems', itemsLocator);
    if (selectedAppAccount.value && selectedAppAccount.value.sortByName) {
      result.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (selectedAppAccount.value && selectedAppAccount.value.sortByType) {
      result.sort((a, b) => b.type.indexOf('folder') - a.type.indexOf('folder'));
    }
    const ident: IdentifiedAppItems = {
      appId: itemsLocator.appId,
      accountId: itemsLocator.accountId,
      parentId: itemsLocator.parentId,
      items: result,
      search: itemsLocator.search,
    };
    setAppItems(ident);
  }

  async function addItemEntry(itemLocator: ItemLocator): Promise<TrackEntry> {
    const entry: TrackEntry =
      await DataWorker.instance().dispatch('TrackEntries/createAppItemEntry', itemLocator.accountId, itemLocator);

    const trackEntriesStore = useTrackEntriesStore(pinia);
    trackEntriesStore.setEntry(entry);
    return entry;
  }

  function getItemsKey(appId: string, accountId: string, parentId: string): string {
    let key: string = appId + '-' + accountId + '-' + parentId;
    if (!parentId) {
      key = appId + '-' + accountId + '-';
    }
    return key;
  }

  async function createNewCredential(userCredential: UserCredential): Promise<AppAccount> {
    const result: AppAccount = await DataWorker.instance().dispatch('AppAccounts/createNewCredential', userCredential);
    return result;
  }

  return {
    userAccounts,
    activeAppId,
    selectedAppAccount,
    containerItemId,
    accounts,
    appItems,
    appItemsForLocator,
    authUrl,
    reAuthUrl,
    setActiveAppId,
    setAppAccounts,
    setSelectedAppAccount,
    setParentItemId,
    changeActiveAppId,
    disableUserAppAccount,
    reenableUserAppAccount,
    deleteUserAppAccount,
    updateAppAccount,
    refreshUserAccounts,
    refreshAppAccounts,
    getItems,
    addItemEntry,
    createNewCredential,
  };
});
