import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { Ref, ref } from 'vue';

import DataWorker from '@/data/storage/DataWorker';
import { AppDevelopmentVersion } from '@/data/version/AppDevelopmentVersion';
import { patchArray } from '@/stores/StoreHelper';

import { useDataSourcesStore } from '../DataSources';

export const useAppVersionsStore = defineStore('AppVersions', () => {
  const appDevelopmentVersions: Ref<Record<string, AppDevelopmentVersion[]>> = ref({});

  function setAppDevelopmentVersions(details:
    {appDevelopmentVersions: AppDevelopmentVersion[], fullRefresh: boolean}): void {
    log.debug(`Setting ${details.appDevelopmentVersions.length} app development versions`);
    // If it's a full refresh, then delete any records that aren't in the updated object
    const updatesByApp: Record<string, AppDevelopmentVersion[]> = {};
    details.appDevelopmentVersions.forEach(version => {
      const appId = version.appId;
      let updatesForApp = updatesByApp[appId];
      if (!updatesForApp) {
        updatesForApp = [];
        updatesByApp[appId] = updatesForApp;
      }
      updatesForApp.push(version);
    });
    if (details.fullRefresh) {
      const removedAppIds: string[] = Object.keys(appDevelopmentVersions.value).filter((appId: string) => {
        return !!updatesByApp[appId];
      });
      for (const removedId of removedAppIds) {
        if (appDevelopmentVersions.value[removedId]) {
          Vue.delete(appDevelopmentVersions.value, removedId);
        }
      }
    }
    Object.keys(updatesByApp).forEach(appId => {
      log.debug(`Setting versions for app with id ${appId}`);
      if (!appDevelopmentVersions.value[appId]) {
        Vue.set(appDevelopmentVersions.value, appId, updatesByApp[appId]);
      } else {
        patchArray(appDevelopmentVersions.value[appId], updatesByApp[appId], details.fullRefresh);
      }
      log.debug(`After set ${Object.keys(appDevelopmentVersions.value).length} apps have known versions`);
    });
  }

  async function revertAppToVersion(version: AppDevelopmentVersion) {
    await DataWorker.instance().dispatch('AppDevelopmentVersions/revertAppToVersion', version);
    // Reverting to an old version may change the datasources, and they're not otherwise rective so
    // force refresh them here
    useDataSourcesStore().refreshDataSourcesMetaData(version.appId);
  }

  async function updateAppVersionLabel(version: AppDevelopmentVersion, newLabel: string) {
    await DataWorker.instance().dispatch('AppDevelopmentVersions/updateVersionLabel', version.id, newLabel);
  }

  return {
    appDevelopmentVersions,
    setAppDevelopmentVersions,
    revertAppToVersion,
    updateAppVersionLabel,
  };
});
