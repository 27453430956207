import { RawLocation } from 'vue-router';

import RouteNames from '@/router/RouteNames';

import { MeetingTrackEntry } from '../datatypes/TrackEntry';

export interface CalendarDate {
  day: number;
  month: number;
  siblingMonth?: boolean;
  weekDay?: number;
  year: number;
}

export enum CalendarFilter {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  SCHEDULE = 'SCHEDULE',
}

export const MAX_HEADER_MEETING_EVENTS = 999;
export const DEFAULT_HEADER_MEETING_EVENTS = 2;

export const isToday = (calDate: CalendarDate): boolean => {
  const date: Date = new Date();
  return calDate.day === date.getDate() && calDate.month === date.getMonth() && calDate.year === date.getFullYear();
};

export const weekdays = (): string[] => {
  return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
};

export const getMonthNames = (): string[] => {
  return ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
};

export const ACCEPTED_INVITE_STATES: string[] = ['YES', 'ACCEPTED'];
export const DECLINED_INVITE_STATES: string[] = ['NO', 'DECLINED'];

export const getDayRoute = (selectedDate: CalendarDate, isWorkspace: boolean): RawLocation => {
  const name: string = isWorkspace ? RouteNames.TRACK_CALENDAR_FILTER_ROUTE_NAME
    : RouteNames.CALENDAR_FILTER_ROUTE_NAME;
  return {
    name: name,
    params: {
      filter: CalendarFilter.DAY.toLowerCase(),
      year: selectedDate.year.toString(),
      month: (selectedDate.month + 1).toString(),
      date: selectedDate.day.toString()
    }
  };
};

export const getMeetingTime = (start: Date, end: Date): string => {
  return start.toLocaleString(undefined, {
    hour: '2-digit', minute: '2-digit', hour12: true
  }) + ' - ' + end.toLocaleString(undefined, {
    hour: '2-digit', minute: '2-digit', hour12: true
  });
};

export const isSameDate = (firstDate: Date, secondDate: Date): boolean => {
  return firstDate.getDate() === secondDate.getDate() && firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getFullYear() === secondDate.getFullYear();
};

export const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

export const getShortMonthNames = (month: number): string => {
  return getMonthNames()[month].substring(0, 3);
};

export const getDayMonthKey = (date: Date): string => {
  return date.getDate() + '/' + (date.getMonth() + 1);
};

/**
 * Calendar object
 */
class Calendar {
  startDate: CalendarDate | null;
  endDate: CalendarDate | null;
  siblingMonths: boolean;
  weekStart: number;

  /**
   * Calendar constructor
   *
   * @param  options  Calendar options
   */
  constructor({
    startDate = null,
    endDate = null,
    siblingMonths = true,
    weekStart = 0,
  }: {
    /**
     * Date object indicating the selected start date
     */
    startDate?: CalendarDate | null;

    /**
     * Date object indicating the selected end date
     */
    endDate?: CalendarDate | null;

    /**
     * Calculate dates from sibling months (before and after the current month, based on weekStart)
     */
    siblingMonths?: boolean;

    /**
     * Day of the week to start the calendar, respects `Date.prototype.getDay` (defaults to `0`, Sunday)
     */
    weekStart?: number;
  } = {}) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.siblingMonths = siblingMonths;
    this.weekStart = weekStart;
  }

  /**
   * Calculate a calendar month
   *
   * @param   year   Year
   * @param   month  Month [0-11]
   * @return  Calendar days, i.e. array of contiguous days that will cover the
   *          requested month with the full leading and trailing week.
   *
   *          Example:
   *              For January 2022 (with a calendar that uses the US convention, i.e. week starting on a Sunday),
   *              it will return 42 entries.
   *
   *              The first 7 entries, will cover the 26th of December 2021 to the Saturday 1st of January 2022.
   *              The following 28 entries will cover the 2nd of January 2022 to the Saturday 29th of January 2022.
   *              And the last 7 entries will cover the 30th of January 2022 to the Saturday 5th of February 2022.
   *
   *              Then entries for 26th of December to 31st of December 2021 and for the 1st of February to the
   *              5th of February 2022 are all marked as being "siblingMonth".
   */
  getCalendar(year: number, month: number): CalendarDate[] {
    const date = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));

    year = date.getUTCFullYear();
    month = date.getUTCMonth();

    const calendar: CalendarDate[] = [];
    const firstDay = date.getUTCDay();
    const firstDate = -((7 - this.weekStart + firstDay) % 7);
    const lastDate = daysInMonth(year, month);
    const lastDay = (lastDate - firstDate) % 7;
    const lastDatePreviousMonth = daysInMonth(year, month - 1);

    let i = firstDate;
    let currentDay;
    let currentDate;
    let currentDateObject: CalendarDate | false = false;
    let otherMonth;
    let otherYear;

    const max = lastDate - i + (lastDay !== 0 ? 7 - lastDay : 0) + firstDate;

    while (i < max) {
      currentDate = i + 1;
      currentDay = ((i < 1 ? 7 + i : i) + firstDay) % 7;
      if (currentDate < 1 || currentDate > lastDate) {
        if (this.siblingMonths) {
          if (currentDate < 1) {
            otherMonth = month - 1;
            otherYear = year;
            if (otherMonth < 0) {
              otherMonth = 11;
              otherYear--;
            }
            currentDate = lastDatePreviousMonth + currentDate;
          } else if (currentDate > lastDate) {
            otherMonth = month + 1;
            otherYear = year;
            if (otherMonth > 11) {
              otherMonth = 0;
              otherYear++;
            }
            currentDate = i - lastDate + 1;
          }

          if (otherMonth !== undefined && otherYear !== undefined) {
            currentDateObject = {
              day: currentDate,
              weekDay: currentDay,
              month: otherMonth,
              year: otherYear,
              siblingMonth: true,
            };
          }
        } else {
          currentDateObject = false;
        }
      } else {
        currentDateObject = {
          day: currentDate,
          weekDay: currentDay,
          month: month,
          year: year,
        };
      }

      if (currentDateObject) {
        calendar.push(currentDateObject);
      }
      i++;
    }
    return calendar;
  }
}

export const setupMeetingsPosition = (meetings: MeetingTrackEntry[]) : MeetingTrackEntry[] => {
  if (meetings.length === 0) {
    return [];
  }

  for (const meeting of meetings) {
    const dateStart = new Date(meeting.startDate ?? 0);
    const dateEnd = new Date(meeting.endDate ?? 0);

    meeting.startHourSlot = dateStart.getHours() * 100 + dateStart.getMinutes();
    meeting.endHourSlot = dateEnd.getHours() * 100 + dateEnd.getMinutes();

    const diff : number = +(dateEnd) - +(dateStart);
    meeting.delta = diff;
    meeting.durationInMinute = diff / 1000 / 60;

    if (meeting.durationInMinute < 15) {
      meeting.delta = 15;
      meeting.endHourSlot += 15;
    }
  }

  let sortedMeetings : Array<MeetingTrackEntry> = meetings.sort((m1 : MeetingTrackEntry, m2 : MeetingTrackEntry) => {
    return (m1?.startHourSlot ?? 0) - (m2?.startHourSlot ?? 0) ||
           (m2.delta ?? 0) - (m1?.delta ?? 0);
  });

  let level = 1;
  let meetingsWithLevel : MeetingTrackEntry[] = [];
  let indexToRemove : number[] = [];

  while (sortedMeetings.length) {
    const sameLevels : MeetingTrackEntry[] = [sortedMeetings[0]];
    indexToRemove.push(0);

    for (let j = 1; j < sortedMeetings.length; ++j) {
      const end = sameLevels[sameLevels.length - 1]?.endHourSlot ?? 0;
      const start = sortedMeetings[j]?.startHourSlot ?? 0;
      if (end <= start) {
        sameLevels.push(sortedMeetings[j]);
        indexToRemove.push(j);
      }
    }

    sameLevels.forEach(meeting => {
      meeting.level = level - 1;
    });
    meetingsWithLevel = meetingsWithLevel.concat(sameLevels);
    level++;
    sortedMeetings = sortedMeetings.filter((_meeting, index) => {
      return indexToRemove.includes(index) === false;
    });
    indexToRemove = [];
  }

  sortedMeetings = meetings.sort((m1 : MeetingTrackEntry, m2 : MeetingTrackEntry) => {
    return (m1?.startHourSlot ?? 0) - (m2?.startHourSlot ?? 0) ||
           (m2.delta ?? 0) - (m1?.delta ?? 0);
  });

  const continuousGroups = [];
  indexToRemove = [];
  while (sortedMeetings.length) {
    const subgroup = [sortedMeetings[0]];
    indexToRemove.push(0);

    for (let i = 1; i < sortedMeetings.length; ++i) {
      const end = sortedMeetings[0]?.endHourSlot ?? 0;
      const start = sortedMeetings[i]?.startHourSlot ?? 0;

      if (end > start) {
        subgroup.push(sortedMeetings[i]);
        indexToRemove.push(i);
      } else {
        break;
      }
    }

    continuousGroups.push(subgroup);
    sortedMeetings = sortedMeetings.filter((_meeting, index) => {
      return indexToRemove.includes(index) === false;
    });
    indexToRemove = [];
  }

  for (const group of continuousGroups) {
    for (const meeting of group) {
      const theMeeting = meetingsWithLevel.find(m => m.id === meeting.id);

      if (!theMeeting) {
        continue;
      }

      const maxLevel = Math.max(...group.map(gr => (gr?.level ?? 0)));

      theMeeting.width = 100 / (maxLevel + 1);
      theMeeting.marginLeft = (theMeeting?.level ?? 0) * theMeeting.width;
      theMeeting.isRightMost = theMeeting.level === maxLevel;
      theMeeting.isBlocked = meetingsWithLevel
        .filter(m => m.id !== meeting.id && (m?.level ?? 0) >= (meeting?.level ?? 0))
        .some(m => {
          const diffStart = (m?.startHourSlot ?? 0) - (meeting?.startHourSlot ?? 0);
          const diffEnd = (m?.endHourSlot ?? 0) - (meeting?.endHourSlot ?? 0);

          return Math.abs(diffStart) <= 70 || Math.abs(diffEnd) <= 70;
        });

      if (!theMeeting.isRightMost) {
        if (theMeeting.isBlocked) {
          theMeeting.width *= 1.75;
        } else {
          theMeeting.width = Math.max(theMeeting.width, 100 - theMeeting.marginLeft);
        }
      }
    }
  }

  return meetingsWithLevel;
};

export { Calendar };
