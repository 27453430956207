export enum ArticleSubscriptionMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export default class ArticleSubscriptionConfig {
  // The ID is just to make storing in the IndexedDB easier
  public id: string = '0';
  public subscribeOnCreateMode: ArticleSubscriptionMode = ArticleSubscriptionMode.AUTOMATIC;
  public subscribeOnEditMode: ArticleSubscriptionMode = ArticleSubscriptionMode.AUTOMATIC;
  public subscribeOnCommentMode: ArticleSubscriptionMode = ArticleSubscriptionMode.AUTOMATIC;
}
