import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { Member, MemberRole, TrackMemberAdd } from '@/data/datatypes/Member';
import { Track } from '@/data/datatypes/Track';
import DataWorker from '@/data/storage/DataWorker';
import pinia from '@/stores/index';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';
import { MembersByTrackId } from '@/stores/TrackMembers.types';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

export const useTrackMembersStore = defineStore('TrackMembers', () => {
  const userStore = useUserStore(pinia);

  const members: Ref<MembersByTrackId> = ref({});
  const membersInitialised: Ref<boolean> = ref(false);

  const membersForTrack: ComputedRef<(trackId: string) => Member[]> = computed(() => {
    return (trackId: string) => members.value[trackId] ? Object.values(members.value[trackId]) : [];
  });

  const membersForActiveTrack: ComputedRef<Member[]> = computed(() => {
    const tracksStore = useTracksStore(pinia);
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && members.value[trackId]) {
      return Object.values(members.value[trackId]);
    } else {
      return [];
    }
  });

  const membersForMeetingTrack: ComputedRef<Member[]> = computed(() => {
    const tracksStore = useTracksStore(pinia);
    const meetingTrack: Track | undefined = tracksStore.meetingTrack;
    if (meetingTrack) {
      if (meetingTrack.id && members.value[meetingTrack.id]) {
        return Object.values(members.value[meetingTrack.id]);
      }
    }
    return [];
  });

  const hasWorkspaceWriteAccess: ComputedRef<boolean> = computed(() => {
    const currentUserId = userStore.currentUserId;
    const currentMember: Member | undefined = membersForActiveTrack.value
      .find((current: Member) => currentUserId === current.userId);
    if (currentMember) {
      return currentMember.role === MemberRole.COORDINATOR || currentMember.role === MemberRole.COLLABORATOR;
    } else {
      return false;
    }
  });

  const isCoordinator: ComputedRef<boolean> = computed(() => {
    const currentUserId = userStore.currentUserId;
    const currentMember: Member | undefined = membersForActiveTrack.value
      .find((current: Member) => currentUserId === current.userId);
    if (currentMember) {
      return currentMember.role === MemberRole.COORDINATOR;
    } else {
      return false;
    }
  });

  const isCoordinatorOrCollaboratorOfMeetingTrack: ComputedRef<boolean> = computed(() => {
    const currentUserId = userStore.currentUserId;
    if (currentUserId) {
      const currentMember: Member | undefined = membersForMeetingTrack.value
        .find((current: Member) => currentUserId === current.userId);
      if (currentMember) {
        return ((currentMember.role === MemberRole.COORDINATOR) || (currentMember.role === MemberRole.COLLABORATOR)) &&
          (currentMember.confirmed === true);
      }
    }
    return false;
  });

  const isCoordinatorOfMeetingTrack: ComputedRef<boolean> = computed(() => {
    const currentUserId = userStore.currentUserId;
    if (currentUserId) {
      const currentMember: Member | undefined = membersForMeetingTrack.value
        .find((current: Member) => currentUserId === current.userId);
      if (currentMember) {
        return (currentMember.role === MemberRole.COORDINATOR) && (currentMember.confirmed === true);
      }
    }
    return false;
  });

  const isOnlyCoordinator: ComputedRef<boolean> = computed(() => {
    const owners: Member[] | undefined = membersForActiveTrack.value
      .filter((current: Member) => current.role === MemberRole.COORDINATOR);
    return owners.length === 1;
  });

  function setMembers(details: { members: Member[]; fullRefresh: boolean }): void {
    const membersToSet: MembersByTrackId = details.fullRefresh ? {} : members.value;
    for (const member of details.members) {
      const currentTrackMembers: { [memberId: string]: Member } = membersToSet[member.trackId] || {};
      setOrPatchObject(currentTrackMembers, member.id, asRecord(member));
      setOrPatchObject(membersToSet, member.trackId, currentTrackMembers);
    }

    if (isUserFetchRequired(members.value, details.members)) {
      userStore.refreshAllUsers();
    }

    if (details.fullRefresh) {
      members.value = membersToSet;
    }
    membersInitialised.value = true;
  }

  function setMember(member: Member): void {
    let trackMembers: { [memberId: string]: Member } = members.value[member.trackId];
    if (!trackMembers) {
      trackMembers = {};
      Vue.set(members.value, member.trackId, trackMembers);
    }
    setOrPatchObject(trackMembers, member.id, asRecord(member));
  }

  function removeMember(member: Member): void {
    if (members.value[member.trackId] && members.value[member.trackId][member.id]) {
      Vue.delete(members.value[member.trackId], member.id);
    }
  }

  async function createMember(memberToCreate: TrackMemberAdd): Promise<Member | undefined> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const createdMember = await DataWorker.instance().dispatch('TrackMembers/createMember', memberToCreate);
      setMember(createdMember);
      return createdMember;
    } catch (error) {
      log.error(`Failed to add track member: ${error}`);
    }
  }

  async function deleteMember(memberToRemove: Member): Promise<void | undefined> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      await DataWorker.instance().dispatch('TrackMembers/deleteMember', memberToRemove);
      removeMember(memberToRemove);
    } catch (error) {
      log.error(`Failed to add track member: ${error}`);
    }
  }

  async function createMembers(membersToCreate: TrackMemberAdd[]): Promise<void> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      for (const memberToCreate of membersToCreate) {
        await createMember(memberToCreate);
      }
    } catch (error) {
      log.error(`Error while adding members to track: ${error}`);
    }
  }

  async function getMembers(trackId: string): Promise<Member[] | undefined> {
    try {
      const members: Member[] = await DataWorker.instance().dispatch('TrackMembers/getMembers', trackId);
      setMembers({ members: members, fullRefresh: false });
      return members;
    } catch (error) {
      log.error(`Error while getting members of track: ${error}`);
    }
  }

  async function updateMember(memberToUpdate: Member): Promise<Member | undefined> {
    if (userStore.isGuestOfActiveTrack) {
      return;
    }
    try {
      const updatedMember = await DataWorker.instance().dispatch('TrackMembers/updateMember', memberToUpdate);
      setMember(updatedMember);
      return updatedMember;
    } catch (error) {
      log.error(`Failed to update track member: ${error}`);
    }
  }

  async function refreshMembers(): Promise<void> {
    if (userStore.isGuestUser) {
      // TODO: handle guests
      return;
    }
    await DataWorker.instance().dispatch('TrackMembers/refreshMembers');
  }

  async function refreshMembersForTrack(trackId: string): Promise<void> {
    if (userStore.isGuestUser) {
      // TODO: handle guests
      return;
    }
    await DataWorker.instance().dispatch('TrackMembers/refreshMembersForTrack', trackId, false);
  }

  function setMembersForTrack(details: { trackId: string; members: Member[] }): void {
    const existingMembersForTrack: Record<string, Member> = members.value[details.trackId] || {};
    for (const member of Object.values(existingMembersForTrack)) {
      if (!details.members.find((current: Member) => current.id === member.id)) {
        removeMember(member);
      }
    }
    setMembers({ members: details.members, fullRefresh: false });
  }

  /**
   * A private chat is hidden for a user by updating their membership for that chat track and setting a 'hidden' flag.
   * First check whether the user is actually a member of the track, then, if the requested isHidden argument is
   * different from their current track hidden state, update it to the requested value.
   * @param payload
   */
  async function setChatHiddenForCurrentUser(payload: { chatTrackId: string, isHidden: boolean }): Promise<void> {
    const { chatTrackId, isHidden } = payload;
    const currentUserId: string | null = userStore.currentUserId;
    if (!currentUserId) {
      return;
    }

    // See if the current user has a membership for the chat track
    const member = membersForTrack.value(chatTrackId).find((member: Member) => member.userId === currentUserId);
    if (!member) {
      return;
    }

    if (member.trackHidden === undefined || member.trackHidden !== isHidden) {
      member.trackHidden = isHidden;
      // Do not include a role change in this update, as only admins can do that
      const memberPatch = Object.assign({}, member, { role: undefined });
      await updateMember(memberPatch);
    }
  }

  function isUserFetchRequired(currentMembers: MembersByTrackId, newMembers: Member[]) {
    // Refresh the user lists if we get any new members
    for (const member of newMembers) {
      if (!currentMembers[member.trackId] || !currentMembers[member.trackId][member.id]) {
        return true;
      }
    }
    return false;
  }

  return {
    members,
    membersInitialised,
    membersForTrack,
    membersForActiveTrack,
    hasWorkspaceWriteAccess,
    isCoordinator,
    isCoordinatorOrCollaboratorOfMeetingTrack,
    isCoordinatorOfMeetingTrack,
    isOnlyCoordinator,
    setMembers,
    createMember,
    deleteMember,
    createMembers,
    getMembers,
    updateMember,
    refreshMembers,
    refreshMembersForTrack,
    setMembersForTrack,
    setChatHiddenForCurrentUser
  };
});
