import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { TextFile } from '@/data/datatypes/TextFile';
import DataWorker from '@/data/storage/DataWorker';
import pinia from '@/stores/index';
import { asRecord, patchObject, setOrPatchObject } from '@/stores/StoreHelper';
import { useUserStore } from '@/stores/User';

export const useEntryTextFilesStore = defineStore('EntryTextFiles', () => {
  const entryTextFiles: Ref<{ [entryId: string]: { [textFileId: string]: TextFile } }> = ref({});
  const textFilesLoaded: Ref<boolean> = ref(false);

  let resolveTextFilesLoadedPromise: () => void;
  const textFilesLoadedPromise: Promise<void> = new Promise((resolve) => {
    resolveTextFilesLoadedPromise = resolve;
  });

  const textFileContentByEntryId: ComputedRef<Record<string, unknown[]>> = computed(() => {
    return Object.keys(entryTextFiles.value).reduce((result: Record<string, unknown[]>, entryId: string) => {
      for (const textFile of Object.values(entryTextFiles.value[entryId])) {
        if (!result[entryId]) {
          Vue.set(result, entryId, []);
        }
        if (textFile.content) {
          try {
            result[entryId].push(JSON.parse(textFile.content));
          } catch (err) {
            log.error(`Failed to process textFile content with a length of ${textFile.content.length}: ${err}`);
          }
        }
      }
      return result;
    }, {});
  });

  function setTextFiles(details: { textFiles: TextFile[]; fullRefresh: boolean }): void {
    const textFilesToSet: { [entryId: string]: { [textFileId: string]: TextFile } } =
      details.fullRefresh ? {} : entryTextFiles.value;
    for (const textFile of details.textFiles) {
      let currentTextFiles: { [textFileId: string]: TextFile } = textFilesToSet[textFile.entryId];
      if (!currentTextFiles) {
        currentTextFiles = {};
        Vue.set(textFilesToSet, textFile.entryId, currentTextFiles);
      }
      setOrPatchObject(currentTextFiles, textFile.id, asRecord(textFile));
    }
    if (details.fullRefresh) {
      entryTextFiles.value = textFilesToSet;
    } else {
      patchObject(entryTextFiles.value, textFilesToSet);
    }
  }

  function setTextFilesLoaded(loaded: boolean): void {
    const existingValue = textFilesLoaded.value;
    textFilesLoaded.value = loaded;
    if (loaded && !existingValue) {
      resolveTextFilesLoadedPromise();
    }
  }

  async function getTextFileContentsForEntry(details: { trackId: string; entryId: string }):
    Promise<unknown[] | undefined> {
    const userStore = useUserStore(pinia);
    try {
      if (userStore.isGuestUser) {
        return;
      }
      if (userStore.isGuestMember(details.trackId)) {
        return;
      }

      if (!textFilesLoaded.value) {
        await textFilesLoadedPromise;
      }

      const textFileContents: unknown[] | undefined = textFileContentByEntryId.value[details.entryId];
      if (textFileContents) {
        return textFileContents;
      }

      const textFiles: TextFile[] | undefined = await DataWorker.instance().dispatch('EntryTextFiles/getTextFiles',
        details.trackId, details.entryId);
      if (textFiles) {
        setTextFiles({ textFiles, fullRefresh: false });
        return textFiles.reduce((result: unknown[], textFile: TextFile) => {
          result.push(JSON.parse(textFile.content));
          return result;
        }, []);
      }
    } catch (error) {
      log.error('Error getting text files: ' + error);
    }
  }

  return {
    textFileContentByEntryId,
    setTextFiles,
    setTextFilesLoaded,
    getTextFileContentsForEntry
  };
});
