import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { TrackOnlineStatus } from '@/data/datatypes/online/TrackOnlineStatus';
import { Track } from '@/data/datatypes/Track';
import DataWorker from '@/data/storage/DataWorker';
import pinia from '@/stores/index';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';
import {
  GuestOnlineStatusByTrackId,
  PresentationScrollViewUpdate,
  TrackOnlineStatusByTrackId
} from '@/stores/TrackOnlineStatuses.types';
import { useTracksStore } from '@/stores/Tracks';

export const useTrackOnlineStatusesStore = defineStore('TrackOnlineStatuses', () => {
  const statuses: Ref<TrackOnlineStatusByTrackId> = ref({});
  const guestOnlineStatuses: Ref<GuestOnlineStatusByTrackId> = ref({});

  const trackOnlineStatusForActiveTrack: ComputedRef<TrackOnlineStatus | undefined> = computed(() => {
    const tracksStore = useTracksStore(pinia);
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId) {
      return statuses.value[trackId];
    }
    return undefined;
  });

  const trackOnlineStatusForActiveMeeting: ComputedRef<TrackOnlineStatus | undefined> = computed(() => {
    const tracksStore = useTracksStore(pinia);
    const meetingTrack: Track | undefined = tracksStore.meetingTrack;
    if (meetingTrack) {
      return statuses.value[meetingTrack.id];
    }
    return undefined;
  });

  function clearGuestOnlineStatuses(trackId: string): void {
    Vue.delete(guestOnlineStatuses.value, trackId);
  }

  function setGuestOnlineStatus(guestStatusUpdate: { trackId: string; onlineStatus: TrackOnlineStatus }): void {
    setOrPatchObject(guestOnlineStatuses.value, guestStatusUpdate.trackId, asRecord(guestStatusUpdate.onlineStatus));
  }

  function setTrackOnlineStatuses(details: { trackOnlineStatus: TrackOnlineStatus[]; fullRefresh: boolean }): void {
    if (details.fullRefresh) {
      const keysToKeep = details.trackOnlineStatus.map((status) => status.trackId);
      Object.values(statuses.value).forEach((status) => {
        if (!keysToKeep.includes(status.trackId)) {
          Vue.delete(statuses.value, status.trackId);
        }
      });
    }
    details.trackOnlineStatus.forEach((status) => {
      setOrPatchObject(statuses.value, status.trackId, asRecord(status));
    });
  }

  async function sendPresentationScrollViewUpdate(details: PresentationScrollViewUpdate): Promise<void> {
    try {
      await DataWorker.instance().dispatch('OnlineStatus/updatePresentationScrollView', details.entryId,
        details.scrollView);
    } catch (error) {
      log.error(`Failed to send presentation scroll view update: ${error}`);
    }
  }

  return {
    statuses,
    guestOnlineStatuses,
    trackOnlineStatusForActiveTrack,
    trackOnlineStatusForActiveMeeting,
    clearGuestOnlineStatuses,
    setGuestOnlineStatus,
    setTrackOnlineStatuses,
    sendPresentationScrollViewUpdate,
  };
});
