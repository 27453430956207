import log from 'loglevel';

export interface AudioPlayer {
  start(): void;
  stop(): void;
}

export class AudioFilePlayer implements AudioPlayer {
  private readonly audio: HTMLAudioElement;

  public constructor(resourceUrl: string, loopAudio = false) {
    this.audio = new Audio();
    this.audio.src = resourceUrl;
    this.audio.loop = loopAudio;
    this.audio.load();
  }

  start(): void {
    log.debug('Starting audio file');
    this.audio.play();
  }

  stop(): void {
    log.debug('Stopping audio file');
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}

export class TonePlayer implements AudioPlayer {
  private readonly firstOscillator: OscillatorNode;
  private readonly secondOscillator: OscillatorNode;
  private readonly gainControl: AudioBufferSourceNode;

  public constructor() {
    const firstFrequency = 440;
    const secondFrequency = 480;
    const filterFrequency = 8000;
    const audioContext = new AudioContext();

    const firstOscillator = audioContext.createOscillator();
    firstOscillator.frequency.value = firstFrequency;

    const secondOscillator = audioContext.createOscillator();
    secondOscillator.frequency.value = secondFrequency;

    const gainNode = audioContext.createGain();
    gainNode.gain.value = 0;

    const gainControl = audioContext.createBufferSource();
    gainControl.buffer = this.createGainControlBuffer(audioContext);
    gainControl.loop = true;

    const lowpassFilter = audioContext.createBiquadFilter();
    lowpassFilter.type = 'lowpass';
    lowpassFilter.frequency.value = filterFrequency;

    firstOscillator.connect(gainNode);
    secondOscillator.connect(gainNode);
    gainControl.connect(gainNode.gain);
    gainNode.connect(lowpassFilter);
    lowpassFilter.connect(audioContext.destination);

    this.firstOscillator = firstOscillator;
    this.secondOscillator = secondOscillator;
    this.gainControl = gainControl;
  }

  private createGainControlBuffer(audioContext: AudioContext): AudioBuffer {
    const channels = 1;
    const sampleRate = audioContext.sampleRate;
    const frameCount = sampleRate * 6;
    const amplitude = 0.5;

    // Create a six-second mono-channel buffer
    const gainControlBuffer = audioContext.createBuffer(channels, frameCount, sampleRate);

    // Buffer data corresponds to 2 seconds 'on' and 4 seconds 'off'
    const bufferData = gainControlBuffer.getChannelData(0);
    for (let i = 0; i < frameCount; i++) {
      const sampleTime = i / audioContext.sampleRate;
      if (sampleTime >= 0 && sampleTime < 2) {
        bufferData[i] = amplitude;
      }
    }

    return gainControlBuffer;
  }

  public start(): void {
    log.debug('Starting ringing tone');
    this.firstOscillator.start();
    this.secondOscillator.start();
    this.gainControl.start();
  }

  public stop(): void {
    log.debug('Stopping ringing tone');
    this.gainControl.stop();
    this.secondOscillator.stop();
    this.firstOscillator.stop();
  }
}
