export default class Features {
  // TODO(FEN-586): When we sign the token that we send to the client, we can trust it in the client. Until then, use an
  // obfuscated value to make it a little harder to work out how to grant yourself other client-side features.
  // I'm not using an enum as I don't know if that will force it to keep the names unobfuscated.
  public static DISABLE_TRACKING: string = 'ieyh973j';
  public static REPORTING: string = 'sjury48r';
  public static GLOBAL_REPORTING: string = 'ayw4jgb';
  public static DASHBOARDS: string = '3939290';
  public static COPY_TRACK: string = 'co12010x';
  public static WHITEBOARD_FOX_APP: string = 'wbf20111';
  public static MEETING_ASSISTANT: string = 'ass21303';
  public static MEETING_BROADCAST: string = 'tit21303';
  public static EXTENDED_TOUR: string = 'coc21303';
  public static TRACK_TRANSFER_DEFAULT_LABELS: string = 'h4476sjf';
  public static CHAT_MESSAGE_EDITS = 'df097e3k';
  public static WEBHOOKS: string = 'O2AGbdCw';
  public static SHARED_CONTENT: string = '61716aaf';
  public static FORCE_GOOGLE_VIEWER: string = '6t6n6otb';
  public static INTEGRATION_CONTENT_MODIFICATION_NOTIFICATIONS: string = '61cfe41a';
  public static POST_CALL_SURVEY_OFF: string = 'off21303';
  public static PDFTRON: string = '36sherk7';
  public static VIRTUAL_ASSISTANT_GOOGLE_USER_ACCOUNT: string = 'h4dhd77';
  public static PERSISTENT_MEETING_UI: string = 'serj348fh';
  public static PASSTHROUGH_MEETINGS: string = 'ps9ad9ds';
  public static POSITIONAL_COMMENTS: string = '8wefjhds';
  public static WORKFLOW: string = 'pz6mjq';
  public static PUBLISH_ROOMS: string = 'pr0914dm';
  public static KNOWLEDGE_BASE: string = 'vo4j2dow';
  public static TRACK_HISTORY: string = 'ls0d002';
  public static DIAL_OUT: string = 'pr0112ds';
  public static INDEX_ENTRIES_FOR_SEARCH: string = 'hd6449jw'; // TODO: Remove
  public static ENABLE_PENDO: string = 'di37g8hd';
  public static UI_FLOW: string = 'sd09a4sk';
  public static TRACK_CREATION_SERVICE: string = 't09ds99k';
  public static RULESET_EDITOR: string = 'rm07122e';
  public static ZOOM_MEETINGS: string = 'ghsjwi34';
  public static APP_CONFIGURATION: string = 'setap147'; // Access to app configuration (create, edit, view definitions)
  public static DEPRECATED_APP_CONFIGURATION_ITEMS: string = 'jdqwwg87'; // Access to items that have moved inside apps
  public static ANALYTICS: string = 'gZhsksi35';
  public static ACTIVEPIECES = '53eca6';
  public static SERVER_DOMAIN_CONTROL = 'hduj14sdc';
  public static AI_ASSISTANT = 'dhrud6k2';
  public static AI_DEV_PAGE = 'sh73hfgj';

  public static isUserFeature(feature: string): boolean {
    return this.USER_FEATURES.lastIndexOf(feature) > -1;
  }

  public static isTrackFeature(feature: string): boolean {
    return this.TRACK_FEATURES.lastIndexOf(feature) > -1;
  }

  public static isGroupFeature(feature: string): boolean {
    return this.GROUP_FEATURES.lastIndexOf(feature) > -1;
  }

  public static isTenantManaged(feature: string): boolean {
    return this.TENANT_MANAGED.lastIndexOf(feature) > -1;
  }

  private static USER_FEATURES = [
    Features.DISABLE_TRACKING,
    Features.REPORTING,
    Features.GLOBAL_REPORTING,
    Features.DASHBOARDS,
    Features.COPY_TRACK,
    Features.WHITEBOARD_FOX_APP,
    Features.MEETING_ASSISTANT,
    Features.MEETING_BROADCAST,
    Features.EXTENDED_TOUR,
    Features.TRACK_TRANSFER_DEFAULT_LABELS,
    Features.WEBHOOKS,
    Features.PERSISTENT_MEETING_UI,
    Features.PASSTHROUGH_MEETINGS,
    Features.PERSISTENT_MEETING_UI,
    Features.PUBLISH_ROOMS,
    Features.WORKFLOW,
    Features.TRACK_HISTORY,
    Features.DIAL_OUT,
    Features.INDEX_ENTRIES_FOR_SEARCH,
    Features.ENABLE_PENDO,
    Features.RULESET_EDITOR,
    Features.APP_CONFIGURATION,
    Features.DEPRECATED_APP_CONFIGURATION_ITEMS,
    Features.SERVER_DOMAIN_CONTROL,
    Features.AI_ASSISTANT,
    Features.AI_DEV_PAGE,
  ];

  private static TRACK_FEATURES = [Features.PDFTRON, Features.POSITIONAL_COMMENTS];

  private static GROUP_FEATURES = [Features.APP_CONFIGURATION, Features.AI_ASSISTANT, Features.AI_DEV_PAGE];

  // Features which a tenant-admin can configure - other features can only be configured by a system-admin
  private static TENANT_MANAGED = [Features.APP_CONFIGURATION];
}
