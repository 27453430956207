export enum KnowledgeBaseNotificationType {
  KB_ARTICLE_EDITED = 'KB_ARTICLE_EDITED',
  KB_ARTICLE_DELETED = 'KB_ARTICLE_DELETED',
  KB_ARTICLE_MENTIONED = 'KB_ARTICLE_MENTIONED',
  KB_ARTICLE_COMMENT = 'KB_ARTICLE_COMMENT',
  KB_ARTICLE_COMMENT_MENTIONED = 'KB_ARTICLE_COMMENT_MENTIONED',
  KB_ARTICLE_ATTACHMENT_ADDED = 'KB_ARTICLE_ATTACHMENT_ADDED',
  KB_ARTICLE_ATTACHMENT_UPDATED = 'KB_ARTICLE_ATTACHMENT_UPDATED'
}

export interface KnowledgeBaseNotification {
  timestamp: string
  type: KnowledgeBaseNotificationType,
  data: string,
}

export enum KnowledgBaseNotificationState {
  PENDING,
  NONE,
}
