import { CombinedNotificationPreference } from '@/data/config/CombinedNotificationPreference';
import { NotificationDigestPreference } from '@/data/config/NotificationDigestPreference';
import { NotificationPreference } from '@/data/config/NotificationPreference';
import { Calendar } from '@/data/datatypes/Calendar';
import { PermissionList } from '@/data/datatypes/permissions/PermissionList';
import { PermissionType } from '@/data/datatypes/permissions/PermissionType';
import { UserCalendarContactDto } from '@/data/datatypes/UserCalendarContact';

interface ContactBase {
  id?: string;
  email: string;
  displayName: string;
  phoneNumber?: string;
  avatar?: string;
  color?: string;
  doNotDisturbActive?: boolean;
}

export interface LimitedUserDetails extends ContactBase {
  id: string;
  guest?: boolean;
  tenantId?: string;
  userStatusEmoji?: string;
  userStatusText?: string;
  userStatusExpiry?: number;
}

export enum TenantUserStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING_DEACTIVATED = 'PENDING_DEACTIVATED',
}

export interface TenantUserDetails extends LimitedUserDetails {
  status?: TenantUserStatus;
  admin?: boolean;
  firstLogin?: number;
  lastOnline?: number;
}

export interface ExternalUserDetails extends LimitedUserDetails {
  allowedPermissionTypes: PermissionType[];
}

export interface UserContact extends ContactBase {
  userId?: string;
  contactId?: string; // userId of the contact
  company?: string;
  jobTitle?: string;
  notes?: string;
  address?: string;
  country?: string;
  city?: string;
  birthDate?: number;
  deleted: boolean;
}

export enum TrackView {
  PREVIOUS = 'PREVIOUS',
  OVERVIEW = 'OVERVIEW',
  CHAT = 'CHAT',
  MEETINGS = 'MEETINGS',
  TASKS = 'TASKS',
  FILES = 'FILES'
}

export interface FullUserDetails extends LimitedUserDetails {
  joinWithMutedAudio?: boolean;
  joinWithMutedVideo?: boolean;
  showCalendarEvents?: boolean;
  chatMobilePushNotifications?: boolean;
  chatDesktopPushNotifications?: boolean;
  openLinks?: boolean;
  state?: string;
  spaceToMuteEnabled?: boolean;
  online?: boolean;
  permissionLists?: PermissionList[];
  channelNotificationPreference?: NotificationPreference;
  directMessageNotificationPreference?: NotificationPreference;
  entryCommentsNotificationPreference?: NotificationPreference;
  entriesChangedNotificationPreference?: CombinedNotificationPreference;
  notificationDigestPreference?: NotificationDigestPreference;
  appearOffline?: boolean;
  showAllTrackMeetings?: boolean;
  // pastMeetings?: PastMeetingDetails[]; This isn't currently used anywhere.
  calendar?: Calendar;
  contacts?: UserContact[];
  showDeclinedInCalendar?: boolean;
  calendarContactPreferences?: UserCalendarContactDto[];
  doNotDisturbActive?: boolean;
  doNotDisturbActiveUntil?: number;
}

export type DoNotDisturbSchedule =
  'NONE' |
  'THIRTY_MINS' |
  'ONE_HOUR' |
  'CUSTOM';

export interface DoNotDisturbUser {
  schedule: DoNotDisturbSchedule;
  custom?: number;
}

export interface DoNotDisturbUserRequest {
  id: string;
  doNotDisturbUser: DoNotDisturbUser;
}

export interface GuestOnlineStatusDetails {
  trackId: string;
  guestId?: string;
  name?: string;
}
