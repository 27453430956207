import Integrations from '@/data/config/Integrations';
import { EntryType, TrackEntry, TrackEntryDetails } from '@/data/datatypes/TrackEntry';
import { getExtension } from '@/data/helpers/FileExtensions';
import { removeDiacritics } from '@/data/helpers/SearchHelper';

export default class EntriesHelper {
  public static isRootEmail(entry: TrackEntry): boolean {
    if (!entry.type) {
      return false;
    }
    return entry.type && entry.type.toString() === EntryType[EntryType.email] && entry.subType === 'root';
  }

  public static getFilteredTrackEntries(
    trackEntries: TrackEntry[], searchTerm: string): TrackEntry[] {
    let filteredSortedEntries: TrackEntry[] = trackEntries;
    if (trackEntries) {
      if (searchTerm) {
        // need to search children as well
        filteredSortedEntries = filteredSortedEntries.filter((entry) => {
          return !this.isRootEmail(entry) &&
            (this.containsCurrentSearchTerm(entry.subject || '', searchTerm) ||
              this.containsCurrentSearchTerm(entry.title || '', searchTerm));
        });
      }
    }
    return filteredSortedEntries;
  }

  private static containsCurrentSearchTerm(stringToSearch: string, searchTerm: string): boolean {
    return stringToSearch !== '' &&
      removeDiacritics(stringToSearch).toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
  }
}

export function getViewUrl(entry: TrackEntry, guestId?: string): string {
  // Append the entry version to prevent the file from caching when adding new/rolling back versions
  if (guestId) {
    const url = `${Integrations.BE}/api/track/${entry.trackId}/entry/${entry.id}/view/guest/${guestId}`;
    return entry.currentVersion ? `${url}?v=${entry.currentVersion.id}` : url;
  } else {
    const url = `${Integrations.BE}/api/track/${entry.trackId}/entry/${entry.id}/view?requiresToken=false`;
    return entry.currentVersion ? `${url}&v=${entry.currentVersion.id}` : url;
  }
}

export function getBinaryUrl(entry: TrackEntry, guestId?: string): string {
  if (guestId) {
    return `${Integrations.BE}/api/track/${entry.trackId}/entry/${entry.id}/binary/guest/${guestId}`;
  } else {
    return `${Integrations.BE}/api/track/${entry.trackId}/entry/${entry.id}/binary`;
  }
}

export function getContentUrl(entry: TrackEntry, guestId?: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((entry.state as any).appOpenUrl) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (entry.state as any).appOpenUrl;
  } else if (entry.url) {
    return entry.url;
  } else {
    return getViewUrl(entry, guestId);
  }
}

export function openInNewWindow(entry: TrackEntry, guestId?: string): void {
  if (entry) {
    window.open(getContentUrl(entry, guestId));
  }
}

export function isMeetingRecord(entry: TrackEntryDetails): boolean {
  return entry?.type === EntryType.meeting_record;
}

export function isRoom(entry: TrackEntryDetails): boolean {
  return (entry?.type === EntryType.publish_room || entry?.type === EntryType.private);
}

export function getType(entry: TrackEntryDetails): string | undefined {
  if (entry.type === EntryType.file) {
    return entry.subType;
  }

  return getExtension(entry.title ?? '') ?? '';
}

const imageFileTypes = ['png', 'jpg', 'jpeg', 'svg', 'webp', 'gif'];

export function isImage(entry: TrackEntryDetails): boolean {
  return imageFileTypes.includes(getType(entry) ?? '') ||
    imageFileTypes.includes(getExtension(entry.title ?? '')?.toLowerCase() ?? '');
}

const videoFileTypes = ['m4a', 'm4v', 'webm', 'mp4', 'mov'];

export function isVideo(entry: TrackEntryDetails): boolean {
  return videoFileTypes.includes(getType(entry) ?? '') ||
    videoFileTypes.includes(getExtension(entry.title ?? '')?.toLowerCase() ?? '');
}

const audioFileTypes = ['mp3', '.wav', '.3gp', '.aac', '.ogg', '.wma'];

export function isAudio(entry: TrackEntryDetails): boolean {
  return audioFileTypes.includes(getType(entry) ?? '') ||
    audioFileTypes.includes(getExtension(entry.title ?? '')?.toLowerCase() ?? '');
}

export function isPdf(entry: TrackEntryDetails): boolean {
  return !!entry && ((!!entry.subType && entry.subType.toLowerCase() === 'pdf') ||
    (getExtension(entry.title ?? '')?.toLowerCase() === 'pdf'));
}

export function isLink(entry: TrackEntryDetails): boolean {
  return !!entry && !!entry.type && entry.type.toLowerCase() === 'link';
}

export function isDownloadableApp(entry: TrackEntryDetails): boolean {
  return (entry.type === 'app_item' && (entry.subType === 'box' || entry.subType === 'onedrive' ||
    entry.subType === 'onedrivegcc' || entry.subType === 'dropbox'));
}

export function isDownloadable(entry: TrackEntryDetails): boolean {
  return ((entry.type === EntryType.file) || isPdf(entry) || isImage(entry) || isAudio(entry) || isVideo(entry));
}

export function isFilesystemApp(entry: TrackEntryDetails): boolean {
  return (entry.type === 'app_item' && (entry.subType === 'box' || entry.subType === 'onedrive' ||
    entry.subType === 'onedrivegcc' || entry.subType === 'dropbox' ||
    entry.subType === 'googledrive'));
}

export function isEmail(entry: TrackEntryDetails): boolean {
  return entry?.type === EntryType.email;
}

export function canRename(entry: TrackEntryDetails): boolean {
  return entry.type === EntryType.file || entry.type === EntryType.folder ||
    entry.type === EntryType.link || entry.subType === 'whiteboardfox';
}

export function canMove(entry: TrackEntryDetails): boolean {
  return entry.type !== EntryType.actions && entry.type !== EntryType.meeting &&
    entry.type !== EntryType.meeting_invite && entry.type !== EntryType.meeting_record &&
    entry.type !== EntryType.publish_room && entry.type !== EntryType.private;
}
