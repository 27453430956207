export enum MeetingContentTab {
  CHAT = 'chat',
  TASKS = 'tasks',
  FILES = 'files',
  PINS = 'pins',
  PARTICIPANTS = 'participants',
}

export interface FilteredContactCounts {
  all: number;
  personal: number;
  tenant: number;
  externalTenant: number;
}
