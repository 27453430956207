import log from 'loglevel';

import {
  allowedConditionsForAttributes,
  BinaryCondition,
  checkCondition,
  conditionalChecks,
  ConditionName,
  ExpressionCondition,
  FormulaCondition,
  FormulaConditionArgs,
  TernaryCondition,
  UnaryCondition,
} from '@/components/tasks/conditional-checking/conditional';
import { AttributeType } from '@/data/tasks/Attribute';
import { AttributeStyle } from '@/data/tasks/AttributeStyle';

// ConditionalStyle and its subtypes define the formatting config as it will be persistently stored
interface ConditionalStyle {
  /** The order of importance of the style. Styles with a *lower* value with override those with a *higher* value */
  precedence: number;
  style: AttributeStyle;
}

export type UnaryFormattingConfig = ConditionalStyle & UnaryCondition;
export type BinaryFormattingConfig = ConditionalStyle & BinaryCondition;
export type TernaryFormattingConfig = ConditionalStyle & TernaryCondition;
export type FormulaFormattingConfig = ConditionalStyle & FormulaCondition;
export type ExpressionFormattingConfig = ConditionalStyle & ExpressionCondition;

export type FormattingConfig = TernaryFormattingConfig |
  BinaryFormattingConfig |
  UnaryFormattingConfig |
  FormulaFormattingConfig |
  ExpressionFormattingConfig;

/**
 * Gets the resulting style after applying 0 or more {@link FormattingConfig} to a property value for a mini app record
 * @param conditions formatting conditions for the attribute
 * @param propertyValue the value of the mini app property against which the conditions are being checked
 * @param formulaConditionArgs details that may be required to calculate a formula condition
 * @param data data that may be required to evaluate an expression condition
 */
export function getConditionalStyle(conditions: FormattingConfig[] | undefined,
  propertyValue: string | undefined, formulaConditionArgs: FormulaConditionArgs,
  precalculatedResultsByIndex?: Record<number, boolean>): AttributeStyle {
  if (conditions === undefined || conditions.length === 0) {
    return {};
  }
  // Sort the conditions in descending order of precedence [n, n-1, ..., 2, 1]
  // NB The array is copied before sorting, as sort() is in-place. Sorting the original will cause an infinite render
  //    loop
  const conditionsByPrecedence = [...conditions];
  conditionsByPrecedence.sort((c1, c2) => c2.precedence - c1.precedence);
  let style: AttributeStyle = {};

  // If the condition with precedence 1 (higher precedence) says text colour should be pink, and the condition with
  // precedence 4 (lower precedence) says text colour should be blue, then the returned colour should be *pink*. So, go
  // through the sorted conditions, overriding lower-precedence styling
  let index: number = 0;
  for (const condition of conditionsByPrecedence) {
    const precalculatedResult: boolean | undefined = precalculatedResultsByIndex?.[index];
    if (checkCondition(condition, propertyValue, formulaConditionArgs, precalculatedResult)) {
      style = {
        ...style,
        ...condition.style
      };
    }
    ++index;
  }
  return style;
}

// Converts the AttributeStyle objects returned from applicableStyles into
// an actual style object that can be applied to the html
export function getAttributeStyleAsStyleObject(attributeStyle: AttributeStyle): Record < string, string > {
  const style: Record<string, string> = { };
  if (attributeStyle.textColour) {
    style.color = attributeStyle.textColour;
  }
  if (attributeStyle.bgColour) {
    style.backgroundColor = attributeStyle.bgColour;
  }
  if (attributeStyle.bold) {
    style.fontWeight = 'bold';
  }
  if (attributeStyle.italic) {
    style.fontStyle = 'italic';
  }
  if (attributeStyle.strikeThrough) {
    style.textDecoration = 'line-through';
  }
  if (attributeStyle.strikeThrough && attributeStyle.underline) {
    style.textDecoration = 'line-through underline';
  } else if (attributeStyle.strikeThrough) {
    style.textDecoration = 'line-through';
  } else if (attributeStyle.underline) {
    style.textDecoration = 'underline';
  }
  return style;
}

/** Creates a default formatting config for an attribute, using the first element in
 * {@link allowedConditionsForAttributes} */
export function createDefaultFormattingConfigForAttribute(attributeType: AttributeType,
  precedence: number): FormattingConfig {
  const conditionName: ConditionName | undefined = allowedConditionsForAttributes[attributeType]?.[0];
  if (conditionName === undefined) {
    const errorMsg = `Unknown condition name while creating default formatting config: ${conditionName}`;
    log.error(errorMsg);
    throw new Error(errorMsg);
  }
  return createDefaultFormattingConfig(conditionName, precedence);
}

// Utility function for creating a default config for a given attribute type - e.g. when adding a new formatting config
// to an attribute. This can be used to populate the initial values in the formatting config dialog
export function createDefaultFormattingConfig(conditionName: ConditionName, precedence: number): FormattingConfig {
  const conditionType = conditionalChecks[conditionName].type;
  const style: AttributeStyle = {};
  switch (conditionType) {
    case 'unary':
      return {
        type: 'unary',
        name: conditionName,
        style,
        precedence,
      } as UnaryFormattingConfig;
    case 'binary':
      return {
        type: 'binary',
        name: conditionName,
        style,
        compareValue1: '',
        precedence
      } as BinaryFormattingConfig;
    case 'ternary':
      return {
        type: 'ternary',
        name: conditionName,
        style,
        compareValue1: '',
        compareValue2: '',
        precedence,
      } as TernaryFormattingConfig;
    case 'formula':
      return {
        type: 'formula',
        name: conditionName,
        style,
        precedence,
        formula: '',
      } as FormulaFormattingConfig;
    case 'expression':
      return {
        type: 'expression',
        name: conditionName,
        style,
        precedence,
        expression: '',
      } as ExpressionFormattingConfig;
    default:
      throw new Error(`Unknown condition type: ${conditionType}`);
  }
}

export type ColourStyleProp = 'bgColour' | 'textColour';
export type TextStyleProperty = 'bold' | 'italic' | 'strikeThrough' | 'underline';
export type EditMode = 'ADD' | 'EDIT';
