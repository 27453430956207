import { AttributeType } from '@/data/tasks/Attribute';

export enum TaskViewType {
  BASE = 'BASE',
  KANBAN = 'KANBAN',
  GANTT = 'GANTT',
  CALENDAR = 'CALENDAR',
  FORM = 'FORM',
  CARD = 'CARD',
  CUSTOM_EXTERNAL = 'CUSTOM_EXTERNAL', // A custom view defined using a URL
  CUSTOM_INTERNAL = 'CUSTOM_INTERNAL', // A custom view defined in the Fender UI designer
}

export enum TaskViewConfigType {
  GROUP_BY = 'groupBy',
  SORT_BY = 'sortBy',
  SORT_DIRECTION = 'sortDirection',
  START_DATE = 'startDate',
  DURATION = 'duration',
  PREREQUISITE = 'prerequisite',
  DATE_SELECTOR = 'dateSelector',
  URL = 'url',
  UI_CONFIG = 'uiConfig',
}

export type OptionInputType = 'attributeSelection' | 'text' | 'textArea';

// When configuring 'sortBy' in a view, this will be the default value that represents
// the absence of a selected attribute. This effectively sorts by creation time by default.
export const SORT_BY_DEFAULT_VALUE = '__sort_by_default__';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface TaskViewConfigOption {
  type: TaskViewConfigType;
  attributeType?: AttributeType;
  label: string;
  inputType?: OptionInputType;
}

export interface TaskViewTypeOption {
  label: string;
  viewType: TaskViewType;
  requiredAttributeTypes?: AttributeType[];
  configurableOptions?: TaskViewConfigOption[];
}

export const VIEW_TYPE_OPTIONS: TaskViewTypeOption[] = [
  {
    label: 'Grid',
    viewType: TaskViewType.BASE,
    configurableOptions: [
      {
        type: TaskViewConfigType.SORT_BY,
        label: 'Sort by',
        inputType: 'attributeSelection'
      },
      {
        type: TaskViewConfigType.SORT_DIRECTION,
        label: 'Sort direction'
      },
    ]
  },
  {
    label: 'Kanban',
    viewType: TaskViewType.KANBAN,
    requiredAttributeTypes: [AttributeType.SELECT],
    configurableOptions: [
      {
        type: TaskViewConfigType.GROUP_BY,
        attributeType: AttributeType.SELECT,
        label: 'Group by',
        inputType: 'attributeSelection'
      },
      {
        type: TaskViewConfigType.SORT_BY,
        label: 'Sort by',
        inputType: 'attributeSelection'
      },
      {
        type: TaskViewConfigType.SORT_DIRECTION,
        label: 'Sort direction'
      },
    ]
  },
  {
    label: 'Gantt',
    viewType: TaskViewType.GANTT,
    requiredAttributeTypes: [AttributeType.RECORD, AttributeType.DATE, AttributeType.NUMBER],
    configurableOptions: [
      {
        type: TaskViewConfigType.START_DATE,
        attributeType: AttributeType.DATE,
        label: 'Start date',
        inputType: 'attributeSelection'
      },
      {
        type: TaskViewConfigType.DURATION,
        attributeType: AttributeType.NUMBER,
        label: 'Duration',
        inputType: 'attributeSelection'
      },
      {
        type: TaskViewConfigType.PREREQUISITE,
        attributeType: AttributeType.RECORD,
        label: 'Prerequisite',
        inputType: 'attributeSelection'
      },
    ]
  },
  {
    label: 'Calendar',
    viewType: TaskViewType.CALENDAR,
    requiredAttributeTypes: [AttributeType.DATE],
    configurableOptions: [
      {
        type: TaskViewConfigType.DATE_SELECTOR,
        attributeType: AttributeType.DATE,
        label: 'View by date',
        inputType: 'attributeSelection'
      }
    ]
  },
  {
    label: 'Card',
    viewType: TaskViewType.CARD,
    configurableOptions: [
      {
        type: TaskViewConfigType.SORT_BY,
        label: 'Sort by',
        inputType: 'attributeSelection'
      },
      {
        type: TaskViewConfigType.SORT_DIRECTION,
        label: 'Sort direction'
      },
    ]
  },
  {
    label: 'Form',
    viewType: TaskViewType.FORM
  },
  {
    label: 'Custom',
    viewType: TaskViewType.CUSTOM_INTERNAL,
  },
  {
    label: 'External',
    viewType: TaskViewType.CUSTOM_EXTERNAL,
    configurableOptions: [
      {
        type: TaskViewConfigType.URL,
        label: 'Url',
        inputType: 'text'
      },
    ]
  },
];

// Maps to Java class MiniAppViewHints
export interface ViewHints {
  // the ordering of columns (e.g. in a Grid view), lower indices to the the left, higher to the right
  colOrder?: string[],
  // a map of the attribute (i.e. column) name to its width, in px
  widths?: Record<string, string>,
}

export type MiniAppViewDataSourceType = 'TABLE' | 'EXTERNAL';

export interface MiniAppViewDataSource {
  id?: string;
  type: MiniAppViewDataSourceType;
  dataSourceId: string;
  isExternalWorkflow?: boolean;
}

interface MiniAppViewDetails {
  trackId: string;
  miniAppId: string;
  tableId?: string;
  type: TaskViewType;
  name: string;
  options?: Partial<Record<TaskViewConfigType, string>>,
  // Rendering hints
  hints?: ViewHints;
  dataSources: MiniAppViewDataSource[];
}

export interface MiniAppView extends MiniAppViewDetails {
  id: string;
}

export interface MiniAppViewPayload extends MiniAppViewDetails {
  id?: string;
  defaultView?: boolean;
  previousViewName?: string;
}

export enum CalendarView {
  // TODO we currently only support month view
  MONTH = 'month',
  // WEEK = 'WEEK',
  // DAY = 'DAY',
}
