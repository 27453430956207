import { render, staticRenderFns } from "./UserStatusIcon.vue?vue&type=template&id=270c1fec&scoped=true&"
import script from "./UserStatusIcon.vue?vue&type=script&setup=true&lang=ts&"
export * from "./UserStatusIcon.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./UserStatusIcon.vue?vue&type=style&index=0&id=270c1fec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270c1fec",
  null
  
)

export default component.exports