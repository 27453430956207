import { RawLocation } from 'vue-router';

import {
  KnowledgeBaseNotification,
  KnowledgeBaseNotificationType
} from '@/data/datatypes/knowledgemgmt/KnowledgeBaseNotification';
import { Notification, NotificationType } from '@/data/datatypes/Notification';
import { LimitedUserDetails } from '@/data/datatypes/UserDetails';

import { NotificationBuilder } from './NotificationBuilder';

export default class KnowledgeBaseNotificationBuilder implements NotificationBuilder {
  private kbNotification: KnowledgeBaseNotification;
  private displayDetailsForUsers!: Map<string, LimitedUserDetails>;
  private eventData: Record<string, unknown> = {};

  constructor(displayDetailsForUsers: Map<string, LimitedUserDetails>,
    kbNotification: KnowledgeBaseNotification) {
    this.displayDetailsForUsers = displayDetailsForUsers;
    this.kbNotification = kbNotification;
    this.eventData = JSON.parse(this.kbNotification.data);
  }

  public build(): Notification {
    const notification = {
      title: this.title,
      subtext: this.subtext,
      icon: this.icon,
      canDelete: true,
      timestamp: new Date(this.kbNotification.timestamp),
      userIdent: this.userIdent,
      type: this.type,
      route: this.route,
      displayNumber: null,
    };

    return notification;
  }

  private get title(): string {
    let title = '';
    if (this.kbNotification.data) {
      title = this.getProperty('articleTitle');
    }

    return title;
  }

  private get subtext(): string {
    let subtext;
    switch (this.kbNotification.type) {
      case KnowledgeBaseNotificationType.KB_ARTICLE_COMMENT:
        subtext = this.userIdent?.displayName + ' commented on ' + this.title;
        break;
      case KnowledgeBaseNotificationType.KB_ARTICLE_COMMENT_MENTIONED:
        subtext = this.userIdent?.displayName + ' mentioned you in a comment';
        break;
      case KnowledgeBaseNotificationType.KB_ARTICLE_MENTIONED:
        subtext = this.userIdent?.displayName + ' mentioned you';
        break;
      case KnowledgeBaseNotificationType.KB_ARTICLE_DELETED:
        subtext = this.userIdent?.displayName + ' deleted the article';
        break;
      case KnowledgeBaseNotificationType.KB_ARTICLE_ATTACHMENT_ADDED:
        subtext = this.userIdent?.displayName + ' attached ' + this.getProperty('filename') + ' to ' + this.title;
        break;
      case KnowledgeBaseNotificationType.KB_ARTICLE_ATTACHMENT_UPDATED:
        subtext = this.userIdent?.displayName + ' updated ' + this.getProperty('filename') + ' on ' + this.title;
        break;
      default:
        subtext = this.title + ' has been updated';
    }
    return subtext;
  }

  private get icon(): string {
    return 'light/book-light';
  }

  private get route(): RawLocation {
    const path: string = this.getProperty('url');
    return { path };
  }

  private get userIdent(): LimitedUserDetails | null {
    const data = JSON.parse(this.kbNotification.data);
    let userId: string;
    switch (this.kbNotification.type) {
      case KnowledgeBaseNotificationType.KB_ARTICLE_DELETED:
        userId = data.deletedById;
        break;
      case KnowledgeBaseNotificationType.KB_ARTICLE_ATTACHMENT_ADDED:
      case KnowledgeBaseNotificationType.KB_ARTICLE_ATTACHMENT_UPDATED:
        userId = data.actionedById;
        break;
      default:
        userId = data.authorId;
    }
    return this.displayDetailsForUsers.get(userId) ?? null;
  }

  private get type(): NotificationType {
    let notificationType;

    switch (this.kbNotification.type) {
      case KnowledgeBaseNotificationType.KB_ARTICLE_COMMENT:
      case KnowledgeBaseNotificationType.KB_ARTICLE_COMMENT_MENTIONED:
        notificationType = NotificationType.ARTICLE_COMMENT;
        break;
      default:
        notificationType = NotificationType.ARTICLE;
    }

    return notificationType;
  }

  private getProperty(name: string): string {
    return this.eventData[name] as string;
  }
}
