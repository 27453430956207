import { Account } from '@/data/datatypes/authAdmin/Account';
import { CafexIdentity } from '@/data/datatypes/authAdmin/CafexIdentity';
import { ProviderIdentity } from '@/data/datatypes/authAdmin/ProviderIdentity';

export const removeDiacritics = (toNormalise: string): string => {
  let normalised = toNormalise;
  // Looks like Babel isn't polyfilling this for us
  if (normalised.normalize) {
    normalised = normalised.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return normalised;
};

export const compareNames = (first: string, second: string): number => {
  const string1: string = removeDiacritics(first ?? '')?.toLowerCase().trim();
  const string2: string = removeDiacritics(second ?? '')?.toLowerCase().trim();
  if (string1 === string2) {
    return 0;
  }
  if (string1 < string2) {
    return -1;
  }
  return 1;
};

export const compare = (itemOne: string | number | undefined, itemTwo: string | number | undefined):
  number => {
  if (itemOne === itemTwo) {
    return 0;
  }
  if (itemOne === null || itemOne === undefined) {
    return -1;
  }
  if (itemTwo === null || itemTwo === undefined) {
    return 1;
  }
  return itemOne < itemTwo ? -1 : 1;
};

export const doesAuthAccountMatch = (account: Account, searchTerm: string): boolean => {
  const term = removeDiacritics(searchTerm).toLowerCase();
  const accountMatches: boolean = doesValueMatch(account.name, term) ||
    doesValueMatch(account.domains, term) ||
    doesValueMatch(account.type, term) ||
    doesValueMatch(account.status, term);

  if (accountMatches) {
    return true;
  }

  if (account.cafexIdentities) {
    if (doesValueMatch(account.cafexIdentities.length.toString(), term)) {
      return true;
    }
    for (const identity of account.cafexIdentities) {
      if (doesCafexIdentityMatch(identity, term)) {
        return true;
      }
    }
  }

  return false;
};

export const doesCafexIdentityMatch = (cafexIdentity: CafexIdentity, searchTerm: string): boolean => {
  const term = removeDiacritics(searchTerm).toLowerCase();
  return doesValueMatch(cafexIdentity.displayName, term) ||
    doesValueMatch(cafexIdentity.firstName, term) ||
    doesValueMatch(cafexIdentity.lastName, term) ||
    doesValueMatch(cafexIdentity.primaryEmail, term) ||
    doesValueMatch(cafexIdentity.type, term) ||
    doesValueMatch(cafexIdentity.status, term);
};

export const doesProviderIdentityMatch = (providerIdentity: ProviderIdentity, searchTerm: string): boolean => {
  const term = removeDiacritics(searchTerm).toLowerCase();
  return doesValueMatch(providerIdentity.email, term) ||
    doesValueMatch(providerIdentity.providerName, term) ||
    doesValueMatch(providerIdentity.providerIdentifier, term);
};

export const doesValueMatch = (value: string, search: string): boolean => {
  if (!value || !search) {
    return false;
  }
  return removeDiacritics(value.toString()).toLowerCase().indexOf(search) >= 0;
};
