export function isEditableElementSelected(): boolean {
  const activeElement = document.activeElement;
  if (activeElement) {
    if (['input', 'textarea'].includes(activeElement.nodeName.toLowerCase()) ||
        (activeElement.nodeName.toLowerCase() === 'div' && (activeElement as HTMLDivElement).isContentEditable)) {
      return true;
    }
    if (activeElement.shadowRoot) {
      const shadowTargetName = activeElement.shadowRoot.activeElement?.tagName.toLowerCase() ?? '';
      if (['input', 'textarea'].includes(shadowTargetName) ||
          (shadowTargetName === 'div' &&
            (activeElement.shadowRoot.activeElement as HTMLDivElement).isContentEditable)) {
        return true;
      }
    }
  }
  return false;
}
