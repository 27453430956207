import { TrackEntry } from '../TrackEntry';

export interface StagedFile {
  id: number;
  file: File;
  uploadPercentage: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isAStagedFile(object: any): object is StagedFile {
  return (object.id || object.id === 0) && object.file && (object.uploadPercentage || object.uploadPercentage === 0);
}

export interface StagedFileDetails {
  files: (StagedFile | TrackEntry)[];
  uploadInProgress: boolean;
}
