import { Ref, ref } from 'vue';

import BrowserDetection from '@/data/BrowserDetection';
import { isEditableElementSelected } from '@/data/helpers/DomHelper';

export function useOrientationDetection() {
  const inTabletPortraitMode: Ref<boolean> = ref(false);
  const inPhoneLandscapeMode: Ref<boolean> = ref(false);

  function checkForLandscapeMode() {
    const activeElement = document.activeElement;
    if (activeElement && isEditableElementSelected()) {
      // Avoid showing the 'unsupported' page when the window dimensions change due to
      // the keyboard being visible
      inPhoneLandscapeMode.value = false;
      // Re-check once the element loses focus. The timeout is because the blur event is fired
      // before the keyboard is hidden (and before any new element is focused)
      activeElement.addEventListener('blur', () => {
        setTimeout(checkForLandscapeMode, 100);
      }, { once: true });
    } else {
      inPhoneLandscapeMode.value = hasLandscapeDimensions();
    }
  }

  function hasLandscapeDimensions(): boolean {
    if (BrowserDetection.isIosApp()) {
      // window innerWidth/innerHeight don't change on rotation in app's WKWebView; documentElement
      // is more reliable here.
      return document.documentElement.clientWidth > document.documentElement.clientHeight;
    }
    return window.innerWidth > window.innerHeight;
  }

  function hasPortraitDimensions(): boolean {
    if (BrowserDetection.isIosApp()) {
      // window innerWidth/innerHeight don't change on rotation in app's WKWebView; documentElement
      // is more reliable here.
      return document.documentElement.clientHeight > document.documentElement.clientWidth;
    }
    // if (screen height > screen width) assume we're in portrait mode
    return window.innerHeight > window.innerWidth;
  }

  // This is for in-meeting rotation in the iPhone app.
  // In the iOS app's webview, the CSS does not apply properly with respect to safe areas on the notched
  // iPhones, unless we force a CSS reflow. This function forces that by briefly changing and resetting
  // the 'left' property. The brief style change is not noticeable to the user because it happens during
  // the native rotation animation.
  function iphoneAppReflow(): void {
    const appEl: HTMLElement | null = document.querySelector('#app');
    if (appEl) {
      setTimeout(() => {
        appEl.style.left = '1px';
        setTimeout(() => {
          appEl.style.left = '0';
        }, 100);
      }, 0);
    }
  }

  async function addOrientationDetection(): Promise<void> {
    // Tablets
    if (BrowserDetection.isTablet()) {
      // portrait mode displays when loaded ('resize' wouldn't have been fired)
      inTabletPortraitMode.value = hasPortraitDimensions();

      window.addEventListener('resize', () => {
        inTabletPortraitMode.value = hasPortraitDimensions();
      });
    }

    // Mobile phones
    if (BrowserDetection.isMobile()) {
      checkForLandscapeMode();

      window.addEventListener('resize', checkForLandscapeMode);
      if (BrowserDetection.isIphoneApp()) {
        window.addEventListener('resize', iphoneAppReflow);
      }
    }
  }

  addOrientationDetection();

  return {
    inTabletPortraitMode,
    inPhoneLandscapeMode
  };
}
