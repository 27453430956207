import log from 'loglevel';
import Vue from 'vue';

import { areArraysEqual } from '@/data/helpers/SortingHelper';

export interface HasId {
  id: string;
}

export const setOrPatchObject: (parent: Record<string, unknown>, property: string, source: Record<string, unknown>,
  removeMissingProps?: boolean) => void =
  (parent: Record<string, unknown>, property: string, source: Record<string, unknown>,
    removeMissingProps: boolean = true) => {
    if (parent[property]) {
      patchObject(parent[property] as Record<string, unknown>, source, removeMissingProps);
    } else {
      Vue.set(parent, property, source);
    }
  };

export const asRecord: (toCast: unknown) => Record<string, unknown> = (toCast: unknown) => {
  return toCast as Record<string, unknown>;
};

export const patchArray: (target: HasId[], source: HasId[], removeMissingProps?: boolean) => void =
(target: HasId[], source: HasId[], removeMissingProps: boolean = true) => {
  if (removeMissingProps) {
    const removedItems: HasId[] = target.filter((targetObj: HasId) => {
      return source.findIndex((sourceObj: HasId) => sourceObj.id === targetObj.id) === -1;
    });
    for (const removedItem of removedItems) {
      target.splice(target.indexOf(removedItem), 1);
    }
  }
  for (const sourceObj of source) {
    const toPatch: HasId | undefined = target.find((item: HasId) => item.id === sourceObj.id);
    if (toPatch) {
      patchObject(asRecord(toPatch), asRecord(sourceObj), removeMissingProps);
    } else {
      target.push(sourceObj);
    }
  }
};

export const patchObject: (target: Record<string, unknown>, source: Record<string, unknown>,
  removeMissingProps?: boolean) => void =
(target: Record<string, unknown>, source: Record<string, unknown>, removeMissingProps: boolean = true) => {
  if (!target || !source) {
    return;
  }
  if (removeMissingProps) {
    const removedProps: string[] = Object.keys(target).filter((targetProp: string) => {
      return !(targetProp in source);
    });
    for (const removedProp of removedProps) {
      Vue.delete(target, removedProp);
    }
  }

  for (const prop of Object.keys(source)) {
    // Don't trigger a mutation on unchanged values
    if (source[prop] === target[prop]) {
      continue;
    }
    if (!source[prop] || !target[prop]) {
      Vue.set(target, prop, source[prop]);
    } else if (Array.isArray(source[prop]) && Array.isArray(target[prop])) {
      const sourceArray = source[prop] as unknown[];
      const targetArray = target[prop] as unknown[];
      if (areArraysEqual(sourceArray, targetArray)) {
        continue;
      }
      if (isArrayWithIds(sourceArray) && isArrayWithIds(targetArray)) {
        patchArray(target[prop] as HasId[], source[prop] as HasId[], removeMissingProps);
      } else {
        // if we don't have IDs - we have no way of correlating the data,
        // so just replace everything with the new data
        if (!removeMissingProps) {
          log.error('We will always remove missing props if there are no IDs');
        }
        targetArray.splice(0, targetArray.length);
        sourceArray.forEach((object) => {
          targetArray.push(object);
        });
      }
    } else if (typeof source[prop] === 'object' && typeof target[prop] === 'object') {
      patchObject(target[prop] as Record<string, unknown>, source[prop] as Record<string, unknown>);
    } else {
      Vue.set(target, prop, source[prop]);
    }
  }
};

function isArrayWithIds(arrayToCheck: unknown[]) {
  if (!arrayToCheck.length) {
    return false;
  }

  let hasIds = true;
  arrayToCheck.forEach((element) => {
    if (!(element as Record<string, unknown>).id) {
      hasIds = false;
    }
  });
  return hasIds;
}
