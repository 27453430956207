import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { PinnedTrackItem, PinnedTrackItemDetails } from '@/data/datatypes/PinnedTrackItem';
import DataWorker from '@/data/storage/DataWorker';
import pinia from '@/stores/index';
import { PinnedTrackItemsByTrackId } from '@/stores/PinnedTrackItems.types';
import { patchObject, setOrPatchObject } from '@/stores/StoreHelper';
import { useTracksStore } from '@/stores/Tracks';

export const usePinnedTrackItemStore = defineStore('PinnedTrackItems', () => {
  const items: Ref<PinnedTrackItemsByTrackId> = ref({});

  const pinnedItemsForActiveTrack: ComputedRef<PinnedTrackItem[]> = computed(() => {
    const tracksStore = useTracksStore(pinia);
    const trackId: string | null = tracksStore.activeTrackId;
    if (trackId && items.value[trackId]) {
      return Object.values(items.value[trackId]);
    } else {
      return [];
    }
  });

  function setItems(details: { items: PinnedTrackItem[]; fullRefresh: boolean }): void {
    const itemsToSet: PinnedTrackItemsByTrackId = details.fullRefresh ? {} : items.value;
    for (const item of details.items) {
      const currentPinnedItems: { [itemId: string]: PinnedTrackItem } = itemsToSet[item.trackId] || {};
      Vue.set(currentPinnedItems, item.id, item);
      setOrPatchObject(itemsToSet, item.trackId, currentPinnedItems);
    }

    if (details.fullRefresh) {
      items.value = itemsToSet;
    } else {
      patchObject(items.value, itemsToSet);
    }
  }

  function setItem(item: PinnedTrackItem): void {
    const pinnedItems: { [itemId: string]: PinnedTrackItem } = items.value[item.trackId] || {};
    Vue.set(pinnedItems, item.id, item);
    setOrPatchObject(items.value, item.trackId, pinnedItems);
  }

  function removeItem(item: PinnedTrackItem): void {
    if (items.value[item.trackId] && items.value[item.trackId][item.id]) {
      Vue.delete(items.value[item.trackId], item.id);
    }
  }

  async function pinItem(details: PinnedTrackItemDetails): Promise<PinnedTrackItem | undefined> {
    try {
      const pinnedItem = await DataWorker.instance().dispatch('PinnedTrackItems/pinItem', details);
      setItem(pinnedItem);
      return pinnedItem;
    } catch (error) {
      log.error(`Failed to pin track item: ${error}`);
    }
  }

  async function unpinItem(item: PinnedTrackItem): Promise<void> {
    try {
      await DataWorker.instance().dispatch('PinnedTrackItems/unpinItem', item);
      removeItem(item);
    } catch (error) {
      log.error(`Failed to unpin track item: ${error}`);
    }
  }

  async function refreshPinnedItems(): Promise<void> {
    try {
      await DataWorker.instance().dispatch('PinnedTrackItems/refreshPinnedItems');
    } catch (error) {
      log.error(`Failed to refresh pinned track items: ${error}`);
    }
  }

  function setItemsForTrack(details: { trackId: string; items: PinnedTrackItem[] }): void {
    const existingItemsForTrack: Record<string, PinnedTrackItem> = items.value[details.trackId] || {};
    for (const item of Object.values(existingItemsForTrack)) {
      if (!details.items.find((current: PinnedTrackItem) => current.id === item.id)) {
        removeItem(item);
      }
    }
    setItems({ items: details.items, fullRefresh: false });
  }

  return {
    items,
    pinnedItemsForActiveTrack,
    setItems,
    pinItem,
    unpinItem,
    refreshPinnedItems,
    setItemsForTrack
  };
});
