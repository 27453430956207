import anchorme from 'anchorme';
import he from 'he';

import { BackedAutocompleteItem } from '@/data/datatypes/components/AutocompleteItem';
import { Track } from '@/data/datatypes/Track';
import { FullUserDetails, LimitedUserDetails } from '@/data/datatypes/UserDetails';
import { validateUrl } from '@/data/helpers/UrlHelper';
import router from '@/router';
import RouteNames from '@/router/RouteNames';
import { useUserStore } from '@/stores/User';

export default class MeetingsHelper {
  public static isPstn(participantName: string): boolean {
    if (participantName.startsWith('+')) {
      return true;
    }

    if (participantName.replace(/[-+()\s]/g, '').match(/^\d+$/)) {
      return true;
    }

    return false;
  }

  public static formatMeetingNumber(meetingNumber: string): string | null {
    if (!meetingNumber) {
      return null;
    }
    let formatted: string = meetingNumber;
    if (formatted.length !== 10) {
      return formatted;
    }
    formatted = `${formatted.substring(0, 3)} ${formatted.substring(3, 6)} ${formatted.substring(6)}`;
    return formatted;
  }

  public static generateQuickDialUri(pstn: string, meetingNumber: string): string {
    const normalisedPstn = pstn.replaceAll(/[^+0-9]/g, '');
    const normalisedMeetingNumber = meetingNumber.replaceAll(/[^0-9]/g, '');
    // comma is a pause and the hash should submit the DTMF
    return `tel:${normalisedPstn},,${normalisedMeetingNumber}#`;
  }

  public track: Track;
  // TODO: Add typing for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public invite: any;
  public formattedMeetingNumber: string | null = null;
  public dialInNumbers: { label: string; number: string; mobileQuickDial: string }[] = [];

  // TODO: Add typing for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(track: Track, guestId: string | null,
    onInitialised?: (formattedMeetingNumber: string, invite: unknown) => void) {
    this.track = track;

    // TODO: Re-implement this through the shared worker (I think)
    // ServerData.getMeetingInviteText(track.id, guestId).then((invite) => {
    //   this.invite = invite;
    //   this.formattedMeetingNumber = MeetingsHelper.formatMeetingNumber(invite.meetingNumber);
    //   this.dialInNumbers = this.invite.dialInNumbers;
    //   if (onInitialised) {
    //     onInitialised(this.formattedMeetingNumber!, invite);
    //   }
    // });
    if (onInitialised) {
      onInitialised('TODO:', { guestId });
    }
  }

  public getInvitePlainText(): string {
    let maxLabelLength: number = 0;
    let maxNumberLength: number = 0;
    if (this.dialInNumbers) {
      for (const dialin of this.dialInNumbers) {
        if (dialin.label.length > maxLabelLength) {
          maxLabelLength = dialin.label.length;
        }
        if (dialin.number.length > maxNumberLength) {
          maxNumberLength = dialin.number.length;
        }
      }
    }

    let result: string = '';
    result += `Track name: ${this.track.title}\n\n`;
    result += `Join meeting: ${(this.invite || {}).meetingLink}\n\n`;
    if (this.dialInNumbers) {
      result += 'Dial in:\n';
      for (const dialin of this.dialInNumbers) {
        result += `  ${dialin.label.padStart(maxLabelLength, ' ')}: ${dialin.number.padEnd(maxNumberLength, ' ')}`;
        result += `    (mobile: tel:${dialin.mobileQuickDial} )\n`;
      }
      result += '\n';
      result += `Dial-in access code: ${this.formattedMeetingNumber}\n`;
    }
    return result;
  }

  public static getUserDisplayValue(user: LimitedUserDetails): string {
    let value: string;
    if (!user.displayName) {
      value = user.email || '';
    } else {
      value = user.displayName;
      if (user.displayName !== user.email) {
        value += ` <${user.email}>`;
      }
    }
    return value;
  }

  public static getMeetLink(description?: string): string {
    if (!description) {
      return '';
    }
    const splitDesc = description.split('\n\n');
    const joinString = 'Join meeting: ';
    const joinSplit = splitDesc.find(item => item.indexOf(joinString) !== -1);
    if (joinSplit) {
      // Look for the content that we add in for meetings first
      const meetLink = new URL(joinSplit.replace(joinString, ''));
      let newUiAppend = '';
      if (location.href.includes('/new-ui-preview')) {
        newUiAppend = '/new-ui-preview';
      }
      return meetLink.origin + newUiAppend + meetLink.pathname;
    } else {
      // If we don't find the meeting content, then look for a URL to this system
      const result: string = he.encode(description);
      const meetingLinks: string[] = [];
      const otherLinks: string[] = [];
      anchorme({
        input: result,
        options: {
          attributes: (url: string) => {
            if (validateUrl(url)) {
              // undo a server side hack for localhost testing - since localhost isn't
              // recognised by anchorme it'll replaced with localhost.com
              let local = location.protocol + '//' + location.host;
              local = local.replace('localhost', 'localhost.com');

              // If it's a pointer to this system, then try to extract a meeting ID
              if (url.startsWith(local)) {
                const routeData = router.resolve(url.substring(local.length));
                if ([
                  RouteNames.TRACK_MEETING_ROUTE_NAME,
                  RouteNames.CHAT_MEETING_ROUTE_NAME,
                ].includes(routeData?.route.name ?? '_nope')) {
                  meetingLinks.push(url);
                } else if (routeData?.route?.params?.trackId) {
                  // It's a link to a track resource, but not a meeting. Assume we want the meeting route
                  const meetingRouteData = router.resolve({
                    name: RouteNames.TRACK_MEETING_ROUTE_NAME,
                    params: { trackId: routeData.route.params.trackId },
                  });
                  otherLinks.push(meetingRouteData.href);
                }
              }
            }
            return {};
          },
        }
      });
      return meetingLinks[0] ?? otherLinks[0] ?? '';
    }
  }

  public static populateAutocompleteItems(result: BackedAutocompleteItem<string>[], user: LimitedUserDetails): void {
    const userStore = useUserStore();
    const currentUser: FullUserDetails | null = userStore.currentUser;
    if (currentUser?.id === user.id) {
      return;
    }
    const value: string = MeetingsHelper.getUserDisplayValue(user);
    const autocompleteItem: BackedAutocompleteItem<string> = {
      itemText: user.email,
      displayText: value,
      element: user.email,
      colour: user.color,
    };
    if (result.indexOf(autocompleteItem) < 0) {
      result.push(autocompleteItem);
    }
  }

  public static nextMeetingMessage(date: number): string {
    const until: number = date - new Date().getTime();
    if (until < 0) {
      return 'right now';
    } else if (until < 1000 * 60 * 60) {
      const mins: number = Math.floor(until / (1000 * 60));
      return mins + ' minute' + (mins !== 1 ? 's' : '');
    } else {
      const hours: number = Math.floor(until / (1000 * 60 * 60));
      const mins: number = Math.floor(until / (1000 * 60)) - 60 * hours;
      const hourText: string = hours + ' hour' + (hours !== 1 ? 's' : '');
      switch (mins) {
        case 0:
          return hourText;
        case 1:
          return hourText + ' 1 minute';
        default:
          return hourText + ' ' + mins + ' minutes';
      }
    }
  }
}
