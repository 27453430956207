export function indexOfNextUnescaped(expressionString: string, charToFind: string, after: number): number {
  let next = after + 1;
  while (next < expressionString.length) {
    const nextChar = expressionString.charAt(next);
    if (nextChar === charToFind) {
      return next;
    } else if (nextChar === '\\') {
      next++; // Skip the next character
    }
    next++;
  }
  return -1;
}
