import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

import ArticleSubscriptionConfig from '@/data/datatypes/knowledgemgmt/ArticleSubscriptionConfig';
import KnowledgeArticleSummary from '@/data/datatypes/knowledgemgmt/KnowledgeArticleSummary';
import {
  KnowledgBaseNotificationState,
  KnowledgeBaseNotification,
} from '@/data/datatypes/knowledgemgmt/KnowledgeBaseNotification';
import UserToken from '@/data/datatypes/UserToken';
import XWikiExistingArticle from '@/data/kb/XWikiExistingArticle';
import DataWorker from '@/data/storage/DataWorker';
import pinia from '@/stores/index';
import { asRecord, patchArray, patchObject } from '@/stores/StoreHelper';
import { useUserStore } from '@/stores/User';

export const useKnowledgeBaseStore = defineStore('KnowledgeBase', () => {
  const articles: Ref<KnowledgeArticleSummary[]> = ref([]);
  const articleSubscriptionConfig: Ref<ArticleSubscriptionConfig> = ref(new ArticleSubscriptionConfig());

  const allArticles: ComputedRef<KnowledgeArticleSummary[]> = computed(() => {
    return articles.value;
  });

  const allArticlesWithNotifications: ComputedRef<KnowledgeArticleSummary[]> = computed(() => {
    return articles.value.filter(a => a.notifications != null);
  });

  const kbNotificationCount: ComputedRef<number> = computed(() => {
    let count = 0;

    allArticlesWithNotifications.value.forEach(a => {
      count += a.notifications?.length ? a.notifications.length : 0;
    });

    return count;
  });

  const kbNotificationState: ComputedRef<KnowledgBaseNotificationState> = computed(() => {
    if (allArticlesWithNotifications.value.length > 0) {
      return KnowledgBaseNotificationState.PENDING;
    }

    return KnowledgBaseNotificationState.NONE;
  });

  const articlesForTrack: ComputedRef<(trackId: string) => KnowledgeArticleSummary[]> = computed(() => {
    return (trackId: string) => {
      return articles.value.filter(a => a.trackIds.includes(trackId));
    };
  });

  function setArticles(details: { articles: KnowledgeArticleSummary[]; removeMissing: boolean }): void {
    patchArray(articles.value, details.articles, details.removeMissing);
  }

  function setArticleSubscriptionConfig(config: ArticleSubscriptionConfig): void {
    if (articleSubscriptionConfig.value) {
      patchObject(articleSubscriptionConfig.value as unknown as Record<string, unknown>, asRecord(config), true);
    } else {
      articleSubscriptionConfig.value = config;
    }
  }

  function syncArticleSubscriptionConfig(): void {
    DataWorker.instance().dispatch('KnowledgeBaseSubscriptionConfig/syncArticleSubscriptionConfig');
  }

  async function applyArticleSubscriptionConfig(config: ArticleSubscriptionConfig): Promise<void> {
    await DataWorker.instance().dispatch('KnowledgeBaseSubscriptionConfig/setArticleSubscriptionConfig', config);
    setArticleSubscriptionConfig(config);
  }

  async function setArticlesFetchingMetadata(details: { articles: KnowledgeArticleSummary[]; removeMissing: boolean })
    : Promise<void> {
    const userStore = useUserStore(pinia);
    const token: UserToken | null = userStore.currentTokenIfValid;
    if (token) {
      const wikiId = token.kbWikiId;
      if (wikiId) {
        setArticles({ articles: details.articles, removeMissing: details.removeMissing });
      }
    }
  }

  async function addArticlesToTrack(details: { trackId: string; newArticles: XWikiExistingArticle[] }): Promise<void> {
    const newArticleIds: string[] = details.newArticles.map(a => a.id.replace('.WebHome', ''));
    await DataWorker.instance().dispatch('KnowledgeBase/addArticlesToTrack', details.trackId, newArticleIds);

    // patch locally stored article state with new articles
    const patchedArticles: KnowledgeArticleSummary[] = [];
    const storedArticlesById: Map<string, KnowledgeArticleSummary> = new Map(allArticles.value.map(a => [a.id, a]));

    for (const newArticle of details.newArticles) {
      const articleId = newArticle.id.replace('.WebHome', '');
      const storedArticleForId = storedArticlesById.get(articleId);
      if (storedArticleForId) {
        storedArticleForId.trackIds.push(details.trackId);
        patchedArticles.push(storedArticleForId);
      } else {
        const newSummary: KnowledgeArticleSummary = {
          id: articleId,
          displayName: newArticle.rawTitle,
          trackIds: [details.trackId],
          url: newArticle.xwikiAbsoluteUrl,
          isDeleted: false
        };
        patchedArticles.push(newSummary);
      }
    }

    setArticles({ articles: patchedArticles, removeMissing: false });
  }

  async function removeArticleFromTrack(details: { trackId: string; articleId: string }): Promise<void> {
    await DataWorker.instance().dispatch('KnowledgeBase/removeArticleFromTrack', details.trackId, details.articleId);

    const articleToUpdate = articles.value.find(a => a.id === details.articleId);
    if (articleToUpdate) {
      const index = articleToUpdate.trackIds.indexOf(details.trackId);
      if (index > -1) {
        articleToUpdate.trackIds.splice(index, 1);
      }
      setArticles({ articles: [articleToUpdate], removeMissing: false });
    }
  }

  async function syncArticles(): Promise<void> {
    await DataWorker.instance().dispatch('KnowledgeBase/syncNow');
  }

  async function removeArticleNotification(details: { article: KnowledgeArticleSummary,
    notification: KnowledgeBaseNotification }): Promise<void> {
    const remainingNotifications = details.article.notifications?.filter(n => {
      const found = n.timestamp === details.notification.timestamp && n.type === details.notification.type;
      return !found;
    });
    await DataWorker.instance().dispatch('KnowledgeBase/setArticleNotifications',
      details.article.id,
      remainingNotifications);
  }

  async function clearArticleNotifications(article: KnowledgeArticleSummary): Promise<void> {
    await DataWorker.instance().dispatch('KnowledgeBase/setArticleNotifications',
      article.id,
      []);
  }

  async function clearAllKnowledgeBaseNotifications(): Promise<void> {
    await DataWorker.instance().dispatch('KnowledgeBase/clearAllKnowledgeBaseNotifications');
  }

  return {
    articles,
    articleSubscriptionConfig,
    allArticles,
    allArticlesWithNotifications,
    kbNotificationCount,
    kbNotificationState,
    articlesForTrack,
    setArticles,
    setArticleSubscriptionConfig,
    syncArticleSubscriptionConfig,
    applyArticleSubscriptionConfig,
    setArticlesFetchingMetadata,
    addArticlesToTrack,
    removeArticleFromTrack,
    syncArticles,
    removeArticleNotification,
    clearArticleNotifications,
    clearAllKnowledgeBaseNotifications
  };
});
