import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

export const usePendoStore = defineStore('Pendo', () => {
  const initData: Ref<unknown> = ref(undefined);

  const pendoInitData: ComputedRef<unknown> = computed(() => {
    return initData.value;
  });

  function setInitData(pendoInitData: unknown): void {
    initData.value = pendoInitData;
  }

  return {
    pendoInitData,
    setInitData
  };
});
