import { perfLog, perfMsg } from '../log/PerformanceLog';

// This tells the brower that the script is a low priority (not needed immediately).
// As soon as the script is downloaded, it is added to the DOM.
export function niceLoadScript(url: string, onLoad: (ev: Event) => void, id?: string): void {
  const preloadStart: number = performance.now();
  const preload = document.createElement('link');
  preload.href = url;
  preload.rel = 'preload';
  preload.as = 'script';
  preload.addEventListener('load', (ev: Event) => {
    perfLog.debug(perfMsg(`Script preloaded (${url})`, false, preloadStart));
    const script = document.createElement('script');
    if (id) {
      script.id = id;
    }
    script.src = (ev.target as HTMLLinkElement).href;
    const loadStart: number = performance.now();
    script.addEventListener('load', () => {
      perfLog.debug(perfMsg(`Script loaded (${url})`, false, loadStart));
    });
    if (onLoad) {
      script.addEventListener('load', onLoad);
    }
    document.head.appendChild(script);
  });
  document.head.appendChild(preload);
}
