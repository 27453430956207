import { Location } from 'vue-router';

import { ChatMessage } from '@/data/datatypes/chat/ChatMessage';
import { AutocompleteItemType } from '@/data/datatypes/components/AutocompleteItem';
import { CustomNotification, Track, TrackActivity, TrackType } from '@/data/datatypes/Track';
import { TabInfo } from '@/data/datatypes/UI/TabbedNavigation';
import { LimitedUserDetails } from '@/data/datatypes/UserDetails';
import Features from '@/data/Features';
import { UIFlow } from '@/data/tasks/UIFlow';
import RouteNames from '@/router/RouteNames';
import { useTasksStore } from '@/stores/Tasks';
import { useUserStore } from '@/stores/User';

export enum TabType {
  DEFAULT = 'DEFAULT',
  APP = 'APP',
  BREAKOUT = 'BREAKOUT',
  PUBLISH_ROOM = 'PUBLISH_ROOM',
  UI_FLOW = 'UI_FLOW',
}

export enum TabNames {
  OVERVIEW = 'Overview',
  MESSAGES = 'Messages',
  ASSETS = 'Assets',
  MEETINGS = 'Meetings',
  TASKS = 'Tasks',
  HISTORY = 'History',
  APP = 'App',
  OVERFLOW = 'More',
}

function getFormattedLastChatMessage(track: Track, displayDetailsForUsers?: Map<string, LimitedUserDetails>):
    string | null {
  let chatDisplayMsg = '';
  if (track.lastChatMessage) {
    chatDisplayMsg = getFormattedChatMessage(track.lastChatMessage, displayDetailsForUsers);
  }
  return chatDisplayMsg;
}

/**
 * Returns the content of the provided {@link ChatMessage}, prefixed with the sender's name, if available.
 * @param message
 * @param displayDetailsForUsers
 */
function getFormattedChatMessage(message: ChatMessage, displayDetailsForUsers?: Map<string, LimitedUserDetails>):
  string {
  let chatDisplayMsg = '';
  if (displayDetailsForUsers && message.senderId) {
    const userDetails: LimitedUserDetails | undefined = displayDetailsForUsers.get(message.senderId);
    chatDisplayMsg = userDetails ? `${userDetails.displayName}: ` : '';
  } else if (message.senderName) {
    chatDisplayMsg = `${message.senderName}: `;
  }
  if (message.chatMessageContent.plain) {
    chatDisplayMsg = `${chatDisplayMsg}${message.chatMessageContent.plain}`;
  }
  return chatDisplayMsg;
}

function getFormattedThreadChatMessage(threadActivity: CustomNotification,
  displayDetailsForUsers?: Map<string, LimitedUserDetails>):
    string | null {
  let chatDisplayMsg = '';
  if (threadActivity.message) {
    if (displayDetailsForUsers && threadActivity.userId) {
      const userDetails: LimitedUserDetails | undefined = displayDetailsForUsers.get(threadActivity.userId);
      chatDisplayMsg = userDetails ? `${userDetails.displayName}: ` : '';
    } else if (threadActivity.userName) {
      chatDisplayMsg = `${threadActivity.userName}: `;
    }
    if (threadActivity.message) {
      chatDisplayMsg = `${chatDisplayMsg}${threadActivity.message}`;
    }
  }

  return chatDisplayMsg;
}

function getLastChatSender(track: Track, displayDetailsForUsers?: Map<string, LimitedUserDetails>):
                              LimitedUserDetails | undefined {
  if (track.lastChatMessage) {
    if (displayDetailsForUsers && track.lastChatMessage.senderId) {
      return displayDetailsForUsers.get(track.lastChatMessage.senderId);
    }
  }

  return undefined;
}

function getLastChatDate(track: Track): number | null {
  return track.lastChatMessage?.date ?? null;
}

function recentActivitySort(a: TrackActivity, b: TrackActivity): number {
  const aDate: number = a?.date || 0;
  const bDate: number = b?.date || 0;

  return bDate - aDate;
}

function getEmailFromSearchResult(searchResult: AutocompleteItemType): string {
  if (searchResult) {
    return searchResult.itemText;
  }
  return '';
}

function getMeetingMonth(time: number): string {
  return new Date(time).toLocaleString(undefined,
    { month: 'short' });
}

function getMeetingDay(time: number): string {
  return new Date(time).toLocaleString(undefined,
    { day: '2-digit' });
}

function getTime(time: number): string {
  return new Date(time).toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' });
}

function getMeetingTime(start: number, end: number): string {
  return getTime(start) + ' - ' + getTime(end);
}

function getMeetingDuration(start: number, end: number): string {
  const meetingStartDate = getMeetingMonth(start) + ' ' + getMeetingDay(start);
  const meetingEndDate = getMeetingMonth(end) + ' ' + getMeetingDay(end);

  if (meetingStartDate === meetingEndDate) {
    return getMeetingMonth(start) + ' ' + getMeetingDay(start) + ' (' + getMeetingTime(start, end) + ')';
  }

  return getMeetingMonth(start) + ' ' + getMeetingDay(start) + ' (' + getTime(start) + ')' + ' - ' +
    getMeetingMonth(end) + ' ' + getMeetingDay(end) + ' (' + getTime(end) + ')';
}

function formatNotificationCount(num?: number): string | null {
  if (!num) {
    return null;
  }
  if (num > 99) {
    return '99+';
  }
  return num.toString();
}

function isTemplateOrChildOfTemplate(track: Track, allTracks: Record<string, Track>): boolean {
  if (track.type === TrackType.TEMPLATE) {
    return true;
  } else if (track.parentTrackId) {
    const parentTrack: Track | undefined = allTracks[track.parentTrackId];
    if (parentTrack?.type === TrackType.TEMPLATE) {
      return true;
    }
  }
  return false;
}

function getRouteForTabInfo(trackId: string, tabInfo?: TabInfo): Location {
  let routeName: string = RouteNames.TRACK_DASHBOARD_ROUTE_NAME;
  const params: Record<string, string> = { trackId };
  if (tabInfo) {
    switch (tabInfo.type) {
      case 'DEFAULT':
        routeName = getRouteNameForDefaultTabType(tabInfo.tabId);
        break;
      case 'APP':
        routeName = RouteNames.TRACK_APP_ROUTE_NAME;
        params.appId = tabInfo.tabId;
        break;
      case 'UI_FLOW':
        routeName = RouteNames.TRACK_UI_FLOW_ROUTE_NAME;
        params.uiFlowId = tabInfo.tabId;
        break;
      case 'BREAKOUT':
        routeName = RouteNames.TRACK_DEFAULT_ROUTE_NAME;
        params.trackId = tabInfo.tabId;
        break;
      case 'PUBLISH_ROOM':
        routeName = RouteNames.TRACK_DEFAULT_ROUTE_NAME;
        params.trackId = tabInfo.tabId;
        break;
      default:
        routeName = RouteNames.TRACK_DASHBOARD_ROUTE_NAME;
    }
  }
  return {
    name: routeName,
    params,
  };
}

function getFirstDisplayableTab(tabs?: TabInfo[], skipWait?: boolean): { tab: TabInfo, wait?: boolean } | undefined {
  if (!tabs?.length) {
    return;
  }
  const userStore = useUserStore();
  for (const tab of tabs) {
    const tasksStore = useTasksStore();
    switch (tab.type) {
      case TabType.DEFAULT:
        // If the history tab is first, then we need to check that the user has permission to view it
        if (tab.tabId !== TabNames.HISTORY ||
          (userStore.isUserFeatureEnabled(Features.TRACK_HISTORY) ?? false)) {
          return { tab };
        }
        break;

      case TabType.APP:
        return { tab };

      case TabType.UI_FLOW:
        // UI flow tabs are awkward as the ISOLATED view doesn't allow navigation to the track, so we can't default to
        // that. To know if it's ISOLATED, we need the Pinia data to load first.
        // eslint-disable-next-line no-case-declarations
        const uiFlows: UIFlow[] = tasksStore.uiFlows;
        // eslint-disable-next-line no-case-declarations
        const uiFlow: UIFlow | undefined = uiFlows?.find((uiFlow: UIFlow) => uiFlow.id === tab.tabId);
        if (uiFlow) {
          const linkAway: boolean = doesUiFlowNavigateAway(uiFlow);

          // If the tab links away from the workspace, then we can't use it as the default view as the workspace will
          // become inaccessible
          if (!linkAway) {
            return { tab };
          }
        }
        // If we don't know about the UI tab then return a wait instruction, unless the caller specifies that it can't
        // wait any longer.
        if (!uiFlow && !skipWait) {
          return { tab, wait: true };
        }
        break;
    }
  }
}

function doesUiFlowNavigateAway(uiFlow: UIFlow): boolean {
  return uiFlow.config.includes('mode: ISOLATED') || uiFlow.config.includes('mode: STANDALONE');
}

function getRouteNameForDefaultTabType(tabId: string) {
  switch (tabId ?? '') {
    case TabNames.OVERVIEW:
      return RouteNames.TRACK_DASHBOARD_ROUTE_NAME;
    case TabNames.MESSAGES:
      return RouteNames.TRACK_CHAT_ROUTE_NAME;
    case TabNames.MEETINGS:
      return RouteNames.TRACK_MEETINGS_ROUTE_NAME;
    case TabNames.ASSETS:
      return RouteNames.TRACK_ENTRIES_ROUTE_NAME;
    case TabNames.TASKS:
      return RouteNames.TRACK_TASKS_ROUTE_NAME;
    case TabNames.HISTORY:
      return RouteNames.TRACK_HISTORY_ROUTE_NAME;
    default:
      return RouteNames.TRACK_DASHBOARD_ROUTE_NAME;
  }
}

export {
  doesUiFlowNavigateAway,
  formatNotificationCount,
  getEmailFromSearchResult,
  getFirstDisplayableTab,
  getFormattedChatMessage,
  getFormattedLastChatMessage,
  getFormattedThreadChatMessage,
  getLastChatDate,
  getLastChatSender,
  getMeetingDay,
  getMeetingDuration,
  getMeetingMonth,
  getMeetingTime,
  getRouteForTabInfo,
  isTemplateOrChildOfTemplate,
  recentActivitySort,
};
