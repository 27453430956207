import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import EmailConfigTestResults from '@/data/config/EmailConfigTestResults';
import MailAccount from '@/data/config/MailAccount';
import MicrosoftConfiguration from '@/data/config/MicrosoftConfiguration';
import TenantSettings, { OfficeSuiteType } from '@/data/config/TenantSettings';
import ApiKey from '@/data/datatypes/ApiKey';
import { AppIntegration } from '@/data/datatypes/AppIntegration';
import { AuditQuery, AuditQueryResponse, TrackNameResponse } from '@/data/datatypes/AuditEvent';
import { BatchUserInviteResponse } from '@/data/datatypes/BatchUserInviteResponse';
import { CreateAppWorkflow } from '@/data/datatypes/CreateAppWorkflow';
import { OdataConfig, OdataUserFullDetails } from '@/data/datatypes/odata/OData';
import { NewPartnershipRequest } from '@/data/datatypes/permissions/NewPartnershipRequest';
import Partnership from '@/data/datatypes/permissions/Partnership';
import { PartnershipPermissions } from '@/data/datatypes/permissions/PartnershipPermissions';
import { PermissionPresets } from '@/data/datatypes/permissions/PermissionPresets';
import { PermissionsResponse } from '@/data/datatypes/permissions/PermissionsResponse';
import { TenantDefaultPermissionDetails } from '@/data/datatypes/permissions/TenantDefaultPermissionDetails';
import { TenantDefaultPermissions } from '@/data/datatypes/permissions/TenantDefaultPermissions';
import UserGroup, { UserGroupDetails } from '@/data/datatypes/permissions/UserGroup';
import {
  CreatePublishRoomPayload,
  CreatePublishTemplateRequest,
  CreatePublishTemplateResponse,
  LimitedWorkspacePublisherConfiguration,
  TemplateConfigImageDetails,
  TemplateContentUpdateDetails,
  WorkspacePublisherConfiguration,
  WorkspacePublishTemplate,
  WorkspacePublishTemplateConfig,
} from '@/data/datatypes/publishing/publish.types';
import { Recipe } from '@/data/datatypes/Recipe';
import { SamlConfigResponse } from '@/data/datatypes/saml/SamlConfigResponse';
import { SamlIdpConfig } from '@/data/datatypes/saml/SamlIdpConfig';
import { ScimToken } from '@/data/datatypes/scim/ScimToken';
import { ScimTokensResponse } from '@/data/datatypes/scim/ScimTokensResponse';
import Tenant from '@/data/datatypes/Tenant';
import {
  CreateTenantClientAppDetails,
  DeleteTenantClientAppDetails,
  TenantClientApp,
  UpdateTenantClientAppDetails,
} from '@/data/datatypes/TenantClientApp';
import { TenantWebhook } from '@/data/datatypes/TenantWebhook';
import { Track, TrackListRequest, TrackListResponse } from '@/data/datatypes/Track';
import TrackLabel from '@/data/datatypes/TrackLabel';
import { TenantUserDetails } from '@/data/datatypes/UserDetails';
import DataWorker from '@/data/storage/DataWorker';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';
import { useTracksStore } from '@/stores/Tracks';

export const useTenantsStore = defineStore('Tenants', () => {
  const tenants: Ref<Record<string, Tenant>> = ref({});
  const publishers: Ref<LimitedWorkspacePublisherConfiguration[]> = ref([]);
  const publishTemplates: Ref<WorkspacePublishTemplate[]> = ref([]);
  const actionWorkflows: Ref<Recipe[]> = ref([]);
  const appWorkflows: Ref<Record<string, Recipe[]>> = ref({});
  const triggerableAppWorkflows: Ref<Record<string, Recipe[]>> = ref({});

  const allTenants: ComputedRef<Tenant[]> = computed(() => {
    return Object.values(tenants.value);
  });

  const allAppWorkflows: ComputedRef<Record<string, Recipe[]>> = computed(() => {
    return appWorkflows.value;
  });

  const allTriggerableAppWorkflows: ComputedRef<Record<string, Recipe[]>> = computed(() => {
    return triggerableAppWorkflows.value;
  });

  const tenantForMeetingTrack: ComputedRef<Tenant | undefined> = computed(() => {
    // Note reference to tracks store here rather than at store scope. This is to avoid the circular dependency issue
    // when both stores reference each other at the wider scope.
    const tracksStore = useTracksStore();
    const meetingTrack: Track | undefined = tracksStore.meetingTrack;
    if (meetingTrack) {
      return tenants.value[meetingTrack.tenantId];
    }
    return undefined;
  });

  function setTenants(details: { tenants: Tenant[]; fullRefresh: boolean }): void {
    // If it's a full refresh, then delete any records that aren't in the updated object
    if (details.fullRefresh) {
      const removedTenants: string[] = Object.keys(tenants.value).filter((tenantId: string) => {
        return details.tenants.findIndex((tenant: Tenant) => tenant.id === tenantId) === -1;
      });
      for (const removedId of removedTenants) {
        delete tenants.value[removedId];
      }
    }

    // Patch the tenant data
    for (const tenant of details.tenants) {
      setOrPatchObject(tenants.value, tenant.id, asRecord(tenant));
    }
  }

  async function refreshActivePiecesActionAppWorkflows(appId: string): Promise<void> {
    const workflows: Recipe[] = await DataWorker.instance().dispatch(
      'Tenants/refreshActivePiecesAppWorkflows', appId);
    setAppWorkflows({ appId, workflows });
  }

  async function refreshActivePiecesTriggerableActionAppWorkflows(appId: string,
    guestId?: string): Promise<void> {
    const workflows: Recipe[] = await DataWorker.instance().dispatch(
      'Tenants/refreshActivePiecesTriggerableAppWorkflows', appId, guestId);
    setTriggerableAppWorkflows({ appId, workflows });
  }

  async function createActivePiecesAppWorkflow(createAppWorkflow: CreateAppWorkflow): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/createActivePiecesAppWorkflow', createAppWorkflow);
  }

  async function deleteActivePiecesAppWorkflow(appId: string, workflowId: string): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/deleteActivePiecesAppWorkflow', appId, workflowId);
  }

  async function importAppWorkflow(appId: string, flow: unknown): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/importAppWorkflow', appId, flow);
  }

  function setAppWorkflows(details: { appId: string; workflows: Recipe[] }): void {
    Vue.set(appWorkflows.value, details.appId, details.workflows);
  }

  function setTriggerableAppWorkflows(details: { appId: string; workflows: Recipe[] }): void {
    Vue.set(triggerableAppWorkflows.value, details.appId, details.workflows);
  }

  async function refreshTenants(): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/refreshTenants');
  }

  async function refreshWorkspacePublishers(): Promise<void> {
    const publishers: WorkspacePublisherConfiguration[] = await DataWorker.instance()
      .dispatch('Tenants/getWorkspacePublishers');
    setPublishers(publishers);
  }

  function setPublishers(publishersToSet: WorkspacePublisherConfiguration[]): void {
    publishers.value = publishersToSet;
  }

  async function refreshWorkspacePublishTemplates(): Promise<void> {
    const publishTemplates: WorkspacePublishTemplate[] = await DataWorker.instance()
      .dispatch('Tenants/getPublishTemplates');
    setPublishTemplates(publishTemplates);
  }

  function setPublishTemplates(publishTemplatesToSet: WorkspacePublishTemplate[]): void {
    publishTemplates.value = publishTemplatesToSet;
  }

  async function getPublishTemplateConfig(templateId: string): Promise<WorkspacePublishTemplateConfig> {
    return await DataWorker.instance().dispatch('Tenants/getPublishTemplateConfig', templateId);
  }

  async function updatePublishTemplateConfig(updatedConfig: WorkspacePublishTemplateConfig):
    Promise<WorkspacePublishTemplateConfig> {
    return await DataWorker.instance().dispatch('Tenants/updatePublishTemplateConfig', updatedConfig);
  }

  async function getTemplateContent(templateId: string): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/getTemplateContent', templateId);
  }

  async function updateTemplateContent(payload: TemplateContentUpdateDetails): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/updateTemplateContent', payload);
  }

  async function uploadTemplateConfigImage(payload: TemplateConfigImageDetails): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/uploadTemplateConfigImage', payload);
  }

  // TODO: This shouldn't be here, it makes no sense how we're doing it atm:
  //   * Tracks are created in the Tracks Pinia store
  //   * Rooms are created in the TrackEntries Pinia store
  //   * Breakout rooms are created in the Tenants Pinia store
  async function createPublishRoom(payload: CreatePublishRoomPayload): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/createPublishRoom', payload);
    // TODO: This is done to mirror how rooms work, but we should update immediately based on the server response above
    // Refresh tracks since there should be a new one for the breakout room
    await DataWorker.instance().dispatch('Tracks/refreshTracks');
  }

  async function createPublishTemplate(payload: CreatePublishTemplateRequest): Promise<CreatePublishTemplateResponse> {
    // TODO FEEJ/SI/GILLES This creates a template config too, but doesn't return it. Seems wasteful to refresh all of
    // them here but we'd need to change the response object to get the template config back if we want to add it
    // directly here
    return await DataWorker.instance().dispatch('Tenants/createPublishTemplate', payload);
  }

  async function updateTenantFeatureGrants(details: { tenantId: string, features: string[] }): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tenants/updateTenantFeatureGrants',
      details.tenantId, details.features);
  }

  async function getTenantFeatureGrants(tenantId: string): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantFeatureGrants', tenantId);
  }

  async function createTenantSubwiki(details: { tenantId: string, wikiName: string }): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/createTenantSubwiki',
      details.tenantId, details.wikiName);
  }

  async function updateTenantUserDetails(details: { tenantId: string, user: TenantUserDetails }):
    Promise<TenantUserDetails> {
    return await DataWorker.instance().dispatch('Tenants/updateTenantUserDetails',
      details.tenantId, details.user);
  }

  async function deactivateUser(details: { tenantId: string, userId: string }):
    Promise<TenantUserDetails> {
    return await DataWorker.instance().dispatch('TenantUsers/deactivateUser',
      details.tenantId, details.userId);
  }

  async function reactivateUser(details: { tenantId: string, userId: string }):
    Promise<TenantUserDetails> {
    return await DataWorker.instance().dispatch('TenantUsers/reactivateUser',
      details.tenantId, details.userId);
  }

  async function getTenantUsers(tenantId: string): Promise<TenantUserDetails[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantUsers', tenantId);
  }

  async function getLimitedTenantTracks(tenantId: string, request: TrackListRequest): Promise<TrackListResponse> {
    return await DataWorker.instance().dispatch('Tenants/getLimitedTenantTracks', tenantId, request);
  }

  async function archiveTenantTracks(details: { tenantId: string, trackIds: string[] }): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/archiveTenantTracks', details.tenantId, details.trackIds);
  }

  async function restoreTenantTracks(details: { tenantId: string, trackIds: string[] }): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/restoreTenantTracks', details.tenantId, details.trackIds);
  }

  async function deleteTenantTracks(details: { tenantId: string, trackIds: string[] }): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/deleteTenantTracks', details.tenantId, details.trackIds);
  }

  async function editTenantDefaultPermissions(details: { tenantId: string, request: TenantDefaultPermissions }):
    Promise<TenantDefaultPermissionDetails> {
    return await DataWorker.instance().dispatch('Tenants/editTenantDefaultPermissions',
      details.tenantId, details.request);
  }

  async function getPermissionPresets(tenantId: string): Promise<PermissionPresets> {
    return await DataWorker.instance().dispatch('Tenants/getPermissionPresets', tenantId);
  }

  async function getPermissionsForTenant(tenantId: string): Promise<PermissionsResponse> {
    return await DataWorker.instance().dispatch('Tenants/getPermissionsForTenant', tenantId);
  }

  async function createPartnership(details: { tenantId: string, request: NewPartnershipRequest })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/createPartnership',
      details.tenantId, details.request);
  }

  async function editPartnership(details: { tenantId: string, partnershipId: string,
    request: PartnershipPermissions, pending: boolean }): Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/editPartnership',
      details.tenantId, details.partnershipId, details.request, details.pending);
  }

  async function approvePartnership(details: { tenantId: string, partnershipId: string, pending: boolean })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/approvePartnership',
      details.tenantId, details.partnershipId, details.pending);
  }

  async function rejectPartnership(details: { tenantId: string, partnershipId: string, pending: boolean })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/rejectPartnership',
      details.tenantId, details.partnershipId, details.pending);
  }

  async function acknowledgePartnershipEditRejection(details: { tenantId: string, partnershipId: string })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/acknowledgePartnershipEditRejection',
      details.tenantId, details.partnershipId);
  }

  async function deletePartnership(details: { tenantId: string, partnershipId: string })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/deletePartnership',
      details.tenantId, details.partnershipId);
  }

  async function disablePartnership(details: { tenantId: string, partnershipId: string })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/disablePartnership',
      details.tenantId, details.partnershipId);
  }

  async function enablePartnership(details: { tenantId: string, partnershipId: string })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/enablePartnership',
      details.tenantId, details.partnershipId);
  }

  async function ignorePartnership(details: { tenantId: string, partnershipId: string })
    : Promise<Partnership> {
    return await DataWorker.instance().dispatch('Tenants/ignorePartnership',
      details.tenantId, details.partnershipId);
  }

  async function getSamlConfig(tenantId: string): Promise<SamlConfigResponse> {
    return await DataWorker.instance().dispatch('Tenants/getSamlConfig', tenantId);
  }

  async function updateSamlConfig(details: { tenantId: string, config: SamlIdpConfig }): Promise<SamlConfigResponse> {
    return await DataWorker.instance()
      .dispatch('Tenants/updateSamlConfig', details.tenantId, details.config);
  }

  async function getScimTokens(): Promise<ScimTokensResponse> {
    return await DataWorker.instance().dispatch('Tenants/getScimTokens');
  }

  async function createScimToken(scimToken: ScimToken): Promise<ScimToken> {
    return await DataWorker.instance().dispatch('Tenants/createScimToken', scimToken);
  }

  async function deleteScimToken(id: string): Promise<void> {
    return await DataWorker.instance().dispatch('Tenants/deleteScimToken', id);
  }

  async function createOdataUser(details: {tenantId: string, username: string}): Promise<OdataUserFullDetails> {
    return await DataWorker.instance().dispatch('Tenants/createOdataUser', details.tenantId, details.username);
  }

  async function getOdataUsers(tenantId: string): Promise<OdataConfig> {
    return await DataWorker.instance().dispatch('Tenants/getOdataUsers', tenantId);
  }

  async function deleteOdataUser(details: {tenantId: string, userId: string}): Promise<void> {
    return await DataWorker.instance().dispatch('Tenants/deleteOdataUser', details.tenantId, details.userId);
  }

  async function addTenantWebhook(details: { tenantId: string, webhook: TenantWebhook }): Promise<TenantWebhook> {
    return await DataWorker.instance()
      .dispatch('Tenants/addTenantWebhook', details.tenantId, details.webhook);
  }

  async function deleteTenantWebhook(details: { tenantId: string, id: string }): Promise<void> {
    return await DataWorker.instance().dispatch('Tenants/deleteTenantWebhook', details.tenantId, details.id);
  }

  async function deleteImage(icon: string): Promise<void> {
    return await DataWorker.instance().dispatch('Tenants/deleteImage', icon);
  }

  async function uploadImage(icon: File): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/uploadImage', icon);
  }

  async function uploadVideo(video: File): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/uploadVideo', video);
  }

  async function uploadAudio(audio: File): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/uploadAudio', audio);
  }

  async function updateTenantSettings(
    details: { tenantId: string, mergedSettings: TenantSettings }): Promise<TenantSettings> {
    return await DataWorker.instance().dispatch('Tenants/updateTenantSettings',
      details.tenantId, details.mergedSettings);
  }

  async function checkTenantUsingOwnStorage(tenantId: string): Promise<boolean> {
    return await DataWorker.instance().dispatch('Tenants/checkTenantUsingOwnStorage', tenantId);
  }

  async function checkGoogleInstall(): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/checkGoogleInstall');
  }

  async function getTenantIntegrations(tenantId: string): Promise<AppIntegration[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantIntegrations', tenantId);
  }

  async function getTenantSettings(tenantId: string): Promise<TenantSettings> {
    return await DataWorker.instance().dispatch('Tenants/getTenantSettings', tenantId);
  }

  async function getTenantDomains(tenantId: string): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantDomains', tenantId);
  }

  async function getWorkflowUserId(tenantId: string): Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/getWorkflowUserId', tenantId);
  }

  async function getTenantWebhooks(tenantId: string): Promise<TenantWebhook[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantWebhooks', tenantId);
  }

  async function createApiKey(details: { tenantId: string, apiKey: ApiKey }): Promise<ApiKey> {
    return await DataWorker.instance().dispatch('Tenants/createApiKey',
      details.tenantId, details.apiKey);
  }

  async function deleteApiKey(details: { tenantId: string, apiKey: ApiKey }): Promise<void> {
    return await DataWorker.instance().dispatch('Tenants/deleteApiKey',
      details.tenantId, details.apiKey);
  }

  async function testEmailConfig(details: { tenantId: string, mailEdits: MailAccount,
    officeSuiteType: OfficeSuiteType, microsoftEdits: MicrosoftConfiguration }): Promise<EmailConfigTestResults> {
    return await DataWorker.instance().dispatch('Tenants/testEmailConfig',
      details.tenantId, details.mailEdits, details.officeSuiteType, details.microsoftEdits);
  }

  async function postTenantUsers(details: { tenantId: string, emails: string[] }): Promise<BatchUserInviteResponse> {
    return await DataWorker.instance().dispatch('Tenants/postTenantUsers',
      details.tenantId, details.emails);
  }

  async function updateDefaultTrackTransferLabels(details: { tenantId: string,
    partnershipId: string, labels: TrackLabel[] }): Promise<TrackLabel[]> {
    return await DataWorker.instance().dispatch('Tenants/updateDefaultTrackTransferLabels',
      details.tenantId, details.partnershipId, details.labels);
  }

  async function getAuditEvents(auditQuery: AuditQuery): Promise<AuditQueryResponse> {
    return await DataWorker.instance().dispatch('Tenants/getAuditEvents',
      auditQuery);
  }

  async function getTrackNames(tenantId: string): Promise<TrackNameResponse[]> {
    return await DataWorker.instance().dispatch('Tenants/getTrackNames',
      tenantId);
  }

  async function getTenantClientApps(tenantId: string): Promise<TenantClientApp[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantClientApps', tenantId);
  }

  async function createTenantClientApp(details: CreateTenantClientAppDetails): Promise<TenantClientApp> {
    return await DataWorker.instance().dispatch('Tenants/createTenantClientApp', details);
  }

  async function updateTenantClientApp(details: UpdateTenantClientAppDetails): Promise<TenantClientApp> {
    return await DataWorker.instance().dispatch('Tenants/updateTenantClientApp', details);
  }

  async function deleteTenantClientApp(details: DeleteTenantClientAppDetails): Promise<void> {
    return await DataWorker.instance().dispatch('Tenants/deleteTenantClientApp', details);
  }

  async function getTenantUserGroups(tenantId: string): Promise<UserGroup[]> {
    return await DataWorker.instance().dispatch('Tenants/getTenantUserGroups', tenantId);
  }

  async function createUserGroup(details: { tenantId: string, group: UserGroupDetails }): Promise<UserGroup> {
    return await DataWorker.instance().dispatch('Tenants/createUserGroup',
      details.tenantId, details.group);
  }

  async function updateUserGroup(details: { tenantId: string, group: UserGroup }): Promise<UserGroup> {
    return await DataWorker.instance().dispatch('Tenants/updateUserGroup',
      details.tenantId, details.group);
  }

  async function deleteUserGroup(details: { tenantId: string, groupId: string }): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/deleteUserGroup', details.tenantId, details.groupId);
  }

  async function getGroupFeatures(details: { tenantId: string, groupId: string }): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tenants/getGroupFeatures',
      details.tenantId, details.groupId);
  }

  async function updateGroupFeatures(details: { tenantId: string, groupId: string, features: string[] }):
  Promise<string[]> {
    return await DataWorker.instance().dispatch('Tenants/updateGroupFeatures',
      details.tenantId, details.groupId, details.features);
  }

  async function getUserFeatures(details: { tenantId: string, userId: string }): Promise<string[]> {
    return await DataWorker.instance().dispatch('Tenants/getUserFeatures',
      details.tenantId, details.userId);
  }

  async function updateUserFeatures(details: { tenantId: string, userId: string, features: string[] })
    : Promise<string> {
    return await DataWorker.instance().dispatch('Tenants/updateUserFeatures',
      details.tenantId, details.userId, details.features);
  }

  async function moveTracksToApp(details: { tenantId: string, miniAppId: string, trackIds: string[] }): Promise<void> {
    await DataWorker.instance().dispatch('Tenants/moveTracksToApp', details.tenantId, details.miniAppId,
      details.trackIds);
  }

  return {
    tenants,
    publishers,
    publishTemplates,
    actionWorkflows,
    allTenants,
    allAppWorkflows,
    allTriggerableAppWorkflows,
    tenantForMeetingTrack,
    setTenants,
    refreshActivePiecesActionAppWorkflows,
    refreshActivePiecesTriggerableActionAppWorkflows,
    createActivePiecesAppWorkflow,
    deleteActivePiecesAppWorkflow,
    importAppWorkflow,
    refreshTenants,
    refreshWorkspacePublishers,
    refreshWorkspacePublishTemplates,
    getPublishTemplateConfig,
    updatePublishTemplateConfig,
    getTemplateContent,
    updateTemplateContent,
    uploadTemplateConfigImage,
    createPublishRoom,
    createPublishTemplate,
    updateTenantFeatureGrants,
    getTenantFeatureGrants,
    createTenantSubwiki,
    updateTenantUserDetails,
    deactivateUser,
    reactivateUser,
    getTenantUsers,
    getLimitedTenantTracks,
    archiveTenantTracks,
    restoreTenantTracks,
    deleteTenantTracks,
    editTenantDefaultPermissions,
    getPermissionPresets,
    getPermissionsForTenant,
    createPartnership,
    editPartnership,
    approvePartnership,
    rejectPartnership,
    acknowledgePartnershipEditRejection,
    deletePartnership,
    disablePartnership,
    enablePartnership,
    ignorePartnership,
    getSamlConfig,
    updateSamlConfig,
    getScimTokens,
    createScimToken,
    deleteScimToken,
    createOdataUser,
    getOdataUsers,
    deleteOdataUser,
    addTenantWebhook,
    deleteTenantWebhook,
    deleteImage,
    uploadImage,
    uploadVideo,
    uploadAudio,
    updateTenantSettings,
    checkTenantUsingOwnStorage,
    checkGoogleInstall,
    getTenantIntegrations,
    getTenantSettings,
    getTenantDomains,
    getWorkflowUserId,
    getTenantWebhooks,
    createApiKey,
    deleteApiKey,
    testEmailConfig,
    postTenantUsers,
    updateDefaultTrackTransferLabels,
    getAuditEvents,
    getTrackNames,
    getTenantClientApps,
    createTenantClientApp,
    updateTenantClientApp,
    deleteTenantClientApp,
    getTenantUserGroups,
    createUserGroup,
    updateUserGroup,
    deleteUserGroup,
    getGroupFeatures,
    updateGroupFeatures,
    getUserFeatures,
    updateUserFeatures,
    moveTracksToApp
  };
});
