import axios, { AxiosRequestConfig } from 'axios';
import log from 'loglevel';

import { ArticleAuthorizationConfig } from '@/data/datatypes/knowledgemgmt/ArticleAuthorizationConfig';
import XWikiArticle from '@/data/kb/XWikiArticle';
import XWikiCreatePageResponse from '@/data/kb/XWikiCreatePageResponse';
import XWikiExistingArticle from '@/data/kb/XWikiExistingArticle';
import XWikiSearchResult from '@/data/kb/XWikiSearchResult';

import { ArticleSubscription } from '../datatypes/knowledgemgmt/ArticleSubscription';
import ArticleSubscriptionConfig from '../datatypes/knowledgemgmt/ArticleSubscriptionConfig';

// Set up API request config
function axiosContext(): AxiosRequestConfig {
  return {
    withCredentials: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json'
    },
  };
}

/**
 * Creates a page in the specified subwiki. More specifically, this creates a space with
 * the specified 'pageName', and a page within that new space named 'WebHome'. This is how
 * XWiki generally implements pages as spaces.
 * Returns an object with the HTTP status code and for successful requests the new page details.
 * A 201 code indicates success; a 304 indicates the page already exists.
 */
async function createPage(baseUrl: string, subwikiId: string, pageName: string): Promise<XWikiCreatePageResponse> {
  const payload: XWikiArticle = { majorVersion: 1, minorVersion: 0, title: pageName };
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/wikis/${subwikiId}/spaces/${pageName}/pages/WebHome`;
  try {
    const response = await axios.put(url, payload, context);
    return {
      statusCode: response.status,
      newPage: response.data as XWikiExistingArticle,
    };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      // not necessarily an error condition: eg a 304 is expected if an article already exists
      log.debug(`Failed to create knowledge article, status code was: ${error.response.status}.`);
      return { statusCode: error.response.status };
    }
  }
  log.error('Failed to create knowledge article, no response.');
  return {};
}

async function getSpace(baseUrl: string, subwikiId: string, spaceId: string): Promise<XWikiExistingArticle> {
  const spacePath = getSpacePath(spaceId);
  const url = `${baseUrl}/rest/wikis/${subwikiId}/spaces/${spacePath}`;
  return getSpaceByUrl(url);
}

async function getSpaceByUrl(url: string): Promise<XWikiExistingArticle> {
  const context: AxiosRequestConfig = axiosContext();
  const response = await axios.get(url, context);
  return response.data as XWikiExistingArticle;
}

async function getSpaceWebHome(baseUrl: string, subwikiId: string, spaceId: string): Promise<XWikiExistingArticle> {
  const context: AxiosRequestConfig = axiosContext();
  const spacePath = getSpacePath(spaceId);

  const url = `${baseUrl}/rest/wikis/${subwikiId}/spaces/${spacePath}/pages/WebHome`;
  const response = await axios.get(url, context);
  return response.data as XWikiExistingArticle;
}

function getSpacePath(spaceId: string): string {
  const spaceName = spaceId.substring(spaceId.lastIndexOf(':') + 1);

  // A space ID can contain dots ('.') that we need to convert to a space URL component ('/spaces/')
  // for the "GET space" request. The exception to this is the '\.' pattern, which is an escaped
  // dot that forms part of the name, in that case we have to just remove the slash.
  // A regex "negative lookbehind" would be ideal here ("replace(/(?<!\\)\./g, '/spaces/')") but
  // unfortunately Safari doesn't support negative lookbehinds. So the method we're using is:
  //    1) Replace all '.' with '/spaces/', then
  //    2) Replace all '\/spaces/' with '.'
  let spacePath = spaceName.replace(/\./g, '/spaces/');
  spacePath = spacePath.replace(/\\\/spaces\//g, '.');
  return spacePath;
}

async function getAllWebHomes(baseUrl: string, subwikiId: string): Promise<XWikiSearchResult[]> {
  const context: AxiosRequestConfig = axiosContext();
  // The following query finds all 'WebHome' pages in the subwiki, filtering out hidden
  // pages. The language clause is in the query to filter out translations - the 'Main' page for
  // example has a bunch of translations by default, which for now we don't want to return
  // here. If in the future we support translated pages we will have to revisit this.
  const query = 'where doc.name = \'WebHome\' AND doc.hidden = 0 AND doc.language = \'\'';
  const url = `${baseUrl}/rest/wikis/${subwikiId}/query?q=${query}&type=xwql`;
  const response = await axios.get(url, context);
  return response.data.searchResults as XWikiSearchResult[];
}

async function getWebhomesForSearchString(baseUrl: string, subwikiId: string, searchTerm: string,
  ownerIds: string[] | undefined, after: string | undefined, before: string | undefined):
  Promise<XWikiExistingArticle[]> {
  const context: AxiosRequestConfig = axiosContext();
  const searchEncoded = encodeURIComponent(searchTerm.toUpperCase());
  let query =
    'where doc.name = \'WebHome\' AND doc.hidden = 0 AND doc.language = \'\' AND doc.space <> \'Sandbox\' AND' +
    `(upper(doc.title) like '%25${searchEncoded}%25' OR upper(doc.content) like '%25${searchEncoded}%25')`;
  if (ownerIds?.length) {
    query += ' AND (' + ownerIds.map(id => 'doc.author = \'XWiki.' + id + '\'').join(' OR ') + ')';
  }
  if (after) {
    query += ' AND doc.creationDate >= \'' + after + '\'';
  }
  if (before) {
    query += ' AND doc.creationDate <= \'' + before + '\'';
  }
  const url = `${baseUrl}/rest/wikis/${subwikiId}/query?q=${query}&type=xwql`;
  const response = await axios.get(url, context);
  const results = response.data.searchResults as XWikiSearchResult[];
  const articles: XWikiExistingArticle[] = [];
  for (const result of results) {
    const articleLink = result.links.find(link => link.rel === 'http://www.xwiki.org/rel/page')?.href;
    if (articleLink) {
      const articleResponse = await axios.get(articleLink, context);
      const article = articleResponse.data as XWikiExistingArticle;
      // Strip comments, macros and markup
      const lines: string[] | undefined = article.content?.split('\n');
      // Lines that start with a # could be comments or part of a macro, both can be removed
      if (lines) {
        const filteredLines = lines.filter(line => !line.startsWith('#'))
          .map(stripHeadingTags);
        const filteredText = filteredLines.join('\n');
        // Escaped macro tags aren't matched because the { characters are escaped separately e.g. ~{~{
        const macroTagRegex = /{{([^\s}]+)[^}]*}}/g;
        const tags: RegExpMatchArray[] = [...filteredText.matchAll(macroTagRegex)];
        let nextChar = 0;
        let trimmedText = '';
        while (tags.length) {
          const startTag = tags.shift();
          if (startTag && startTag[1]) {
            let closingTag = tags.shift();
            while (closingTag?.[1] && closingTag[1] !== '/' + startTag[1]) {
              closingTag = tags.shift();
            }
            if (closingTag?.index) {
              const tagStart = startTag.index;
              const tagEnd = closingTag.index + closingTag[0].length;
              trimmedText += filteredText.slice(nextChar, tagStart);
              nextChar = tagEnd + 1;
            }
          }
        }
        if (nextChar < filteredText.length) {
          trimmedText += filteredText.substring(nextChar);
        }
        article.content = stripWikiSyntaxTags(trimmedText);
      }
      articles.push(article);
    }
  }
  return articles;
}

function stripHeadingTags(line: string): string {
  // If a line starts with 1 or more = characters, remove the starting = characters
  // and also remove the next, unescaped, contiguous block of = characters
  if (line.startsWith('=')) {
    let start = 1;
    while (line.at(start) === '=') {
      ++start;
    }
    let closing = line.indexOf('=', start);
    while (closing !== -1 && line.at(closing - 1) === '~') {
      closing = line.indexOf('=', closing);
    }
    if (closing === -1) {
      closing = line.length;
    }
    let afterClose = closing + 1;
    while (line.length > afterClose && line.at(afterClose) === '=') {
      ++afterClose;
    }
    if (closing !== -1) {
      return line.substring(start, closing) + line.substring(afterClose);
    }
    return line.substring(start);
  }
  return line;
}

function stripWikiSyntaxTags(value: string): string {
  // Strip links
  const linkRegex = /\[\[(.+?)>>.+?\]\]/g;
  const links = [...value.matchAll(linkRegex)];
  let result = '';
  let nextChar = 0;
  for (const link of links) {
    if (link[1] && link[0] && link.index !== undefined) {
      result += value.substring(nextChar, link.index);
      result += link[1];
      nextChar = link.index + link[0].length;
    }
  }
  result += value.substring(nextChar);
  // Strip (% class %) tags
  const classRegex = /\(%.+?%\)/g;
  // Strip unambiguous macro tags
  result = result
    .replaceAll('//', '')
    .replaceAll('(((', '')
    .replaceAll(')))', '')
    .replaceAll(classRegex, '');
  // Strip escapes
  result = result
    .replaceAll('~=', '=')
    .replaceAll('~/', '/')
    .replaceAll('~(', '(')
    .replaceAll('~)', ')')
    .replaceAll('~{', '{')
    .replaceAll('~>', '>')
    .replaceAll('~~', '~')
    .replaceAll('~[', '[');

  return result;
}

async function getArticlePermissions(baseUrl: string, articleId: string): Promise<ArticleAuthorizationConfig> {
  articleId = encodeURIComponent(articleId);
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/authz/${articleId}`;
  const response = await axios.get(url, context);
  return response.data as ArticleAuthorizationConfig;
}

async function updateArticlePermissions(baseUrl: string, articleId: string, updatedConfig: ArticleAuthorizationConfig)
  : Promise<void> {
  articleId = encodeURIComponent(articleId);
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/authz/${articleId}`;
  const response = await axios.put(url, updatedConfig, context);

  if (response.status !== 204) {
    throw new Error(`Unable to update Knowledge Article permissions: [${response.status}] - ${response.data}`);
  }
}

async function getSubscriptions(baseUrl: string): Promise<ArticleSubscription[]> {
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/notifications/subscriptions`;
  const response = await axios.get(url, context);
  return response.data as ArticleSubscription[];
}

async function getSubscriptionConfig(baseUrl: string): Promise<ArticleSubscriptionConfig> {
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/notifications/configuration`;
  const response = await axios.get(url, context);
  return response.data as ArticleSubscriptionConfig;
}

async function updateSubscriptionConfig(baseUrl: string, config: ArticleSubscriptionConfig): Promise<void> {
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/notifications/configuration`;
  const response = await axios.put(url, config, context);

  if (response.status !== 204) {
    throw new Error(`Unable to update Knowledge Article Subscription Config: [${response.status}] - ${response.data}`);
  }
}

async function updateSubscription(baseUrl: string, subscription: ArticleSubscription): Promise<void> {
  const pageId = encodeURIComponent(subscription.pageId);
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/notifications/subscriptions/${pageId}`;
  const response = await axios.put(url, subscription, context);

  if (response.status !== 204) {
    throw new Error(`Unable to update Knowledge Article Subscription state: [${response.status}] - ${response.data}`);
  }
}

async function clearSubscriptions(baseUrl: string): Promise<void> {
  const context: AxiosRequestConfig = axiosContext();
  const url = `${baseUrl}/rest/fender/notifications/subscriptions`;
  await axios.delete(url, context);
}

export {
  clearSubscriptions,
  createPage,
  getAllWebHomes,
  getArticlePermissions,
  getSpace,
  getSpaceByUrl,
  getSpaceWebHome,
  getSubscriptionConfig,
  getSubscriptions,
  getWebhomesForSearchString,
  updateArticlePermissions,
  updateSubscription,
  updateSubscriptionConfig,
};
