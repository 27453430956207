interface Window {
  webkit: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
declare let window: Window;

export default class IosNative {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static invokeNative(method: string, args: any = null): boolean {
    if (window.webkit) {
      if (window.webkit.messageHandlers[method]) {
        window.webkit.messageHandlers[method].postMessage(args);
        return true;
      }
    }
    return false;
  }
}
