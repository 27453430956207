import { defineStore } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';
import { Route } from 'vue-router';

export const useRouteStore = defineStore('Route', () => {
  const currentRoute: Ref<Route | null> = ref(null);

  const route: ComputedRef<Route | null> = computed(() => {
    return currentRoute.value;
  });

  function routeChanged(newRoute: Route) {
    currentRoute.value = newRoute;
  }

  return {
    route,
    routeChanged
  };
});
