import UserToken from '@/data/datatypes/UserToken';

export interface GetUserDetailsAsGuestPayload {
  userId: string;
  trackId: string;
  guestId: string;
}

type GuestTokenRequestBase = {
  // Setting ignoreCachedToken to true will cause a token request to attempt to get a new token
  // from the server, even if there is a guest token for the track already available in the cache.
  // In some situations (e.g. updating a guest online state), the guest token will be valid in terms of
  // its expiry, but the server won't like it because the guest hasn't been marked as online recently
  // (the interval within which a guest must continue to use their token is much less than the expiry
  // duration of the token)
  ignoreCachedToken?: boolean;
  userAuthenticated: boolean;
  lastGuestId?: string;
}

export enum GuestTokenRequestType {
  USER_TRACK = 'user',
  DEFAULT = 'default',
  APP = 'app'
}

// If we re-introduce the use of per-user tracks, then we won't know the ID when requesting a token,
// But will know the domain of the tenant and the user part of the email
type UserTrackGuestTokenRequest = GuestTokenRequestBase & {
  type: GuestTokenRequestType.USER_TRACK;
  domain: string;
  user: string;
}

type DefaultGuestTokenRequest = GuestTokenRequestBase & {
  type: GuestTokenRequestType.DEFAULT;
  entryId: string;
  trackId: string;
}

type AppGuestTokenRequest = GuestTokenRequestBase & {
  type: GuestTokenRequestType.APP;
  trackId: string;
}

/** Request content for a guest needing a token for a track or an entry */
export type GuestTokenRequest = UserTrackGuestTokenRequest | DefaultGuestTokenRequest | AppGuestTokenRequest;

export interface GuestTokenResponse {
  token: UserToken;
  dataSyncRequired: boolean;
}
