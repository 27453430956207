import anchorme from 'anchorme';

export const validateUrl = (url: string): boolean => {
  // anchorme.validate.url has been seen to hang if it detects text containing unescaped parentheses as a URL
  let toValidate: string = url;
  if (toValidate?.includes('(') || toValidate?.includes(')')) {
    toValidate = toValidate.replaceAll('(', '%28').replaceAll(')', '%29');
  }
  return anchorme.validate.url(toValidate);
};
