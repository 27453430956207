import {
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears
} from 'date-fns';

import { AttributeType } from '@/data/tasks/Attribute';
import { FormulaResult, Operator } from '@/data/tasks/Formula';

export enum FunctionName {
  MIN = 'MIN',
  MAX = 'MAX',
  IF = 'IF',
  AVERAGE = 'AVERAGE',
  CEILING = 'CEILING',
  FLOOR = 'FLOOR',
  TRIM = 'TRIM',
  UPPER = 'UPPER',
  LOWER = 'LOWER',
  PROPER = 'PROPER',
  SUBSTITUTE = 'SUBSTITUTE',
  NOT = 'NOT',
  DATEADD = 'DATEADD',
  DATEDIFF = 'DATEDIFF',
  TODAY = 'TODAY',
}

export enum TimeUnit {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

abstract class OperatorHandler {
  constructor(returnType: AttributeType) {
    this.returnType = returnType;
  }

  returnType: AttributeType;

  abstract resolve(leftOperand: string, rightOperand: string): string;
}

abstract class NumericalCalculationHandler extends OperatorHandler {
  resolve(leftOperand: string, rightOperand: string): string {
    return String(this.calculate(Number.parseFloat(leftOperand), Number.parseFloat(rightOperand)));
  }

  abstract calculate(leftOperand: number, rightOperand: number): number;
}

abstract class ComparisonHandler extends OperatorHandler {
  constructor() {
    super(AttributeType.CHECKBOX);
  }
}

abstract class NumericalComparisonHandler extends ComparisonHandler {
  resolve(leftOperand: string, rightOperand: string): string {
    return this.compare(Number.parseFloat(leftOperand), Number.parseFloat(rightOperand)) ? 'true' : '';
  }

  abstract compare(leftOperand: number, rightOperand: number): boolean
}

abstract class DateComparisonHandler extends ComparisonHandler {
  resolve(leftOperand: string, rightOperand: string): string {
    return this.compare(new Date(leftOperand), new Date(rightOperand)) ? 'true' : '';
  }

  abstract compare(leftOperand: Date, rightOperand: Date): boolean
}

abstract class BooleanOperationHnadler extends ComparisonHandler {
  resolve(leftOperand: string, rightOperand: string): string {
    return this.apply(!!leftOperand, !!rightOperand) ? 'true' : '';
  }

  abstract apply(leftOperand: boolean, rightOperand: boolean): boolean
}

const STRINGS_EQUAL_HANDLER = new class extends ComparisonHandler {
  resolve(leftOperand: string, rightOperand: string): string {
    return leftOperand === rightOperand ? 'true' : '';
  }
}();

const STRINGS_NOT_EQUAL_HANDLER = new class extends ComparisonHandler {
  resolve(leftOperand: string, rightOperand: string): string {
    return leftOperand !== rightOperand ? 'true' : '';
  }
}();

class TextConcetenationHandler extends OperatorHandler {
  private leftType: AttributeType;
  private rightType: AttributeType;

  constructor(leftType: AttributeType, rightType: AttributeType) {
    super(AttributeType.TEXT);
    this.leftType = leftType;
    this.rightType = rightType;
  }

  resolve(leftOperand: string, rightOperand: string): string {
    const leftCast = castHandlerTable[this.leftType]?.[AttributeType.TEXT];
    const rightCast = castHandlerTable[this.rightType]?.[AttributeType.TEXT];
    if (!leftCast) {
      throw new Error('Unable to convert a ' + this.leftType + ' to text');
    }
    if (!rightCast) {
      throw new Error('Unable to convert a ' + this.rightType + ' to text');
    }
    return leftCast(leftOperand) + rightCast(rightOperand);
  }
}

/**
 * A tree of handlers organised by operator, left operand type, right operand type.
 *
 * e.g. '+'.number.text is the handler for an expression like: 24 + 'sometext'
 */
export const operatorHandlerTable:
Partial<Record<Operator, Partial<Record<AttributeType, Partial<Record<AttributeType, OperatorHandler>>>>>> = {
  '+': {
    number: {
      number: new class extends NumericalCalculationHandler {
        calculate(left: number, right: number): number {
          return left + right;
        }
      }(AttributeType.NUMBER),
      percent: new class extends NumericalCalculationHandler {
        calculate(left: number, right: number): number {
          return left + (left * (right / 100));
        }
      }(AttributeType.NUMBER),
      text: new TextConcetenationHandler(AttributeType.NUMBER, AttributeType.TEXT)
    },
    percent: {
      percent: new class extends NumericalCalculationHandler {
        calculate(left: number, right: number): number {
          return left + right;
        }
      }(AttributeType.PERCENT),
      text: new TextConcetenationHandler(AttributeType.PERCENT, AttributeType.TEXT),
    },
    text: {
      text: new TextConcetenationHandler(AttributeType.TEXT, AttributeType.TEXT),
      longText: new TextConcetenationHandler(AttributeType.TEXT, AttributeType.LONG_TEXT),
      number: new TextConcetenationHandler(AttributeType.TEXT, AttributeType.NUMBER),
      percent: new TextConcetenationHandler(AttributeType.TEXT, AttributeType.PERCENT),
      date: new TextConcetenationHandler(AttributeType.TEXT, AttributeType.DATE),
      select: new TextConcetenationHandler(AttributeType.TEXT, AttributeType.SELECT),
    },
    date: {
      text: new TextConcetenationHandler(AttributeType.DATE, AttributeType.TEXT),
      longText: new TextConcetenationHandler(AttributeType.DATE, AttributeType.TEXT),
    },
    select: {
      text: new TextConcetenationHandler(AttributeType.SELECT, AttributeType.TEXT),
      longText: new TextConcetenationHandler(AttributeType.SELECT, AttributeType.TEXT),
    },
    longText: {
      text: new TextConcetenationHandler(AttributeType.LONG_TEXT, AttributeType.TEXT),
      longText: new TextConcetenationHandler(AttributeType.LONG_TEXT, AttributeType.TEXT),
      number: new TextConcetenationHandler(AttributeType.LONG_TEXT, AttributeType.NUMBER),
      percent: new TextConcetenationHandler(AttributeType.LONG_TEXT, AttributeType.PERCENT),
      date: new TextConcetenationHandler(AttributeType.LONG_TEXT, AttributeType.DATE),
      select: new TextConcetenationHandler(AttributeType.LONG_TEXT, AttributeType.SELECT),
    }
  },
  '-': {
    number: {
      number: new class extends NumericalCalculationHandler {
        calculate(left: number, right: number): number {
          return left - right;
        }
      }(AttributeType.NUMBER),
      percent: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand - (leftOperand * (rightOperand / 100));
        }
      }(AttributeType.NUMBER),
    },
    percent: {
      percent: new class extends NumericalCalculationHandler {
        calculate(left: number, right: number): number {
          return left - right;
        }
      }(AttributeType.PERCENT),
    }
  },
  '=': {
    number: {
      number: new class extends NumericalComparisonHandler {
        compare(leftOperand: number, rightOperand: number): boolean {
          // Compare strings with fixed precision to prevent floating point preciision errors
          // from making the values not equal
          return leftOperand.toFixed(10) === rightOperand.toFixed(10) || leftOperand === rightOperand;
        }
      }(),
    },
    checkbox: {
      checkbox: new class extends BooleanOperationHnadler {
        apply(leftOperand: boolean, rightOperand: boolean): boolean {
          return leftOperand === rightOperand;
        }
      }(),
    },
    date: {
      date: new class extends DateComparisonHandler {
        compare(leftOperand: Date, rightOperand: Date): boolean {
          return leftOperand.getTime() === rightOperand.getTime();
        }
      }(),
    },
    text: {
      text: STRINGS_EQUAL_HANDLER,
      longText: STRINGS_EQUAL_HANDLER,
      select: STRINGS_EQUAL_HANDLER,
    },
    longText: {
      text: STRINGS_EQUAL_HANDLER,
      longText: STRINGS_EQUAL_HANDLER,
      select: STRINGS_EQUAL_HANDLER,
    },
    select: {
      text: STRINGS_EQUAL_HANDLER,
      longText: STRINGS_EQUAL_HANDLER,
      select: STRINGS_EQUAL_HANDLER,
    },
  },
  '!=': {
    number: {
      number: new class extends NumericalComparisonHandler {
        compare(leftOperand: number, rightOperand: number): boolean {
          return leftOperand !== rightOperand && leftOperand.toFixed(10) !== rightOperand.toFixed(10);
        }
      }()
    },
    date: {
      date: new class extends DateComparisonHandler {
        compare(leftOperand: Date, rightOperand: Date): boolean {
          return leftOperand.getTime() !== rightOperand.getTime();
        }
      }(),
    },
    text: {
      text: STRINGS_NOT_EQUAL_HANDLER,
      longText: STRINGS_NOT_EQUAL_HANDLER,
      select: STRINGS_NOT_EQUAL_HANDLER,
    },
    longText: {
      text: STRINGS_NOT_EQUAL_HANDLER,
      longText: STRINGS_NOT_EQUAL_HANDLER,
      select: STRINGS_NOT_EQUAL_HANDLER,
    },
    select: {
      text: STRINGS_NOT_EQUAL_HANDLER,
      longText: STRINGS_NOT_EQUAL_HANDLER,
      select: STRINGS_NOT_EQUAL_HANDLER,
    },
    checkbox: {
      checkbox: new class extends BooleanOperationHnadler {
        apply(leftOperand: boolean, rightOperand: boolean): boolean {
          return leftOperand !== rightOperand;
        }
      }(),
    },
  },
  '>': {
    number: {
      number: new class extends NumericalComparisonHandler {
        compare(leftOperand: number, rightOperand: number): boolean {
          return leftOperand > rightOperand;
        }
      }(),
    },
    date: {
      date: new class extends DateComparisonHandler {
        compare(leftOperand: Date, rightOperand: Date): boolean {
          return leftOperand.getTime() > rightOperand.getTime();
        }
      }(),
    },
  },
  '<': {
    number: {
      number: new class extends NumericalComparisonHandler {
        compare(leftOperand: number, rightOperand: number): boolean {
          return leftOperand < rightOperand;
        }
      }(),
    },
    date: {
      date: new class extends DateComparisonHandler {
        compare(leftOperand: Date, rightOperand: Date): boolean {
          return leftOperand.getTime() < rightOperand.getTime();
        }
      }(),
    },
  },
  '>=': {
    number: {
      number: new class extends NumericalComparisonHandler {
        compare(leftOperand: number, rightOperand: number): boolean {
          return leftOperand.toFixed(10) === rightOperand.toFixed(10) || leftOperand >= rightOperand;
        }
      }(),
    },
    date: {
      date: new class extends DateComparisonHandler {
        compare(leftOperand: Date, rightOperand: Date): boolean {
          return leftOperand.getTime() >= rightOperand.getTime();
        }
      }(),
    },
  },
  '<=': {
    number: {
      number: new class extends NumericalComparisonHandler {
        compare(leftOperand: number, rightOperand: number): boolean {
          return leftOperand.toFixed(10) === rightOperand.toFixed(10) || leftOperand <= rightOperand;
        }
      }(),
    },
    date: {
      date: new class extends DateComparisonHandler {
        compare(leftOperand: Date, rightOperand: Date): boolean {
          return leftOperand.getTime() <= rightOperand.getTime();
        }
      }(),
    }
  },
  AND: {
    checkbox: {
      checkbox: new class extends BooleanOperationHnadler {
        apply(leftOperand: boolean, rightOperand: boolean): boolean {
          return leftOperand && rightOperand;
        }
      }(),
    },
  },
  OR: {
    checkbox: {
      checkbox: new class extends BooleanOperationHnadler {
        apply(leftOperand: boolean, rightOperand: boolean): boolean {
          return leftOperand || rightOperand;
        }
      }(),
    },
  },
  '*': {
    number: {
      number: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand * rightOperand;
        }
      }(AttributeType.NUMBER),
      percent: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand * (rightOperand / 100);
        }
      }(AttributeType.NUMBER)
    },
    percent: {
      percent: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand * (rightOperand / 100);
        }
      }(AttributeType.PERCENT),
    }
  },
  '/': {
    number: {
      number: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand / rightOperand;
        }
      }(AttributeType.NUMBER),
      percent: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand / (rightOperand / 100);
        }
      }(AttributeType.NUMBER)
    },
    percent: {
      percent: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand / (rightOperand / 100);
        }
      }(AttributeType.PERCENT)
    }
  },
  '%': {
    number: {
      number: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return leftOperand % rightOperand;
        }
      }(AttributeType.NUMBER)
    }
  },
  '^': {
    number: {
      number: new class extends NumericalCalculationHandler {
        calculate(leftOperand: number, rightOperand: number): number {
          return Math.pow(leftOperand, rightOperand);
        }
      }(AttributeType.NUMBER)
    }
  },
};

const NO_OP_CAST = (value: string): string => value;
const STRING_CAST = (value: string): string => value || '';

export const castHandlerTable:
Partial<Record<AttributeType, Partial<Record<AttributeType, (value: string) => string>>>> = {
  number: {
    number: NO_OP_CAST,
    text: STRING_CAST,
    longText: STRING_CAST,
    percent: (value: string) => String(Number.parseFloat(value) * 100),
  },
  percent: {
    percent: NO_OP_CAST,
    number: (value: string) => String(Number.parseFloat(value) / 100),
    text: (value: string) => value ? value + '%' : '',
    longText: (value: string) => value ? value + '%' : '',
  },
  checkbox: {
    text: (value: string) => String(Boolean(value)),
    longText: (value: string) => String(Boolean(value)),
  },
  text: {
    text: STRING_CAST,
    longText: STRING_CAST,
  },
  longText: {
    text: STRING_CAST,
    longText: STRING_CAST,
  },
  select: {
    text: NO_OP_CAST,
    longText: NO_OP_CAST,
  }
};

export const functionHandlerTable: Record<FunctionName, (...args: FormulaResult[]) => string> = {
  [FunctionName.MIN]: (...args: FormulaResult[]) => {
    const argValues = args.map(arg => Number.parseFloat(arg.value));
    return `${Math.min(...argValues)}`;
  },
  [FunctionName.MAX]: (...args: FormulaResult[]) => {
    const argValues = args.map(arg => Number.parseFloat(arg.value));
    return `${Math.max(...argValues)}`;
  },
  [FunctionName.IF]: (arg1: FormulaResult, arg2: FormulaResult, arg3: FormulaResult) => {
    if (arg1.value) {
      return arg2.value;
    }
    return arg3.value;
  },
  [FunctionName.AVERAGE]: (...args: FormulaResult[]) => {
    const sum = args.reduce((total: number, arg: FormulaResult) => Number(arg.value) + total, 0);
    return `${sum / args.length}`;
  },
  [FunctionName.CEILING]: (arg: FormulaResult) => {
    return `${Math.ceil(Number(arg.value))}`;
  },
  [FunctionName.FLOOR]: (arg: FormulaResult) => {
    return `${Math.floor(Number(arg.value))}`;
  },
  [FunctionName.TRIM]: (arg: FormulaResult) => {
    return arg.value.trim();
  },
  [FunctionName.UPPER]: (arg: FormulaResult) => {
    return arg.value.toUpperCase();
  },
  [FunctionName.LOWER]: (arg: FormulaResult) => {
    return arg.value.toLowerCase();
  },
  [FunctionName.PROPER]: (arg: FormulaResult) => {
    // This doesn't work well for names like McDonald, but it matches how Sheets works.
    return arg.value.split(/(\s)/)
      .map((w) => w.length ? w[0].toUpperCase() + w.substring(1).toLowerCase() : w)
      .join('');
  },
  [FunctionName.SUBSTITUTE]: (toSearch: FormulaResult, toReplace: FormulaResult, replaceWith: FormulaResult,
    ...occurrencesToReplace: FormulaResult[]) => {
    const occurrenceNumbersToReplace: number[] = occurrencesToReplace.map(
      (r: FormulaResult) => Number(r.value));
    let currentOccurrenceNumber = 0;
    return toSearch.value.replaceAll(toReplace.value, (match: string) => {
      ++currentOccurrenceNumber;
      if (occurrenceNumbersToReplace.length === 0 || occurrenceNumbersToReplace.indexOf(currentOccurrenceNumber) > -1) {
        return replaceWith.value;
      } else {
        return match;
      }
    });
  },
  [FunctionName.NOT]: (arg: FormulaResult) => {
    return arg.value === 'true' ? '' : 'true';
  },
  [FunctionName.DATEADD]: (dateString: FormulaResult, toAdd: FormulaResult, timeUnit: FormulaResult) => {
    const date = new Date(dateString.value);
    let amount = Number.parseFloat(toAdd.value);

    // JS Date does this floor/ceil anyway, but let's do it here, otherwise we get surprising results when
    // adding weeks. For consistent behaviour, if the function says to add 5.9 weeks, we'd expect that to
    // actually add 5 weeks, or 25 days. But as we're multiplying by 7 first, we'd actually add Math.floor(5.9 * 7) ==
    // 41 days.
    if (amount < 1) {
      amount = Math.ceil(amount);
    } else {
      amount = Math.floor(amount);
    }

    switch (timeUnit.value.toUpperCase()) {
      case TimeUnit.HOURS:
        date.setHours(date.getHours() + amount);
        break;
      case TimeUnit.DAYS:
        date.setDate(date.getDate() + amount);
        break;
      case TimeUnit.WEEKS:
        date.setDate(date.getDate() + (amount * 7));
        break;
      case TimeUnit.MONTHS:
        date.setMonth(date.getMonth() + amount);
        break;
      case TimeUnit.YEARS:
        date.setFullYear(date.getFullYear() + amount);
        break;
    }
    return date.toISOString();
  },
  [FunctionName.DATEDIFF]: (fromDateString: FormulaResult, toDateString: FormulaResult, timeUnit: FormulaResult) => {
    const fromDate = new Date(fromDateString.value);
    const toDate = new Date(toDateString.value);
    if (!fromDate || fromDate?.toString() === 'Invalid Date') {
      throw new Error('Missing from date');
    }

    if (!toDate || toDate?.toString() === 'Invalid Date') {
      throw new Error('Missing to date');
    }

    let diff;
    switch (timeUnit.value.toUpperCase()) {
      case TimeUnit.HOURS:
        diff = differenceInHours(toDate, fromDate);
        break;
      case TimeUnit.DAYS:
        diff = differenceInDays(toDate, fromDate);
        break;
      case TimeUnit.WEEKS:
        diff = differenceInWeeks(toDate, fromDate);
        break;
      case TimeUnit.MONTHS:
        diff = differenceInMonths(toDate, fromDate);
        break;
      case TimeUnit.YEARS:
        diff = differenceInYears(toDate, fromDate);
        break;
    }

    return diff?.toString() ?? '';
  },
  [FunctionName.TODAY]: () => {
    const currentDate = new Date();
    return currentDate.toString();
  },
};

abstract class FunctionArgValidator {
  public functionName: string;

  constructor(functionName: string) {
    this.functionName = functionName;
  }

  public abstract getReturnType (...argReturnTypes: AttributeType[]): AttributeType;
}

export class NoArgTypeValidator extends FunctionArgValidator {
  private readonly returnType: AttributeType;

  constructor(functionName: string, returnType: AttributeType) {
    super(functionName);
    this.returnType = returnType;
  }

  getReturnType(...argTypes: AttributeType[]): AttributeType {
    if (argTypes.length > 0) {
      throw new Error(`${this.functionName} does not take any arguments`);
    }

    return this.returnType;
  }
}

export class SameArgTypeValidator extends FunctionArgValidator {
  private validReturnTypes: AttributeType[];

  constructor(functionName: string, validReturnTypes: AttributeType[]) {
    super(functionName);
    this.validReturnTypes = validReturnTypes;
  }

  public getReturnType(...argTypes: AttributeType[]): AttributeType {
    // Check that all args are the same type, and match one of the types in the valid array
    const returnType = argTypes[0];
    if (!this.validReturnTypes.includes(returnType)) {
      throw new Error(`Invalid argument type for ${this.functionName}. Expected
      ${this.validReturnTypes.join(' or ')} but found ${returnType}`);
    }

    argTypes.forEach((argTypes) => {
      if (argTypes !== returnType) {
        throw new Error('Incompatible argument types for ' + this.functionName + ' function: ' + returnType +
          ' and ' + argTypes);
      }
    });

    return returnType;
  }
}

export class IfArgTypeValidator extends FunctionArgValidator {
  public getReturnType(...argTypes: AttributeType[]): AttributeType {
    // IF functions should always have 3 args
    if (argTypes.length !== 3) {
      throw new Error('Expected 3 arguments for ' + this.functionName +
        ' function but found ' + argTypes.length);
    }
    // The first arg must be a boolean i.e. a checkbox
    if (argTypes[0] !== AttributeType.CHECKBOX) {
      throw new Error('Invalid first argument type found for ' + this.functionName +
        ' function. Expected checkbox but got ' + argTypes[0]);
    }
    // The last 2 args are the results and must be of the same time
    if (argTypes[1] !== argTypes[2]) {
      throw new Error('Incompatible argument types for ' + this.functionName +
        ' function: ' + argTypes[1] + ' and ' + argTypes[2]);
    }
    // We can return either the second or third arg here as they are the same type
    return argTypes[1];
  }
}

export class SingleArgTypeValidator extends FunctionArgValidator {
  private validReturnTypes: AttributeType[];

  constructor(functionName: string, validReturnTypes: AttributeType[]) {
    super(functionName);
    this.validReturnTypes = validReturnTypes;
  }

  public getReturnType(...argTypes: AttributeType[]): AttributeType {
    // Should be exactly one arg and match one of the valid return types
    if (argTypes.length !== 1) {
      throw new Error('Expected 1 argument for ' + this.functionName + ' function but found ' + argTypes.length);
    }

    const returnType = argTypes[0];
    if (!this.validReturnTypes.includes(returnType)) {
      throw new Error('Invalid argument type for ' + this.functionName + ' function: ' + returnType);
    }
    return returnType;
  }
}

export class SubstituteArgTypeValidator extends FunctionArgValidator {
  public getReturnType(...argTypes: AttributeType[]): AttributeType {
    // Should be at least 3 args
    if (argTypes.length < 3) {
      throw new Error('Expected 3 or 4 arguments for ' + this.functionName +
        ' function but found ' + argTypes.length);
    }

    // The first three args must either be TEXT or LONG_TEXT
    const validReturnTypes = [AttributeType.TEXT, AttributeType.LONG_TEXT];
    argTypes.slice(0, 3).forEach((returnType, idx) => {
      if (!validReturnTypes.includes(returnType)) {
        throw new Error(`Invalid argument type for ${this.functionName} at position ${idx}. Expected text but found
        ${returnType}`);
      }
      return returnType;
    });

    // If there are more than 4 args, the remaining must be of type number
    argTypes.slice(3).forEach((argType) => {
      if (argType !== AttributeType.NUMBER) {
        throw new Error('Invalid argument type for ' + this.functionName +
          ' function. Expected number but found ' + argTypes[3]);
      }
    });

    // Use the first arg as the return type
    return argTypes[0];
  }
}

export class DateAddTypeValidator extends FunctionArgValidator {
  public getReturnType(...argTypes: AttributeType[]): AttributeType {
    // Should be exactly 3 args
    if (argTypes.length !== 3) {
      throw new Error('Expected 3 arguments for ' + this.functionName + ' function but found ' + argTypes.length);
    }

    // The first arg must be a date attribute and will also be the return type
    const returnType = argTypes[0];
    const validReturnTypes = [AttributeType.DATE, AttributeType.CREATED_DATE, AttributeType.LAST_UPDATED];
    if (!validReturnTypes.includes(returnType)) {
      throw new Error('Invalid first argument type for ' + this.functionName + ' function: ' + returnType);
    }

    // The second arg must be a number
    if (argTypes[1] !== AttributeType.NUMBER) {
      throw new Error('Invalid second argument for ' + this.functionName +
        ' function. Expected number but found ' + argTypes[3]);
    }

    // The third arg must be a time unit i.e. days, weeks, etc
    if (argTypes[2] !== AttributeType.TIME_UNIT) {
      throw new Error('Invalid third argument for ' + this.functionName +
        ' function. Expected time unit but found ' + argTypes[2]);
    }

    return returnType;
  }
}

export class DateDiffTypeValidator extends FunctionArgValidator {
  public getReturnType(...argTypes: AttributeType[]): AttributeType {
    // Should be exactly 3 args
    if (argTypes.length !== 3) {
      throw new Error('Expected 3 arguments for ' + this.functionName + ' function but found ' + argTypes.length);
    }

    // First and second args must be dates
    const dateTypes = [AttributeType.DATE, AttributeType.CREATED_DATE, AttributeType.LAST_UPDATED];
    argTypes.slice(0, 2).forEach((argType, idx) => {
      if (!dateTypes.includes(argType)) {
        throw new Error(`Invalid argument for ${this.functionName} at position ${idx}.
        Expected date but found ${argType}`);
      }
    });

    // The first arg must be a time unit i.e. days, weeks
    if (argTypes[2] !== AttributeType.TIME_UNIT) {
      throw new Error('Invalid argument for ' + this.functionName +
        ' function. Expected date time unit but found ' + argTypes[3]);
    }

    // Return number
    return AttributeType.NUMBER;
  }
}

export const functionReturnTypeHandler: Partial<Record<FunctionName, FunctionArgValidator>> = {
  [FunctionName.MIN]: new SameArgTypeValidator(FunctionName.MIN,
    [AttributeType.DATE, AttributeType.PERCENT, AttributeType.NUMBER]),
  [FunctionName.MAX]: new SameArgTypeValidator(FunctionName.MAX,
    [AttributeType.DATE, AttributeType.PERCENT, AttributeType.NUMBER]),
  [FunctionName.IF]: new IfArgTypeValidator(FunctionName.IF),
  [FunctionName.AVERAGE]: new SameArgTypeValidator(FunctionName.AVERAGE,
    [AttributeType.PERCENT, AttributeType.NUMBER]),
  [FunctionName.CEILING]: new SingleArgTypeValidator(FunctionName.CEILING,
    [AttributeType.PERCENT, AttributeType.NUMBER]),
  [FunctionName.FLOOR]: new SingleArgTypeValidator(FunctionName.FLOOR,
    [AttributeType.PERCENT, AttributeType.NUMBER]),
  [FunctionName.TRIM]: new SingleArgTypeValidator(FunctionName.TRIM,
    [AttributeType.TEXT, AttributeType.LONG_TEXT]),
  [FunctionName.UPPER]: new SingleArgTypeValidator(FunctionName.UPPER,
    [AttributeType.TEXT, AttributeType.LONG_TEXT]),
  [FunctionName.LOWER]: new SingleArgTypeValidator(FunctionName.LOWER,
    [AttributeType.TEXT, AttributeType.LONG_TEXT]),
  [FunctionName.PROPER]: new SingleArgTypeValidator(FunctionName.PROPER,
    [AttributeType.TEXT, AttributeType.LONG_TEXT]),
  [FunctionName.SUBSTITUTE]: new SubstituteArgTypeValidator(FunctionName.SUBSTITUTE),
  [FunctionName.NOT]: new SingleArgTypeValidator(FunctionName.NOT, [AttributeType.CHECKBOX]),
  [FunctionName.DATEADD]: new DateAddTypeValidator(FunctionName.DATEADD),
  [FunctionName.DATEDIFF]: new DateDiffTypeValidator(FunctionName.DATEDIFF),
  [FunctionName.TODAY]: new NoArgTypeValidator(FunctionName.TODAY, AttributeType.DATE),
};

export type FunctionHint = { name: FunctionName, hint: string, argsExample?: string[]; }

export const functionHints: Record<FunctionName, FunctionHint> = {
  [FunctionName.AVERAGE]: {
    name: FunctionName.AVERAGE,
    hint: 'Find the average of one or more number or percent values',
    argsExample: ['value1', '[value2, …]']
  },
  [FunctionName.CEILING]: {
    name: FunctionName.CEILING,
    hint: 'Round up a number or percent value',
    argsExample: ['value']
  },
  [FunctionName.DATEADD]: {
    name: FunctionName.DATEADD,
    hint: 'Add a period to a date',
    argsExample: ['from_date', 'value', 'time_unit']
  },
  [FunctionName.DATEDIFF]: {
    name: FunctionName.DATEDIFF,
    hint: 'Find the difference between two dates',
    argsExample: ['from_date', 'to_date', 'time_unit']
  },
  [FunctionName.FLOOR]: {
    name: FunctionName.FLOOR,
    hint: 'Round down a number or percent value',
    argsExample: ['value']
  },
  [FunctionName.IF]: {
    name: FunctionName.IF,
    hint: 'Return one of two values, depending on logical expression',
    argsExample: ['logical_expression', 'value_if_true', 'value_if_false']
  },
  [FunctionName.LOWER]: {
    name: FunctionName.LOWER,
    hint: 'Convert text to lower case',
    argsExample: ['value']
  },
  [FunctionName.MAX]: {
    name: FunctionName.MAX,
    hint: 'Find the maximum of one or more number, percent, or date values',
    argsExample: ['value1', '[value2, …]']
  },
  [FunctionName.MIN]: {
    name: FunctionName.MIN,
    hint: 'Find the minimum of one or more number, percent, or date values',
    argsExample: ['value1', '[value2, …]']
  },
  [FunctionName.PROPER]: {
    name: FunctionName.PROPER,
    hint: 'Capitalise each word in some text',
    argsExample: ['text_value']
  },
  [FunctionName.SUBSTITUTE]: {
    name: FunctionName.SUBSTITUTE,
    hint: 'Replace some or all occurrences of existing text',
    argsExample: ['text_to_search', 'search_phrase', 'replacement_text', '[occurrence_number, …]']
  },
  [FunctionName.TRIM]: {
    name: FunctionName.TRIM,
    hint: 'Remove whitespace from start and end of text',
    argsExample: ['text_value']
  },
  [FunctionName.UPPER]: {
    name: FunctionName.UPPER,
    hint: 'Convert text to upper case',
    argsExample: ['text_value']
  },
  [FunctionName.NOT]: {
    name: FunctionName.NOT,
    hint: 'Return opposite of logical value',
    argsExample: ['logical_expression']
  },
  [FunctionName.TODAY]: {
    name: FunctionName.TODAY,
    hint: 'Return the current date',
    argsExample: [],
  }
};
