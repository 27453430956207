function createListenersForWrapper<T extends EventTarget, R>(
  component: Vue, unwrapValue: (target: T) => R): Record<string, (event: Event) => void> {
  return {
    focus: () => { component.$emit('focus'); },
    blur: () => { component.$emit('blur'); },
    input: (event: Event) => { component.$emit('input', unwrapValue(event.target as T)); },
    change: (event: Event) => { component.$emit('change', unwrapValue(event.target as T)); },
  };
}

function randomElementId(prefix: string): () => string {
  return () => {
    let id = prefix || 'element';
    id += (Math.random()).toFixed(10).substring(2);
    return id;
  };
}

export { createListenersForWrapper, randomElementId };
