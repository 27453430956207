import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { ApnsFullEndpointProperties } from '@/data/datatypes/pushmessaging/pushmessaging.types';
import { NotificationPermissionStatus } from '@/data/pushmessaging/NotificationPermissionStatus';
import PushMessaging from '@/data/pushmessaging/PushMessaging';
import ServerData from '@/data/ServerData';
import { ApnsDetails } from '@/stores/PushNotification.types';

export const usePushNotificationStore = defineStore('PushNotification', () => {
  /**
   * Local storage key for the flag indicating whether a user previously dismissed the 'enable notifications'
   * banner. We use this so we can stop bugging the user, and instead display an 'enable notifications' button in
   * their user settings.
   */
  const NOTIFICATIONS_DISMISSED_KEY: string = 'permissionsBannerDismissed';

  /**
   * This is the original key for the website Push ID and is kept to allow backward compatibility with browser windows
   * still running the old code (Safari seems prone to doing this).
   */
  const APNS_PUSH_ID_OLDKEY: string = 'cafexApnsWebsitePushId';

  /**
   * The website Push ID needs to be configured on the server as a system setting, but available to the client (for
   * making APNS  requests). We don't want to be grabbing it from the server all the time, so keep it in local storage -
   * the Push ID is unique per application server.
   *
   * This is the original key for this and is kept to allow backward compatibility with browser windows
   * still running the old code (Safari seems prone to doing this).
   */
  const APNS_PUSH_ID_KEY: string = 'cfxApnsWebPushIdJson';

  /**
   * How long is the ApnsID considered valid before checking if it has changed.
   */
  const APNS_ID_TIMEOUT: number = 24 * 60 * 60 * 1000; // 1 day

  const pushNotificationState: Ref<NotificationPermissionStatus | null> = ref(null);
  /**
   * Indicates that the Notifications are still denied even after the user tried to enable them.
   * This happens in particular in Safari where, once the user has denied, it will never ask again.
   *
   * This means the user needs to go into the safari settings and change the state there. And
   * to do that, we need a special error message, that this flag triggers...
   */
  const pushNotificationStillDenied: Ref<boolean> = ref(false);
  const pushBannerDismissed: Ref<boolean> = ref(false);
  const apnsDetails: Ref<ApnsDetails | null> = ref(null);

  function setPushNotificationStillDenied(newVal: boolean): void {
    pushNotificationStillDenied.value = newVal;
  }

  function setBannerDismissed(newVal: boolean): void {
    pushBannerDismissed.value = newVal;
    localStorage.setItem(NOTIFICATIONS_DISMISSED_KEY, JSON.stringify(newVal));
  }

  function setNotificationState(newVal: NotificationPermissionStatus): void {
    pushNotificationState.value = newVal;
  }

  function setApnsDetails(newVal: ApnsFullEndpointProperties | null): void {
    if (newVal) {
      apnsDetails.value = { details: newVal, expires: new Date().getTime() + APNS_ID_TIMEOUT };
      if ((newVal?.current?.pushId?.trim().length ?? 0) === 0) {
        localStorage.removeItem(APNS_PUSH_ID_KEY);
      } else {
        localStorage.setItem(APNS_PUSH_ID_KEY, JSON.stringify(apnsDetails.value));
      }
      // Also update the old field to maintain compatibility with browser windows
      // still running the old code (Safari is prone to this).
      if ((newVal?.previous?.pushId?.trim().length ?? 0) === 0) {
        localStorage.removeItem(APNS_PUSH_ID_OLDKEY);
      } else {
        localStorage.setItem(APNS_PUSH_ID_OLDKEY, apnsDetails.value.details.previous.pushId);
      }
    } else {
      apnsDetails.value = null;
      localStorage.removeItem(APNS_PUSH_ID_KEY);
      localStorage.removeItem(APNS_PUSH_ID_OLDKEY);
    }
  }

  async function getApnsDetails(): Promise<ApnsFullEndpointProperties | null> {
    const now = new Date().getTime();
    const pushIdInfo = apnsDetails.value;

    // Push ID isn't stored or has expired; try to get it from the app server
    if (pushIdInfo === null || (pushIdInfo.expires <= now)) {
    // In case it's been done in another tab, regrab from localStorage (keeps things simple)
      const pushIdInfoJSON = localStorage.getItem(APNS_PUSH_ID_KEY) ?? null;
      // But invalidate it after a day, so that we can pick up a change in ID...
      let apnsEndpoint: ApnsFullEndpointProperties | null = null;
      if (pushIdInfoJSON) {
        try {
          const pushIdInfo = JSON.parse(pushIdInfoJSON);
          if (pushIdInfo.expires && pushIdInfo.expires > new Date().getTime() && pushIdInfo.details) {
            apnsEndpoint = pushIdInfo.details ?? null;
          }
        } catch (error) {
          // Unexpected format => needs a refresh...
        }
      }

      let srvError: boolean = false;
      if (!apnsEndpoint) {
        try {
          apnsEndpoint = await ServerData.getApnsInfo();
        } catch (err) {
          srvError = true;
          // logError('Could not get Push ID from CafeX Server');
        }
      }
      if (!srvError) {
        setApnsDetails(apnsEndpoint);
      }
    }
    return apnsDetails.value?.details ?? null;
  }

  async function clearPushNotificationStillDenied(): Promise<void> {
    pushNotificationStillDenied.value = false;
  }

  async function loadSettings(): Promise<void> {
    pushNotificationState.value = await PushMessaging.getNotificationPermissionState();
    pushBannerDismissed.value = localStorage.getItem(NOTIFICATIONS_DISMISSED_KEY) === 'true';
    apnsDetails.value = null;

    // TODO: Guard against it being denied
    PushMessaging.register();
  }

  async function requestPushNotificationPermission(): Promise<void> {
    await PushMessaging.requestPushNotificationPermission();
    pushNotificationState.value = await PushMessaging.getNotificationPermissionState();
  }

  async function unregisterPushNotifications(): Promise<void> {
    await PushMessaging.unregister();
    pushNotificationState.value = await PushMessaging.getNotificationPermissionState();
  }

  return {
    pushNotificationState,
    pushNotificationStillDenied,
    pushBannerDismissed,
    setPushNotificationStillDenied,
    setBannerDismissed,
    setNotificationState,
    getApnsDetails,
    clearPushNotificationStillDenied,
    loadSettings,
    requestPushNotificationPermission,
    unregisterPushNotifications,
  };
});
