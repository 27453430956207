import { computed, ComputedRef, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

import { AppName } from '@/data/Branding';
import { TrackType } from '@/data/datatypes/Track';
import { getChatName } from '@/data/helpers/ChatHelper';
import { useKnowledgeBaseStore } from '@/stores/KnowledgeBase';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

const tracksStore = useTracksStore();
const userStore = useUserStore();
const knowledgeBaseStore = useKnowledgeBaseStore();

export function usePageTitle() {
  const route = useRoute();

  const kbNotificationCount: ComputedRef<number> = computed(() => {
    return knowledgeBaseStore.kbNotificationCount;
  });

  const currentUserId: ComputedRef<string> = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return userStore.currentUserId!;
  });

  const pageTitle: ComputedRef<string> = computed(() => {
    let title: string | undefined;
    if (tracksStore.activeTrack) {
      if (tracksStore.activeTrack.type === TrackType.PRIVATE_CHAT) {
        return getChatName(tracksStore.activeTrack, currentUserId.value, userStore.displayDetailsForUsers);
      } else {
        title = tracksStore.activeTrack.title;
      }
    } else {
      title = route.meta?.title;
      if (!title && route.matched) {
        for (let i = route.matched.length - 1; i >= 0 && !title; --i) {
          if (route.matched[i].meta.title) {
            title = route.matched[i].meta.title;
          }
        }
      }
    }
    return title || AppName;
  });

  const notificationCount: ComputedRef<number> = computed(() => {
    return (tracksStore.trackNotifications ? tracksStore.trackNotifications.length : 0) + kbNotificationCount.value;
  });

  function updatePageTitle(): void {
    let toSet: string = pageTitle.value;
    if (notificationCount.value) {
      toSet = `(${notificationCount.value}) ${toSet}`;
    }
    document.title = toSet;
  }

  watch(() => pageTitle.value, () => {
    updatePageTitle();
  }, { immediate: true });

  watch(() => notificationCount.value, () => {
    updatePageTitle();
  }, { immediate: true });
}
