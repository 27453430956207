import { Track } from '@/data/datatypes/Track';
import { TrackEntry } from '@/data/datatypes/TrackEntry';
import { compare, removeDiacritics } from '@/data/helpers/SearchHelper';

export enum TrackSortOrder {
  Date,
  Name,
  Author,
  LastChat,
}

export enum TrackFilterType {
  None,
  Owned,
  Shared,
}

export function concatLabels(track: Track): string {
  let labels: string = '';
  if (track.trackLabels) {
    for (const label of track.trackLabels) {
      // TODO: This doesn't work when the label is an option. The value is the UUID pointing to the label option.
      labels += label.value;
      labels += ' ';
    }
  }
  return labels;
}

export const sortTracks = (tracks: Track[], order: TrackSortOrder = TrackSortOrder.Name, searchTerm?: string,
  isAscending: boolean = true): Track[] => {
  let filteredTracks: Track[];
  if (order === TrackSortOrder.Date) {
    filteredTracks = tracks.slice(0);
    filteredTracks.sort((first: Track, second: Track) => {
      return isAscending ? compare(second.created, first.created) : compare(first.created, second.created);
    });
  } else if (order === TrackSortOrder.LastChat) {
    filteredTracks = tracks.slice(0);
    filteredTracks.sort((first: Track, second: Track) => {
      const firstDate: number = first.lastChatMessage ? first.lastChatMessage.date : 0;
      const secondDate: number = second.lastChatMessage ? second.lastChatMessage.date : 0;
      return isAscending ? compare(secondDate, firstDate) : compare(firstDate, secondDate);
    });
  } else {
    filteredTracks = tracks.slice(0);
    filteredTracks.sort((first, second) => {
      let comparison: number = 0;
      if (order === TrackSortOrder.Name) {
        if (!first.title) {
          comparison = 1;
        } else if (!second.title) {
          comparison = -1;
        } else {
          comparison = compare(first.title.toLowerCase(), second.title.toLowerCase());
        }
      } else if (order === TrackSortOrder.Author) {
        comparison = compare(first.owner, second.owner);
      }

      if (comparison === 0) {
        comparison = compare(first.id, second.id);
      }
      return isAscending ? comparison : comparison * -1;
    });
  }
  if (searchTerm) {
    const filterFor = searchTerm;
    const filtered: Track[] = filteredTracks.filter((track: Track) => {
      const combinedSearchContent =
        `${track.title || ''} ${track.subtitle || ''} ${track.summary || ''} ${concatLabels(track)}`;
      return removeDiacritics(combinedSearchContent).toLowerCase()
        .indexOf(removeDiacritics(filterFor.toLowerCase())) >= 0;
    });
    filteredTracks = filtered;
  }
  return filteredTracks;
};

export const moveArrayElement = (array: unknown[], sourceIndex: number, targetIndex: number): void => {
  if (targetIndex >= array.length || targetIndex < 0 || sourceIndex >= array.length || sourceIndex < 0) {
    // We only support moving within the current array, not padding the ends or whatever it would mean to do these.
    return;
  }
  // Remove the element we're manipulating from the array and grab a reference to it.
  const elementToMove: unknown = array.splice(sourceIndex, 1)[0];
  // Re-insert it back into the array at the desired index
  array.splice(targetIndex, 0, elementToMove);
};

export const sortByCreated = (first: TrackEntry, second: TrackEntry): number => {
  let comparison: number = compare(first?.created, second?.created);
  // If they were created at the same time (or missing the data) then compare by ID for a deterministic ordering
  if (comparison === 0) {
    comparison = compare(first.id, second.id);
  }
  return comparison;
};

export const areArraysEqual = <T>(a: T[], b: T[], orderInsensitive: boolean = false,
  compareFn?: (a: T, b: T) => number, itemEqualityFn?: (a: T, b: T) => boolean): boolean => {
  if (a === b) {
    return true;
  }
  if (a === null || b === null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }
  if (orderInsensitive) {
    a.sort(compareFn);
    b.sort(compareFn);
  }
  for (let i = 0; i < a.length; i++) {
    if (itemEqualityFn) {
      if (!itemEqualityFn(a[i], b[i])) {
        return false;
      }
    } else {
      if (a[i] !== b[i]) {
        return false;
      }
    }
  }
  return true;
};

export const areObjectsEqual = <T>(a: T, b: T, ignoredKeys: string[] = []): boolean => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }

  // Check that they contain the same keys
  const aKeys: string[] = Object.keys(a);
  const bKeys: string[] = Object.keys(b);
  for (const aKey of aKeys) {
    if (!ignoredKeys.includes(aKey) && !bKeys.includes(aKey)) {
      return false;
    }
  }
  for (const bKey of bKeys) {
    if (!ignoredKeys.includes(bKey) && !aKeys.includes(bKey)) {
      return false;
    }
  }

  // Check that each property is equal
  let key: keyof typeof a;
  for (key in a) {
    if (ignoredKeys.includes(key)) {
      continue;
    }
    const valueA = a[key];
    const valueB = b[key];
    if (typeof valueA === 'object' && Array.isArray(valueA) &&
      !areArraysEqual(valueA, valueB as unknown as unknown[], false, undefined, areObjectsEqual)) {
      return false;
    } else if (typeof valueA === 'object') {
      const equal: boolean = areObjectsEqual(valueA, valueB);
      if (!equal) {
        return false;
      }
    } else if (valueA !== valueB) {
      return false;
    }
  }
  return true;
};
