import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { removeUserTokenFromLocalStorage } from '@/data/helpers/TokenHelper';
import DataWorker from '@/data/storage/DataWorker';
import { asRecord, setOrPatchObject } from '@/stores/StoreHelper';
import ConnectionState from '@/workers/datatypes/ConnectionState';

export const useConnectionStore = defineStore('Connection', () => {
  const connectionState: Ref<ConnectionState | undefined> = ref(undefined);
  const dbInvalidated: Ref<boolean> = ref(false);
  const connectionStateInitialised: Ref<boolean> = ref(false);

  // Implementation note: silentRefreshFailing is maintained by the window/tab Javascript.
  // That is why it is kept separate from the connectionState, which is being controlled
  // and refreshed by the ConnectionWorkerModule.
  const silentRefreshFailing: Ref<boolean> = ref(false);

  function setConnectionState(newVal: ConnectionState): void {
    setOrPatchObject(asRecord(connectionState), 'value', asRecord(newVal));
    connectionStateInitialised.value = true;
    if (newVal?.authFailure) {
      // If we don't remove this then it'll just get reloaded and used again
      removeUserTokenFromLocalStorage();
    }
  }

  function setDbInvalidated(): void {
    dbInvalidated.value = true;
    DataWorker.setInvalidated();
  }

  function setSilentRefreshFailing(newVal: boolean): void {
    silentRefreshFailing.value = newVal;
  }

  return {
    connectionState,
    dbInvalidated,
    silentRefreshFailing,
    connectionStateInitialised,
    setConnectionState,
    setDbInvalidated,
    setSilentRefreshFailing
  };
});
