import { ChatMessage, ChatMessageDetails } from '@/data/datatypes/chat/ChatMessage';
import { StagedFileDetails } from '@/data/datatypes/chat/StagedFile';

export class ChatMessageState {
  // Top level chat messages (i.e. not replies)
  orderedTrackChatMessages: ChatMessage[] = [];
  orderedCommentsByEntry: Record<string, ChatMessage[]> = {};
  // Map of parent chat message ID -> replies to that parent message
  orderedReplies: Record<string, ChatMessage[]> = {};
  trackChatMessagesInitialised: boolean = false;
  entriesWithCommentsInitalised: Record<string, boolean> = {};
  usersTyping: Record<string, number> = {};
  waitingForMessages: boolean = false;
  waitingForComments: boolean = false;
  selectedMessageId: string | null = null;
  fullChatMessageHistoryRetrieved: boolean = false;
  fullCommentHistoryRetrieved: boolean = false;
  chatMessageToView: { messageId: string | null; parentMessageId?: string } = { messageId: null };
  placeholderMessages: ChatMessage[] = [];
}

export interface SendChatMessagePayload {
  message: ChatMessageDetails | ChatMessage;
  stagedFileDetails?: StagedFileDetails;
  guestId?: string;
}

export interface ChatViewPermissions {
  activeTrackId: string | null;
  canViewActiveTrackChat: boolean | null;
  canViewMeetingChat: boolean | null;
}
