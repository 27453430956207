const TODAY_FORMAT = new Intl.DateTimeFormat(undefined, { hour: 'numeric', minute: 'numeric' });
const THIS_WEEK_FORMAT = new Intl.DateTimeFormat(undefined, { weekday: 'short' });
const THIS_WEEK_LONG_FORMAT = new Intl.DateTimeFormat(undefined, { weekday: 'long' });
const THIS_YEAR_FORMAT = new Intl.DateTimeFormat(undefined, { month: 'short', day: 'numeric' });
const THIS_YEAR_LONG_FORMAT = new Intl.DateTimeFormat(undefined, { month: 'long', day: 'numeric' });
const PREVIOUS_YEAR_FORMAT = new Intl.DateTimeFormat(undefined);
const FRIENDLY_FORMAT_DATE = new Intl.DateTimeFormat(undefined, { month: 'long', day: 'numeric', year: 'numeric' });
const FRIENDLY_FORMAT_TIME = TODAY_FORMAT;
const TIME_WITH_MILLIS = new Intl.DateTimeFormat(undefined,
  { hour: 'numeric', minute: 'numeric', second: 'numeric', fractionalSecondDigits: 3 });

function getRecentActivityDate(date: Date, shortenDate: boolean = true): string | null {
  if (!date) {
    return null;
  }
  const dateTime = date.getTime();
  const nowDate: Date = new Date();

  const isToday: boolean =
    nowDate.getDate() === date.getDate() &&
    nowDate.getMonth() === date.getMonth() &&
    nowDate.getFullYear() === date.getFullYear();

  let formattedDate;
  if (isToday) {
    formattedDate = TODAY_FORMAT.format(date);
  } else {
    const sixDaysAgo = new Date(nowDate.getTime());
    sixDaysAgo.setHours(0);
    sixDaysAgo.setMinutes(0);
    sixDaysAgo.setSeconds(0);
    sixDaysAgo.setMilliseconds(0);
    sixDaysAgo.setDate(sixDaysAgo.getDate() - 6);

    if (dateTime > sixDaysAgo.getTime()) {
      formattedDate = shortenDate ? THIS_WEEK_FORMAT.format(date) : THIS_WEEK_LONG_FORMAT.format(date);
    } else {
      const elevenMonthsAgo = new Date(nowDate.getTime());
      elevenMonthsAgo.setHours(0);
      elevenMonthsAgo.setMinutes(0);
      elevenMonthsAgo.setSeconds(0);
      elevenMonthsAgo.setMilliseconds(0);
      elevenMonthsAgo.setMonth(elevenMonthsAgo.getMonth() - 11);
      if (dateTime > elevenMonthsAgo.getTime()) {
        formattedDate = shortenDate ? THIS_YEAR_FORMAT.format(date) : THIS_YEAR_LONG_FORMAT.format(date);
      } else {
        formattedDate = PREVIOUS_YEAR_FORMAT.format(date);
      }
    }
  }

  return formattedDate;
}

function getHoursAndMinutes(milliseconds: number): string {
  const minutes: number = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours: number = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
}

function getFriendlyTime(date?: Date): string | null {
  if (!date) {
    return null;
  }
  return `${FRIENDLY_FORMAT_TIME.format(date)}`;
}

function getFriendlyDateTime(date?: Date): string | null {
  if (!date) {
    return null;
  }
  return `${FRIENDLY_FORMAT_DATE.format(date)} at ${FRIENDLY_FORMAT_TIME.format(date)}`;
}

function getLongDate(date?: Date): string | null {
  if (!date) {
    return null;
  }
  return FRIENDLY_FORMAT_DATE.format(date);
}

function getFullDateWithMillis(date?: Date): string | null {
  if (!date) {
    return null;
  }
  return `${FRIENDLY_FORMAT_DATE.format(date)} ${TIME_WITH_MILLIS.format(date)}`;
}

export {
  getFriendlyDateTime,
  getFriendlyTime,
  getFullDateWithMillis,
  getHoursAndMinutes,
  getLongDate,
  getRecentActivityDate,
};
